import { useParams } from "react-router-dom";

/**
 * SEARCH PARAMS
 */
export interface SearchParams {
  tj_id: string;
  stop_id: string;
}
export const useSearchParams = (): SearchParams => useParams<SearchParams>();

/**
 * PLANNING PARAMS
 */
export interface PlanningParams {
  tjal_id: string;
  stop_id: string;
}

export const usePlanningParams = (): PlanningParams => useParams<PlanningParams>();

/**
 * DELIVERED PARAMS
 */
export interface DeliveredParams {
  tjal_id: string;
  stop_id: string;
}

export const useDeliveredParams = (): DeliveredParams => useParams<DeliveredParams>();

/**
 * DAY ROUTE PARAMS
 */
export interface DayRouteParams {
  /**
   * yyyy-mm-dd
   */
  date: string;
  /**
   * user id
   */
  user_id: string;
}

export interface DayRouteStopParams extends DayRouteParams {
  /**
   * stop id
   */
  stop_id: string;
}

export const useDayRouteParams = (): DayRouteParams => useParams<DayRouteParams>();
export const useDayRouteStopParams = (): DayRouteStopParams => useParams<DayRouteStopParams>();

/**
 * INBOX PARAMS
 */
export interface InboxParams {
  tjal_id: string;
}

export const useInboxParams = (): InboxParams => useParams<InboxParams>();
