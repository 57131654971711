import { useKeyPress } from "@brenger/react";
import { Keyboard } from "@capacitor/keyboard";
import * as Sentry from "@sentry/react";
import { DeviceState, PermissionStatus } from "onesignal-cordova-plugin/types/Subscription";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as semver from "semver";
import {
  useAppInfo,
  useAuth,
  useDeviceId,
  useDeviceInfo,
  useGeolocation,
  UseGeolocation,
  useNativeAppVersion,
  useNativePlatform,
  useTranslation,
} from ".";

import { NativeAppMessagesKey } from "../AppMessages/NativeAppMessges";
import { Config } from "../config";
import { coreClient } from "../utils";

interface UseNativeAppEffects {
  /**
   * UseGeolocation is stateful and therefore this instance needs to be shared with App
   * so that said state can be drilled down to whatever component need to react™️ to it.
   */
  geolocation: UseGeolocation;
  /**
   * App messages related to the native app's version or geolocation.
   * This will get passed to the main App component and rendered as persistent banners.
   */
  appMessages: Set<NativeAppMessagesKey>;
}

/**
 * This hook is a collection of effects that should run when the app mounts on a native platform.
 * To this end, every effect within this hook must have `isNativePlatform` in its dependency list.
 */
export const useNativeAppEffects = (): UseNativeAppEffects => {
  const { t } = useTranslation();
  const deviceInfo = useDeviceInfo();
  const deviceId = useDeviceId();
  const appInfo = useAppInfo();
  const isNativePlatform = useNativePlatform();
  const auth = useAuth();
  const onEnter = useKeyPress("Enter");
  const history = useHistory();
  const geolocation = useGeolocation();
  const upsertInternalAttributes = useMutation(coreClient.users.upsertInternalAttributes);
  const nativeAppVersion = useNativeAppVersion({ version: appInfo?.version, platform: deviceInfo?.platform });
  const [deviceState, setDeviceState] = React.useState<DeviceState>();

  const appMessages = new Set<NativeAppMessagesKey>();

  /**
   * ----------
   * Configure keyboard
   * ----------
   */
  useEffect(() => {
    // Make sure iOS shows "done" on native select
    // For more, see https://github.com/ionic-team/capacitor/discussions/3478
    if (deviceInfo?.platform === "ios") {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }, [Keyboard, deviceInfo?.platform]);

  /**
   * This effect is to handle a special case in a mobile context. It's annoying for users who
   * are on mobile devices to tap outside the keyboard in order to blur the input they are focused on.
   * Rather, such users should be able to escape the input by simply hitting "Enter" on the mobile keyboard.
   * Therefore, whenever a mobile device user hits the enter button, let's be sure to blur the active element.
   */
  useEffect(() => {
    if (isNativePlatform && onEnter) {
      const activeElement = document.activeElement;
      if (activeElement) {
        // NOTE: optimistically type cast activeElement as an HTMLElement so we have access to blur method.
        // However, be aware that the active element can also be something else, and therefore we must
        // wrap in try/catch to avoid breaking the app in such cases. Catch can be a simple no-op, though.
        try {
          (activeElement as HTMLElement).blur();
        } catch (e) {
          // No-op. Nothing to do here. Carry on.
        }
      }
    }
  }, [isNativePlatform, onEnter]);

  /**
   * ----------
   * CONFIGURE GEOLOCATION
   * ----------
   */
  useEffect(() => {
    if (!isNativePlatform) return;

    // Ensure that all deps are present before calling "ready" method on geolocation plugin.
    if (!t) return;
    if (!auth.userId) return;
    if (!deviceId) return;
    if (!deviceInfo) return;

    // You must call #ready once and only once, each time your app is launched.
    geolocation.ready({
      deviceInfo,
      deviceId,
      userId: auth.userId,
      channelName: t((d) => d.app.background_geolocation.notification.channel),
      notificationTitle: t((d) => d.app.background_geolocation.notification.title),
      notificationText: t((d) => d.app.background_geolocation.notification.message),
      rationaleTitle: t((d) => d.app.background_geolocation.notification.rationale_title),
      rationaleText: t((d) => d.app.background_geolocation.notification.rationale_message),
    });
  }, [isNativePlatform, t, auth.userId, deviceInfo, deviceId]);

  /**
   * ----------
   * ROUTE CHANGE TODOS
   * ----------
   */
  useEffect(() => {
    if (!isNativePlatform) return;

    const onHistoryChange = (): void => {
      nativeAppVersion.checkVersion();
      geolocation.triggerPluginStateCallback();
      geolocation.triggerProviderStateCallback();
    };

    // Check everything on mount.
    onHistoryChange();

    // Then, on each subsequent history change event, check everything again.
    const removeHistoryListener = history.listen(onHistoryChange);

    return () => {
      removeHistoryListener();
    };
  }, [isNativePlatform, geolocation.triggerPluginStateCallback, geolocation.triggerProviderStateCallback]);

  /**
   * --------------------
   * CONFIG SENTRY
   * --------------------
   */
  useEffect(() => {
    if (!isNativePlatform) return;

    if (appInfo) {
      Sentry.configureScope((scope) => {
        if (appInfo?.version) scope.setTag("app_version", appInfo?.version);
        if (appInfo?.build) scope.setTag("app_build", appInfo.build);
      });
    }
  }, [isNativePlatform, appInfo]);

  /**
   * --------------------
   * SET INTERNAL USER ATTRIBUTES
   * --------------------
   */
  useEffect(
    () => {
      if (!isNativePlatform) return;

      if (auth.userId && deviceInfo && appInfo && deviceId) {
        // Hackery: coerce device info into an acceptable type for the api-client
        // Sept 2021 update: keep device_info so that JSON structure stays same format.
        // However, capacitor moved app-specific info to a new key from v2 => v3.
        // Therefore, re-map to old way here.
        const coercedDeviceInfo = deviceInfo as unknown as {
          [key: string]: string | number | boolean;
        };
        upsertInternalAttributes.mutate({
          id: auth.userId,
          internal_attributes: {
            device_info: {
              device_id: deviceId,
              appVersion: appInfo.version,
              ...coercedDeviceInfo,
            },
            background_geolocation: {
              isPluginEnabled: geolocation.isPluginEnabled,
              isDeviceEnabled: geolocation.isDeviceEnabled,
              isGpsOn: geolocation.isGpsOn,
              authorizationStatus: geolocation.authorizationStatus,
              accuracyAuthorization: geolocation.accuracyAuthorization,
            },
          },
        });
      }
    },
    // Whenever any of these states change, "phone home" to core to track it.
    [
      isNativePlatform,
      auth.userId,
      deviceId,
      appInfo,
      deviceInfo,
      geolocation.isPluginEnabled,
      geolocation.authorizationStatus,
      geolocation.isGpsOn,
      geolocation.accuracyAuthorization,
      geolocation.isDeviceEnabled,
    ]
  );

  useEffect(() => {
    if (isNativePlatform) {
      window.plugins?.OneSignal?.getDeviceState((state) => setDeviceState(state));
    }
  }, [isNativePlatform]);

  /**
   * Show push prompt message (MODAL) when its never prompted and user didn't see the message
   *  IOS PermissionStatus: 0 = NotDetermined, 1 = Denied, 2 = Authorized, 3 = Provisional, 4 = Ephemeral
   */
  const notDeterminedStatus: PermissionStatus = 0;
  useEffect(() => {
    if (!auth.isLoggedIn) return;

    if (deviceState) {
      if (deviceInfo?.platform == "ios" && deviceState.notificationPermissionStatus === notDeterminedStatus) {
        appMessages.add("app-push-prompt");
      }

      /**
       * For android devices running OS version 13.x prompting is required,
       * This check is maybe not needed since they set de permissions for lower versions to true
       * Only show push prompt: not granted notification permission and push is not disabled
       */
      const currentAppVersion = semver.coerce(deviceInfo?.osVersion || "");
      const minSupportedVersion = semver.coerce(13);
      if (
        deviceInfo?.platform === "android" &&
        currentAppVersion &&
        minSupportedVersion &&
        semver.gte(currentAppVersion, minSupportedVersion) &&
        !deviceState.hasNotificationPermission &&
        !deviceState.pushDisabled
      )
        appMessages.add("app-push-prompt");
    }
  }, [deviceState, deviceInfo, auth.isLoggedIn]);
  /**
   * --------------------
   * MESSAGES TO DISPLAY ACROSS THE TOP OF THE APP (BANNER STYLE)
   * These are related to checks against the device's settings / app permissions / app versions.
   * --------------------
   */

  let appStoreUrl = "";
  if (deviceInfo?.platform === "android") appStoreUrl = Config.GOOGLE_PLAY_STORE_URL;
  if (deviceInfo?.platform === "ios") appStoreUrl = Config.IOS_APP_STORE_URL;

  /**
   * Current app version is NOT supported.
   */
  if (isNativePlatform && !nativeAppVersion.isSupported && appStoreUrl) {
    appMessages.add("app-version-unstable");
  }

  /**
   * Current app version IS supported BUT there is an update available.
   */
  if (isNativePlatform && nativeAppVersion.isSupported && nativeAppVersion.isUpdateAvailable && appStoreUrl) {
    appMessages.add("app-version-new-update");
  }

  return { geolocation, appMessages };
};
