import { Locale, SupportedTimeZones } from "@brenger/api-client";
import axios from "axios";
import { template, templateSettings } from "lodash";
import { useCallback, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { Config } from "../config";
import { useAuth, useSelector } from "../hooks";
import { userSettingsActions } from "../store/settings";
import { CacheKey, CacheTTL, coreClient, localeNormalizer, routePlannerClient } from "../utils";

import { De } from "../../i18n/de";
import { En } from "../../i18n/en";
import { Nl } from "../../i18n/nl";

type TranslationData = En | De | Nl;

// The regex used to interpolate values into translation templates.
// Example: Driver has status {{user.level}}
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

// Set up an i18n axios instance so that we can specify interceptors (see below).
const i18nInstance = axios.create({
  baseURL: Config.I18N_URL,
});

// Set up a small client whose methods useQuery can invoke.
const i18nClient = {
  async retrieve(locale: Locale): Promise<TranslationData | undefined> {
    // TECH DEBT ALERT: This is a hack bc of the way we decided to do languages in core for now.
    // Eventually, we'll want to migrate to fetching languages by sending full locales to core when fetching translation files.
    const language = localeNormalizer.parseLanguage(locale);
    const { data } = await i18nInstance.get(language);
    // Only return the driver_v2 key so we don't cache too much.
    return data.driver_v2;
  },
};

type EnhanceTranslation = (trans: string, data?: { [key: string]: string | number | undefined | null }) => string;
type TranslationCallback = (d: TranslationData, e: EnhanceTranslation) => string;
interface UseTranslationConfig {
  locale: Locale;
  changeLocale(locale: Locale): void;
  timeZone: SupportedTimeZones;
}

export interface UseTranslation {
  /**
   * Accepts a callback function that returns a translated string.
   * The callback function provides two args: d (the typed translation data) and e (for templating).
   */
  t(cb: TranslationCallback): string;
  i18n: UseTranslationConfig;
}

export const useTranslation = (): UseTranslation => {
  const { locale, timeZone } = useSelector((state) => state.settings);

  const auth = useAuth();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  /**
   * FETCH UPDATED TRANSLATION METHOD
   */
  const translation = useQuery([CacheKey.TRANSLATIONS, locale], () => i18nClient.retrieve(locale), {
    enabled: !!locale,
    // Translations are critical, so retry twice before accepting failure.
    retry: 2,
    cacheTime: CacheTTL.XL * 1000,
  });

  // Be sure to update locale header for all subsequent requests whenver the locale changes!
  useEffect(() => {
    coreClient.users.setLocale(locale);
    routePlannerClient.geo.setLocale(locale);
  }, [locale]);

  /**
   * CHANGE (UPDATE) LOCALE FUNC
   */
  const changeLocale = (nextLocale: Locale): void => {
    /**
     * FIRST - clear cache and dispatch new locale to it gets persisted.
     * The translation files are quite large, so no point in keep in-memory when a new language is selected.
     * Therefore, remove old language prior to triggering fetch of new one.
     */
    queryClient.invalidateQueries([CacheKey.TRANSLATIONS]);
    // On success callback after the user's preferred language is successfully updated on the backend.
    // Use this callback to update the global state and communicate the language settings across app.
    dispatch(userSettingsActions.setLocale(nextLocale));

    /**
     * SECOND - update user's preferred_locale when possible
     */
    if (auth.user) {
      updateUser.mutate({
        userId: auth.userId as string,
        preferred_locale: nextLocale,
      });
    }
  };

  const updateUser = useMutation(coreClient.users.update);

  /**
   * Creates a compiled template function that can interpolate data properties.
   */
  const enhance = useCallback((trans: string, data?: { [key: string]: string | number | undefined | null }): string => {
    const compiled = template(trans);
    return compiled(data);
  }, []);

  /**
   * TRANSLATE FUNC
   */
  const translate = useCallback(
    (callback: TranslationCallback): string => {
      /**
       * Coerce translation.result to one of the two translations
       * Does not matter that it may be undefined, because wrapped in a try/catch!
       */
      try {
        return callback(translation.data as TranslationData, enhance);
      } catch (err) {
        return "";
      }
    },
    [!!translation.data, !!translation.error, translation.isLoading, enhance]
  );

  return {
    i18n: {
      locale,
      changeLocale,
      timeZone,
    },
    /**
     * Wrap translation in a safety func + try/catch to suppress object undefined error & offer sane defaults.
     */
    t: translate,
  };
};
