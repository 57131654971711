import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";

export const ScrollToTopOnMount: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { action } = useHistory();

  React.useEffect(() => {
    // When navigating "back", do not scroll to top.
    // We do this in order to maintain the user's earlier list position.
    if (action === "POP") return;

    window.scrollTo(0, 0);

    // Re-run this effect whenever the pathname changes or a new history action is registered.
  }, [action, pathname]);

  const child = children as React.ReactElement;
  return child;
};
