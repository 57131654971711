import { DayRouteActivity, DayRouteActivityType, GeoLocationDetails } from "@brenger/api-client";
import {
  Button,
  DateTimePeriodSelector,
  IconAdd,
  IconInfoCircle,
  InputNumberWithControls,
  Label,
  Select,
  Spacer,
  Strong,
  Textarea,
  useModalState,
} from "@brenger/react";
import cn from "classnames";
import setDateTime from "date-fns/set";
import React from "react";
import { InputTextWithGeoAutocomplete, Modal, Options } from "../../../components";
import { UseForm, useFormatDate, useTranslation } from "../../../hooks";
import { serializeAddress } from "../../../utils";

export const getDefaultDatetimePeriodForDayRouteActivity = (date: string): { start: Date; end: Date } => {
  return {
    start: setDateTime(new Date(date), { hours: 6, minutes: 0, seconds: 0 }),
    end: setDateTime(new Date(date), { hours: 22, minutes: 0, seconds: 0 }),
  };
};

export type DayRouteActivityFormType = {
  type: DayRouteActivityType;
  search: string;
  place?: GeoLocationDetails;
  service_time_seconds: number;
  start_time: string;
  end_time: string;
  // Should default to 0 - keep undefined as a possibility for defaults in the future (ie, automatic)
  index?: number;
  // Not required for a delivery activity that has a related pickup activity with this field already filled in.
  capacity_m3?: number;
  description?: string;
  temp_uuid?: string;
  temp_related_day_route_activity?: string;
};

interface Props {
  form: UseForm.Form<DayRouteActivityFormType>;
  activities: DayRouteActivity[];
}

export const DayRouteActivityForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  // NOTE: make time section active by default if initial state has a search address already.
  // This suggests that we are updating (rather than creating) a custom stop.
  const timeSection = useModalState(Boolean(props.form.getValue("search")));
  const timeInfoModal = useModalState();
  const formatHourMinute = useFormatDate("hour-minute");
  const formatTime = (date: Date): string => {
    return formatHourMinute(date.toISOString()) as string;
  };

  // When the controlling form has capacity_m3 in its initial state, then we know to show it.
  const showCapacityInput = Boolean(props.form.data.capacity_m3);

  // NOTE: show the description field when dealing with a CUSTOM_OTHER stop (not a normal pickup/delivery)
  const showDescriptionInput = props.form.data.type.value === "custom_other";

  const indexOptions = props.activities
    // NOTE: no use in showing break here - drivers can always use the re-order controls.
    .filter((activity) => {
      return activity.type !== "break";
    })
    .map((activity, index) => {
      const lastIndex = props.activities.length;
      const isLastActivity = activity.index === lastIndex - 1;

      // NOTE: use map's index for value user sees - this way we do not show gap where filtered-out break would normally be.
      const text = `Stop ${index + 1}: ${serializeAddress(activity.address)}`;
      const nextOption = (
        <option key={activity.index} value={activity.index}>
          {text}
        </option>
      );

      const lastOption = (
        <option key={lastIndex} value={lastIndex}>
          {t((d) => d.day_route.create_stop.options.last)}
        </option>
      );

      // When last activity, also include AS LAST STOP as an option
      if (isLastActivity) {
        return [nextOption, lastOption];
      }

      return nextOption;
    });

  return (
    <div>
      <Label text={t((d) => d.day_route.create_stop.labels.address)}>
        <InputTextWithGeoAutocomplete
          value={props.form.data.search.value}
          onChange={(search) => props.form.set({ search })}
          onSelect={(place) => props.form.set({ place })}
          includeHouseNumber={true}
        />
      </Label>
      {showDescriptionInput && (
        <Label text={t((d) => d.day_route.create_stop.describe_stop)} className={cn("mt-4")}>
          <Textarea
            value={props.form.data.description?.value}
            onChange={(description) => props.form.set({ description })}
          />
        </Label>
      )}
      <Label text={t((d) => d.day_route.create_stop.labels.service_time)} className={cn("mt-4")}>
        {/* NOTE: user sees in minutes but form has in seconds, so do conversion accordingly! */}
        <InputNumberWithControls
          min={0}
          max={60}
          step={5}
          units={t((d) => d.calendar.minute_full)}
          value={props.form.data.service_time_seconds.value / 60}
          onChange={(service_time_minutes) => {
            props.form.set({ service_time_seconds: service_time_minutes * 60 });
          }}
        />
      </Label>

      {/* When we deal with an existing activity, it's easier to have + and - contro */}
      {showCapacityInput && (
        <Label
          text={<Strong>{t((d) => d.day_route.create_stop.estimated_space)}</Strong>}
          className={cn("mt-4")}
          error={props.form.data.capacity_m3?.isDirty && props.form.data.capacity_m3?.error}
        >
          <InputNumberWithControls
            min={0}
            max={10}
            step={0.1}
            units={"m³"}
            value={props.form.data.capacity_m3?.value || 5}
            onChange={(capacity_m3) => props.form.set({ capacity_m3: Number(capacity_m3.toFixed(1)) })}
          />
        </Label>
      )}
      {!timeSection.isActive && (
        <button onClick={timeSection.open} className={cn("mt-4", "text-blue-600", "flex", "items-center")}>
          <div className={cn("mr-1")}>
            <IconAdd />
          </div>
          <div>{t((d) => d.day_route.add_timeframe_cta)}</div>
        </button>
      )}
      {timeSection.isActive && (
        <>
          <Spacer h={4} />
          <div className={cn("pb-1")}>
            <Strong>{t((d) => d.stop_edit.update_dtp.heading)}</Strong>
          </div>
          <DateTimePeriodSelector
            timeFormatter={formatTime}
            onChange={(d) => {
              props.form.set({ start_time: d.start });
              props.form.set({ end_time: d.end });
            }}
            value={{
              start: props.form.data.start_time.value,
              end: props.form.data.end_time.value,
            }}
          />
        </>
      )}

      {timeSection.isActive && (
        <button onClick={timeInfoModal.open} className={cn("mt-4", "text-blue-600", "flex", "items-center")}>
          <div className={cn("mr-2")}>
            <IconInfoCircle />
          </div>
          <div>{t((d) => d.day_route.add_timeframe_info_cta)}</div>
        </button>
      )}
      {/* NOTE: only show the select for other activities when they are available. */}
      {indexOptions.length > 0 && (
        <Label text={t((d) => d.day_route.create_stop.select_before_which_stop)} className={cn("mt-4")}>
          <Select
            className="w-full"
            value={props.form.data.index?.value}
            onChange={(index: string) => props.form.set({ index: Number(index) })}
          >
            <Options placeholderText="--">{indexOptions}</Options>
          </Select>
        </Label>
      )}
      <Modal isActive={timeInfoModal.isActive} closeHandler={timeInfoModal.close}>
        <h3>{t((d) => d.day_route.add_timeframe_info_cta)}</h3>
        <div className={cn("mt-4")}>{t((d) => d.day_route.add_timeframe_explanation)}</div>
        <Button buttonType="secondary" onClick={timeInfoModal.close} className={cn("mt-4", "w-full")}>
          {t((d) => d.actions.understood)}
        </Button>
      </Modal>
    </div>
  );
};
