import * as React from "react";
import cn from "classnames";
import { MessageType } from "@brenger/react";

const classNamesForMessageType: { [key in MessageType]: string[] } = {
  alert: ["bg-blue-50", "text-blue-600"],
  info: ["bg-blue-200", "text-blue-600"],
  error: ["bg-red-600", "text-white"],
  success: ["bg-green-200", "text-green-400"],
};

interface Props {
  type: MessageType;
}

// This component is for displaying APP-WIDE messages (above the header) as, for example,
// in the case when location services are not available or turned off on mobile user's devices.
export const AppMessage: React.FC<Props> = ({ children, type, ...unused }) => {
  return (
    <div className={cn("w-full", "p-2", "border-b", "border-white", classNamesForMessageType[type])} {...unused}>
      <div className={cn("container", "mx-auto")}>{children}</div>
    </div>
  );
};
