import * as React from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useQuery } from "react-query";
import { CoreLanguage } from "@brenger/api-client";

import { Config } from "../config";
import { localeNormalizer } from "../utils";
import { useSelector } from ".";
import { Extras } from "@sentry/types";

const axiosInstance = axios.create({ baseURL: Config.TRANSLATION_URL });
const defaultParams = { key: Config.TRANSLATION_KEY };

interface TranslateTextRequest {
  text: string;
  targetLanguage: CoreLanguage;
}

interface Translation {
  translatedText: string;
  detectedSourceLanguage: string;
}

interface TranslateTextReponse {
  data: {
    translations: Translation[];
  };
}

// Google Translation API Docs: https://cloud.google.com/translate/docs/basic/translating-text
const googleTranslateClient = {
  async translateText(args: TranslateTextRequest): Promise<Translation | undefined> {
    try {
      const data = {
        q: [args.text],
        target: args.targetLanguage,
        format: "text",
      };
      const response = await axiosInstance.post("/", data, { params: defaultParams });
      // Pluck out the first translation from the list. This should be the one with the highest confidence interval.
      const [translation] = (response.data as TranslateTextReponse).data.translations;
      return translation;
    } catch (err) {
      // only send 50% of errors
      const sampleRate = 50;
      if (Math.random() * 100 <= sampleRate) {
        Sentry.captureMessage("Unsuccessful response from Google Translate API", { extra: err as Extras });
      }
    }
  },
};

interface UseUserGeneratedContent {
  text: string;
  /**
   * Whether the returned "text" has been translated.
   */
  isTextTranslated: boolean;
  /**
   * Whether the returned "text" can be translated (ie, differs from user's in-app language setting.)
   */
  isTextTranslatable: boolean;
  /**
   * Show the untranslated, source text.
   */
  showSourceText(): void;
  /**
   * Fetch the translation.
   */
  showTranslatedText(): void;
}

export const useUserGeneratedContent = (text?: string | null): UseUserGeneratedContent => {
  const userSettings = useSelector((state) => state.settings);
  const [isTextTranslated, setIsTextTranslated] = React.useState(false);
  const [translatedText, setTranslatedText] = React.useState("");
  const userLanguage = localeNormalizer.parseLanguage(userSettings.locale);

  /**
   * Fetch a translation as soon as the text is made available.
   */
  const translateText = useQuery(
    ["GOOGLE_TRANSLATE", text],
    () => googleTranslateClient.translateText({ text: text as string, targetLanguage: userLanguage }),
    {
      enabled: !!text,
    }
  );

  /**
   * Determine whether the provided text is translatable by comparing the user's language settings
   * against the detected language returned by the translation API.
   */
  const isTextTranslatable = translateText.data?.detectedSourceLanguage !== userLanguage;

  /**
   * Set the raw source to state by default, pending a response from translate API.
   */
  React.useEffect(() => {
    showSourceText();
  }, [text]);

  /**
   * When the translated API tells us that the source text differs from the user's language setting,
   * we want to eagerly set the translated text.
   */
  React.useEffect(() => {
    if (isTextTranslatable && translateText.data?.translatedText) {
      setTranslatedText(translateText.data?.translatedText);
      setIsTextTranslated(true);
    }
  }, [isTextTranslatable, !!translateText.data, text]);

  /**
   * Show original source text again.
   */
  const showSourceText = React.useCallback(() => {
    setTranslatedText(text || "");
    setIsTextTranslated(false);
  }, [text]);

  return {
    isTextTranslated,
    isTextTranslatable,
    showSourceText,
    showTranslatedText: translateText.refetch,
    text: translatedText,
  };
};
