// ADD COMMON TYPES THAT ARE NOT SUPPORTED BY THE API-CLIENT HERE

/**
 * These are the main views in the driver dashboard. Many components are the same across all views.
 * However, there is often some slight different in how the data is displayed. Therefore, we use
 * this type to dictate the context and do imperative logic around what to display.
 */
export type JobPresentation = "search" | "planning" | "delivered" | "dayroute-details" | "planning-stop-edit";
export type ProfilePresentation = "reviews" | "details";

/**
 * A nice utility to filter out empty items from a list with type safety.
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
