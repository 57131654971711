import { Delivery, Pickup } from "@brenger/api-client";
import { Button, IconLoader } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import { Location } from "@transistorsoft/capacitor-background-geolocation";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { Config } from "../../config";
import { useAuth, useDeviceInfo, UseGeolocation, useTranslation } from "../../hooks";
import { ConfirmStopLocation } from "../../pages/job/PlanningConfirmStop/methods";
import { CacheKey, coreClient, logger, routePlannerClient } from "../../utils";
import { ConfirmStopPunctualityMessage } from "./ConfirmStopPunctualityMessage";

interface Props {
  stop: Pickup | Delivery;
  isConfirmDisabled: boolean;
  isConfirmLoading: boolean;
  confirmStop(args: ConfirmStopLocation): void;
  geolocation: UseGeolocation;
}

export const ConfirmStopButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isPluginReady, isPluginEnabled, isDeviceEnabled, getCurrentPosition } = props.geolocation;
  const hasLocationService = isPluginReady && isPluginEnabled && isDeviceEnabled;
  const deviceInfo = useDeviceInfo();
  const auth = useAuth();
  /**
   * Current location
   */
  const [locationLoading, setLocationLoading] = React.useState(false);
  const [location, setLocation] = React.useState<null | Location>(null);

  /**
   * Retrieve proximity, only when coordinates are set
   */
  const stopId = getIdFromIri(props.stop["@id"]) || "";
  const proximity = useQuery(
    [CacheKey.RETRIEVE_GEO_STOP_PROXIMITY, stopId, JSON.stringify(location?.coords)],
    () =>
      routePlannerClient.geo.retrieveProximityStop({
        stopId,
        lat: location?.coords.latitude as number,
        lng: location?.coords.longitude as number,
      }),
    {
      enabled: !!(location?.coords.latitude && location?.coords.longitude),
    }
  );
  // "OFF" means the proximity service is switched off, should be handled as positive result
  const isProximate = proximity?.data?.valid === "yes" || proximity?.data?.valid === "off";

  /**
   * Function that flips loading state of location and tries to get the current location
   */
  const getLocation = async (): Promise<void> => {
    setLocationLoading(true);
    getCurrentPosition({
      desiredAccuracy: 50, // Try to fetch a location with an accuracy of `50` meters.
      timeout: 30, // 30 second timeout to fetch location
      maximumAge: 1000, // Accept the last-known-location if not older than 1000 ms.
      samples: 3, // How many location samples to attempt.
    })
      .then((loc) => {
        setLocation(loc);
      })
      .catch((error) => {
        logConfirmError(`code ${error}`);
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };

  const retryProximity = async (): Promise<void> => {
    // simply retry to get a location
    await getLocation();
    // refresh proximity
    await setTimeout(proximity.refetch, 100);
  };

  const logConfirmError = (error: string): void => {
    logger.error(`Confirm location error: ${error}`, {
      auth,
      deviceInfo,
      geoPlugin: props.geolocation,
    });
  };
  /**
   * Listen for geo plugin enabling
   * When the plugin is enabled, then retrieve current coords
   */
  React.useEffect(() => {
    if (hasLocationService) {
      getLocation();
    }
  }, [isPluginEnabled]);

  /**
   * Setup payload for confirmation rules and confirm
   */
  const defaultProximity = hasLocationService ? "unknown" : "no_data";

  const confirmStopPayload: ConfirmStopLocation = {
    proximity: proximity?.data?.valid || defaultProximity,
    lat: location?.coords?.latitude,
    lng: location?.coords?.longitude,
    location_service: hasLocationService,
    device_platform: deviceInfo?.platform || "unknown",
  };

  /**
   * We are loading
   * - when we have location service, AND
   * - when coordinates is loading OR
   * - when proximity is loading
   */
  const loadingLocationData = hasLocationService && (locationLoading || proximity.isLoading);

  const confirmationRules = useQuery(
    [CacheKey.RETRIEVE_CONFIRMATION_RULES, stopId, JSON.stringify(confirmStopPayload)],
    () =>
      coreClient.stops.retrieveConfirmationRules({
        id: stopId,
        ...confirmStopPayload,
      }),
    {
      enabled: !loadingLocationData,
    }
  );

  if (loadingLocationData || confirmationRules.isLoading) {
    return (
      <>
        <div className={cn("mt-4", "flex", "justify-center", "items-center")}>
          <IconLoader className={cn("w-6", "h-6")} />
        </div>
      </>
    );
  }

  return (
    <>
      {Config.NODE_ENV === "staging" && (
        <details>
          <summary>Debug location payload</summary>
          {JSON.stringify(confirmStopPayload)}
          {"Core confirmation rules:"}
          {JSON.stringify(confirmationRules.data)}
        </details>
      )}
      <ConfirmStopPunctualityMessage
        confirmationRules={confirmationRules.data}
        hasLocationService={hasLocationService}
        geolocation={props.geolocation}
      />
      {/* When not proximate, but location service is switched on, offer a retry action  */}
      {!isProximate && hasLocationService && (
        <div className={cn("my-4")}>
          <Button
            disabled={props.isConfirmLoading}
            className={cn("w-full")}
            onClick={async () => await retryProximity()}
          >
            {t((d) => d.punctuality_message.redetermine_location_cta)}
          </Button>
        </div>
      )}
      <Button
        disabled={props.isConfirmDisabled}
        buttonType="secondary"
        className={cn("w-full")}
        loading={props.isConfirmLoading}
        onClick={() => {
          if (confirmStopPayload.proximity === "unknown" && confirmStopPayload.location_service) {
            logConfirmError('Proximity "unknown" with location service');
          }
          props.confirmStop(confirmStopPayload);
        }}
      >
        {t(
          (d) => d.planning.labels[props.stop["@type"] === "Pickup" ? "confirm_stop_pickup" : "confirm_stop_delivery"]
        )}
      </Button>
    </>
  );
};
