import { UserRole } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Config } from "../config";
import { CacheKey, coreClient, localeNormalizer, Routes } from "../utils";
import { useAuth } from "./useAuth";
import { PlanningParams, SearchParams } from "./useParams";
import { useTjalContext } from "./useTjalContext";
import { useTranslation } from "./useTranslation";

type FreshChatLocale = "nl" | "en" | "de" | "fr" | "es";

interface UserProperties {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  meta?: {
    transport_job_id?: string | null;
    day_route_id?: string | null;
    user_role?: UserRole | null;
    user_id?: string | null;
    user_friendly_id?: string | null;
  };
}

interface InitParams extends UserProperties {
  locale: string;
  token: string;
  host: string;
  open: boolean;
}

interface FreshChatWidget extends UseFreshChat {
  init: (params: InitParams) => void;
  isInitialized: () => boolean;
  user: {
    setLocale: (locale: FreshChatLocale) => void;
    setProperties: (userProperties: UserProperties) => void;
    update: (userProperties: UserProperties) => void;
    clear: () => Promise<void>;
  };
  setTags(tags: string[]): void;
  // Opens the Freshchat Widget
  open: () => void;
  // Make the messenger appaear
  show: () => void;
  // Hide
  hide: () => void;
  on(event: string, callback: () => void): void;
}

declare global {
  interface Window {
    fcWidget?: FreshChatWidget;
  }
}

// Expose a much smaller interface to hook users.
// For more available methods, see docs: https://developers.freshchat.com/web-sdk/
interface UseFreshChat {
  widget?: FreshChatWidget;
  isPresent: boolean;
  isLoading: boolean;
  open: () => void;
  hide: () => void;
}

export const useFreshChat = (): UseFreshChat => {
  const [widget, setWidget] = useState<undefined | FreshChatWidget>(window.fcWidget);
  const [isPresent, setIsPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { i18n } = useTranslation();
  const location = useLocation();
  const search = matchPath<SearchParams>(location.pathname, {
    path: Routes.SEARCH_JOB_DETAILS,
    exact: true,
  });
  const planning = matchPath<PlanningParams>(location.pathname, {
    path: Routes.PLANNING_JOB_DETAILS,
    exact: true,
  });
  const searchTj = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB, search?.params.tj_id as string],
    () => coreClient.transportJobs.retrieve({ id: search?.params.tj_id as string }),
    {
      enabled: !!search?.params.tj_id,
    }
  );
  const { tj: planningTj } = useTjalContext(planning?.params.tjal_id);
  const jobId = searchTj.data?.short_id || planningTj.data?.short_id || undefined;
  const meta = {
    user_role: auth.isLoggedIn
      ? auth.isAccountAdmin
        ? "ROLE_ACCOUNT_ADMIN"
        : "ROLE_DRIVER"
      : (null as UserRole | null),
    user_id: getIdFromIri(auth.user?.["@id"]) || null,
    user_friendly_id: auth.user?.friendly_id || null,
    transport_job_id: jobId || null,
  };
  /**
   * To be called once
   */
  const init = (): void => {
    if (!Config.FRESH_CHAT_TOKEN) {
      return;
    }
    setIsLoading(true);
    const id = "brenger-hook-freshchat-lib";
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://wchat.freshchat.com/js/widget.js";
    script.id = id;
    script.onload = (): void => {
      setWidget(window.fcWidget);
      (window.fcWidget as FreshChatWidget).init({
        open: true,
        locale: localeNormalizer.parseLocale(i18n.locale).split("-")[0].toLowerCase(),
        token: Config.FRESH_CHAT_TOKEN as string,
        firstName: auth.user?.first_name || undefined,
        lastName: auth.user?.last_name || undefined,
        email: auth.user?.email || undefined,
        phone: auth.user?.phone || undefined,
        host: "https://wchat.freshchat.com",
        meta,
      });
      (window.fcWidget as FreshChatWidget).on("widget:opened", () => {
        setIsLoading(false);
        setIsPresent(true);
      });
    };
    document.head.appendChild(script);
  };

  const open = (): void => {
    if (widget?.isInitialized()) {
      widget.open();
      widget.show();
      setIsPresent(true);
      return;
    }
    init();
  };

  const hide = (): void => {
    if (widget?.isInitialized()) {
      widget?.hide();
      setIsPresent(false);
    }
  };

  useEffect(() => {
    widget?.user.update({
      meta: { transport_job_id: jobId || null },
    });
  }, [jobId, location]);

  useEffect(() => {
    if (!auth.isLoggedIn) {
      widget?.user.clear();
      return;
    }
    if (!isLoading && widget) {
      widget.user.update({
        firstName: auth.user?.first_name || undefined,
        lastName: auth.user?.last_name || undefined,
        email: auth.user?.email || undefined,
        phone: auth.user?.phone || undefined,
        meta,
      });
      widget.setTags(["logged_in_driver"]);
    }
  }, [auth, isLoading, widget]);

  return {
    widget,
    isPresent,
    isLoading,
    open,
    hide,
  };
};
