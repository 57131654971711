import * as React from "react";
import cn from "classnames";
import { IconLoader, Message } from "@brenger/react";

import { useNativeKeyboardEvents } from "../../hooks";
import { ScrollToTopOnMount } from "..";

interface Props {
  loading?: boolean | null;
  infoText?: string | React.ReactNode | null;
  errorText?: string | null;
  successText?: string | null;
  cta?: React.ReactNode;
  stickyFooter?: React.ReactNode;
  scrollToTopOnMount?: boolean;
  nav?: React.ReactNode;
}

// Make a separate Sticky Footer component so that keyboard events only mount
// when we actually have a sticky footer on the page.
const StickyFooter: React.FC = (props) => {
  const [showFooter, setShowFooter] = React.useState(true);

  // Add event listener for native keyboard. We want to hide the sticky footer
  // when it appears in order to prevent cramping the available screen space.
  const onWillShow = React.useCallback((): void => {
    setShowFooter(false);
  }, [setShowFooter]);

  const onDidHide = React.useCallback((): void => {
    setShowFooter(true);
  }, [setShowFooter]);

  useNativeKeyboardEvents({ onWillShow, onDidHide });

  if (!showFooter) {
    return null;
  }

  return (
    <>
      <div
        className={cn("flex", "flex-col", "sticky", "no-print", "z-20", "bg-white", "bottom-0-with-safe-area-inset")}
      >
        {/* This div sits atop the footer elements and adds a slight gradient so that it feels natural
            as items scroll beneath the footer */}
        <div
          className={cn("h-4", "-mt-4")}
          style={{ backgroundImage: "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))" }}
        />
        <footer className={cn("w-full", "bg-white")}>{props.children}</footer>
      </div>
      {/* This little div site below the footer elements and fill in the extra space. It is expressed in
      CSS because it has to utilize some env vars to work on native device layout as well. See stylesheet
      for further details. */}
      <div className={cn("bottom-0-with-safe-area-bg")} />
    </>
  );
};

export const Page: React.FC<Props> = ({
  loading,
  infoText,
  errorText,
  successText,
  cta,
  stickyFooter,
  nav,
  scrollToTopOnMount = true,
  children,
}) => {
  return (
    <PageWrap scrollToTopOnMount={scrollToTopOnMount}>
      {nav}
      {/* PAGE MAIN CONTENT */}
      <article
        className={cn("relative", "flex", "flex-col", "flex-grow", "container", "mx-auto", "px-3", "pt-4", {
          // The sticky footer takes care of its own bottom padding - so only add this to page when sticky footer not present.
          "pb-8": !stickyFooter,
        })}
      >
        <div
          className={cn("flex", "flex-col", "flex-grow", {
            // Include mx-2 so that page content is slightly smaller than sticky footer.
            "mx-2": Boolean(stickyFooter),
            // Include pb-3 to add some separation between page content in sticky footer (when present)
            "pb-8": Boolean(stickyFooter),
          })}
        >
          {loading && (
            <div style={{ minHeight: "20rem" }} className={cn("flex", "justify-center", "items-center", "flex-grow")}>
              <IconLoader className={cn("w-6", "h-6")} />
            </div>
          )}
          {!loading && successText && (
            <Message className={cn("mb-4")} type="success">
              {successText}
            </Message>
          )}
          {!loading && errorText && (
            <Message className={cn("mb-4")} type="error">
              {errorText}
            </Message>
          )}
          {!loading && infoText && (
            <Message className={cn("mb-4")} type="info">
              {infoText}
            </Message>
          )}
          {!loading && cta}
          {!loading && children}
        </div>
        {stickyFooter && <StickyFooter>{stickyFooter}</StickyFooter>}
      </article>
    </PageWrap>
  );
};

interface PageWrapProps {
  scrollToTopOnMount?: boolean;
}

const PageWrap: React.FC<PageWrapProps> = ({ scrollToTopOnMount, children }) => {
  if (scrollToTopOnMount) {
    return <ScrollToTopOnMount>{children}</ScrollToTopOnMount>;
  }

  return <>{children}</>;
};
