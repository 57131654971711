import { DayRoute, DayRouteActivity, UpdateDayRoute } from "@brenger/api-client";
import { Button, IconAddStop, IconCheck, IconEditBox, IconStartDriving, Message, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useMutation, UseQueryResult } from "react-query";
import { useHistory } from "react-router-dom";
import { useDayRouteParams, useFormatDate, useTranslation } from "../../../hooks";
import { coreClient } from "../../../utils";

interface FooterProps {
  className?: string;
  activities: DayRouteActivity[];
  setHasChanged: (v: boolean) => void;
  hasChanged: boolean;
  setIsEditing: (v: boolean) => void;
  isEditing: boolean;
  dayRoute: UseQueryResult<DayRoute>;
}

export const DayRouteFooter: React.FC<FooterProps> = (props) => {
  const { t } = useTranslation();
  const { data: dayRoute } = props.dayRoute;
  const params = useDayRouteParams();
  const history = useHistory();
  const goToDayRouteAdd = (): void => history.push(`/day-route/${params.date}/${params.user_id}/add`);
  const formatDateForApi = useFormatDate("api-date");

  // Callback after successfully updating the day route
  const onUpdateSuccess = (): void => {
    // Toggle states
    props.setHasChanged(false);
    props.setIsEditing(false);
    // Grab fresh dayRoute data after update.
    props.dayRoute.refetch();
  };

  const updateDayRoute = useMutation(coreClient.dayRoutes.update, { onSuccess: onUpdateSuccess });

  // Save updated day route
  const saveDayRoute = (dayroute: UpdateDayRoute["dayRoute"]): void => {
    updateDayRoute.mutate({
      routeId: getIdFromIri(dayRoute) || "",
      dayRoute: dayroute,
    });
  };

  const onEdit = (): void => {
    // NOTE: only trigger an update on the day route if something has actaully changed.
    // The isEdit will toggle back in the onSuccess callback of the mutation.
    if (props.hasChanged) {
      saveDayRoute({ day_route_activities: props.activities });
    } else {
      // If nothing changed, simply toggle back to non-edit-mode.
      props.setIsEditing(false);
    }
  };
  // CASE: day route data has not been fetched yet
  if (!dayRoute) return null;

  const today = new Date().toISOString();
  const dateToday = formatDateForApi(today);
  const isDayRouteForToday = dateToday === params.date;
  const startedDriving = dayRoute.started_driving_at !== null;
  const showStartButton = isDayRouteForToday && Boolean(dayRoute?.day_route_activities?.length) && !startedDriving;
  const StartDrivingButton: React.FC<{ classname?: string }> = ({ classname }) => (
    <Button
      buttonType="secondary"
      loading={updateDayRoute.isLoading}
      className={cn(classname)}
      size="lg"
      onClick={() => {
        saveDayRoute({ started_driving_at: new Date().toISOString() });
      }}
      iconPos={"right"}
      icon={<IconStartDriving width={"20px"} height={"20px"} />}
    >
      <Strong>{t((d) => d.day_route.start_driving.cta)}</Strong>
    </Button>
  );

  const containerCN = cn("mt-2", "mr-1", "md:w-full", { "w-full": !showStartButton });
  const buttonCN = cn("h-12", "md:w-full", { "w-full": !showStartButton, "w-12": showStartButton });
  return (
    <div>
      {updateDayRoute.error && (
        <Message className={cn("my-4")} type="error">
          {(updateDayRoute.error as Error).message}
        </Message>
      )}

      <div className={cn("flex", "justify-between")}>
        {showStartButton && <StartDrivingButton classname={cn("w-full", "h-12", "mt-2", "mr-1")} />}
        {/* OWN STOP  */}
        <div className={containerCN}>
          <Button
            className={buttonCN}
            buttonType="primary"
            icon={<IconAddStop className={cn("ml-1", "md:ml-0")} />}
            onClick={goToDayRouteAdd}
          >
            <div className={cn("md:block", { hidden: showStartButton })}>
              {t((d) => d.day_route.details.actions.own_stop)}
            </div>
          </Button>
        </div>
        <div className={containerCN}>
          {/* NOTE: depending on isEditing state, we display two different buttons. Hence, the ternery. */}
          {props.isEditing ? (
            <Button
              size="lg"
              buttonType="secondary"
              className={buttonCN}
              icon={<IconCheck className={cn("ml-1", "md:ml-0")} />}
              iconPos={"left"}
              loading={updateDayRoute.isLoading}
              onClick={onEdit}
            >
              <div className={cn("md:block", { hidden: showStartButton })}>
                {t((d) => d.day_route.details.actions.save_route_order)}
              </div>
            </Button>
          ) : (
            <Button
              size="lg"
              buttonType="primary"
              className={buttonCN}
              icon={<IconEditBox className={cn("ml-1", "md:ml-0")} />}
              disabled={props.activities.length === 1}
              onClick={() => {
                props.setIsEditing(true);
              }}
            >
              <div className={cn("md:block", { hidden: showStartButton })}>
                {t((d) => d.day_route.details.actions.edit_order)}
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
