import { Address, Delivery, DeliveryCommitment, Pickup, PickupCommitment } from "@brenger/api-client";
import { Button, Card, IconArrowLeft, IconArrowRight, IconFilter, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import compareDesc from "date-fns/compareDesc";
import isSameDay from "date-fns/isSameDay";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { IconLoader } from "@brenger/react";
import { Grid, ListSummary, Page, PillTjalState, TabNav, TjalDetails, TransportJobDetails } from "../../../components";
import { Routes, formatLocality } from "../../../utils";

import { SearchJobID } from "../../../components";
import {
  useAuth,
  useDeliveredFilter,
  useFormatDate,
  useFormatTimeframe,
  usePagination,
  useSelector,
  useTabNavigation,
  useTranslation,
} from "../../../hooks";

export const DeliveredJobList: React.FC = () => {
  const { t } = useTranslation();
  const deliveredFilter = useSelector((state) => state.deliveredFilter);
  const [page, setPage] = React.useState(1);
  const tabs = useTabNavigation("dashboard");
  const history = useHistory();
  const auth = useAuth();
  const formatDateFull = useFormatDate("date-short");
  const formatTimeframe = useFormatTimeframe();
  const formatDateForApi = useFormatDate("api-date");
  const formattedDateFrom = formatDateFull(new Date(deliveredFilter.dateFrom).toUTCString());
  const formattedDateTo = formatDateFull(new Date(deliveredFilter.dateTo).toUTCString());

  // Scroll user to the top when the page number changes.
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // This hook takes in a raw, unfiltered list of transport job account links and returns a filtered
  // list based on the user's selected settings.
  const { tjals, filteredTjals, setSearchFilter } = useDeliveredFilter({ page });

  const paginationControls = usePagination({ list: tjals.data, setPage, page });

  if (tjals.isError) {
    return <Page nav={<TabNav tabs={tabs} />} loading={tjals.isLoading} errorText={(tjals.error as Error)?.message} />;
  }

  return (
    <Page
      nav={<TabNav tabs={tabs} />}
      stickyFooter={
        <div className={cn("flex", "items-center")}>
          {/* ONLY SHOW BACK PAGINATION CONTROLS WHEN THERE IS MORE THAN ONE PAGE OF RESULTS */}
          {(paginationControls.hasPreviousPage || paginationControls.hasNextPage) && (
            <div className={cn("mr-2")}>
              <Button
                buttonType="primary-outline"
                onClick={paginationControls.goToPreviousPage}
                disabled={!paginationControls.hasPreviousPage}
              >
                <IconArrowLeft />
              </Button>
            </div>
          )}
          <div className={cn("w-full")}>
            <Button
              className={cn("w-full")}
              onClick={() => history.push(Routes.DELIVERED_JOB_LIST_FILTER)}
              icon={<IconFilter className={cn("h-4")} />}
            >
              {t((d) => d.delivered.filter.toggle)}
            </Button>
          </div>
          {/* ONLY SHOW FORWARD PAGINATION CONTROLS WHEN THERE IS MORE THAN ONE PAGE OF RESULTS */}
          {(paginationControls.hasPreviousPage || paginationControls.hasNextPage) && (
            <div className={cn("ml-2")}>
              <Button
                buttonType="primary-outline"
                onClick={paginationControls.goToNextPage}
                disabled={!paginationControls.hasNextPage}
              >
                <IconArrowRight />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <SearchJobID setSearchFilter={setSearchFilter} />
      <section>
        {/* Indicate to drivers the timeframe being shown to them so that they are not confused about missing jobs. */}
        <Message className={cn("mb-4")} type="info">
          <small className={cn("capitalize", "inline-flex")}>
            {t((d, withTemplate) =>
              withTemplate(d.delivered.filter.show_date_range, {
                dates: `${formattedDateFrom} — ${formattedDateTo}`,
              })
            )}
          </small>
        </Message>

        <div className={cn("flex", "items-center", "my-2")}>
          <h5>{t((d) => d.delivered.title)}</h5>
        </div>

        {!tjals.data && (
          <div style={{ minHeight: "20rem" }} className={cn("flex", "justify-center", "items-center", "flex-grow")}>
            <IconLoader className={cn("w-6", "h-6")} />
          </div>
        )}

        {/* HANDLE EMPTY STATE */}
        {!tjals.isLoading && Object.keys(filteredTjals).length === 0 && (
          <div className={cn("text-gray-600")}>
            <i> {t((d) => d.delivered.filter.zero_results)}</i>
          </div>
        )}
        {/* DISPLAY CARDS */}
        {Object.keys(filteredTjals)
          // OBJECT KEYS ARE UNORDERED - SO MUST ENFORCE ORDERING BELOW.
          // 1) convert the date strings into date objects
          .map((formattedDateString) => new Date(formattedDateString))
          // 2) use date-fns compareDesc
          .sort(compareDesc)
          // 3) convert date objects back into formatted date string
          .map((date) => formatDateForApi(date.toISOString()))
          // 4) render UI
          .map((date, idx) => {
            return (
              <div key={date} className={cn({ "mt-6": idx !== 0 })}>
                <h4 className={cn("capitalize", "mb-2")}>
                  {/* NOTE: convert ugly API date string ("2020-02-25") back to something our utils can work with. */}
                  {formatDateFull(new Date(date).toUTCString())}
                </h4>
                <Grid className={cn("my-2")} cols={{ lg: 2 }} gap={4}>
                  {filteredTjals[date]?.map((tjal) => {
                    // Parse out the first and last delivery commitments
                    const firstPickupCommitment = tjal.pickup_commitments[0] as PickupCommitment;
                    const lastDeliveryCommitment = tjal.delivery_commitments[
                      tjal.delivery_commitments.length - 1
                    ] as DeliveryCommitment;
                    const firstStop = firstPickupCommitment.pickup as Pickup;
                    const firstStopAddress = firstStop.address as Address;
                    const lastStop = lastDeliveryCommitment.delivery as Delivery;
                    const lastStopAddress = lastStop.address as Address;

                    let pickupPeriod = formatTimeframe({
                      start: firstPickupCommitment.committed_datetime_period.start,
                      end: firstPickupCommitment.committed_datetime_period.end,
                    });
                    let deliveryPeriod = formatTimeframe({
                      start: lastDeliveryCommitment.committed_datetime_period.start,
                      end: lastDeliveryCommitment.committed_datetime_period.end,
                    });

                    // Check if the pickup & delivery are on the same day.
                    const isJobSameDay = isSameDay(
                      new Date(firstPickupCommitment.committed_datetime_period.start),
                      new Date(lastDeliveryCommitment.committed_datetime_period.end)
                    );

                    // If NOT, append the date to the timeframe for maximal clarity.
                    if (!isJobSameDay) {
                      pickupPeriod = `${pickupPeriod} (${formatDateFull(
                        firstPickupCommitment.committed_datetime_period.start
                      )})`;
                      deliveryPeriod = `${deliveryPeriod} (${formatDateFull(
                        lastDeliveryCommitment.committed_datetime_period.end
                      )})`;
                    }

                    return (
                      <Link key={tjal["@id"]} className={cn("block")} to={`/delivered/${getIdFromIri(tjal)}`}>
                        <Card className={cn("h-full")}>
                          <TransportJobDetails
                            from={formatLocality(firstStopAddress.locality, firstStopAddress.administrative_area)}
                            pickupPeriod={pickupPeriod}
                            to={formatLocality(lastStopAddress.locality, lastStopAddress.administrative_area)}
                            deliveryPeriod={deliveryPeriod}
                            // Only allow account admins to see the price
                            price={auth.isAccountAdmin ? tjal.transport_job_total_payout : undefined}
                            isPrepaid={tjal.transport_job_prepaid}
                            isBundled={tjal.transport_job_bundled}
                            isBusiness={tjal.transport_job_is_business}
                          />
                          <TjalDetails
                            // Only account admins should see the name of the driver. Sub accounts only see their own jobs.
                            driver={auth.isAccountAdmin ? tjal.driver_user : null}
                            state={<PillTjalState state={tjal.state} />}
                          />
                        </Card>
                      </Link>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        <ListSummary {...paginationControls} />
      </section>
    </Page>
  );
};
