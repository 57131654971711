import { ItemSet } from "@brenger/api-client";
import { Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";

import {
  AddressDetails,
  BreadcrumbNav,
  ClientReferences,
  ConfirmationSignatures,
  DateTimePeriods,
  ItemSetAttributes,
  MetaItem,
  Page,
  PhotoProofs,
  StaticMap,
  StopConfirmedAtPill,
  StopDetailPills,
  UserGeneratedContent,
} from "../../../components";
import { useDeliveredParams, useMapContext, useStopContext, useTranslation } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";

export const DeliveredStopDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useDeliveredParams();

  const breadcrumbs = [
    { to: "/delivered", text: t((d) => d.app.tabs.delivered) },
    { text: "--", to: `/delivered/${params.tjal_id}` },
    { text: "--" },
  ];

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );

  const stopContext = useStopContext(stop.data || null);

  const mapContext = useMapContext({
    presentation: "delivered",
    tj: stopContext.transportJob,
    stopId: params.stop_id,
  });

  const itemSets = (stop.data?.item_sets as ItemSet[] | undefined) || [];

  if (!stop.data) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={stop.isLoading}
        errorText={(stop.error as Error)?.message}
      />
    );
  }

  const stopType = stop.data?.["@type"];

  // When we have gathered the stop context and the address, update the breadcrumbs.
  if (stopContext.stopNumber && stopContext.address && stopContext.transportJob) {
    breadcrumbs[1].text = `#${stopContext.transportJob.short_id}`;
    breadcrumbs[2].text = `${stopContext.stopNumber}: ${stopContext.address?.locality}`;
  }

  return (
    <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}>
      {stopContext.committedDtps && (
        <div className={cn("flex", "justify-between", "items-center")}>
          <DateTimePeriods periods={stopContext.committedDtps} />
          <div>
            <StopConfirmedAtPill
              stopType={stopType}
              itemSets={itemSets}
              // Use the "last" commitment in the list (in the rare case where the driver has more than one)
              committedDtps={stopContext.committedDtps}
            />
          </div>
        </div>
      )}
      <Spacer h={3} />
      <StaticMap markers={mapContext.markers} />
      <Spacer h={3} />
      <StopDetailPills details={stop.data.details} itemSets={itemSets} />
      <Spacer h={1} />
      {/* Render title and description of all item sets for this stop. */}
      <h4>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</h4>
      {itemSets.map((itemSet, idx) => {
        return (
          <div className={cn("mb-2", "flex", "flex-col")} key={idx}>
            <MetaItem name="Items" value={itemSet.title} />
            {itemSet.description && <MetaItem name="Details" value={itemSet.description} />}
            {itemSet.assembly_description && (
              <MetaItem
                name={t((d) => d.transport_job.stop_details.services.assembly)}
                value={<UserGeneratedContent text={itemSet.assembly_description} />}
              />
            )}
          </div>
        );
      })}
      <h5>{t((d) => d.transport_job.headings.address)}</h5>
      <AddressDetails
        address={stopContext.address}
        instructions={stop.data.details.instructions}
        presentation="delivered"
      />
      {/* ClientReferences and ItemSetAttributes are often times not present, therefore they handle own margin internally. */}
      <ClientReferences className={cn("mt-3")} itemSets={itemSets} />
      <ItemSetAttributes className={cn("mt-3")} itemSets={itemSets} />
      <Spacer h={3} />
      <h5>{t((d) => d.transport_job.headings.signatures)}</h5>
      <ConfirmationSignatures
        itemSets={itemSets}
        stopType={stopType}
        emptyStateText={t((d) => d.app.empty_state.generic)}
      />
      <Spacer h={3} />
      <h5>{t((d) => d.transport_job.headings.photo_proofs)}</h5>
      <PhotoProofs itemSets={itemSets} stopType={stopType} emptyStateText={t((d) => d.app.empty_state.generic)} />
    </Page>
  );
};
