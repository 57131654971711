import { Address, Delivery, DeliveryCommitment, Pickup, PickupCommitment } from "@brenger/api-client";
import { Button, Card, IconArrowLeft, IconArrowRight, Message, Strong, IconFilter } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import compareAsc from "date-fns/compareAsc";
import isSameDay from "date-fns/isSameDay";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  getStopDetails,
  Grid,
  ListSummary,
  Page,
  PillTjalState,
  TabNav,
  TjalDetails,
  TransportJobDetails,
} from "../../../components";
import {
  useAuth,
  useFormatDate,
  useFormatTimeframe,
  usePagination,
  usePlanningFilter,
  useSelector,
  useTabNavigation,
  useTranslation,
} from "../../../hooks";
import { planningFilterActions } from "../../../store/planning/filter";
import { PlanningFilterState } from "../../../store/planning/types";
import { formatLocality, Routes } from "../../../utils";

const getHasActivePlanningFilters = (state: PlanningFilterState): boolean => {
  return Boolean(state.user) || state.tjalStates.length > 0;
};

export const PlanningJobList: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const auth = useAuth();
  const [page, setPage] = React.useState(1);
  const formatDateFull = useFormatDate("date-full");
  const formatDateForApi = useFormatDate("api-date");
  const formatTimeframe = useFormatTimeframe();
  const tabs = useTabNavigation("dashboard");
  const dispatch = useDispatch();
  const planningFilter = useSelector((state) => state.planningFilter);
  const hasActivePlanningFilters = getHasActivePlanningFilters(planningFilter);

  // Scroll user to the top when the page number changes.
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const { filteredTjals, tjals } = usePlanningFilter({ page });

  const paginationControls = usePagination({ list: tjals.data, setPage, page });

  if (!tjals.data || tjals.isError) {
    return <Page nav={<TabNav tabs={tabs} />} loading={tjals.isLoading} errorText={(tjals.error as Error)?.message} />;
  }

  return (
    <Page
      nav={<TabNav tabs={tabs} />}
      stickyFooter={
        <div className={cn("flex", "items-center")}>
          {/* ONLY SHOW BACK PAGINATION CONTROLS WHEN THERE IS MORE THAN ONE PAGE OF RESULTS */}
          {(paginationControls.hasPreviousPage || paginationControls.hasNextPage) && (
            <div className={cn("mr-2")}>
              <Button
                buttonType="primary-outline"
                onClick={paginationControls.goToPreviousPage}
                disabled={!paginationControls.hasPreviousPage}
              >
                <IconArrowLeft />
              </Button>
            </div>
          )}
          <div className={cn("w-full")}>
            <Button
              className={cn("w-full")}
              onClick={() => history.push(Routes.PLANNING_JOB_LIST_FILTER)}
              icon={<IconFilter className={cn("h-4")} />}
            >
              {t((d) => d.planning.filter.toggle)}
            </Button>
          </div>
          {/* ONLY SHOW FORWARD PAGINATION CONTROLS WHEN THERE IS MORE THAN ONE PAGE OF RESULTS */}
          {(paginationControls.hasPreviousPage || paginationControls.hasNextPage) && (
            <div className={cn("ml-2")}>
              <Button
                buttonType="primary-outline"
                onClick={paginationControls.goToNextPage}
                disabled={!paginationControls.hasNextPage}
              >
                <IconArrowRight />
              </Button>
            </div>
          )}
        </div>
      }
    >
      <section>
        {hasActivePlanningFilters && (
          <Message type="info" className={cn("mb-4")}>
            <div className={cn("flex", "justify-between", "flex-wrap")}>
              <Link to={Routes.PLANNING_JOB_LIST_FILTER} className={cn("mr-4", "underline")}>
                <Strong>{t((d) => d.search.filter.active_filters)}</Strong>
              </Link>
              <Button
                size="sm"
                onClick={() => {
                  // Because the filters are not handled server-side,
                  // it's wise to reset to page 1 of results.
                  setPage(1);
                  dispatch(planningFilterActions.reset());
                }}
              >
                {t((d) => d.actions.remove)}
              </Button>
            </div>
          </Message>
        )}
        {/* HANDLE EMPTY STATE */}
        {Object.keys(filteredTjals).length === 0 && (
          <Message type="info">{t((d) => d.planning.filter.zero_results)}</Message>
        )}
        {/* DISPLAY CARDS */}
        {Object.keys(filteredTjals)
          // OBJECT KEYS ARE UNORDERED - SO MUST ENFORCE ORDERING BELOW.
          // 1) convert the date strings into date objects
          .map((formattedDateString) => new Date(formattedDateString))
          // 2) use date-fns compareAsc
          .sort(compareAsc)
          // 3) convert date objects back into formatted date string
          .map((date) => formatDateForApi(date.toISOString()))
          // 4) render UI
          .map((formattedDateString, idx) => {
            return (
              <div key={formattedDateString} className={cn({ "mt-6": idx !== 0 })}>
                <div className={cn("flex", "justify-between", "items-center")}>
                  <h4 className={cn("capitalize")}>
                    {/* NOTE: convert ugly API date string ("2020-02-25") back to something our utils can work with. */}
                    {formatDateFull(new Date(formattedDateString).toUTCString())}
                  </h4>
                  <Button
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/day-route/${formatDateForApi(new Date(formattedDateString).toUTCString())}/${auth.userId}`
                      )
                    }
                  >
                    {t((d) => d.day_route.details.actions.view)}
                  </Button>
                </div>
                <Grid className={cn("my-2")} cols={{ lg: 2 }} gap={4}>
                  {filteredTjals[formattedDateString]?.map((tjal) => {
                    // When there is a dispute_reason and the TJAL shows up in this list, we can
                    // safely assume that the TJ has a "disputed" state.
                    const isDisputed = Boolean(tjal.dispute_reason);
                    // Parse out the first and last delivery commitments
                    const firstPickupCommitment = tjal.pickup_commitments[0] as PickupCommitment;
                    const lastDeliveryCommitment = tjal.delivery_commitments[
                      tjal.delivery_commitments.length - 1
                    ] as DeliveryCommitment;
                    const firstStop = firstPickupCommitment.pickup as Pickup;
                    const firstStopAddress = firstStop.address as Address;
                    const lastStop = lastDeliveryCommitment.delivery as Delivery;
                    const lastStopAddress = lastStop.address as Address;

                    let pickupPeriod = formatTimeframe({
                      start: firstPickupCommitment.committed_datetime_period.start,
                      end: firstPickupCommitment.committed_datetime_period.end,
                    });
                    let deliveryPeriod = formatTimeframe({
                      start: lastDeliveryCommitment.committed_datetime_period.start,
                      end: lastDeliveryCommitment.committed_datetime_period.end,
                    });

                    // Check if the pickup & delivery are on the same day.
                    const isJobSameDay = isSameDay(
                      new Date(firstPickupCommitment.committed_datetime_period.start),
                      new Date(lastDeliveryCommitment.committed_datetime_period.end)
                    );

                    // If NOT, append the date to the timeframe for maximal clarity.
                    if (!isJobSameDay) {
                      pickupPeriod = `${pickupPeriod} (${formatDateFull(
                        firstPickupCommitment.committed_datetime_period.start
                      )})`;
                      deliveryPeriod = `${deliveryPeriod} (${formatDateFull(
                        lastDeliveryCommitment.committed_datetime_period.end
                      )})`;
                    }

                    // The stop details are deep in the stop commitments
                    const pickups = (tjal.pickup_commitments as PickupCommitment[]).map((e) => e.pickup) as Pickup[];
                    const deliveries = (tjal.delivery_commitments as DeliveryCommitment[]).map(
                      (e) => e.delivery
                    ) as Delivery[];
                    const tjalDetails = getStopDetails(pickups, deliveries);
                    return (
                      <Link key={tjal["@id"]} className={cn("block")} to={`/planning/${getIdFromIri(tjal)}`}>
                        <Card className={cn("h-full")}>
                          <TransportJobDetails
                            from={formatLocality(firstStopAddress.locality, firstStopAddress.administrative_area)}
                            pickupPeriod={pickupPeriod}
                            to={formatLocality(lastStopAddress.locality, lastStopAddress.administrative_area)}
                            deliveryPeriod={deliveryPeriod}
                            // Calculate the stop count by getting the length of all committments
                            stopCount={[...tjal.pickup_commitments, ...tjal.delivery_commitments].length}
                            // Only allow account admins to see the price
                            price={auth.isAccountAdmin ? tjal.transport_job_total_payout : undefined}
                            isDisputed={isDisputed}
                            isPrepaid={tjal.transport_job_prepaid}
                            isBundled={tjal.transport_job_bundled}
                            isBusiness={tjal.transport_job_is_business}
                            all_stop_details={{
                              carrying_help: tjalDetails.map((f) => f?.carrying_help),
                              situation: tjalDetails.map((s) => s.situation),
                            }}
                          />
                          <TjalDetails
                            // Only account admins should see the name of the driver. Sub accounts only see their own jobs.
                            driver={auth.isAccountAdmin ? tjal.driver_user : null}
                            // No sense in showing the TJAL state here when it is in dispute.
                            // That state is shown above in TransportJobDetails.
                            state={isDisputed ? null : <PillTjalState state={tjal.state} />}
                          />
                        </Card>
                      </Link>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        <ListSummary
          currentRangeStart={Object.values(filteredTjals).reduce((p, c) => p + c.length, 0) ? 1 : 0}
          currentRangeEnd={Object.values(filteredTjals).reduce((p, c) => p + c.length, 0)}
          totalItems={tjals.data["hydra:totalItems"]}
        />
      </section>
    </Page>
  );
};
