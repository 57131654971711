import { useQuery } from "react-query";
import { getIdFromIri } from "@brenger/utils";
import { MessageType } from "@brenger/react";

import { useTranslation } from ".";
import { coreClient } from "../utils";

interface Response {
  canConfirmStop: boolean;
  cannotConfirmStopReason: string | null;
  messageType: MessageType;
  loading: boolean;
}

interface UseCanConfirmStop {
  iri?: string;
  showSuccessMessage?: boolean;
}

/**
 * This hook accepts a stop and returns a "gated" message that indicates if a stop can be confirmed yet.
 * If the hook returns a message, it means that the stop CANNOT be confirmed.
 */
export const useCanConfirmStop = ({ iri, showSuccessMessage = true }: UseCanConfirmStop): Response => {
  const { t } = useTranslation();

  let reason = null;
  let type: MessageType = "info";
  let result = false;

  const isPickup = iri?.startsWith("/pickups");
  const canConfirmPickup = useQuery(
    ["CAN_CONFIRM", getIdFromIri(iri)],
    () => coreClient.pickups.canDriverConfirm(getIdFromIri(iri) as string),
    {
      enabled: !!isPickup,
      cacheTime: 0,
    }
  );

  if (isPickup) {
    result = !!canConfirmPickup.data?.result;

    const cannotConfirmPickupReason = canConfirmPickup.data?.reason;
    if (
      (cannotConfirmPickupReason && showSuccessMessage) ||
      (cannotConfirmPickupReason &&
        !showSuccessMessage &&
        cannotConfirmPickupReason !== "transition_blocked_by_current_state_pickup_confirmed_by_driver")
    ) {
      // NOTE: important to signal the value of this message via the correct message type.
      switch (cannotConfirmPickupReason) {
        case "transition_blocked_by_current_state_pickup_confirmed_by_driver":
          type = "success";
          break;
        default:
          type = "info";
      }

      const translatedReason = t((d) => d.planning.cannot_confirm_reason[cannotConfirmPickupReason]);
      // In case we do not yet have a translation for this reason, just show raw version.
      reason = translatedReason || cannotConfirmPickupReason;
    }
  }

  const isDelivery = iri?.startsWith("/deliveries");
  const canConfirmDelivery = useQuery(
    ["CAN_CONFIRM", getIdFromIri(iri)],
    () => coreClient.deliveries.canDriverConfirm(getIdFromIri(iri) as string),
    {
      enabled: !!isDelivery,
      cacheTime: 0,
    }
  );

  if (isDelivery) {
    result = !!canConfirmDelivery.data?.result;

    const cannotConfirmDeliveryReason = canConfirmDelivery.data?.reason;

    if (
      (cannotConfirmDeliveryReason && showSuccessMessage) ||
      (cannotConfirmDeliveryReason &&
        !showSuccessMessage &&
        cannotConfirmDeliveryReason !== "transition_blocked_by_current_state_delivery_confirmed_by_driver")
    ) {
      // NOTE: important to signal the value of this message via the correct message type.
      switch (cannotConfirmDeliveryReason) {
        case "transition_blocked_by_current_state_delivery_confirmed_by_driver":
          type = "success";
          break;
        default:
          type = "info";
      }

      const translatedReason = t((d) => d.planning.cannot_confirm_reason[cannotConfirmDeliveryReason]);
      // In case we do not yet have a translation for this reason, just show raw version.
      reason = translatedReason || cannotConfirmDeliveryReason;
    }
  }

  return {
    loading: canConfirmPickup.isLoading || canConfirmDelivery.isLoading,
    cannotConfirmStopReason: reason,
    canConfirmStop: result,
    messageType: type,
  };
};
