import { Button, useModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";
import OneSignalWeb from "react-onesignal";
import { Modal } from "../components";
import { usePersistedState, useTranslation } from "../hooks";
import { PersistedStorageKey } from "../utils";

export const WebPushPrompt: React.FC = () => {
  const { t } = useTranslation();
  const [hasSeenWebPushMessage, setHasSeenWebPushMessage] = usePersistedState<boolean>({
    initialState: false,
    defaultState: false,
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_WEB_PROMPT_NOTIFICATION,
  });
  const webPromptModal = useModalState();

  React.useEffect(() => {
    if (hasSeenWebPushMessage) {
      webPromptModal.close();
      return;
    }
    webPromptModal.open();
  }, [hasSeenWebPushMessage]);

  return (
    <Modal
      key={"modal"}
      isActive={webPromptModal.isActive}
      closeHandler={() => {
        setHasSeenWebPushMessage(true);
        webPromptModal.close();
      }}
    >
      <h3>{t((d) => d.web_push_modal.title)}</h3>
      <div>{t((d) => d.web_push_modal.body)}</div>
      <div className={cn("flex", "justify-center")}>
        <Button
          className={"mx-4"}
          buttonType="white"
          onClick={() => {
            setHasSeenWebPushMessage(true);
            webPromptModal.close();
          }}
        >
          {t((d) => d.web_push_modal.no)}
        </Button>
        <Button
          className={"mx-4"}
          buttonType="primary"
          onClick={() => {
            OneSignalWeb.showNativePrompt();
            webPromptModal.close();
          }}
        >
          {t((d) => d.web_push_modal.yes)}
        </Button>
      </div>
    </Modal>
  );
};
