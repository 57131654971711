import "@brenger/react/index.css";
import "@brenger/react/fontFace.css";
import "@brenger/react/fontClasses.css";

import { fontLoader } from "@brenger/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { App } from "./App";
import { AppContainer, ScrollPast } from "./components";
import { Config } from "./config";
import { ErrorBoundary } from "./ErrorBoundary";
import { useNativeStatusBar, ViewportProvider } from "./hooks";
import { configureStore } from "./store";
import { logger } from "./utils";

logger.init();
fontLoader();

// We might move this to api.ts - depending on further config
const queryClient = new QueryClient();

const Root: React.FC = () => {
  const { store, persistor } = configureStore();
  // Make white the default. See hook for more details.
  useNativeStatusBar({ backgroundColor: "white" });

  return (
    <>
      <Helmet defaultTitle="Brenger" titleTemplate="%s | Brenger" />
      <QueryClientProvider client={queryClient}>
        {Config.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
              <BrowserRouter>
                <ViewportProvider>
                  <AppContainer>
                    <ScrollPast />
                    <App />
                  </AppContainer>
                </ViewportProvider>
              </BrowserRouter>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("app-root"));
