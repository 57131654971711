import * as React from "react";

import { PreviewHeader } from "../../../components";
import { SearchJobListRaw } from "../../";

/**
 * This view is for non-authed users. It displays the Search Job List (aka Open Transport Jobs)
 * and adds some simple text / copy above it. This view is different from most views because it is
 * not wrapped in the usual Page component and does not have the navigation normally available to
 * auth'd users.
 */
export const PreviewJobList: React.FC = () => {
  return (
    <>
      <PreviewHeader />
      <SearchJobListRaw presentation="preview" />
    </>
  );
};
