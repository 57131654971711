import { DayRoute, GeoLocationDetails, ProposalDayRoute, UpdateDayRoute } from "@brenger/api-client";
import { Button, InputCheckbox, Label, Select } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import setDateTime from "date-fns/set";
import * as React from "react";
import { useMutation, UseQueryResult } from "react-query";
import { InputTextWithGeoAutocomplete, Options } from "../../../components";
import { useDayRouteParams, useForm, useFormatDate, useTranslation } from "../../../hooks";
import { coreClient, intervalsInTime } from "../../../utils";
import { DayRouteStartEndCard, TIME_PLACEHOLDER } from "./DayRouteStartEndCard";
import { DayRouteStartEndWrapper } from "./DayRouteStartLocationWrapper";

interface Props {
  dayRoute: UseQueryResult<DayRoute | ProposalDayRoute>;
}

export const DayRouteEndLocation: React.FC<Props> = ({ dayRoute }) => {
  const { t } = useTranslation();
  const params = useDayRouteParams();
  const formatHourMinute = useFormatDate("hour-minute");

  const [isEditingEditingLocation, setIsEditingEndLocation] = React.useState(false);

  const updateDefaults = useMutation(coreClient.dayRoutes.updateDefaults);
  const onSuccess = (): void => {
    dayRoute.refetch();
    setIsEditingEndLocation(false);
  };

  const updateRoute = useMutation(coreClient.dayRoutes.update, { onSuccess });
  const start = setDateTime(new Date(params.date), { hours: 11, minutes: 0, seconds: 0 });
  const end = setDateTime(new Date(params.date), { hours: 22, minutes: 0, seconds: 0 });
  const endTimeOptions = intervalsInTime(start, end, 15);
  const endAddress = dayRoute.data?.end_address;
  const endTime = dayRoute.data?.end_time;

  const form = useForm({
    initialState: {
      search: endAddress ? [endAddress.line1, endAddress.locality].filter(Boolean).join(", ") : "",
      // NOTE: when there is a end time, be sure to format it as an ISO string so that it matches the options.
      // This ensures that the Select is properly controlled and can initialize with any previously selected options.
      endTime: endTime ? new Date(endTime).toISOString() : TIME_PLACEHOLDER,
      place: undefined as GeoLocationDetails | undefined,
      saveAsDefault: false,
    },
    validators: {
      search: (val) => !val,
      endTime: (val) => !val || val === TIME_PLACEHOLDER,
    },
  });

  // When the day route changes, we need to keep the end location form up to date with active day route
  React.useEffect(() => {
    if (endAddress || endTime) {
      form.set({
        // @TODO make this DRY - same logic as in useForm's initial state.
        search: endAddress ? [endAddress.line1, endAddress.locality].filter(Boolean).join(", ") : "",
        // See note in useForm initial state.
        // @TODO make this DRY - same logic as in useForm's initial state.
        endTime: endTime ? new Date(endTime).toISOString() : TIME_PLACEHOLDER,
        saveAsDefault: false,
      });
    }
  }, [dayRoute]);

  const submitLocation = (): void => {
    // Grab the end time from the form (will be initialized there if already exists or added when selected for the first time)
    const dayRouteBody: UpdateDayRoute["dayRoute"] = {
      end_time: form.data.endTime.value,
    };

    // If there is a place returned by the geo input, favor that over the default end address.
    const place = form.data.place.value;

    if (place) {
      dayRouteBody.end_address = {
        line1: place.address.line1 || "",
        line2: place.address.line2 || "",
        postal_code: place.address.postal_code || "",
        locality: place.address.locality,
        municipality: place.address.secondary_subdivision,
        country_code: place.address.country_code,
        lat: place.address.latitude,
        lng: place.address.longitude,
      };
    }

    if (form.data.saveAsDefault.value) {
      updateDefaults.mutate({
        to: {
          hour: new Date(form.data.endTime.value).getUTCHours(),
          minute: new Date(form.data.endTime.value).getUTCMinutes(),
        },
        user: params.user_id,
        end_address: dayRouteBody?.end_address || dayRoute.data?.end_address || undefined,
      });
    }

    updateRoute.mutate({
      routeId: getIdFromIri(dayRoute.data) as string,
      dayRoute: dayRouteBody,
    });
  };

  if (isEditingEditingLocation) {
    return (
      <DayRouteStartEndWrapper type={"end"}>
        <Label text={t((d) => d.day_route.end_location.labels.end_location)}>
          <InputTextWithGeoAutocomplete
            value={form.data.search.value}
            onChange={(search) => form.set({ search })}
            onSelect={(place) => form.set({ place })}
            includeHouseNumber={true}
          />
        </Label>
        <div className={cn("pt-4")}>
          <Label text={t((d) => d.day_route.end_location.labels.end_time)}>
            <Select
              className={cn("w-full")}
              value={form.data.endTime.value}
              onChange={(nextStartTime: string) => {
                form.set({ endTime: nextStartTime });
              }}
            >
              <Options placeholderText={TIME_PLACEHOLDER} placeholderValue={TIME_PLACEHOLDER}>
                {endTimeOptions?.map((time, idx) => {
                  return (
                    <option key={idx} value={time}>
                      {formatHourMinute(time)}
                    </option>
                  );
                })}
              </Options>
            </Select>
          </Label>
        </div>
        <div className={cn("py-4")}>
          <Label text={"Save for every day of the week"} position={"right"}>
            <InputCheckbox
              checked={form.data.saveAsDefault.value}
              onChange={() => {
                form.set({ saveAsDefault: !form.data.saveAsDefault.value });
              }}
            />
          </Label>
        </div>
        <div className={cn("flex")}>
          <Button
            className={cn("mr-2", "w-full")}
            buttonType={"gray-outline"}
            onClick={() => setIsEditingEndLocation(false)}
          >
            {t((d) => d.day_route.start_location.labels.cancel)}
          </Button>
          <Button
            className={cn("ml-2", "w-full")}
            disabled={form.hasErrors}
            loading={updateRoute.isLoading}
            buttonType="secondary"
            onClick={submitLocation}
          >
            {t((d) => d.day_route.start_location.labels.save)}
          </Button>
        </div>
      </DayRouteStartEndWrapper>
    );
  }

  return (
    <DayRouteStartEndWrapper type={"end"} time={dayRoute.data?.end_address_eta || dayRoute.data?.end_time || undefined}>
      <DayRouteStartEndCard
        location={form.data?.search?.value}
        LocationTime={{ label: t((d) => d.day_route.end_location.labels.time_end), value: form.data.endTime.value }}
        setIsEditingEndLocation={() => setIsEditingEndLocation(true)}
      />
    </DayRouteStartEndWrapper>
  );
};
