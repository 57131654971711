import * as React from "react";
import BackgroundGeolocation, {
  CurrentPositionRequest,
  Location,
  LocationError,
  ProviderChangeEvent,
} from "@transistorsoft/capacitor-background-geolocation";
import * as Sentry from "@sentry/react";
import { DeviceInfo } from "@capacitor/device";

import { useNativePlatform } from "../hooks";
import { Config } from "../config";

type Authorization =
  | "AUTHORIZATION_STATUS_NOT_DETERMINED"
  | "AUTHORIZATION_STATUS_RESTRICTED"
  | "AUTHORIZATION_STATUS_DENIED"
  | "AUTHORIZATION_STATUS_ALWAYS"
  | "AUTHORIZATION_STATUS_WHEN_IN_USE";

type AccuracyAuthorization = "ACCURACY_AUTHORIZATION_FULL" | "ACCURACY_AUTHORIZATION_REDUCED";

interface UseGeolocationReadyParams {
  deviceId: string;
  deviceInfo: DeviceInfo;
  userId: string;
  channelName: string;
  rationaleTitle: string;
  rationaleText: string;
  notificationTitle: string;
  notificationText: string;
}

export interface UseGeolocation {
  authorizationStatus: Authorization;
  accuracyAuthorization: AccuracyAuthorization;
  isAuthorized: boolean;
  isPluginEnabled: boolean;
  isDeviceEnabled: boolean;
  isPluginReady: boolean;
  isGpsOn: boolean;
  start(): Promise<void>;
  stop(): Promise<void>;
  ready(params: UseGeolocationReadyParams): Promise<void>;
  triggerProviderStateCallback(): Promise<void>;
  triggerPluginStateCallback(): Promise<void>;
  getCurrentPosition(
    options: CurrentPositionRequest,
    success?: (location: Location) => void,
    failure?: (errorCode: LocationError) => void
  ): Promise<Location>;
}

export const useGeolocation = (): UseGeolocation => {
  const isNativePlatform = useNativePlatform();

  const [isDeviceEnabled, setIsDeviceEnabled] = React.useState(false);
  const [isPluginEnabled, setIsPluginEnabled] = React.useState(false);
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isGpsOn, setIsGpsOn] = React.useState(false);
  const [isPluginReady, setIsPluginReady] = React.useState(false);
  const [accuracyAuthorization, setAccuracyAuthorization] = React.useState<AccuracyAuthorization>(
    "ACCURACY_AUTHORIZATION_REDUCED"
  );
  const [authorizationStatus, setAuthorizationStatus] = React.useState<Authorization>(
    "AUTHORIZATION_STATUS_NOT_DETERMINED"
  );

  /**
   * MAKE THE GEOLOCATION PLUGIN "READY"
   */
  const ready = React.useCallback(
    async (params: UseGeolocationReadyParams) => {
      if (!isNativePlatform) return;

      try {
        await BackgroundGeolocation.ready({
          // Read as desired "location setting".
          // It's basically up to user, but in app we will provide more guidance to make the right choice
          // Either by the priming modal before giving permission, or by the red message on top of the app to draw attention
          // Setting this option to "any" prevents "backgroundPermissionRationale" modal to pop up.
          locationAuthorizationRequest: "WhenInUse",
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          // Allow the background-service to continue tracking when app terminated.
          stopOnTerminate: false,
          // Auto start tracking when device is powered-up
          startOnBoot: true,
          // https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#distancefilter:~:text=Optional-,distanceFilter,-distanceFilter%3A
          distanceFilter: 100,
          // keeping max persisted records 10.000 record
          // this is to prevent the app from growing a lot in disk size, and still have about 1000 kms of logging if needed.
          maxRecordsToPersist: 10000,
          // Only track working hours
          schedule: ["1-7 6:00-22:00"],
          foregroundService: true,
          forceReloadOnBoot: true,
          url: Config.USER_LOCATION_URL,
          backgroundPermissionRationale: {
            title: params.rationaleTitle,
            message: params.rationaleText,
          },
          notification: {
            channelName: params.channelName,
            title: params.notificationTitle,
            text: params.notificationText,
            smallIcon: "drawable/b_icon",
            largeIcon: "drawable/b_icon",
          },
          headers: {
            // Use this header to communicate the version of the plugin that is provding coordinates.
            // The "old" plugin we initially used provided a different body, so our geo service needs to know.
            "X-GEO-PAYLOAD-VERSION": 2,
          },
          extras: {
            userId: params.userId,
            deviceId: params.deviceId || null,
          },
        });
        setIsPluginReady(true);
      } catch (error) {
        Sentry.captureMessage("Making geolocation plugin ready failed.", {
          extra: { error },
        });
      }
    },
    [isNativePlatform]
  );

  /**
   * STATE HANDLER
   * Note: Because this handler gets re-used inside other callbacks, there is no point in memo-izing it.
   */
  const handleProviderEvent = (event: ProviderChangeEvent): void => {
    setIsDeviceEnabled(event.enabled);
    setIsGpsOn(event.gps);

    /**
     * iOS only - this is related to iOS 14 location accuracy settings.
     */
    switch (event.accuracyAuthorization) {
      case BackgroundGeolocation.ACCURACY_AUTHORIZATION_FULL:
        setIsAuthorized(true);
        setAccuracyAuthorization("ACCURACY_AUTHORIZATION_FULL");
        break;

      default:
        setIsAuthorized(false);
        setAccuracyAuthorization("ACCURACY_AUTHORIZATION_REDUCED");
        break;
    }

    /**
     * Check status events coming from underlying device.
     */
    switch (event.status) {
      case BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED:
        // iOS only
        setIsAuthorized(false);
        setAuthorizationStatus("AUTHORIZATION_STATUS_NOT_DETERMINED");
        break;
      case BackgroundGeolocation.AUTHORIZATION_STATUS_RESTRICTED:
        // iOS only
        setIsAuthorized(false);
        setAuthorizationStatus("AUTHORIZATION_STATUS_RESTRICTED");
        break;
      case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
        // Android & iOS
        setIsAuthorized(false);
        setAuthorizationStatus("AUTHORIZATION_STATUS_DENIED");
        break;
      case BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS:
        // Android & iOS
        setIsAuthorized(true);
        setAuthorizationStatus("AUTHORIZATION_STATUS_ALWAYS");
        break;
      case BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE:
        // Android & iOS
        // We changed our demands, "when in use" is enough
        setIsAuthorized(true);
        setAuthorizationStatus("AUTHORIZATION_STATUS_WHEN_IN_USE");
        break;
    }
  };

  /**
   * MOUNT GEOLOCATION EVENT LISTENERS
   */
  React.useEffect(() => {
    if (!isNativePlatform) return;
    /**
     * onEnabledChange - eg: when start/stop get triggered.
     */
    BackgroundGeolocation.onEnabledChange((isEnabled) => {
      setIsPluginEnabled(isEnabled);
    });

    /**
     * onProviderChange - when something on the device is changed - eg: permissions, location settings, etc.
     */
    BackgroundGeolocation.onProviderChange(handleProviderEvent);

    return () => {
      BackgroundGeolocation.removeListeners();
    };
  }, [isNativePlatform]);

  /**
   * START GEOLOCATION PLUGIN
   */
  const start = React.useCallback(async (): Promise<void> => {
    if (!isPluginReady) return;

    try {
      await BackgroundGeolocation.startSchedule();
    } catch (error) {
      Sentry.captureMessage("Error starting geolocation plugin.", { extra: { error } });
    }
  }, [isPluginReady]);

  /**
   * STOP GEOLOCATION PLUGIN
   */
  const stop = React.useCallback(async (): Promise<void> => {
    if (!isNativePlatform) return;

    try {
      // https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#schedule:~:text=BackgroundGeolocation.stop()%3B
      // explicitly stop the schedule
      await BackgroundGeolocation.stopSchedule();
      // explicitly stop tracking
      await BackgroundGeolocation.stop();
    } catch (error) {
      Sentry.captureMessage("Error stopping geolocation plugin.", { extra: { error } });
    }
  }, [isNativePlatform]);

  /**
   * A way to manually check the start/stop state of the plugin.
   */
  const triggerPluginStateCallback = React.useCallback(async () => {
    if (!isNativePlatform) return;

    const pluginState = await BackgroundGeolocation.getState();
    setIsPluginEnabled(pluginState.enabled);
  }, [isNativePlatform]);

  /**
   * A way to manually check the settings and state of the underlying platform / device.
   */
  const triggerProviderStateCallback = React.useCallback(async () => {
    if (!isNativePlatform) return;

    const providerState = await BackgroundGeolocation.getProviderState();
    handleProviderEvent(providerState);
  }, [isNativePlatform]);

  return {
    start,
    stop,
    isAuthorized,
    authorizationStatus,
    isPluginEnabled,
    isDeviceEnabled,
    ready,
    isGpsOn,
    isPluginReady,
    accuracyAuthorization,
    triggerPluginStateCallback: triggerPluginStateCallback,
    triggerProviderStateCallback: triggerProviderStateCallback,
    getCurrentPosition: BackgroundGeolocation.getCurrentPosition,
  };
};
