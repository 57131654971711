import * as React from "react";

import { userDataClient } from "../../../utils";
import { Modal } from "../../../components";
import { useTranslation } from "../../../hooks";
import { Button, Spacer } from "@brenger/react";
import cn from "classnames";

interface Props {
  isActive: boolean;
  closeHandler(): void;
  selectedPhotoProofIndex: number;
  photoProofIris: string[];
  setPhotoProofIris(dataUrls: string[]): void;
}

export const ConfirmStopImageModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const currentResource = props.photoProofIris[props.selectedPhotoProofIndex];

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <Spacer h={4} />
      <img src={userDataClient.getBaseForIri(currentResource)} />
      <Spacer h={4} />
      <Button
        className={cn("w-full")}
        buttonType="warning"
        onClick={() => {
          // remove the selected index from the parent components state
          props.setPhotoProofIris(
            props.photoProofIris.filter((...args) => {
              const idx = args[1];
              return props.selectedPhotoProofIndex !== idx;
            })
          );
          // close the modal
          props.closeHandler();
        }}
      >
        {t((d) => d.planning.labels.remove_photo)}
      </Button>
      <Spacer h={1} />
      <Button className={cn("w-full")} buttonType="white" onClick={props.closeHandler}>
        {t((d) => d.modal.cancel)}
      </Button>
    </Modal>
  );
};
