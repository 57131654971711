import * as React from "react";
import cn from "classnames";

export const AppContainer: React.FC = (props) => {
  return (
    // See css rules for this id, as there are some important mobile considerations stated there.
    <main id="app-container" className={cn("flex", "flex-col")}>
      {props.children}
    </main>
  );
};
