import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import * as Sentry from "@sentry/react";
// Below is local storage.
// import storage from "redux-persist/lib/storage";
// Below is session storage
import storage from "redux-persist/lib/storage";

import { searchFilter } from "./search/filter";
import { planningFilter } from "./planning/filter";
import { deliveredFilter } from "./delivered/filter";
import { settings } from "./settings";

export const rootReducer = combineReducers({
  searchFilter,
  planningFilter,
  settings,
  deliveredFilter,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    /**
     * This config dictate the persistor version for our redux store.
     * Whenever this value changes, we clear the client's local storage
     * and reset the redux state to the default values.
     */
    version: 1.0,
    // Add reducers that should not be persisted to this list.
    blacklist: [],
    // Check persisted version against current version and reset persisted state any time there is a mismatch.
    // https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md#alternative
    migrate(state, nextVersion) {
      // Get a reference to the previously persisted version of the app.
      const previousVersion = state?._persist.version || nextVersion;
      // If previous version is less than next version (aka, the version configured above)
      // then purge all redux-related storage!
      if (previousVersion < nextVersion) {
        // Resolving with undefined effectively resets the persisted states.
        return Promise.resolve(undefined);
      }
      return Promise.resolve(state);
    },
  },
  rootReducer
);

// Add redux state to sentry logs.
// https://docs.sentry.io/platforms/javascript/react/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureStore = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = createStore(persistedReducer, composeWithDevTools(sentryReduxEnhancer as any));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const persistor = persistStore(store as any);
  return {
    store,
    persistor,
  };
};
