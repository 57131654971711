import { DayRouteActivity } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import * as React from "react";
import { useQuery } from "react-query";
import { BreadcrumbNav, Page } from "../../../components";
import { useDayRouteStopParams, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { DayRouteCustomStopDetails } from "./DayRouteCustomStopDetails";
import { DayRouteNormalStopDetails } from "./DayRouteNormalStopDetails";

export const getDayRouteStopHumanIndex = (currentId: string, activities: DayRouteActivity[] = []): number | string => {
  const activitiesWithhoutBreak = activities.filter((activity) => activity.type !== "break") || [];
  const indexFound = activitiesWithhoutBreak.findIndex((activity) => getIdFromIri(activity) === currentId);
  if (indexFound > -1) {
    return indexFound + 1;
  }
  return "?";
};

export const DayRouteStopDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useDayRouteStopParams();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );
  const stopIndex = getDayRouteStopHumanIndex(params.stop_id, dayRoute.data?.day_route_activities);
  const activity = dayRoute.data?.day_route_activities?.find((act) => getIdFromIri(act) === params.stop_id);

  const breadcrumbs = [
    { to: Routes.PLANNING_JOB_LIST, text: t((d) => d.app.tabs.planning) },
    { to: `/day-route/${params.date}/${params.user_id}`, text: t((d) => d.day_route.details.title) },
    { text: "--" },
  ];
  if (activity) {
    breadcrumbs[2].text = `${stopIndex}: ${activity.address?.locality}`;
  }

  if (!activity) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        errorText={(dayRoute.error as Error)?.message || t((d) => d.day_route.activity_not_found)}
        loading={dayRoute.isLoading}
      />
    );
  }
  return (
    <>
      {["pickup", "delivery"].includes(activity.type) && (
        <DayRouteNormalStopDetails activity={activity} breadcrumbs={breadcrumbs} />
      )}
      {!["pickup", "delivery"].includes(activity.type) && (
        <DayRouteCustomStopDetails activity={activity} breadcrumbs={breadcrumbs} />
      )}
    </>
  );
};
