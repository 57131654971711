import * as React from "react";

import { PreviewHeader } from "../../../components";
import { SearchJobListFilterRaw } from "../../";

/**
 * The preview job list filter page is basically the same as the regualar job list filter.
 * The only difference is the page URL. Therefore, pass a "presentation" prop to the
 * filter component and handle the minor routing differences accordingly.
 */
export const PreviewJobListFilter: React.FC = () => {
  return (
    <>
      <PreviewHeader />
      <SearchJobListFilterRaw presentation="preview" />
    </>
  );
};
