import * as React from "react";
import cn from "classnames";
import { useMutation } from "react-query";
import { Redirect, useHistory } from "react-router-dom";
import isBefore from "date-fns/isBefore";
import {
  Pickup,
  Delivery,
  Route,
  TransportJob,
  Address,
  PickupCommitment,
  DeliveryCommitment,
} from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Timeline, Button, Spacer, Strong, Emphasis, IconUpdate, IconSync } from "@brenger/react";

import { Page, BreadcrumbNav, MetaItem } from "../../../components";
import { usePlanningParams, useTjalContext, useTranslation, useFormatTimeframe } from "../../../hooks";
import { coreClient, Routes, formatLocality } from "../../../utils";

import { reducer, initialState } from "./store";

export const getOrderedStopsFromTj = (tj?: TransportJob, route?: Route | null): Array<Pickup | Delivery> => {
  if (!tj) return [];

  const allStops = [...tj.pickups, ...tj.deliveries];

  if (tj && !tj.bundled) return allStops;

  if (tj.bundled && !route) return allStops;

  const orderedRouteStopIds = [...(route?.stops || [])]
    .sort((a) => {
      return a.index;
    })
    .map((routeStop) => {
      return routeStop.pickup || routeStop.delivery;
    });

  return orderedRouteStopIds
    .map((stopId) => {
      // We can safely assume there is a Pickup or Delivery because we filter out
      // undefined cases next!
      return allStops.find((stop) => stop["@id"] === stopId) as Pickup | Delivery;
    })
    .filter(Boolean);
};

export const PlanningJobDetailsUpdate: React.FC = () => {
  const formatTimeframe = useFormatTimeframe();
  const { t } = useTranslation();
  const params = usePlanningParams();
  const { push } = useHistory();
  const { tjal, tj, route } = useTjalContext(params.tjal_id);
  const stops = getOrderedStopsFromTj(tj.data, route.data);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const jobDetailsPage = `${Routes.PLANNING_JOB_LIST}/${params.tjal_id}`;
  const updateDtps = useMutation(coreClient.dateTimePeriods.update);

  // A list of the DTPs per stop (grabbed from the TJAL)
  const pickupDtps = (tjal.data?.pickup_commitments as PickupCommitment[] | undefined)?.map((commitment) => {
    return {
      stop: (commitment.pickup as Pickup)["@id"],
      dtp: commitment.committed_datetime_period,
    };
  });

  const deliveryDtps = (tjal.data?.delivery_commitments as DeliveryCommitment[] | undefined)?.map((commitment) => {
    return {
      stop: (commitment.delivery as Delivery)["@id"],
      dtp: commitment.committed_datetime_period,
    };
  });

  const dtps = [...(pickupDtps || []), ...(deliveryDtps || [])];

  React.useEffect(() => {
    if (stops.length && dtps.length) {
      dispatch({
        type: "SET_STOPS",
        payload: { stops, dtps },
      });
    }
  }, [stops.length, dtps.length]);

  const breadcrumbs = [
    {
      text: t((d) => d.app.tabs.planning),
      to: Routes.PLANNING_JOB_LIST,
    },
    {
      text: `#${tj.data?.short_id || "--"}`,
      to: jobDetailsPage,
    },
    // @TODO: trans needed
    { text: "Update" },
  ];

  const onSubmit = (): void => {
    updateDtps.reset();
    // Collect all updated DTPs and send to core in one batch.
    const updatedDtps = state
      .filter((s) => {
        return s.isUpdated;
      })
      .map((s) => {
        const dtpId = getIdFromIri(s.dtp);
        return {
          id: dtpId || "",
          dateTimePeriod: {
            start: s.dtp.start,
            end: s.dtp.end,
          },
        };
      });
    updatedDtps.forEach((dtp) => {
      updateDtps.mutate(dtp, {
        onSuccess: () => {
          window.location.assign(jobDetailsPage);
        },
      });
    });
  };

  const hasUpdatedDtps = state.some((stop) => stop.isUpdated);

  // We only allow time frame update for bundled and guranteed jobs (aka directly claimable)
  if (tj.data && !tj.data.directly_claimable && !tj.data.bundled) {
    return <Redirect to={jobDetailsPage} />;
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
      loading={tjal.isLoading || tj.isLoading}
      errorText={updateDtps.error ? (updateDtps.error as Error)?.message : null}
      stickyFooter={
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          loading={updateDtps.isLoading}
          disabled={!hasUpdatedDtps}
          onClick={onSubmit}
          icon={<IconSync className={cn("h-4")} />}
        >
          {t((d) => d.actions.update_timeframes)}
        </Button>
      }
    >
      <div>{t((d) => d.planning.messages.update_timeframes_desc)}</div>
      <Spacer h={4} />
      <div className={cn("flex", "justify-between")}>
        <Button size="sm" buttonType="primary-outline" onClick={() => push(jobDetailsPage)}>
          {t((d) => d.modal.cancel)}
        </Button>
        <Button
          size="sm"
          disabled={!hasUpdatedDtps}
          onClick={() => {
            if (tj.data && tjal.data) {
              dispatch({ type: "SET_STOPS", payload: { stops, dtps } });
            }
          }}
        >
          {t((d) => d.actions.reset_timeframes)}
        </Button>
      </div>
      <Spacer h={4} />
      {state.map(({ stop, dtp, availableMinutesToDecrement, availableMinutesToIncrement, isUpdated }, idx) => {
        // The stops are already ordered, so safe to use idx + 1 as actual route stop number.
        const stopNumber = idx + 1;

        // Only show the update controls if we are still BEFORE the change deadline returned by core.
        const canUpdate =
          stop.last_change_time_deadline && isBefore(new Date(), new Date(stop.last_change_time_deadline));

        return (
          <Timeline key={idx} isFirst={idx === 0} isLast={idx === state.length - 1}>
            <div className={cn("flex", "justify-between")}>
              <div className={cn("pr-4", "flex", "flex-col")}>
                <MetaItem
                  name={`${t((d) => d.transport_job.stop)} ${stopNumber}`}
                  value={formatLocality(
                    (stop.address as Address).locality,
                    (stop.address as Address).administrative_area
                  )}
                />
                <div className={cn("flex", "items-center")}>
                  <div className={cn("text-blue-600")}>
                    <Strong>{formatTimeframe({ start: dtp.start, end: dtp.end })}</Strong>
                  </div>
                  {/* Show a little update icon when the DTP has been dirtied. */}
                  {isUpdated && <IconUpdate className={cn("w-4", "h-4", "ml-1", "text-green-400")} />}
                </div>
                {!canUpdate && (
                  <div className={cn("text-gray-800")}>
                    <Emphasis>{t((d) => d.planning.messages.update_deadline_exceeded)}</Emphasis>
                  </div>
                )}
              </div>
              <div className={cn("flex", "items-center", "flex-shrink-0")}>
                {canUpdate && (
                  <div className={cn("flex", "flex-wrap")}>
                    <Button
                      buttonType="primary-outline"
                      size="sm"
                      disabled={availableMinutesToDecrement === 0}
                      onClick={() => {
                        dispatch({ type: "DECREMENT", payload: { stop } });
                      }}
                    >
                      <Strong className={cn("lowercase", "flex")}>-{availableMinutesToDecrement}m</Strong>
                    </Button>
                    <Spacer w={1} h={1} />
                    <Button
                      buttonType="primary-outline"
                      size="sm"
                      disabled={availableMinutesToIncrement === 0}
                      onClick={() => {
                        dispatch({ type: "INCREMENT", payload: { stop } });
                      }}
                    >
                      <Strong className={cn("lowercase", "flex")}>+{availableMinutesToIncrement}m</Strong>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Timeline>
        );
      })}
    </Page>
  );
};
