import { Button, InputText, Label, Message, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Modal } from "../../../components";
import { useAuth, useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, getDigitLength, isEmailValid } from "../../../utils";

interface Props {
  isActive: boolean;
  closeHandler(): void;
}

export const UserAddModal: React.FC<Props> = (props) => {
  const auth = useAuth();

  const accountAddressId = getIdFromIri(auth.user?.account?.address) || "";
  const accountAddress = useQuery(
    [CacheKey.RETRIEVE_ADDRESS, accountAddressId],
    () => coreClient.addresses.retrieve({ id: accountAddressId }),
    {
      enabled: !!accountAddressId,
    }
  );
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const form = useForm({
    initialState: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    validators: {
      first_name: (val) => !val,
      last_name: (val) => !val,
      email: (val) => !isEmailValid(val),
      phone: (val) => !val || getDigitLength(val) < 10 || getDigitLength(val) > 14,
    },
  });

  const createUserForAccountAdmin = useMutation(coreClient.users.createUserForAdmin, {
    onSuccess: () => {
      // This is the mutation success callback.
      // Trigger a page reload when a new user has been successfully created.
      // This ensures that cache is cleared and that the profile > details page will
      // start displaying the newly created user in the Driver Select.
      queryClient.clear();
      window.location.reload();
    },
  });

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <h3>{t((d) => d.profile.add_user)}</h3>
      <Spacer h={2} />
      <Label
        text={t((d) => d.profile.first_name)}
        error={form.isFieldDirty("first_name") && form.getError("first_name")}
      >
        <InputText
          className={cn("w-full")}
          value={form.getValue("first_name")}
          onChange={(first_name) => form.set({ first_name })}
        />
      </Label>
      <Spacer h={4} />
      <Label text={t((d) => d.profile.last_name)} error={form.isFieldDirty("last_name") && form.getError("last_name")}>
        <InputText
          className={cn("w-full")}
          value={form.getValue("last_name")}
          onChange={(last_name) => form.set({ last_name })}
        />
      </Label>
      <Spacer h={4} />

      <Label text={t((d) => d.profile.email)} error={form.isFieldDirty("email") && form.getError("email")}>
        <InputText className={cn("w-full")} value={form.getValue("email")} onChange={(email) => form.set({ email })} />
      </Label>
      <Spacer h={4} />

      <Label text={t((d) => d.profile.phone_number)} error={form.isFieldDirty("phone") && form.getError("phone")}>
        <InputText className={cn("w-full")} value={form.getValue("phone")} onChange={(phone) => form.set({ phone })} />
      </Label>
      <Spacer h={4} />
      <Button
        className={cn("w-full")}
        loading={createUserForAccountAdmin.isLoading}
        buttonType="secondary"
        disabled={form.hasErrors}
        onClick={() => {
          // Cannot proceed with creating a new user if the account address details cannot be fetched.
          // We do not ask the driver to provide these details when creating a sub account. Rather, we
          // copy the account details to the new user's address field.
          if (!accountAddress.data) return null;

          createUserForAccountAdmin.reset();
          createUserForAccountAdmin.mutate({
            first_name: form.getValue("first_name"),
            last_name: form.getValue("last_name"),
            gender: "unknown",
            email: form.getValue("email"),
            username: form.getValue("email"),
            // A sub-account created by an account admin should be activate right away.
            is_suspended: false,
            has_professional_profile: true,
            phone: form.getValue("phone"),
            // For now, we will only allow account admins to create "ROLE_DRIVER" users.
            // In the future, we may want to support a select UI so different types can be created.
            roles: ["ROLE_DRIVER"],
            address: {
              country_code: accountAddress.data?.country_code,
              locality: accountAddress.data?.locality,
              postal_code: accountAddress.data?.postal_code,
              line1: accountAddress.data?.line1,
              line2: accountAddress.data?.line2,
            },
          });
        }}
      >
        {t((d) => d.actions.submit)}
      </Button>
      <Spacer h={2} />
      <Button className={cn("w-full")} buttonType="primary-outline" onClick={props.closeHandler}>
        {t((d) => d.modal.cancel)}
      </Button>
      {createUserForAccountAdmin.isError && (
        <Message className={cn("mt-2")} type="error">
          {(createUserForAccountAdmin.error as Error)?.message}
        </Message>
      )}
    </Modal>
  );
};
