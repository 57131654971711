import { NpsScore } from "@brenger/api-client";
import { Button, InputRange, Spacer, useModalState } from "@brenger/react";
import cn from "classnames";
import { addMonths, isAfter } from "date-fns";
import * as React from "react";
import { useMutation } from "react-query";

import { Modal } from "../../components";
import { useAuth, usePersistedState, useTranslation } from "../../hooks";
import { coreClient, PersistedStorageKey } from "../../utils";

export const NpsForm: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const modalState = useModalState();
  const [npsScore, setNpsScore] = React.useState<undefined | NpsScore>();
  const [nextNpsDate, setNextNpsDate] = usePersistedState<string | null>({
    initialState: "not-ready", // when localstorage is not retrieved yet
    defaultState: null, // when nothing is set yet
    version: 1,
    key: PersistedStorageKey.NEXT_NPS_DATE,
  });
  const now = new Date();

  // Setup DriverReview
  const createReview = useMutation(coreClient.driverReviews.create, {
    onSuccess: (): void => {
      // After succesful filing a driver review, ask again after a month
      setNextNpsDate(addMonths(now, 1).toISOString());
    },
  });

  React.useEffect(() => {
    // bail early when not logged in or when storage isn't ready or suspended or new user
    const monthOldUser = auth?.user?.created_at && isAfter(now, addMonths(new Date(auth.user.created_at), 1));
    if (
      typeof nextNpsDate === "undefined" ||
      nextNpsDate === "not-ready" ||
      !auth.isLoggedIn ||
      auth.isSuspended ||
      !monthOldUser
    ) {
      return;
    }
    if (nextNpsDate === null || isAfter(now, new Date(nextNpsDate))) {
      modalState.open();
      return;
    }
    modalState.close();
  }, [nextNpsDate, auth]);

  const closehandler = (): void => {
    modalState.close();
    // When the driver decides to ignore this time, ask again after a month
    setNextNpsDate(addMonths(now, 1).toISOString());
  };

  return (
    <Modal
      isActive={auth.isLoggedIn && modalState.isActive}
      closeHandler={closehandler}
      autoScroll={true}
      closeByBackground={false}
    >
      <h3>{t((d) => d.nps.modal.title)}</h3>
      <div>{t((d) => d.nps.modal.body)}</div>
      <Spacer h={2} />
      <InputRange
        min={1}
        max={10}
        value={npsScore || 0}
        withScale={true}
        onChange={(score) => setNpsScore(Number(score) as NpsScore)}
      />
      <Spacer h={2} />
      <div className={cn("flex", "justify-between")}>
        <Button buttonType={"primary-outline"} onClick={closehandler}>
          {t((d) => d.nps.modal.remind_later)}
        </Button>
        <Button
          disabled={typeof npsScore === "undefined"}
          loading={createReview.isLoading}
          onClick={() => createReview.mutate({ score: npsScore as NpsScore })}
        >
          {t((d) => d.nps.modal.submit)}
        </Button>
      </div>
    </Modal>
  );
};
