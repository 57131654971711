import { Animation, createAnimation } from "@ionic/core";

interface AnimationOptions {
  animated: boolean;
  direction: "forward" | "back";
  enteringEl: Element;
  leavingEl: Element;
}

export const slideInNavAnimation = (_: Element, opts: AnimationOptions): Animation => {
  const isForward = opts.direction === "forward";
  return createAnimation()
    .addElement(isForward ? opts.enteringEl : opts.leavingEl)
    .easing("cubic-bezier(.37,1.03,.81,1.01)")
    .keyframes([
      { offset: 0, transform: "translate3d(100vw,0,0)" },
      { offset: 1, transform: "translate3d(0,0,0)" },
    ])
    .direction(isForward ? "normal" : "reverse")
    .duration(300);
};
