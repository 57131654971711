import * as React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Spacer, IconBrengerLogo, IconAccount } from "@brenger/react";

import { LanguageSelect } from "..";
import { Routes } from "../../utils";

/**
 * The header to be shown on preview pages (when user is not auth'd)
 */
export const PreviewHeader: React.FC = () => {
  return (
    <div className={cn("flex", "items-center", "justify-between", "px-4", "py-3")}>
      <IconBrengerLogo className={cn("text-blue-600", "fill-current")} />
      <div className={cn("flex", "items-center")}>
        <LanguageSelect />
        <Spacer w={6} />
        <Link to={Routes.LOGIN}>
          <IconAccount className={cn("text-gray-800", "sm:h-10", "sm:w-10", "h-8", "w-8")} />
        </Link>
      </div>
    </div>
  );
};
