import { Account } from "@brenger/api-client";
import { Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";

import { DriverSelect } from "../../../components";
import { useAuth, useTranslation, useUrlSearchParam } from "../../../hooks";
import { NewPageWrapper } from "../layout/PageWrapper";
import { UserAccountForm } from "./UserAccountForm";
import { UserAddressForm } from "./UserAddressForm";
import { UserBankAccountForm } from "./UserBankAccountForm";
import { UserDetailsForm } from "./UserDetailsForm";
import { UserProfileImage } from "./UserProfileImage";
import { IonicRoutes } from "../../../utils";

export const MoreProfile: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const account = auth.user?.account as Account | undefined;
  const userParam = useUrlSearchParam({ key: "user_iri", delete: true, defaultValue: auth.user?.["@id"] });
  const [selectedUserIRI, setSelectedUserIRI] = React.useState("");

  React.useEffect(() => {
    if (userParam) setSelectedUserIRI(userParam);
  }, [userParam]);

  return (
    <NewPageWrapper title={t((d) => d.more.profile)} backUrl={IonicRoutes.more.home()}>
      <UserProfileImage
        userId={getIdFromIri(selectedUserIRI)}
        accountName={account?.name}
        // Can only edit your own profile image.
        canEdit={auth.user?.["@id"] === selectedUserIRI}
      />
      <Spacer h={4} />
      <h5>{t((d) => d.profile.personal_details)}</h5>
      <DriverSelect
        className={cn("mt-2", "mb-2")}
        labelText={t((d) => d.profile.select_courier)}
        accountId={getIdFromIri(auth.user?.account)}
        value={selectedUserIRI}
        onChange={({ userIRI }) => setSelectedUserIRI(userIRI)}
      />
      <Spacer h={2} />
      {/* all can edit own details */}
      <UserDetailsForm userId={getIdFromIri(selectedUserIRI)} canEdit={true} />
      {/* Only account admin can see the address details */}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.address_details)}</h5>
          <Spacer h={2} />
          <UserAddressForm addressId={getIdFromIri(auth.user?.address)} canEdit={true} />
        </>
      )}
      {/* Only account admin can see the address details */}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.account_details)}</h5>
          <Spacer h={2} />
          <UserAccountForm accountId={getIdFromIri(auth.user?.account)} canEdit={true} />
        </>
      )}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.bank_details)}</h5>
          <Spacer h={2} />
          <UserBankAccountForm
            canEdit={true}
            bankAccountIds={(auth.user?.account as Account | undefined)?.bank_accounts
              .map((iri) => getIdFromIri(iri) || "")
              .filter(Boolean)}
          />
        </>
      )}
    </NewPageWrapper>
  );
};
