import * as React from "react";

/**
 * On iOS devices, the notch / status bar is handled uniquely in some cases.
 * The viewport-fit of the app is set to "cover" so that the entire viewport (including the area around the notch) gets used.
 * However, to prevent the status bar from being covered, all the app content is pushed below the notch (aka fixed div) by
 * using the webkit-supported CSS "env(sare-area-inset-{top|right|bottom|left})" variable. See "index.css" for further details.
 * This hook is responsible for changing the color of that fixed div so that white/blue screens are seamless.
 * Another note: Must be careful to mind "Status Bar Style" in xcode. A white/blue notch background requires Dark Content, of course.
 */

type BackgroundColor = "white" | "blue";

interface Params {
  backgroundColor: BackgroundColor;
}

export const backgroundColorMap: { [key in BackgroundColor]: string } = {
  // This is the default.
  white: "#ffffff",
  // This is hex version of bg-blue-600.
  blue: "#0073fa",
};

export const useNativeStatusBar = (params: Params): void => {
  React.useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement | null;
    // This is the fixed element behind the status bar (inside the safe offset areas) on mobile devices.
    const statusBarBackgroundEl = document.querySelector("#status-bar-bg") as HTMLDivElement | null;

    const backgroundColor = backgroundColorMap[params.backgroundColor] || backgroundColorMap.white;

    if (body) body.style.backgroundColor = backgroundColor;
    if (statusBarBackgroundEl) statusBarBackgroundEl.style.backgroundColor = backgroundColor;

    // Return to default when this component unmounts.
    return () => {
      if (body) body.style.backgroundColor = backgroundColorMap.white;
      if (statusBarBackgroundEl) statusBarBackgroundEl.style.backgroundColor = backgroundColorMap.white;
    };
  }, [params]);
};
