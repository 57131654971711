import { Account, Address, TransportJob, TransportJobAccountLink } from "@brenger/api-client";
import { Button, H2, IconCheck, IconUpdate, Message, Spacer, Strong, Timeline } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "react-query";

import {
  Breadcrumb,
  BreadcrumbNav,
  ClaimJobAgreement,
  DailyFoodEmoji,
  DriverSelect,
  MetaItem,
  Page,
} from "../../../components";
import {
  useAuth,
  useConfirmModal,
  useCreateLinkError,
  useFormatDate,
  useFormatTimeframe,
  useTranslation,
} from "../../../hooks";
import { CacheKey, coreClient, formatLocality, notEmpty } from "../../../utils";

import { getOrderedStopsFromTj } from "../PlanningJobDetailsUpdate";
import { initialState, reducer } from "../PlanningJobDetailsUpdate/store";

interface ClaimBundledJobProps {
  breadcrumbs: Breadcrumb[];
  tj: TransportJob;
  onSuccessFullyClaimed(tjal: TransportJobAccountLink): void;
}

export const ClaimBundledJob: React.FC<ClaimBundledJobProps> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [showUpdateControls, setShowUpdateControls] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const formatDateFull = useFormatDate("date-full");
  const formatMonthDay = useFormatDate("month-day");
  const formatDateForApi = useFormatDate("api-date");
  const formatTimeframe = useFormatTimeframe();
  const [driverUser, setDriverUser] = React.useState("");

  React.useEffect(() => {
    // Pre-select the current user as the assumed driverUser who will be assigned to this job.
    auth.user && setDriverUser(auth.user["@id"]);
  }, [auth.user]);

  const routeId = getIdFromIri(props.tj.transport_route);
  const route = useQuery(
    [CacheKey.RETRIEVE_ROUTE, routeId],
    () => coreClient.routes.retrieve({ id: routeId as string }),
    {
      enabled: !!routeId,
    }
  );

  const routeStopList = route.data?.stops || [];
  // Get a list of all start times and convert to API date format (eg:"2020-12-11")
  const routeStartDtpList = routeStopList.map((stop) => stop.proposed_datetime_period.start).map(formatDateForApi);

  // Generate a unique set of dates using the API date and then convert back to ISO String so we can format later
  const uniqDtpList = Array.from(new Set(routeStartDtpList))
    .filter(notEmpty)
    .map((d) => new Date(d).toISOString());

  const stops = getOrderedStopsFromTj(props.tj, route.data);
  const dtps = routeStopList.map((routeStop) => {
    return {
      stop: routeStop.pickup || routeStop.delivery || "",
      dtp: routeStop.proposed_datetime_period,
    };
  });

  React.useEffect(() => {
    if (stops.length && dtps.length) {
      dispatch({
        type: "SET_STOPS",
        payload: { stops, dtps },
      });
    }
  }, [stops.length, dtps.length]);

  const createLink = useMutation(coreClient.transportJobAccountLinks.create, {
    onSuccess: props.onSuccessFullyClaimed,
  });

  const createLinkError = useCreateLinkError(createLink.error as Error);

  const [ConfirmModal, activateConfirmModal] = useConfirmModal(() => {
    // On success callback when user agrees to confirm modal.
    createLink.mutate({
      driver_user: driverUser,
      driver_account: (auth.user?.account as Account | undefined)?.["@id"] || "",
      transport_job: props.tj["@id"],
      // Grab all the pickup/delivery info from state and massage into the format that Core expects.
      pickup_commitments: state
        .filter((p) => p.stop["@type"] === "Pickup")
        .map((p) => {
          return {
            pickup: p.stop["@id"],
            committed_datetime_period: {
              start: p.dtp.start,
              end: p.dtp.end,
            },
          };
        }),
      delivery_commitments: state
        .filter((p) => p.stop["@type"] === "Delivery")
        .map((p) => {
          return {
            delivery: p.stop["@id"],
            committed_datetime_period: {
              start: p.dtp.start,
              end: p.dtp.end,
            },
          };
        }),
    });
  });

  if (!route.data || route.error) {
    return (
      <Page
        loading={route.isLoading}
        nav={<BreadcrumbNav breadcrumbs={props.breadcrumbs} />}
        errorText={(route.error as Error)?.message}
      />
    );
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={props.breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          disabled={!driverUser}
          icon={<IconCheck />}
          onClick={() => {
            createLink.reset();
            activateConfirmModal({
              title: t((d) => d.search.messages.confirm_claim_modal_title),
              message: t((d) => d.search.messages.confirm_claim_bundled_job),
            });
          }}
        >
          {t((d) => d.transport_job.actions.claim_bundle)}
        </Button>
      }
    >
      <H2>{t((d) => d.transport_job.bundle_schedule)}</H2>
      <Spacer h={2} />
      <div>{t((d) => d.search.messages.claim_flexible_bundled_job_instructions)}</div>
      <Spacer h={4} />
      <div className={cn("flex", "justify-between")}>
        {/* Show the full date for the bundled job being claimed at the top of the form */}
        {/* Must account for the rare case when the proposed DTPs on the bundled job spans more than one day. */}
        {uniqDtpList.map((dtp, idx) => {
          return (
            <div key={idx}>
              <Strong className={cn("capitalize")}>{formatDateFull(dtp)}</Strong>
            </div>
          );
        })}
        {showUpdateControls ? (
          <Button
            size="sm"
            buttonType="primary-outline"
            onClick={() => {
              dispatch({ type: "SET_STOPS", payload: { stops, dtps } });
              setShowUpdateControls(false);
            }}
          >
            {t((d) => d.actions.reset_timeframes)}
          </Button>
        ) : (
          <Button size="sm" buttonType="primary" onClick={() => setShowUpdateControls(true)}>
            {t((d) => d.actions.update_timeframes)}
          </Button>
        )}
      </div>
      <Spacer h={3} />
      {state.map(({ stop, dtp, availableMinutesToDecrement, availableMinutesToIncrement, isUpdated }, idx) => {
        // The stops are already ordered, so safe to use idx + 1 as actual route stop number.
        const stopNumber = idx + 1;
        // Use this naive approach to place lunch reminder in the middle of the timline.
        const isMiddle = stopNumber === Math.floor(state.length / 2);

        return (
          <React.Fragment key={idx}>
            <Timeline isFirst={idx === 0} isLast={idx === state.length - 1}>
              <div className={cn("flex", "justify-between")}>
                <div className={cn("pr-4", "flex", "flex-col")}>
                  <MetaItem
                    name={`${t((d) => d.transport_job.stop)} ${stopNumber}`}
                    value={formatLocality(
                      (stop.address as Address).locality,
                      (stop.address as Address).administrative_area
                    )}
                  />
                  <div className={cn("flex", "items-center")}>
                    <div>
                      <Strong className={cn("text-blue-600")}>
                        {/* Show the time frame for the stop (AND, if the bundled job spans multiple days, show the day + month next to it) */}
                        {formatTimeframe({ start: dtp.start, end: dtp.end })}{" "}
                        {uniqDtpList.length > 1 && `(${formatMonthDay(dtp.start)})`}
                      </Strong>
                    </div>
                    {/* Show a little update icon when the DTP has been dirtied. */}
                    {isUpdated && <IconUpdate className={cn("w-4", "h-4", "ml-1", "text-green-400")} />}
                  </div>
                </div>
                <div className={cn("flex", "items-center", "flex-shrink-0")}>
                  {showUpdateControls && (
                    <div className={cn("flex", "flex-wrap")}>
                      <Button
                        buttonType="primary-outline"
                        size="sm"
                        disabled={availableMinutesToDecrement === 0}
                        onClick={() => {
                          dispatch({ type: "DECREMENT", payload: { stop } });
                        }}
                      >
                        -{availableMinutesToDecrement}m
                      </Button>
                      <Spacer w={1} h={1} />
                      <Button
                        buttonType="primary-outline"
                        size="sm"
                        disabled={availableMinutesToIncrement === 0}
                        onClick={() => {
                          dispatch({ type: "INCREMENT", payload: { stop } });
                        }}
                      >
                        +{availableMinutesToIncrement}m
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Timeline>
            {isMiddle && (
              <Timeline isFirst={false} isLast={false} showPoint={false}>
                <div className={cn("p-4", "rounded-full", "bg-blue-100", "inline-block")}>
                  <div>
                    <Strong className={cn("text-blue-600", "mr-1")}>{t((d) => d.search.messages.lunch_break)}</Strong>
                    <DailyFoodEmoji />
                  </div>
                </div>
              </Timeline>
            )}
          </React.Fragment>
        );
      })}
      <DriverSelect
        disableSuspendedDrivers={true}
        className={cn("mt-3")}
        labelText={t((d) => d.search.labels.select_driver)}
        accountId={getIdFromIri(auth.user?.account)}
        value={driverUser}
        onChange={({ userIRI }) => setDriverUser(userIRI)}
      />
      {createLinkError && (
        <Message className={cn("mt-4")} type="error">
          {createLinkError}
        </Message>
      )}
      <Spacer h={4} />
      <ClaimJobAgreement />
      <ConfirmModal />
    </Page>
  );
};
