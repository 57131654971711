import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import addMinutes from "date-fns/addMinutes";
import subMinutes from "date-fns/subMinutes";
import addYears from "date-fns/addYears";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { DateTimePeriodParams } from "@brenger/api-client";
import { Time } from "../pages/dayRoute/utils";

// Brenger history starts here. Use this to limit InputDate start point.
export const INPUT_DATE_MIN = new Date("2017-01-01").toISOString();
// Give one year in advance from current date when clamping InputDate.
export const INPUT_DATE_MAX = addYears(new Date(), 1).toUTCString();

/**
 * For every N number of of minutes between start & end, returns:
 * [{start: "2020-06-08T21:25:56.944Z", end: "2020-06-08T21:25:56.944Z"}]
 */
export const intervalsForDateTimePeriod = (
  start?: string,
  end?: string,
  minutes?: number
): DateTimePeriodParams[] | null => {
  // Bail if missing these parameters
  if (!start || !end || !minutes) return null;
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  // Get total number of minutes between start & end
  const totalMinutes = differenceInMinutes(endDate, startDate);
  const intervals = totalMinutes / minutes;
  const dateTimePeriods: DateTimePeriodParams[] = [];
  for (let i = 0; i < intervals; i++) {
    dateTimePeriods.push({
      start: formatISO(addMinutes(startDate, minutes * i)),
      end: formatISO(addMinutes(startDate, minutes * (i + 1))),
    });
  }
  return dateTimePeriods;
};

export const intervalsInTime = (start?: Date, end?: Date, minutes?: number): string[] | null => {
  // Bail if missing these parameters
  if (!start || !end || !minutes) return null;
  // Get total number of minutes between start & end
  const totalMinutes = differenceInMinutes(end, start);
  const intervals = totalMinutes / minutes;
  const timeOptions: string[] = [];
  for (let i = 0; i <= intervals; i++) {
    timeOptions.push(addMinutes(start, minutes * i).toISOString());
  }
  return timeOptions;
};

/**
 * Takes a start and end time and return a structured difference in terms of HOURS + MINUTES.
 */
export const differenceInHoursAndMinutes = (
  start?: string,
  end?: string
): { hours: number; minutes: number } | null => {
  if (!start || !end) return null;

  const minuteDiff = differenceInMinutes(parseISO(end), parseISO(start));
  return {
    hours: Math.floor(minuteDiff / 60),
    minutes: Math.floor(minuteDiff % 60),
  };
};

/*
 * Chosen for a small util function to convert any local dateTime to a utc iso string, so we have it in one place.
 * This util should be used before talking to brenger API, brenger API talks in utc only.
 */
export const getDateTimeInUTC = (dateStr: string): string => {
  const localDate = parseISO(dateStr);
  return localDate.toISOString();
};

/**
 * Accepts seconds and returns a rounded-up number in minutes.
 */
export const formatSecondsToMinutes = (seconds: number): number => {
  return Math.ceil(seconds / 60);
};

/**
 * Calculate seconds in to HOURS + MINUTES.
 */

export const formatSecondsToHoursMinutes = (time: number | null): Time | null => {
  if (!time) return null;
  const hours = Math.floor(time / 3600);
  const secondsLeft = time - hours * 3600;
  const minutes = Math.floor(secondsLeft / 60);
  return { hours, minutes };
};

/**
 * Is time "now" within DTPs
 * Optional grace period
 */
export const isNowWithinDTPs = (dtps: null | DateTimePeriodParams[], gracePeriodInMinutes = 0): boolean => {
  const now = new Date();
  if (!dtps) {
    return false;
  }
  return dtps.some((dtp) => {
    const start = subMinutes(parseISO(dtp.start), gracePeriodInMinutes);
    const end = addMinutes(parseISO(dtp.end), gracePeriodInMinutes);
    return isAfter(now, start) && isBefore(now, end);
  });
};
