import React from "react";
import { useHistory, useLocation } from "react-router-dom";

interface ScrollPosition {
  pathname: string;
  x: number;
  y: number;
}

export const ScrollPast: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [past, setPast] = React.useState<ScrollPosition[]>([]);

  // Clean up function, to remove the entry
  const pop = (): void => setPast(past.slice(0, past.length - 1));

  React.useEffect(() => {
    history.listen((_, action) => {
      if (action !== "PUSH") {
        // we only want to record when moving forwards
        return;
      }
      // we record as a stack, so same behaviour as how the router behaves
      setPast(
        past.concat({
          pathname,
          x: window.scrollX,
          y: window.scrollY,
        })
      );
    });
  }, [pathname]);

  React.useEffect(() => {
    if (history.action !== "POP") {
      // we only want to restore if we are going back
      return;
    }
    // We want the last recorded push
    const record = past[past.length - 1];
    if (!record || (record.x === 0 && record.y === 0)) {
      // We did not record yet, scroll is 0, or not the right pathname
      return;
    }

    if (record.pathname !== pathname) {
      // we might be out of sync for some reason
      pop();
      return;
    }
    // if we get here, we have a match, but we do want to let the browser figure it out first.
    setTimeout(() => {
      const { scrollX, scrollY } = window;
      if (scrollX === 0 && scrollY === 0) {
        // We gave it time, now it's time to manually set the scroll height.
        window.scrollTo(record.x, record.y);
      }
      // clean up
      pop();
    }, 100);
  }, [history.action, pathname]);

  return null;
};
