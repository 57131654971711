import * as React from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import cn from "classnames";
import { useModalState, IconBrengerLogo, IconRefresh, IconHamburger, IconBackArrow } from "@brenger/react";

import { useScrollOffset, useAuth } from "../../hooks";
import { Routes } from "../../utils";
import { useQueryClient } from "react-query";
import { AppMenu } from "..";

export const AppHeader: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const menu = useModalState();
  const isInbox = matchPath(pathname, { path: Routes.INBOX_DETAILS, exact: true });
  const hasReachedScrollOffset = useScrollOffset(2);
  const queryClient = useQueryClient();
  /**
   * Execute POP event when history length is > 2 AND when the pathname is NOT a "root" pathname.
   * When browser behaviour is new tab (like most browsers),
   * the homepage of that tab is considered 1 and the page you are actually viewing is 2
   */
  const showGoBackButton =
    history.length > 2 && pathname.split("/").filter(Boolean).length > 1 && !pathname.startsWith("/profile");

  return (
    <>
      <AppMenu isActive={menu.isActive} closeHandler={menu.close} />
      {/* note: make sure px aligns with AppMenu so there is seamless open / close button location */}
      <header
        className={cn(
          "flex",
          "items-center",
          "justify-between",
          "w-full",
          "py-4",
          "px-3",
          "z-30",
          "bg-white",
          "no-print",
          {
            // The inbox details view has its own sticky header that supercedes this one.
            sticky: !isInbox,
            "top-0-with-safe-area-inset": !isInbox,
            "shadow-md": !isInbox && hasReachedScrollOffset,
          }
        )}
      >
        {/* DISPLAY THE "GO BACK TO" ICON WHEN AVAILABLE */}
        {showGoBackButton ? (
          <button className={cn("w-1/3", "flex", "justify-start", "items-center")} onClick={() => history.goBack()}>
            <IconBackArrow width="14" height="12" className={cn("fill-current", "text-blue-600")} />
          </button>
        ) : (
          <button
            className={cn("w-1/3", "flex", "justify-start", "items-center")}
            onClick={() => {
              queryClient.clear();
              window.location.reload();
            }}
          >
            <IconRefresh width="17" height="12" className={cn("fill-current", "text-blue-600")} />
          </button>
        )}
        <div className={cn("w-1/3", "flex", "justify-center", "items-center")}>
          <button onClick={() => history.push("/planning")}>
            <IconBrengerLogo width="58" height="12" className={cn("h-4", "text-blue-600", "fill-current")} />
          </button>
        </div>
        <div className={cn("w-1/3", "flex", "justify-end", "items-center")}>
          {auth.isLoggedIn && (
            <button onClick={menu.open}>
              <IconHamburger width="17" height="12" className={cn("text-blue-600", "fill-current")} />
            </button>
          )}
        </div>
      </header>
    </>
  );
};
