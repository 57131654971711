import { ConfirmationRuleReason, ConfirmationRules } from "@brenger/api-client";
import { Button, Strong, IconRouteLocations, IconStopwatchCheck, IconStopwatchExclamation } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import {} from "../../components";
import { UseGeolocation, useNativePlatform, useTranslation } from "../../hooks";

interface Props {
  hasLocationService: boolean;
  confirmationRules?: ConfirmationRules;
  geolocation: UseGeolocation;
}

type MessageType = "not_native" | "no_location_service" | ConfirmationRuleReason;

const getMessage = ({
  hasLocationService,
  confirmationRules,
  isNativePlatform,
}: Props & { isNativePlatform: boolean }): { type: MessageType; icon: React.ReactNode } => {
  if (!isNativePlatform) {
    return { type: "not_native", icon: <IconRouteLocations width="58" height="72" /> };
  }
  if (!hasLocationService) {
    return { type: "no_location_service", icon: <IconRouteLocations width="58" height="72" /> };
  }
  if (confirmationRules?.reason === "both_proximate_on_time") {
    return {
      type: "both_proximate_on_time",
      icon: <IconStopwatchCheck width="48" height="52" className={cn("fill-green-400")} />,
    };
  }
  return {
    type: confirmationRules?.reason || "neither_proximate_on_time",
    icon: <IconStopwatchExclamation width="48" height="52" className={cn("fill-green-400")} />,
  };
};

export const ConfirmStopPunctualityMessage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isNativePlatform = useNativePlatform();
  const { isPluginReady, start } = props.geolocation;
  const message = getMessage({ ...props, isNativePlatform });

  return (
    <div className={cn("my-4")}>
      <div className={cn("flex", "justify-between")}>
        <div className={cn("flex-shrink", "mr-4")}>
          <div className={cn("mb-2")}>
            <Strong>{t((d) => d.punctuality_message[message.type].title)}</Strong>
          </div>
          <div>{t((d) => d.punctuality_message[message.type].text)}</div>
        </div>
        <div className="flex-shrink-0">{message.icon}</div>
      </div>
      {message.type === "no_location_service" && isNativePlatform && (
        <div className={cn("mt-4")}>
          <Button
            className={cn("w-full")}
            disabled={!isPluginReady}
            onClick={async () => {
              await start();
            }}
          >
            {t((d) => d.punctuality_message.location_cta)}
          </Button>
        </div>
      )}
    </div>
  );
};
