import { HorizontalLine, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery, useQueryClient } from "react-query";
import { AddressDetails, Overlay } from "../../../components";
import { useAuth, useFormatTimeframe, usePlanningParams, useStopContext, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { CancelOrRevoke } from "../CancelOrRevoke";
import { EditCommittedDtp } from "../EditCommittedDtp";
import { EditDriver } from "../EditDriver";
import { NotAllowed } from "../NotAllowed";
import { RouteOverViewBundled } from "../RouteOverviewBundled";
import { RouteOverViewNormal } from "../RouteOverviewNormal";

export const TransportJobEditStop: React.FC = () => {
  const { t } = useTranslation();
  const params = usePlanningParams();
  const formatDTP = useFormatTimeframe();
  const auth = useAuth();

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );
  const stopContext = useStopContext(stop.data || null, params.tjal_id);

  const queryClient = useQueryClient();
  const onSuccessDTPupdate = (): void => {
    queryClient.removeQueries([CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, params.tjal_id]);
    queryClient.removeQueries([CacheKey.RETRIEVE_STOP, params.stop_id]);
    queryClient.removeQueries([CacheKey.RETRIEVE_DAY_ROUTE]);
    queryClient.removeQueries([CacheKey.RETRIEVE_DAY_ROUTE_LEGS]);
  };

  // Initialize the driverUser with the current one from the TJAL
  const stopType = stop.data?.["@type"];
  const isBundled = stopContext.transportJob?.bundled;

  if (!auth.isAccountAdmin) {
    return (
      <Overlay
        activePath={Routes.PLANNING_STOP_DETAILS_EDIT}
        title={t((d) => d.stop_edit.headings.change_cancel_stop)}
        loading={stopContext.loading}
        exitPath={`/planning/${params.tjal_id}/stops/${params.stop_id}`}
      >
        <NotAllowed />
      </Overlay>
    );
  }
  return (
    <Overlay
      activePath={Routes.PLANNING_STOP_DETAILS_EDIT}
      title={t((d) => d.stop_edit.headings.change_cancel_stop)}
      loading={stopContext.loading}
      exitPath={`/planning/${params.tjal_id}/stops/${params.stop_id}`}
    >
      <h6 className={cn("flex", "justify-between", "items-center", "uppercase")}>
        {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
      </h6>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <div>
        <b>{t((d) => d.transport_job.headings.address)}</b>
      </div>
      <AddressDetails
        presentation={"planning-stop-edit"}
        address={stopContext.address}
        instructions={stop.data?.details.instructions}
      />
      <Spacer h={2} />
      <div>
        <b>{t((d) => d.stop_edit.headings.available_dtp)}</b>
      </div>
      {stop.data?.available_datetime_periods.map((dtp, i) => {
        return <div key={i}>{formatDTP(dtp)}</div>;
      })}
      <Spacer h={2} />
      {
        <EditCommittedDtp
          checkDisabled={true}
          availableDtps={stop.data?.available_datetime_periods}
          committedDtp={stopContext.committedDtps?.[0] || null}
          onSuccess={onSuccessDTPupdate}
        />
      }
      <Spacer h={4} />
      <h5>{t((d) => d.stop_edit.headings[isBundled ? "change_bundle" : "change_single_job"])}</h5>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <Spacer h={1} />
      {!isBundled && (
        <RouteOverViewNormal
          tjId={stopContext.transportJob?.short_id}
          isPickup={stopType === "Pickup"}
          pickup={stopContext.transportJob?.pickups[0]}
          delivery={stopContext.transportJob?.deliveries[0]}
        />
      )}
      {isBundled && (
        <RouteOverViewBundled
          tjId={stopContext.transportJob?.short_id}
          activeStop={stop.data || null}
          route={stopContext.route?.stops}
          tj={stopContext.transportJob}
        />
      )}
      <Spacer h={2} />
      <EditDriver tjalId={params.tjal_id} successPath={`/planning/${params.tjal_id}`} />
      <Spacer h={4} />
      <CancelOrRevoke
        tjalId={params.tjal_id}
        isBundled={stopContext.transportJob?.bundled}
        isDirectClaimable={stopContext.transportJob?.directly_claimable}
        successPath={Routes.PLANNING_JOB_LIST}
      />
    </Overlay>
  );
};
