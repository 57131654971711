/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getIdFromIri } from "@brenger/utils";
import { useQuery } from "react-query";

import { Account, ProgressLevel, UserMe } from "@brenger/api-client";
import { CacheKey, coreClient, getFirstAndLastName, logger } from "../utils";

export const userLevelWeightMap: { [key in ProgressLevel]: number } = {
  bronze: 1,
  gold: 2,
  platinum: 3,
  diamond: 4,
};

export const useAuth = () => {
  const userData = useQuery(CacheKey.RETRIEVE_CURRENT_USER, doLogin);

  const userLevel: ProgressLevel = userData.data?.level || "bronze";
  const accountLevel: ProgressLevel = (userData.data?.account as Account | undefined)?.level || "bronze";

  return {
    isSuspended: Boolean(userData.data?.is_suspended),
    isDriver: Boolean(userData.data?.roles?.includes("ROLE_DRIVER")),
    isAccountAdmin: Boolean(userData.data?.roles?.includes("ROLE_ACCOUNT_ADMIN")),
    isCustomer: Boolean(userData.data?.roles?.includes("ROLE_USER")),
    isLoggedIn: Boolean(userData.data),
    user: userData.data,
    fullName: getFirstAndLastName(userData.data),
    userId: getIdFromIri(userData.data?.["@id"]),
    userLevel: {
      // This is the "raw" driver level returned by core - it can be used for validation purposes,
      raw: userLevel,
      // This is the "weight" of the driver's level for comparision purposes.
      weight: userLevelWeightMap[userLevel],
    },
    accountLevel: {
      // This is the "raw" account level returned by core - it can be used for validation purposes,
      raw: accountLevel,
      // This is the "weight" of the account's level for comparision purposes.
      weight: userLevelWeightMap[accountLevel],
    },
    // Properly handle the loading state of both the initial query + the dependent query.
    loading: userData.isLoading,
  };
};

// Simple function that checks login status first and then retrieve current user
// Squashed to one function so that we can combine one query state for this operation
// Brings down excessive render times and prevents unwanted login screen flickers
const doLogin = async (): Promise<UserMe | null> => {
  try {
    const login = await coreClient.users.isUserLoggedIn();
    if (!login) {
      return null;
    }
    const user = await coreClient.users.retrieveCurrentUser();
    if (!user) {
      return null;
    }
    return user;
  } catch (e) {
    logger.dev(e);
    return null;
  }
};
