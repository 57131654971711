import { Vehicle } from "@brenger/api-client";
import { Button, Spacer, useModalState, IconDelete, IconEditPencil } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { VehicleEditModal } from ".";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useTranslation } from "../../../hooks/useTranslation";
import { coreClient, Routes } from "../../../utils";

type Props = {
  canDelete?: boolean;
  vehicle?: Vehicle;
  onEditSuccess?: () => void;
  onDeleteSuccess?: () => void;
};

export const EditOrDeleteVehicle: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const editModal = useModalState();
  const history = useHistory();
  const editSuccess = (): void => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
    editModal.close();
  };

  const onSuccess = (): void => {
    if (props.onDeleteSuccess) {
      props.onDeleteSuccess();
      return;
    }
    history.push(Routes.USER_VEHICLES);
  };
  const deleteVehicle = useMutation(coreClient.vehicles.delete, { onSuccess });

  const [DeleteVehicleConfirmationModal, confirmDeleteVehicle] = useConfirmModal(() => {
    // On success callback when user agrees to confirm modal.
    deleteVehicle.mutate({ id: getIdFromIri(props.vehicle?.["@id"]) as string });
  });
  if (!props.vehicle) {
    return null;
  }
  return (
    <div className={cn("flex")}>
      {props.canDelete && (
        <>
          <Button
            size="sm"
            buttonType="warning-outline"
            onClick={() =>
              confirmDeleteVehicle({
                title: t((d) => d.actions.remove),
                message: t((d) => d.actions.are_you_sure),
              })
            }
          >
            <IconDelete />
          </Button>
          <Spacer w={2} />
        </>
      )}
      <Button size="sm" buttonType="gray-outline" onClick={editModal.open}>
        <IconEditPencil />
      </Button>
      {<VehicleEditModal {...editModal} vehicle={props.vehicle} onEditSuccess={editSuccess} />}
      <DeleteVehicleConfirmationModal buttonType="warning" />
    </div>
  );
};
