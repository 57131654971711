import * as React from "react";
import groupBy from "lodash/groupBy";
import { useQuery } from "react-query";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import { TransportJobAccountLink, PickupCommitment } from "@brenger/api-client";
import { useSelector } from ".";
import { coreClient, CacheKey } from "../utils";
import { useFormatDate } from "./useFormatDate";

/**
 * Filtered TJALS are grouped by date.
 */
interface FilteredTjals {
  [date: string]: TransportJobAccountLink[];
}

interface Params {
  page?: number;
}

/**
 * This hook takes care of making the request for planning TJALs as well as
 * filtering the resulting tjals. This hook can be used on the planning job list page
 * as well as the planning job list filter page.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePlanningFilter = (params?: Params) => {
  const page = params?.page || 1;
  const formatDateForApi = useFormatDate("api-date");
  const query = useQuery([CacheKey.LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS, page], () =>
    coreClient.transportJobAccountLinks.list({
      page,
      presentation: "planning",
      date_from: formatDateForApi(subDays(new Date(), 14).toISOString()),
      date_to: formatDateForApi(addDays(new Date(), 365).toISOString()),
    })
  );

  const tjals = query.data?.["hydra:member"];

  const { tjalStates, user } = useSelector((state) => state.planningFilter);

  /**
   * Filtering all this data is an expensive operation. Therefore, only re-calculate...
   * ...when the dependency list changes.
   */
  const filteredTjals: FilteredTjals = React.useMemo(() => {
    let filterResult = tjals || [];

    /**
     * Only show TJAL for particular user when one has been selected.
     */
    if (user) {
      filterResult = filterResult.filter((tjal) => {
        return tjal.driver_user["@id"] === user;
      });
    }

    /**
     * Group by TJAL state (only if certain TJAL states are selected).
     */
    if (tjalStates.length) {
      filterResult = filterResult.filter((tjal) => {
        return tjalStates.includes(tjal.state);
      });
    }

    /**
     * Group list by dates.
     */
    return groupBy(filterResult, (tjal: TransportJobAccountLink) => {
      const firstPickupCommitment = (tjal.pickup_commitments[0] as PickupCommitment).committed_datetime_period.start;
      return firstPickupCommitment ? formatDateForApi(firstPickupCommitment) : null;
    });
  }, [tjals, user, tjalStates]);

  return {
    tjals: query,
    filteredTjals,
    filteredTjalsCount: Object.values(filteredTjals).reduce((p, c) => p + c.length, 0),
  };
};
