import { Strong } from "@brenger/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { AppMessage } from "../components";

import cn from "classnames";
import { WebPushPrompt } from "./WebPushPrompt";
import { useTranslation } from "../hooks";
import { Routes } from "../utils";

export type AppMessagesKey = "offline-mode" | "missing-profile-image" | "web-push-prompt";

type Props = {
  appMessages: Set<AppMessagesKey>;
};
export const AppMessages: React.FC<Props> = ({ appMessages }) => {
  const { t } = useTranslation();

  const OfflineMode: React.FC = () => (
    <AppMessage key="offline-mode" type="error">
      <div>Offline mode turned ON</div>
    </AppMessage>
  );

  const MissingProfileImage: React.FC = () => (
    <AppMessage key="profile-image" type="info">
      <div>
        <span>{t((d) => d.profile.image_required)}</span>
        <Link to={Routes.USER_DETAILS} className={cn("inline-flex", "ml-1")}>
          <Strong className={cn("underline")}>
            <span className={cn("capitalize")}>{t((d) => d.actions.view_profile)}</span>
          </Strong>
        </Link>
      </div>
    </AppMessage>
  );

  return (
    <>
      {appMessages.has("offline-mode") && <OfflineMode />}
      {appMessages.has("missing-profile-image") && <MissingProfileImage />}
      {appMessages.has("web-push-prompt") && <WebPushPrompt />}
    </>
  );
};
