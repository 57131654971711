import { Button, IconFilter, Message, Strong, useModalState } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Grid, ListSummary, Page, TabNav } from "../../../components";
import {
  NO_DTP_GROUP_KEY,
  useFormatDate,
  useSearchFilter,
  useSelector,
  useTabNavigation,
  useTranslation,
} from "../../../hooks";
import { searchFilterActions } from "../../../store/search/filter";
import { SearchFilterState } from "../../../store/search/types";
import { Routes } from "../../../utils";
import { SearchJobListItem } from "./SearchJobListItem";

import { isSameDay } from "date-fns";
import { SearchJobListVatNotification } from "./SearchJobListVatNotification";

export type SearchListPresentation = "preview" | "search";

/**
 * Check the search filter state for any alterations from the default state.
 * We do this in order to determine whether we show the user a "clear filters" button.
 * Having that button is important because the filters are persisted in local storage
 * and users may forget that they have previously applied them.
 */
const getNumberOfActiveSearchFilters = (state: SearchFilterState): number => {
  return (
    state.conditions.length +
    state.countries.length +
    state.regions.length +
    state.jobLevels.length +
    (state.pickupDate ? 1 : 0)
  );
};

/**
 * In some cases, such as the Preview page, we do not show the nav.
 */
interface Props {
  presentation: SearchListPresentation;
}

const filterLocationMap: { [key in SearchListPresentation]: Routes } = {
  preview: Routes.PREVIEW_JOB_LIST_FILTER,
  search: Routes.SEARCH_JOB_LIST_FILTER,
};

/**
 * Export "raw" version of this component so that it can be configured for use with both the:
 * 1) search job list AND
 * 2) the preview job list.
 */
export const SearchJobListRaw: React.FC<Props> = ({ presentation }) => {
  const { t } = useTranslation();
  const formatDateFull = useFormatDate("date-full");
  const dispatch = useDispatch();
  const searchFilter = useSelector((state) => state.searchFilter);
  const numberOfActiveSearchFilters = getNumberOfActiveSearchFilters(searchFilter);
  const tabs = useTabNavigation("dashboard");
  const history = useHistory();
  const vatNotificationState = useModalState();

  const isNavActive = presentation !== "preview";

  const { openTransportJobs, filteredOpenTransportJobs, isLoading, activeFilter } = useSearchFilter();

  const totalJobsCount = (openTransportJobs.data || []).length;
  let filteredJobsCount = filteredOpenTransportJobs.ungrouped.length;
  const stringifiedActiveFilter = JSON.stringify(activeFilter);
  if (!openTransportJobs.data || openTransportJobs.isError || isLoading) {
    return (
      <Page
        scrollToTopOnMount={false}
        nav={<TabNav tabs={tabs} isActive={isNavActive} />}
        loading={isLoading}
        errorText={(openTransportJobs.error as Error)?.message}
      />
    );
  }
  const days = Object.keys(filteredOpenTransportJobs.grouped);
  return (
    <>
      <Page
        scrollToTopOnMount={false}
        nav={<TabNav tabs={tabs} isActive={isNavActive} />}
        stickyFooter={
          <Button
            className={cn("w-full")}
            onClick={() => history.push(filterLocationMap[presentation])}
            icon={<IconFilter />}
          >
            {t((d) => d.search.filter.title)}
          </Button>
        }
      >
        {numberOfActiveSearchFilters > 0 && (
          <Message type="info" className={cn("mb-4")}>
            <div className={cn("flex", "justify-between", "flex-wrap")}>
              <Link to={Routes.SEARCH_JOB_LIST_FILTER} className={cn("mr-4", "underline")}>
                <Strong>{t((d) => d.search.filter.active_filters)}</Strong> ({numberOfActiveSearchFilters})
              </Link>
              <Button size="sm" onClick={() => dispatch(searchFilterActions.reset())}>
                {t((d) => d.actions.remove)}
              </Button>
            </div>
          </Message>
        )}
        {filteredJobsCount === 0 && (
          <Message type="info" className={cn("mb-4")}>
            {t((d) => d.search.filter.zero_results)}
          </Message>
        )}
        {/*
         * DISPLAY JOBS:
         * GROUPED BY DATE: Only when personalized is active
         * NOT GROUPED: when "new" or "price" sorting method is active
         */}
        {activeFilter.sortBy.field === "personalized" &&
          days.map((day, iDay) => {
            filteredJobsCount = filteredOpenTransportJobs.grouped[day].length;
            return (
              <div key={iDay}>
                <h4 className={cn("capitalize", "mb-2")}>
                  <>
                    {day === NO_DTP_GROUP_KEY && t((d) => d.search.heading_jobs_without_dtp)}
                    {day !== NO_DTP_GROUP_KEY && (
                      <>{isSameDay(new Date(), new Date(day)) ? t((d) => d.calendar.today) : formatDateFull(day)}</>
                    )}
                  </>
                </h4>
                <Grid cols={{ lg: 2 }} gap={4} className={cn("mb-4")}>
                  {filteredOpenTransportJobs.grouped[day].map((openTJ, i) => (
                    <SearchJobListItem
                      key={i}
                      index={i}
                      openTJ={openTJ}
                      filteredJobsCount={filteredJobsCount}
                      totalJobsCount={totalJobsCount}
                      activeFilter={stringifiedActiveFilter}
                      sortByField={activeFilter.sortBy.field}
                    />
                  ))}
                </Grid>
              </div>
            );
          })}
        {activeFilter.sortBy.field !== "personalized" && (
          <Grid cols={{ lg: 2 }} gap={4} className={cn("mb-4")}>
            {filteredOpenTransportJobs.ungrouped.map((openTJ, i) => (
              <SearchJobListItem
                key={i}
                index={i}
                openTJ={openTJ}
                filteredJobsCount={filteredJobsCount}
                totalJobsCount={totalJobsCount}
                activeFilter={stringifiedActiveFilter}
                sortByField={activeFilter.sortBy.field}
              />
            ))}
          </Grid>
        )}

        <ListSummary
          currentRangeStart={filteredJobsCount ? 1 : 0}
          currentRangeEnd={filteredJobsCount}
          /**
           * Just check the hydra meta data from the first page of results to figure out total items for entire data set.
           * Furthermore, subtract the length of the hidden jobs list so there is no confunction about the total number of items.
           */
          totalItems={(openTransportJobs.data.length || 0) - searchFilter.hiddenJobIds.length}
        />
      </Page>
      <SearchJobListVatNotification
        isActive={vatNotificationState.isActive}
        open={vatNotificationState.open}
        close={vatNotificationState.close}
      />
    </>
  );
};

export const SearchJobList: React.FC = () => <SearchJobListRaw presentation="search" />;
