import { DateTimePeriod, DateTimePeriodParams } from "@brenger/api-client";
import { Button, DateTimePeriodSelector, Small, Spacer, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { differenceInMinutes, isAfter, isSameDay, isSameMinute, set, sub } from "date-fns";
import * as React from "react";
import { useMutation } from "react-query";
import { useFormatDate, useTranslation } from "../../hooks";
import { coreClient } from "../../utils";

interface Props {
  committedDtp: DateTimePeriod | null;
  availableDtps?: DateTimePeriod[];
  checkDisabled?: boolean;
  onSuccess?(): void;
}

export const EditCommittedDtp: React.FC<Props> = ({ committedDtp, availableDtps, checkDisabled, onSuccess }) => {
  const { t } = useTranslation();
  const availableDtp = availableDtps?.find((dtp) =>
    isSameDay(new Date(dtp.start), new Date(committedDtp?.start as string))
  );

  const [value, setValue] = React.useState<DateTimePeriod | null>();
  const [showError, setShowError] = React.useState(false);
  const updateDtp = useMutation(coreClient.dateTimePeriods.update, { onSuccess });

  React.useEffect(() => {
    if (committedDtp) {
      setValue(committedDtp);
    }
  }, [committedDtp]);

  const setDtpValue = (newDtp: DateTimePeriodParams): void => {
    const diffMinutes = differenceInMinutes(new Date(newDtp.end), new Date(newDtp.start));
    const isValid = 120 <= diffMinutes && diffMinutes <= 240;
    setShowError(!isValid);
    setValue({
      ...(committedDtp as DateTimePeriod),
      ...newDtp,
    });
  };

  const formatHourMinute = useFormatDate("hour-minute");

  const formatTime = (date: Date): string => {
    return formatHourMinute(date.toISOString()) as string;
  };

  if (!committedDtp || !value || !availableDtp || availableDtps?.length === 0) {
    return null;
  }

  const isSame =
    isSameMinute(new Date(value.start), new Date(committedDtp.start)) &&
    isSameMinute(new Date(value.end), new Date(committedDtp.end));

  // is before Available DTP minus 1 day and set time to 20:00
  const deadline = availableDtp?.start
    ? set(
        new Date(
          sub(new Date(availableDtp.start), {
            days: 1,
          })
        ),
        {
          hours: 20,
          minutes: 0,
          seconds: 0,
        }
      )
    : null;
  const isDisabled =
    checkDisabled && deadline
      ? isAfter(
          // now
          new Date(),
          deadline
        )
      : false;

  return (
    <>
      <div>
        <Strong>{t((d) => d.stop_edit.update_dtp.heading)}</Strong>
        <br />
        <Small className="italic">{t((d) => d.stop_edit.update_dtp.allowed_till)}</Small>
      </div>
      <Spacer h={1} />
      <DateTimePeriodSelector
        timeFormatter={formatTime}
        onChange={setDtpValue}
        disabled={isDisabled}
        value={value}
        limits={
          availableDtp
            ? {
                min: availableDtp.start,
                max: availableDtp.end,
              }
            : undefined
        }
      />
      {showError && <div className={cn("text-red-600", "mt-1")}>{t((d) => d.stop_edit.update_dtp.length_error)}</div>}
      <Spacer h={4} />
      <Button
        className={cn("w-full")}
        buttonType={"secondary"}
        loading={updateDtp.isLoading}
        disabled={showError || isDisabled || updateDtp.isLoading || isSame}
        onClick={() =>
          updateDtp.mutate({
            id: getIdFromIri(value?.["@id"]) || "",
            dateTimePeriod: {
              start: value.start,
              end: value.end,
            },
          })
        }
      >
        {t((d) => d.stop_edit.update_dtp.confirm)}
      </Button>
    </>
  );
};
