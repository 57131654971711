import { Button, IconLoader } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AppMessages } from "./AppMessages/AppMessages";
import { NativeAppMessages } from "./AppMessages/NativeAppMessges";
import { AppHeader, AppMessage, NpsForm, Page, ProtectedRoute } from "./components";
import { useAppEffects, useAuth, useNativeAppEffects, useTranslation } from "./hooks";
import * as Pages from "./pages";
import { MoreSection } from "./pages";
import { Routes } from "./utils";

export const App: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  /**
   * The following hooks hold a collection of critical effects that must happen on App mount.
   * These effects have been divided into two collections:
   * useAppEffects - those which are appropriate / generic enough for any underlying platform.
   * useNativeAppEffects - those which are only relevant in the context of a native platform.
   */
  const { appMessages } = useAppEffects();
  const { geolocation, appMessages: nativeAppMessages } = useNativeAppEffects();

  /**
   * Render a plain, full-page loader while user auth status is being resolved.
   */
  if (auth.loading) {
    return (
      <div className={cn("flex", "justify-center", "items-center", "flex-grow")}>
        <IconLoader className={cn("w-6", "h-6")} />
      </div>
    );
  }
  /**
   * These are the public / no-auth routes.
   */
  if (!auth.isLoggedIn) {
    return (
      <Switch>
        <Route exact path={Routes.LOGIN} component={Pages.Login} />
        <Route exact path={Routes.PREVIEW_JOB_LIST} component={Pages.PreviewJobList} />
        <Route exact path={Routes.PREVIEW_JOB_LIST_FILTER} component={Pages.PreviewJobListFilter} />
        {/* Do not make this route EXACT. It needs to match on both /preview/:tj_id AND /preview/:tj_id/preview */}
        <Route path={Routes.PREVIEW_JOB_DETAILS} component={Pages.PreviewJobDetailsOverlay} />
        {/*
         * Ensure login component knows where the user arrived from so can potentially redirect after succsssful login.
         * See react-router docs for more details.
         */}
        <Redirect to={{ pathname: Routes.LOGIN, state: { referrer: location.pathname } }} />
      </Switch>
    );
  }

  /**
   * A suspended user can only view the delivered section. This is helpful for drivers who
   * still need access to invoices, etc, after being suspended from our platform.
   */
  if (auth.isSuspended) {
    return (
      <>
        <AppMessage type="error">{t((d) => d.auth.messages.suspended)}</AppMessage>
        <AppHeader />
        <Switch>
          {/* DELIVERED PAGES */}
          <Route exact path={Routes.DELIVERED_JOB_LIST} component={Pages.DeliveredJobList} />
          <Route exact path={Routes.DELIVERED_JOB_LIST_FILTER} component={Pages.DeliveredJobListFilter} />
          <Route exact path={Routes.DELIVERED_JOB_DETAILS} component={Pages.DeliveredJobDetails} />
          <Route exact path={Routes.LOGOUT}>
            {/* Pass the geolocation object directly so that the component remains reactive to changes. */}
            <Pages.Logout geolocation={geolocation} />
          </Route>
          <Redirect to={Routes.DELIVERED_JOB_LIST} />
        </Switch>
      </>
    );
  }

  if (auth.isDriver || auth.isAccountAdmin) {
    return (
      <MoreSection>
        <AppMessages appMessages={appMessages} />
        <NativeAppMessages appMessages={nativeAppMessages} />
        <AppHeader />
        <Switch>
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_JOB_LIST}
            component={Pages.SearchJobList}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_JOB_DETAILS_PREVIEW}
            component={Pages.SearchJobDetailsOverlay}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_JOB_LIST_FILTER}
            component={Pages.SearchJobListFilter}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_JOB_DETAILS}
            component={Pages.SearchJobDetails}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_CLAIM_JOB}
            component={Pages.SearchClaimJob}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.SEARCH_STOP_DETAILS}
            component={Pages.SearchStopDetails}
          />
          {/* PLANNING PAGES */}
          <Route exact path={Routes.PLANNING_JOB_LIST} component={Pages.PlanningJobList} />
          <Route exact path={Routes.PLANNING_JOB_LIST_FILTER} component={Pages.PlanningJobListFilter} />
          <Route exact path={Routes.PLANNING_JOB_DETAILS} component={Pages.PlanningJobDetails} />
          {/* 
          Stop details: needs two routes, 
          1) for displaying details
          2) for the editing slide over. */}
          <Route
            exact
            path={[Routes.PLANNING_STOP_DETAILS, Routes.PLANNING_STOP_DETAILS_EDIT]}
            component={Pages.PlanningStopDetails}
          />
          <Route exact path={Routes.PLANNING_CONFIRM_STOP}>
            <Pages.PlanningConfirmStop geolocation={geolocation} />
          </Route>
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.PLANNING_JOB_LIST}
            path={Routes.PLANNING_JOB_DETAILS_UPDATE}
            component={Pages.PlanningJobDetailsUpdate}
          />
          {/* DELIVERED PAGES */}
          <Route exact path={Routes.DELIVERED_JOB_LIST} component={Pages.DeliveredJobList} />
          <Route exact path={Routes.DELIVERED_JOB_LIST_FILTER} component={Pages.DeliveredJobListFilter} />
          <Route exact path={Routes.DELIVERED_JOB_DETAILS} component={Pages.DeliveredJobDetails} />
          <Route exact path={Routes.DELIVERED_STOP_DETAILS} component={Pages.DeliveredStopDetails} />
          {/* USER PROFILE PAGES */}
          <Route exact path={Routes.USER_REVIEWS} component={Pages.UserReviews} />
          <Route exact path={Routes.USER_DETAILS} component={Pages.UserDetails} />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.USER_DETAILS}
            path={Routes.USER_VEHICLES}
            component={Pages.UserVehicles}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.USER_DETAILS}
            path={Routes.USER_VEHICLES_CREATE}
            component={Pages.UserVehiclesCreate}
          />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.USER_VEHICLES}
            path={Routes.USER_VEHICLE_DETAILS}
            component={Pages.UserVehiclesDetails}
          />
          <Route exact path={Routes.USER_ID} component={Pages.UserId} />
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.USER_REVIEWS}
            path={Routes.USER_ROUTES}
            component={Pages.UserRoutes}
          />
          {/* DAY ROUTE PAGES */}
          <Route exact path={Routes.DAY_ROUTE} component={Pages.DayRoute} />
          <Route exact path={Routes.DAY_ROUTE_DETAILS} component={Pages.DayRouteDetails} />
          <Route exact path={Routes.DAY_ROUTE_ADD} component={Pages.DayRouteAdd} />
          <Route exact path={Routes.DAY_ROUTE_ADD_PICKUP} component={Pages.DayRouteAddPickup} />
          <Route exact path={Routes.DAY_ROUTE_ADD_DELIVERY} component={Pages.DayRouteAddDelivery} />
          <Route exact path={Routes.DAY_ROUTE_ADD_JOB} component={Pages.DayRouteAddJob} />
          <Route exact path={Routes.DAY_ROUTE_ADD_OTHER} component={Pages.DayRouteAddOther} />
          {/* 
          Stop details: needs two routes, 
          1) for displaying details
          2) for the editing slide over. */}
          <Route
            exact
            path={[Routes.DAY_ROUTE_STOP_DETAILS, Routes.DAY_ROUTE_STOP_DETAILS_EDIT]}
            component={Pages.DayRouteStopDetails}
          />
          <Route exact path={Routes.DAY_ROUTE_STOP_CONFIRM}>
            <Pages.DayRouteConfirmStop geolocation={geolocation} />
          </Route>
          {/* INBOX PAGES */}
          <Route exact path={Routes.INBOX_LIST} component={Pages.InboxList} />
          <Route exact path={Routes.INBOX_DETAILS} component={Pages.InboxDetails} />
          {/* SETTINGS PAGE */}
          <Route exact path={Routes.USER_SETTINGS}>
            {/* Pass the geolocation object directly so that the component remains reactive to changes. */}
            <Pages.UserSettings geolocation={geolocation} />
          </Route>
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.USER_SETTINGS}
            path={Routes.FORGET_ACCOUNT}
            component={Pages.ForgetAccount}
          />
          {/* DASHBOARD */}
          <ProtectedRoute
            exact
            isAuthorized={auth.isAccountAdmin}
            redirectTo={Routes.DASHBOARD_FOR_USER}
            path={Routes.DASHBOARD_FOR_ACCOUNT}
            component={Pages.DashboardForAdmin}
          />
          <Route exact path={Routes.DASHBOARD_FOR_USER} component={Pages.DashboardForUser} />
          {/* AUTH PAGES */}
          <Route exact path={Routes.LOGOUT}>
            {/* Pass the geolocation object directly so that the component remains reactive to changes. */}
            <Pages.Logout geolocation={geolocation} />
          </Route>
          {/* DAY ROUTE FOR CURRENT DAY IS DEFAULT VIEW UPON LOGIN */}
          <Redirect to={Routes.DAY_ROUTE} />
        </Switch>
        <NpsForm />
      </MoreSection>
    );
  }
  /**
   * Catch-all scenario - user likely end up here because of a funky or unsupported role.
   */
  return (
    <Switch>
      {/* Render a page at root that communicate the error state and provide a way for logging out. */}
      <Route exact path="/">
        <Page
          errorText={t((d) => d.app.errors.role_not_supported)}
          infoText={auth.user?.roles?.join(", ")}
          cta={
            <Button className={cn("w-full")} onClick={() => history.push(Routes.LOGOUT)}>
              {t((d) => d.app.menu.logout)}
            </Button>
          }
        />
      </Route>
      {/* Must provide the logout route so that the logout CTA / route above is operable. */}
      <Route exact path={Routes.LOGOUT}>
        {/* Pass the geolocation object directly so that the component remains reactive to changes. */}
        <Pages.Logout geolocation={geolocation} />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};
