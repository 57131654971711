import { IconArrowLeft, IconLoader, Message } from "@brenger/react";
import { IonPage, IonRouterLink } from "@ionic/react";
import cn from "classnames";
import React from "react";

interface Props {
  error?: string;
  loading?: boolean;
  title?: string;
  backUrl?: string;
}

export const NewPageWrapper: React.FC<Props> = ({ loading, error, title, backUrl, children }) => {
  return (
    <IonPage className={"lg:pl-52 absolute inset-0 overflow-auto"} onClick={(e) => e.stopPropagation()}>
      <div className="min-h-screen bg-white">
        {title && (
          <div className="flex items-justify p-4 border-b border-b-gray-200 br-text-blue-600">
            {backUrl && (
              <IonRouterLink routerDirection="back" routerLink={backUrl}>
                <IconArrowLeft className="w-6 h-6" />
              </IonRouterLink>
            )}
            <b className="mx-auto">{title}</b>
          </div>
        )}
        <div className={cn("p-4 lg:p-10", { "mt-4": !title })}>
          <div className="md:max-w-4xl md:mx-auto">
            {loading && (
              <div style={{ minHeight: "20rem" }} className={cn("flex", "justify-center", "items-center", "flex-grow")}>
                <IconLoader className={cn("w-6", "h-6")} />
              </div>
            )}
            {!loading && error && <Message type="error">{error}</Message>}
            {!loading && !error && children}
          </div>
        </div>
      </div>
    </IonPage>
  );
};
