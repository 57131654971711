import { ProgressLevel } from "@brenger/api-client";
import {
  Button,
  H3,
  IconAssembly,
  IconCheckCircle,
  IconPersonAdd,
  IconSync,
  IconTailgate,
  InputCheckbox,
  InputDate,
  InputRadio,
  Label,
  Pill,
  Select,
  Spacer,
} from "@brenger/react";
import cn from "classnames";
import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import parseDate from "date-fns/parse";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { BreadcrumbNav, Grid, Page } from "../../../components";
import { useFormatDate, useSearchFilter, useSelector, useTranslation } from "../../../hooks";
import { searchFilterActions as actions } from "../../../store/search/filter";
import { RegionNL, SortByField, SortByOrder } from "../../../store/search/types";
import { Routes } from "../../../utils";

/**
 * Below are some small utils to stringify and parse the sort by values in order to make
 * them use-able with HTML option values.
 */
const stringifySortBy = (field: SortByField, order: SortByOrder): string => `${field}::${order}`;
const parseSortBy = (sortBy: string): [SortByField, SortByOrder] => sortBy.split("::") as [SortByField, SortByOrder];

const regionsForNL: RegionNL[] = [
  "drenthe",
  "flevoland",
  "friesland",
  "gelderland",
  "groningen",
  "limburg",
  "noord-brabant",
  "noord-holland",
  "overijssel",
  "utrecht",
  "zeeland",
  "zuid-holland",
];

const today = new Date();
const tomorrow = addDays(today, 1);
const dayAfterTomorrow = addDays(today, 2);
const dayAfterAfterTomorrow = addDays(today, 3);

export type SearchFilterPresentation = "preview" | "search";

interface Props {
  presentation: SearchFilterPresentation;
}

const listLocationMap: { [key in SearchFilterPresentation]: Routes } = {
  preview: Routes.PREVIEW_JOB_LIST,
  search: Routes.SEARCH_JOB_LIST,
};

export const SearchJobListFilterRaw: React.FC<Props> = (props) => {
  const formatDay = useFormatDate("weekday");
  const formatMonthDay = useFormatDate("month-day");
  const formatDateForApi = useFormatDate("api-date");
  const { t } = useTranslation();

  const breadcrumbs = [
    { to: listLocationMap[props.presentation], text: t((d) => d.app.tabs[props.presentation]) },
    { text: "filter" },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const { openTransportJobs, numberOfFilteredOpenJobs } = useSearchFilter();
  const { sortBy, countries, regions, pickupDate, conditions, jobLevels } = useSelector((state) => state.searchFilter);

  if (!openTransportJobs.data?.length || openTransportJobs.error) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={openTransportJobs.isLoading}
        errorText={(openTransportJobs.error as Error)?.message}
      />
    );
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          onClick={() => history.push(listLocationMap[props.presentation])}
          icon={<IconSync className={cn("h-4")} />}
        >
          {t((d, withTemplate) => {
            return withTemplate(d.search.filter.show, {
              number: numberOfFilteredOpenJobs,
            });
          })}
        </Button>
      }
    >
      <H3>{t((d) => d.search.filter.sort_by)}</H3>
      <Spacer h={2} />
      <Select
        value={stringifySortBy(sortBy.field, sortBy.order)}
        onChange={(stringifiedSortBy) => {
          const [field, order] = parseSortBy(stringifiedSortBy);
          dispatch(actions.setSortBy({ field, order }));
        }}
      >
        <option value={stringifySortBy("created_at", "desc")}>{t((d) => d.search.filter.newest)}</option>
        <option value={stringifySortBy("personalized", "desc")}>{t((d) => d.search.filter.personalized)}</option>
        <option value={stringifySortBy("price", "desc")}>{t((d) => d.search.filter.price)}</option>
      </Select>
      <Spacer h={4} />
      <H3>{t((d) => d.search.filter.by_location)}</H3>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={2}>
        <Label text={t((d) => d.search.filter.netherlands)} position="right">
          <InputCheckbox checked={countries.includes("nl")} onChange={() => dispatch(actions.setCountry("nl"))} />
        </Label>
        <Label text={t((d) => d.search.filter.belgium)} position="right">
          <InputCheckbox checked={countries.includes("be")} onChange={() => dispatch(actions.setCountry("be"))} />
        </Label>
        <Label text={t((d) => d.search.filter.germany)} position="right">
          <InputCheckbox checked={countries.includes("de")} onChange={() => dispatch(actions.setCountry("de"))} />
        </Label>
        <Label text={t((d) => d.search.filter.other)} position="right">
          <InputCheckbox checked={countries.includes("other")} onChange={() => dispatch(actions.setCountry("other"))} />
        </Label>
      </Grid>
      {/* SHOW DUTCH REGIONS WHEN NL IS SELECTED */}
      {countries.includes("nl") && (
        <>
          <Spacer h={2} />
          <H3>{t((d) => d.search.filter.by_region)}</H3>
          <Spacer h={2} />
          <Grid cols={{ lg: 2 }} gap={2}>
            {regionsForNL.map((region) => {
              return (
                <Label key={region} text={region} position="right" className={cn("capitalize")}>
                  <InputCheckbox
                    checked={regions.includes(region)}
                    onChange={() => dispatch(actions.setRegion(region))}
                  />
                </Label>
              );
            })}
          </Grid>
        </>
      )}
      {/* PICKUP DATE SELECT */}
      <Spacer h={4} />
      <H3>{t((d) => d.search.filter.by_date)}</H3>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={2}>
        {/* ALL DAYS */}
        <Label text={t((d) => d.search.filter.view_all_days)} position="right">
          <InputRadio checked={!pickupDate} onChange={() => dispatch(actions.setPickupDate(null))} />
        </Label>
        {/* TODAY ONLY */}
        <Label text={`${t((d) => d.search.filter.today)} (${formatMonthDay(today.toISOString())})`} position="right">
          <InputRadio
            className={cn("capitalize")}
            checked={!!pickupDate && isSameDay(new Date(pickupDate), today)}
            onChange={() => dispatch(actions.setPickupDate(today))}
          />
        </Label>
        {/* TOMORROW ONLY */}
        <Label
          className={cn("capitalize")}
          text={`${t((d) => d.search.filter.tomorrow)} (${formatMonthDay(tomorrow.toISOString())})`}
          position="right"
        >
          <InputRadio
            checked={!!pickupDate && isSameDay(new Date(pickupDate), tomorrow)}
            onChange={() => dispatch(actions.setPickupDate(tomorrow))}
          />
        </Label>
        {/* DAY AFTER TOMORROW ONLY */}
        <Label
          className={cn("capitalize")}
          text={`${formatDay(dayAfterTomorrow.toISOString())} (${formatMonthDay(dayAfterTomorrow.toISOString())})`}
          position="right"
        >
          <InputRadio
            checked={!!pickupDate && isSameDay(new Date(pickupDate), dayAfterTomorrow)}
            onChange={() => dispatch(actions.setPickupDate(dayAfterTomorrow))}
          />
        </Label>
        {/* DAY AFTER AFTER TOMORROW ONLY */}
        <Label
          text={`${formatDay(dayAfterAfterTomorrow.toISOString())} (${formatMonthDay(
            dayAfterAfterTomorrow.toISOString()
          )})`}
          className={cn("capitalize")}
          position="right"
        >
          <InputRadio
            checked={!!pickupDate && isSameDay(new Date(pickupDate), dayAfterAfterTomorrow)}
            onChange={() => dispatch(actions.setPickupDate(dayAfterAfterTomorrow))}
          />
        </Label>
        {/* SELECT SPECIFIC DATE */}
        <Label text={t((d) => d.search.filter.view_specific_date)} position="right">
          <InputRadio
            checked={!!pickupDate && isAfter(new Date(pickupDate), dayAfterAfterTomorrow)}
            onChange={() => dispatch(actions.setPickupDate(addDays(new Date(), 4)))}
          />
        </Label>
      </Grid>
      {!!pickupDate && isAfter(new Date(pickupDate), dayAfterAfterTomorrow) && (
        <>
          <Spacer h={2} />
          <InputDate
            // NOTE: Min should be greater than the last possible radio button! IE, greater than dayAfterAfter tmrw.
            min={formatDateForApi(addDays(dayAfterAfterTomorrow, 1).toISOString())}
            max={formatDateForApi(addDays(new Date(), 365).toISOString())}
            value={formatDateForApi(new Date(pickupDate).toISOString())}
            onChange={(date) => {
              // NOTE: parse the date string from InputDate (yyyy-mm-dd) into a JS date.
              dispatch(actions.setPickupDate(parseDate(date, "yyyy-MM-dd", new Date())));
            }}
          />
        </>
      )}
      <Spacer h={4} />
      <H3>{t((d) => d.search.filter.by_condition)}</H3>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={2}>
        {/* PREPAID */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.transport_job.prepaid)}</span>
              <IconCheckCircle className={cn("text-green-400", "ml-2")} />
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("prepaid")}
            onChange={() => dispatch(actions.setCondition("prepaid"))}
          />
        </Label>
        {/* BUNDLED */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span className={cn("mr-2")}>{t((d) => d.transport_job.bundled)}</span>
              <Pill marginY={"none"} type="blue" className={cn("mr-2")}>
                {t((d) => d.transport_job.bundled)}
              </Pill>
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("bundled")}
            onChange={() => dispatch(actions.setCondition("bundled"))}
          />
        </Label>
        {/* COMBO JOB */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span className={cn("mr-2")}>{t((d) => d.transport_job.combo_job)}</span>
              <Pill marginY={"none"} type="blue" className={cn("mr-2")}>
                {t((d) => d.transport_job.combo_job)}
              </Pill>
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("combo_job")}
            onChange={() => dispatch(actions.setCondition("combo_job"))}
          />
        </Label>
        {/* EXTRA DRIVER */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.transport_job.stop_details.carrying_help.extra_driver)}</span>
              <IconPersonAdd className={cn("ml-2", "w-6", "h-6")} />
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("extra_driver")}
            onChange={() => dispatch(actions.setCondition("extra_driver"))}
          />
        </Label>
        {/* EQUIPMENT TAILGATE */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.transport_job.stop_details.carrying_help.equipment_tailgate)}</span>
              <IconTailgate className={cn("ml-2", "w-6", "h-6")} />
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("equipment_tailgate")}
            onChange={() => dispatch(actions.setCondition("equipment_tailgate"))}
          />
        </Label>
        {/* ASSEMBLY REQUIRED */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.transport_job.stop_details.services.assembly)}</span>
              <IconAssembly className={cn("ml-2", "w-6", "h-6")} />
            </span>
          }
          position="right"
        >
          <InputCheckbox
            checked={conditions.includes("assembly")}
            onChange={() => dispatch(actions.setCondition("assembly"))}
          />
        </Label>
      </Grid>
      <Spacer h={4} />
      <H3>{t((d) => d.search.filter.by_status)}</H3>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={2}>
        {(["bronze", "gold", "platinum", "diamond"] as ProgressLevel[]).map((level) => {
          return (
            <Label key={level} text={t((d) => d.level[level])} position="right">
              <InputCheckbox
                checked={jobLevels.includes(level)}
                onChange={() => dispatch(actions.setJobLevel(level))}
              />
            </Label>
          );
        })}
      </Grid>
    </Page>
  );
};

export const SearchJobListFilter: React.FC = () => <SearchJobListFilterRaw presentation="search" />;
