import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { H3, H4, Card as CardRaw, Button, IconArrowLeft } from "@brenger/react";

import { Page, BreadcrumbNav, Grid } from "../../../components";
import { useDayRouteParams, useTranslation } from "../../../hooks";
import { Routes } from "../../../utils";

const Card: React.FC<{ mainText: string; subText: string }> = (props) => {
  return (
    <CardRaw>
      <H4>{props.mainText}</H4>
      <div>{props.subText}</div>
    </CardRaw>
  );
};

export const DayRouteAdd: React.FC = () => {
  const { t } = useTranslation();
  const params = useDayRouteParams();
  const dayRouteUrl = `/day-route/${params.date}/${params.user_id}`;

  return (
    <Page
      nav={
        <BreadcrumbNav
          breadcrumbs={[
            { to: Routes.PLANNING_JOB_LIST, text: t((d) => d.app.tabs.planning) },
            { to: dayRouteUrl, text: t((d) => d.day_route.details.title) },
            { text: t((d) => d.actions.add) },
          ]}
        />
      }
      stickyFooter={
        <Link to={dayRouteUrl}>
          <Button className={cn("w-full")} icon={<IconArrowLeft />}>
            {t((d) => d.actions.go_back)}
          </Button>
        </Link>
      }
    >
      <H3>{t((d) => d.day_route.create_stop.prompt)}</H3>
      <Grid cols={{ lg: 2 }} gap={4} className={cn("mt-4")}>
        <Link to={(currentLocation) => `${currentLocation.pathname}/job`}>
          <Card mainText={t((d) => d.transport_job.from_a_to_b)} subText={t((d) => d.transport_job.from_a_to_b_desc)} />
        </Link>
        <Link to={(currentLocation) => `${currentLocation.pathname}/pickup`}>
          <Card mainText={t((d) => d.transport_job.pickup_stop)} subText={t((d) => d.transport_job.pickup_stop_desc)} />
        </Link>
        <Link to={(currentLocation) => `${currentLocation.pathname}/delivery`}>
          <Card
            mainText={t((d) => d.transport_job.delivery_stop)}
            subText={t((d) => d.transport_job.delivery_stop_desc)}
          />
        </Link>
        <Link to={(currentLocation) => `${currentLocation.pathname}/other`}>
          <Card
            mainText={t((d) => d.day_route.create_stop.other_stop)}
            subText={t((d) => d.day_route.create_stop.other_stop_desc)}
          />
        </Link>
      </Grid>
    </Page>
  );
};
