import { InputCheckbox, InputNumber, InputText, Label, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Grid } from "../../../components";
import { LicensePlate } from "../../../components/data/LicensePlate";
import { useTranslation } from "../../../hooks";
import { CacheKey, IonicRoutes, coreClient } from "../../../utils";
import { EditOrDeleteVehicle } from "../../profile/UserVehicleEdit/EditOrDeleteVehicle";
import { NewPageWrapper } from "../layout/PageWrapper";
import { VehicleSummary } from "./VehicleSummary";

export const MoreVehiclesDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const retrieveVehicle = useQuery([CacheKey.RETRIEVE_VEHICLE_LIST, params.id], () =>
    coreClient.vehicles.retrieve({ id: params.id })
  );

  const vehicle = retrieveVehicle.data;

  return (
    <NewPageWrapper
      title={t((d) => d.more.vehicles)}
      backUrl={IonicRoutes.more.vehicles()}
      loading={retrieveVehicle.isLoading}
      error={(retrieveVehicle.error as Error)?.message}
    >
      <div className={cn("flex", "items-center", "justify-between")}>
        <h3 className={cn("capitalize")}>
          {vehicle?.make} {vehicle?.model}
        </h3>
        <div className={cn("flex")}>
          <EditOrDeleteVehicle canDelete={true} vehicle={vehicle} onEditSuccess={retrieveVehicle.refetch} />
        </div>
      </div>
      <Spacer h={4} />
      <VehicleSummary vehicle={vehicle} />
      <Spacer h={4} />
      <h4>{t((d) => d.vehicles.title)}</h4>
      <Spacer h={4} />
      <Label text={`${t((d) => d.vehicles.make)} + ${t((d) => d.vehicles.model)}`}>
        <div>
          <InputText className={cn("capitalize", "w-full")} value={vehicle?.make + " " + vehicle?.model} disabled />
        </div>
      </Label>
      <Spacer h={4} />
      <div>{t((d) => d.vehicles.max_volume_cm)}</div>
      <Spacer h={2} />
      <Grid gap={4} cols={{ default: 3 }}>
        <Label text={t((d) => d.dims.length)}>
          <InputNumber className={cn("w-full")} min={0} value={vehicle?.loading_dimensions_length_cm} disabled />
        </Label>
        <Label text={t((d) => d.dims.width)}>
          <InputNumber className={cn("w-full")} min={0} value={vehicle?.loading_dimensions_width_cm} disabled />
        </Label>
        <Label text={t((d) => d.dims.height)}>
          <InputNumber className={cn("w-full")} min={0} value={vehicle?.loading_dimensions_height_cm} disabled />
        </Label>
      </Grid>
      <Spacer h={4} />
      <Label text={t((d) => d.vehicles.max_capacity_kg)}>
        <InputNumber className={cn("w-full")} min={0} value={vehicle?.loading_weight_limit_kg} disabled />
      </Label>
      <Spacer h={4} />
      <Label text={t((d) => d.vehicles.license_plate)}>
        <div className={cn("flex")}>
          <LicensePlate vehicle={vehicle} />
        </div>
      </Label>
      <Spacer h={4} />
      <Label text={t((d) => d.vehicles.euronorm)}>
        <InputText className={cn("w-full")} value={vehicle?.euro_norm?.toString()} disabled />
      </Label>
      <Spacer h={6} />
      <Grid gap={4} cols={{ lg: 2 }}>
        <div>
          <Label text={`${t((d) => d.vehicles.tailgate)}?`} position="right">
            <InputCheckbox defaultChecked={vehicle?.has_tailgate} disabled />
          </Label>
        </div>
        <div>
          <Label text={`${t((d) => d.vehicles.pallet_jack)}?`} position="right">
            <InputCheckbox defaultChecked={vehicle?.has_pallet_jack} disabled />
          </Label>
        </div>
      </Grid>
    </NewPageWrapper>
  );
};
