import { Button, Spacer } from "@brenger/react";
import * as React from "react";

import { Modal } from "../../../components";
import { useTranslation } from "../../../hooks";

interface Props {
  isActive: boolean;
  closeHandler(): void;
}

export const ForgetRequestSuccessModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <h3>{t((d) => d.settings.forget_account.received_title)}</h3>
      <Spacer h={3} />
      <div>{t((d) => d.settings.forget_account.received_text)}</div>
      <Spacer h={3} />
      <Button buttonType="primary" onClick={props.closeHandler}>
        {t((d) => d.tooltip.understood)}
      </Button>
    </Modal>
  );
};
