import { Account } from "@brenger/api-client";
import { H2, H4, IconMedal, IconSmile, IconVan, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { DashboardCard, ExternalLink, Grid, Page } from "../../../components";
import { useAuth, useDashboardQuery, useTranslation } from "../../../hooks";
import { CacheKey, Routes, coreClient, getDeletedUserList, getSanitizedUserList } from "../../../utils";
import { SubAccountCard } from "./SubAccountCard";

export const cardCns = cn(
  "text-left",
  "p-4",
  "rounded-md",
  "flex",
  "flex-col",
  "justify-between",
  "bg-white",
  "h-full"
);

export const DashboardForAdmin: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const accountData = useQuery(
    [CacheKey.RETRIEVE_ACCOUNT, auth.user?.account?.["@id"]],
    () => coreClient.accounts.retrieve({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const accountUsers = useQuery(
    [CacheKey.RETRIEVE_USERS, auth.user?.account?.["@id"]],
    () => coreClient.accounts.listUsers({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const userList = accountUsers.data?.["hydra:member"];
  const sanitizedUserList = userList ? getSanitizedUserList(userList) : [];
  const deletedUserList = userList ? getDeletedUserList(userList) : [];

  const jobsData = useDashboardQuery();

  return (
    // add parent div to Page component so we can make entire view blue
    <div className={cn("bg-blue-600", "flex-grow", "pb-4")}>
      <Page errorText={(jobsData.error as Error)?.message}>
        <div className={cn("flex", "flex-col", "items-center", "text-white")}>
          <H4>{t((d) => d.dashboard.page_desc_account)}</H4>
          <H2>Hi {(auth.user?.account as Account | undefined)?.name}</H2>
          <Spacer h={4} />
          <div className={cn("flex", "items-center", "justify-center", "rounded-full", "bg-green-400", "h-24", "w-24")}>
            <IconMedal className={cn("w-16", "h-16")} />
          </div>
          <Spacer h={4} />
          <H4>{t((d) => d.dashboard.status_desc)}</H4>
          <H2>{t((d) => d.level[auth.accountLevel.raw])}</H2>
        </div>
        <Spacer h={4} />
        <Grid cols={{ default: 2 }} gap={4}>
          <ExternalLink url={t((d) => d.dashboard.performance_tips_url)}>
            <div className={cn(cardCns)}>
              <div className={cn("inline-block")}>{t((d) => d.dashboard.performance_tips)}</div>
              <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end")} />
            </div>
          </ExternalLink>
          <ExternalLink url={t((d) => d.dashboard.create_sub_account_url)}>
            <div className={cn(cardCns, "cursor-pointer")}>
              <div className={cn("inline-block")}>{t((d) => d.profile.add_user)}</div>
              <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end")} />
            </div>
          </ExternalLink>
        </Grid>
        <Spacer h={6} />
        <div className={cn("flex", "justify-center")}>
          <IconVan className={cn("text-white", "w-12", "h-12")} />
        </div>
        <Spacer h={6} />
        <Grid cols={{ default: 2 }} gap={4}>
          <DashboardCard
            to={Routes.PLANNING_JOB_LIST}
            value={jobsData.plannedJobsCount || "--"}
            label={t((d) => d.dashboard.planned)}
          />
          <DashboardCard
            to={Routes.PLANNING_JOB_LIST}
            value={jobsData.pendingJobsCount || "--"}
            label={t((d) => d.dashboard.pending)}
          />
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={jobsData.totalJobsCount || "--"}
            label={t((d) => d.dashboard.transports_total)}
          />
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={jobsData.lastQuarterJobsCount || "--"}
            label={t((d) => d.dashboard.transports_last_90_days)}
          />
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={
              accountData.data?.total_confirmation_with_proximity_success_rate
                ? `${Math.round(accountData.data.total_confirmation_with_proximity_success_rate * 100)}%`
                : "--"
            }
            label={t((d) => d.dashboard.total_confirmation_with_proximity_success_rate_15june_onward)}
          />
        </Grid>
        <Spacer h={6} />
        <div className={cn("w-full", "text-center", "text-white")}>
          <H2>
            {t((d) => d.dashboard.overview_couriers)} ({accountUsers.data?.["hydra:totalItems"] || "--"})
          </H2>
        </div>
        <Spacer h={6} />
        <Grid cols={{ lg: 2 }} gap={4}>
          {sanitizedUserList.map((user) => (
            <SubAccountCard key={user["@id"]} user={user} />
          ))}
        </Grid>
        {deletedUserList.length !== 0 && (
          <>
            <div className={cn("flex", "items-center", "pt-4")}>
              <hr className={cn("grow")} />
              <div className={cn("text-white", "px-4")}>
                <div>{t((d) => d.overview_couriers_deleted)}</div>
              </div>
              <hr className={cn("grow")} />
            </div>
            <div className={cn("text-white", "text-center", "pb-4")}>
              <div>{t((d) => d.overview_couriers_deleted_explain)}</div>
            </div>
            <Grid cols={{ lg: 2 }} gap={4}>
              {deletedUserList.map((user) => (
                <SubAccountCard key={user["@id"]} user={user} />
              ))}
            </Grid>
          </>
        )}
        <Spacer h={6} />
      </Page>
    </div>
  );
};
