import * as React from "react";
import { ItemSet, ItemSetAttributes as ItemSetAttributeObject, ItemSetAttribute } from "@brenger/api-client";

import { useTranslation } from "../../hooks";
import { MetaItems } from "..";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  itemSets?: ItemSet[];
}

/**
 * Render item set attributes when found any in the given itemSets
 */
export const ItemSetAttributes: React.FC<Props> = ({ itemSets, ...unused }) => {
  const { t } = useTranslation();

  const itemSetAttributes = itemSets
    ?.filter((itemSet) => {
      // Filter out empty and array-like attributes values
      return Boolean(itemSet.attributes) && !Array.isArray(itemSet.attributes);
    })
    .map((itemSet) => {
      // Because we filtered out any array-like attributes above, we can now safely coerce this to the object type now.
      const attributes = itemSet.attributes as ItemSetAttributeObject;
      // Clone the object before deleting properties from it.
      const clonedAttributes = { ...(attributes || {}) };
      // Remove any of the black-listed attributes that we don't want to show to drivers
      Reflect.deleteProperty(clonedAttributes, "business_order_type");
      Reflect.deleteProperty(clonedAttributes, "instant_transport");
      return clonedAttributes;
    })
    .filter((attributes) => {
      // Make sure we only keep objects that have items (after we deleted blacklisted ones in above map)
      return Object.keys(attributes).length > 0;
    })
    .flatMap((attributes) => {
      // Convert list of objects into list of entries
      // [{attr: value}, {attr: value}] => [[attr, value], [attr, value]]
      return Object.entries(attributes);
    });

  if (!itemSetAttributes?.length) return null;

  const items = itemSetAttributes.map(([name, value]) => {
    // Must coerce the key to a proper ItemSetAttribute here. Calling Object.keys converts keys to string literals.
    const attributeName = name as ItemSetAttribute;

    // Some attributes have a boolean value, in which case there's nothing to really render for drivers as the value.
    // More important in these cases is accessing the translation for the present attribute.
    // One example is the note about `generated_items` - when this is present, we want to use the key access / display a translation.
    const isValueRenderWorthy = ["string", "number"].includes(typeof value);

    return {
      name: t((d) => d.transport_job.item_sets.attributes[attributeName]),
      // Due to `isvalueRenderWorthy` we can safely cast value as `string | number`
      value: isValueRenderWorthy ? (value as string | number) : null,
    };
  });

  return <MetaItems {...unused} items={items} />;
};
