import * as React from "react";
import { Redirect } from "react-router-dom";
import { useAuth, useFormatDate } from "../../../hooks";

export const DayRoute: React.FC = () => {
  const auth = useAuth();
  const formatDateForApi = useFormatDate("api-date");
  const dayRouteUrl = `/day-route/${formatDateForApi(new Date().toUTCString())}/${auth.userId}`;
  return <Redirect to={dayRouteUrl} />;
};
