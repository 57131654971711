/**
 * ALERT: New router in the making with IonicRouter
 * The Enum way are classic routes.
 * If the feature you are adding should be supported in the old routing system then add it the enum way
 * If it only needs to live in the new system add it to IonicRoutes.
 */

export const IonicRoutes = {
  more: {
    home: (): string => {
      return "/more";
    },
    inbox: (tjalId?: string): string => {
      if (tjalId) {
        return `/more/inbox/${tjalId}`;
      }
      return `/more/inbox`;
    },
    dashboard: (): string => {
      return "/more/dashboard";
    },
    reviews: (): string => {
      return "/more/reviews";
    },
    brengerId: (): string => {
      return "/more/brenger-id";
    },
    profile: (): string => {
      return "/more/profile";
    },
    vehicles: (id?: string): string => {
      if (id === "create") {
        return `/more/vehicles-create`;
      }
      if (id) {
        return `/more/vehicles/${id}`;
      }
      return `/more/vehicles`;
    },
    couriers: (): string => {
      return "/more/couriers";
    },
    favouriteRoutes: (): string => {
      return "/more/favourite-routes";
    },
    locationAndLanguage: (): string => {
      return "/more/location-and-language";
    },
    forget: (): string => {
      return "/more/forget";
    },
  },
  support: {
    home: (): string => {
      return "/support";
    },
  },
};

export enum Routes {
  // FILTERS (FOR ALL SECTION)
  SEARCH_JOB_LIST_FILTER = "/filter/search",
  PLANNING_JOB_LIST_FILTER = "/filter/planning",
  DELIVERED_JOB_LIST_FILTER = "/filter/delivered",
  PREVIEW_JOB_LIST_FILTER = "/filter/preview",
  // SEARCH SECTION
  SEARCH_JOB_LIST = "/search",
  SEARCH_JOB_DETAILS = "/search/:tj_id",
  SEARCH_JOB_DETAILS_PREVIEW = "/search/:tj_id/preview",
  SEARCH_STOP_DETAILS = "/search/:tj_id/stops/:stop_id",
  SEARCH_CLAIM_JOB = "/search/:tj_id/claim",
  // PLANNING SECTION
  PLANNING_JOB_LIST = "/planning",
  PLANNING_JOB_DETAILS = "/planning/:tjal_id",
  PLANNING_JOB_DETAILS_UPDATE = "/planning/:tjal_id/update",
  PLANNING_STOP_DETAILS = "/planning/:tjal_id/stops/:stop_id",
  PLANNING_STOP_DETAILS_EDIT = "/planning/:tjal_id/stops/:stop_id/edit",
  PLANNING_CONFIRM_STOP = "/planning/:tjal_id/stops/:stop_id/confirm",
  // DELIVERED SECTION
  DELIVERED_JOB_LIST = "/delivered",
  DELIVERED_JOB_DETAILS = "/delivered/:tjal_id",
  DELIVERED_STOP_DETAILS = "/delivered/:tjal_id/stops/:stop_id",
  // INBOX SECTION
  INBOX_LIST = "/inbox",
  INBOX_DETAILS = "/inbox/:tjal_id",
  // SETTINGS SECTION
  USER_SETTINGS = "/settings",
  FORGET_ACCOUNT = "/settings/forget",
  // DASHBOARD
  DASHBOARD_FOR_USER = "/dashboard/user",
  DASHBOARD_FOR_ACCOUNT = "/dashboard/account",
  // USER PROFILE SECTION
  USER_ID = "/profile/id",
  USER_DETAILS = "/profile/details",
  USER_REVIEWS = "/profile/reviews",
  USER_ROUTES = "/profile/routes",
  USER_VEHICLES = "/profile/vehicles",
  USER_VEHICLE_DETAILS = "/profile/vehicles/:id",
  USER_VEHICLES_CREATE = "/profile/vehicles/create",
  // DAY ROUTE
  DAY_ROUTE = "/day-route",
  DAY_ROUTE_DETAILS = "/day-route/:date/:user_id",
  DAY_ROUTE_ADD = "/day-route/:date/:user_id/add",
  DAY_ROUTE_ADD_PICKUP = "/day-route/:date/:user_id/add/pickup",
  DAY_ROUTE_ADD_DELIVERY = "/day-route/:date/:user_id/add/delivery",
  DAY_ROUTE_ADD_JOB = "/day-route/:date/:user_id/add/job",
  DAY_ROUTE_ADD_OTHER = "/day-route/:date/:user_id/add/other",
  DAY_ROUTE_STOP_DETAILS = "/day-route/:date/:user_id/activity/:stop_id",
  DAY_ROUTE_STOP_DETAILS_EDIT = "/day-route/:date/:user_id/activity/:stop_id/edit",
  DAY_ROUTE_STOP_CONFIRM = "/day-route/:date/:user_id/activity/:stop_id/confirm",

  // PREVIEW SECTION (NO-AUTH)
  PREVIEW_JOB_LIST = "/preview",
  PREVIEW_JOB_DETAILS = "/preview/:tj_id",
  // AUTH VIEWS
  LOGIN = "/login",
  LOGOUT = "/logout",
}
