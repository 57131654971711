import * as React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { Strong, IconCaretRight } from "@brenger/react";

import { Routes } from "../../utils";

export interface Breadcrumb {
  text: string;
  /**
   * When "to" is undefined, the breadcrumb renders as plain text without a link.
   */
  to?: string | Routes;
}

interface Props {
  breadcrumbs?: Breadcrumb[];
}

export const BreadcrumbNav: React.FC<Props> = (props) => {
  return (
    <div className={cn("w-full", "bg-gray-200", "br-text-sm")} style={{ height: "43px" }}>
      <div className={cn("container", "mx-auto", "px-4", "uppercase", "flex", "py-2", "items-center", "flex-wrap")}>
        {props.breadcrumbs?.map((b, idx) => {
          if (b.to) {
            return (
              <React.Fragment key={idx}>
                <Link className={cn("mr-1", "text-blue-600")} to={b.to}>
                  <div>{b.text}</div>
                </Link>
                <IconCaretRight className={cn("w-4", "h-4", "text-gray-600", "mr-1")} />
              </React.Fragment>
            );
          }
          return (
            <div key={idx}>
              <Strong>{b.text}</Strong>
            </div>
          );
        })}
      </div>
    </div>
  );
};
