import { Account } from "@brenger/api-client";
import { Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";

import { BreadcrumbNav, DriverSelect, Page, TabNav } from "../../../components";
import { useAuth, useTabNavigation, useTranslation, useUrlSearchParam } from "../../../hooks";
import { UserAccountForm } from "./UserAccountForm";
import { UserAddressForm } from "./UserAddressForm";
import { UserBankAccountForm } from "./UserBankAccountForm";
import { UserDetailsForm } from "./UserDetailsForm";
import { UserProfileImage } from "./UserProfileImage";

export const UserDetails: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const account = auth.user?.account as Account | undefined;
  const userParam = useUrlSearchParam({ key: "user_iri", delete: true, defaultValue: auth.user?.["@id"] });
  const [selectedUserIRI, setSelectedUserIRI] = React.useState("");

  React.useEffect(() => {
    if (userParam) setSelectedUserIRI(userParam);
  }, [userParam]);

  let nav = <BreadcrumbNav breadcrumbs={[{ text: t((d) => d.app.menu.profile) }]} />;
  const tabs = useTabNavigation("profile");

  // We only want to show tab nav when there is more than one tab available.
  // Otherwise, it looks awkward, and better to show breadcrumbs.
  // This comes up between admin users and sub users.
  // Sub users can only access one tab.
  if (tabs && tabs.length > 1) {
    nav = <TabNav tabs={tabs} />;
  }

  return (
    <Page nav={nav}>
      <UserProfileImage
        userId={getIdFromIri(selectedUserIRI)}
        accountName={account?.name}
        // Can only edit your own profile image.
        canEdit={auth.user?.["@id"] === selectedUserIRI}
      />
      <Spacer h={4} />
      <h5>{t((d) => d.profile.personal_details)}</h5>
      <DriverSelect
        className={cn("mt-2", "mb-2")}
        labelText={t((d) => d.profile.select_courier)}
        accountId={getIdFromIri(auth.user?.account)}
        value={selectedUserIRI}
        onChange={({ userIRI }) => setSelectedUserIRI(userIRI)}
      />
      <Spacer h={2} />
      {/* all can edit own details */}
      <UserDetailsForm userId={getIdFromIri(selectedUserIRI)} canEdit={true} />
      {/* Only account admin can see the address details */}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.address_details)}</h5>
          <Spacer h={2} />
          <UserAddressForm addressId={getIdFromIri(auth.user?.address)} canEdit={true} />
        </>
      )}
      {/* Only account admin can see the address details */}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.account_details)}</h5>
          <Spacer h={2} />
          <UserAccountForm accountId={getIdFromIri(auth.user?.account)} canEdit={true} />
        </>
      )}
      {auth.isAccountAdmin && (
        <>
          <Spacer h={6} />
          <h5>{t((d) => d.profile.bank_details)}</h5>
          <Spacer h={2} />
          <UserBankAccountForm
            canEdit={true}
            bankAccountIds={(auth.user?.account as Account | undefined)?.bank_accounts
              .map((iri) => getIdFromIri(iri) || "")
              .filter(Boolean)}
          />
        </>
      )}
    </Page>
  );
};
