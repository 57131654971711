import { IInputText, InputDate } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router";
import { useDayRouteParams, useFormatDate } from "../../../hooks";
import { INPUT_DATE_MAX, INPUT_DATE_MIN } from "../../../utils";

type DayRouteDateSelectionProps = IInputText;

export const DayRouteDateSelection: React.FC<DayRouteDateSelectionProps> = (props) => {
  const history = useHistory();
  const params = useDayRouteParams();
  const formatDateForApi = useFormatDate("api-date");

  const onDateChange = (value?: string): void => {
    if (value) history.push(`/day-route/${value}/${params.user_id}`);
  };

  return (
    <InputDate
      className={cn("w-full")}
      value={formatDateForApi(new Date(params.date).toISOString())}
      onChange={onDateChange}
      min={formatDateForApi(INPUT_DATE_MIN)}
      max={formatDateForApi(INPUT_DATE_MAX)}
      {...props}
    />
  );
};
