import * as React from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Address, RouteMeta } from "@brenger/api-client";
import { Button, Spacer, H2, H4, Card } from "@brenger/react";

import { useTranslation, useSearchParams, useMapContext } from "../../../hooks";
import {
  Modal,
  Page,
  TransportJobDetails,
  StaticMap,
  TextPlaceholder,
  StopDetailPills,
  ExternalLink,
  getStopDetails,
} from "../../../components";
import { Routes, coreClient, isComboJob, formatSecondsToHoursMinutes, formatLocality, CacheKey } from "../../../utils";
import { PreviewJobList } from "../PreviewJobList";
import { Config, LOCALE_TEMPLATE } from "../../../config";

/**
 * This is a teaser page. It just displays slightly more info thant he list view and
 * then includes some CTAs in the footer.
 */
export const PreviewJobDetailsOverlay: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useSearchParams();

  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, params.tj_id], () =>
    coreClient.transportJobs.retrieve({ id: params.tj_id })
  );

  const mapContext = useMapContext({ presentation: "search", tj: tj.data });

  const tjData = tj.data;
  const tjalDetails = tjData ? getStopDetails(tjData.pickups, tjData.deliveries) : [];
  const pickup = tjData && tjData.pickups[0];
  return (
    <>
      <PreviewJobList />
      <Modal isActive={true} closeHandler={() => history.push(Routes.PREVIEW_JOB_LIST)}>
        <div className={cn("relative", "h-full")}>
          <Page
            loading={tj.isLoading}
            scrollToTopOnMount={false}
            stickyFooter={
              <div className={cn("flex", "w-full")}>
                <ExternalLink
                  className={cn("w-full")}
                  url={Config.DRIVER_SIGN_UP_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
                >
                  <Button className={cn("w-full")} buttonType="primary-outline">
                    {t((d) => d.actions.read_more)}
                  </Button>
                </ExternalLink>
                <Spacer w={4} />
                <ExternalLink url={Config.DRIVER_APPLICATION_URL} className={cn("w-full")}>
                  <Button className={cn("w-full")}>{t((d) => d.actions.sign_up)}</Button>
                </ExternalLink>
              </div>
            }
          >
            {tjData ? (
              <>
                {/* @TODO trans needed */}
                {/* Note: using actual title could leak too much info */}
                <H2>Transport</H2>
                <Spacer h={2} />
                <StaticMap
                  markers={mapContext.markers}
                  points={mapContext.points}
                  googleMapsUrl={mapContext.googleMapsUrl}
                />
                <Spacer h={4} />
                <TransportJobDetails
                  price={tjData.total_payout}
                  isBundled={tjData.bundled}
                  isComboJob={isComboJob(tjData)}
                  isBusiness={tjData.business}
                  rewardTime={formatSecondsToHoursMinutes(tjData.rewarded_working_time)}
                  maxVolume={tjData.max_volume_m3_inflated}
                  distance={(tjData.route as RouteMeta)?.distance}
                  itemSets={tjData.item_sets}
                  all_stop_details={{
                    carrying_help: tjalDetails.map((f) => f?.carrying_help),
                    situation: tjalDetails.map((s) => s.situation),
                  }}
                  presentation="search"
                />
                {/* Create TWO faux cards with text placeholders to entice drivers */}
                {pickup && (
                  <div className={cn("relative", "px-2")}>
                    <Card className={cn("mt-4")}>
                      <div className={cn("flex", "items-center", "flex-wrap")}>
                        <H4 className={cn("mr-1")}>{t((d) => d.transport_job.stop)} 1</H4>
                        <H4 className={cn("truncate")}>
                          {/* Include the locality info from the first pickup only */}
                          {formatLocality(
                            (pickup.address as Address).locality,
                            (pickup.address as Address).administrative_area
                          )}
                        </H4>
                      </div>
                      <TextPlaceholder className={cn("w-48")} />
                      <div>
                        {/* Show the stop details for the first pickup only */}
                        <StopDetailPills details={pickup.details} itemSets={tjData.item_sets} />
                      </div>
                    </Card>
                  </div>
                )}
                <div className={cn("relative", "px-2")}>
                  <Card className={cn("my-4")}>
                    <div className={cn("flex", "items-center")}>
                      <H4 className={cn("mr-1")}>{t((d) => d.transport_job.stop)} 2</H4>
                      <TextPlaceholder />
                    </div>
                    <TextPlaceholder className={cn("w-48")} />
                    <div>
                      <TextPlaceholder />
                    </div>
                  </Card>
                  {/* Add a white linear gradient to the last card in the list in order to create the "teaser" effect */}
                  <div
                    className={cn("absolute", "bottom-0", "h-64", "w-full")}
                    style={{ backgroundImage: "linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))" }}
                  />
                </div>
              </>
            ) : null}
          </Page>
        </div>
      </Modal>
    </>
  );
};
