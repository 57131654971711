import * as React from "react";
import { DayRouteActivity } from "@brenger/api-client";
import { Button } from "@brenger/react";
import { isAfter } from "date-fns";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import cn from "classnames";

import { Breadcrumb, BreadcrumbNav, Page } from "../../../components";
import { useDayRouteStopParams, useTranslation } from "../../../hooks";
import { coreClient } from "../../../utils";

interface Props {
  breadcrumbs: Breadcrumb[];
  activity: DayRouteActivity;
}

export const DayRouteConfirmCustomStop: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const params = useDayRouteStopParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onSuccess = (): void => {
    queryClient.clear();
    history.push(`/day-route/${params.date}/${params.user_id}/activity/${params.stop_id}`);
  };

  const confirmStop = useMutation(coreClient.dayRoutesActivities.confirmCustomStop, { onSuccess });
  const canConfirm = isAfter(new Date(), new Date(params.date));
  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={props.breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          disabled={!canConfirm || confirmStop.isLoading}
          loading={confirmStop.isLoading}
          onClick={() => confirmStop.mutate({ id: params.stop_id })}
        >
          {t((d) => d.planning.labels.confirm_stop)}
        </Button>
      }
    >
      <div>{t((d) => d.day_route.own_stop.confirm_body)}</div>
    </Page>
  );
};
