import { Button, Card, IconAdd, Pill, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Grid, Page, TabNav } from "../../../components";
import { LicensePlate } from "../../../components/data/LicensePlate";
import { useTabNavigation, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { VehicleSummary } from "../UserVehiclesDetails/VehicleSummary";

export const UserVehicles: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const tabs = useTabNavigation("profile");
  const vehicles = useQuery([CacheKey.RETRIEVE_VEHICLE_LIST], () => coreClient.vehicles.list());
  const hasVehicles = (vehicles.data?.["hydra:totalItems"] || 0) > 0;

  return (
    <Page
      loading={vehicles.isLoading}
      nav={<TabNav tabs={tabs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          icon={<IconAdd />}
          onClick={() => push(Routes.USER_VEHICLES_CREATE)}
        >
          {t((d) => d.profile.add_new_vehicle)}
        </Button>
      }
      infoText={hasVehicles ? null : t((d) => d.vehicles.list_empty_state)}
    >
      {hasVehicles && (
        <h3>
          {t((d) => d.vehicles.list_title)} ({vehicles.data?.["hydra:totalItems"]})
        </h3>
      )}
      <Spacer h={4} />
      <Grid gap={4} cols={{ lg: 2 }}>
        {vehicles.data?.["hydra:member"].map((vehicle) => {
          return (
            <Link key={vehicle["@id"]} className={cn("block")} to={`/profile${vehicle["@id"]}`}>
              <Card type="gray">
                <h5 className="capitalize">
                  {vehicle.make} {vehicle.model}
                </h5>
                <Spacer h={2} />
                <VehicleSummary vehicle={vehicle} />
                <div className={cn("p-2")}>
                  <LicensePlate vehicle={vehicle} />
                  <div className={cn("flex", "mt-2")}>
                    {vehicle.has_tailgate && (
                      <Pill type="gray-outline" className={cn("mr-1")}>
                        {t((d) => d.vehicles.tailgate)}
                      </Pill>
                    )}
                    {vehicle.has_pallet_jack && <Pill type="gray-outline">{t((d) => d.vehicles.pallet_jack)}</Pill>}
                  </div>
                </div>
              </Card>
            </Link>
          );
        })}
      </Grid>
    </Page>
  );
};
