import {
  IconLoader,
  IconAccount,
  IconAssignment,
  IconChat,
  IconDashboard,
  IconInfo,
  IconMail,
  IconSettings,
  IconSignOut,
  IconTag,
  IconVerifiedAlt,
  IconClose,
  IconMultipleStop,
} from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Dialog } from "..";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { useTranslation } from "../../hooks";
import { useFreshChat } from "../../hooks/useFreshchat";
import { Routes } from "../../utils";

import { ExternalLink } from "./ExternalLink";

const menuLinkCns = cn("flex", "items-center", "py-2", "px-4", "br-font-bold", "mb-2", "capitalize");

const MenuLink: React.FC<NavLinkProps> = ({ className, children, ...props }) => {
  return (
    <div>
      <NavLink
        activeClassName={cn("bg-gray-200")}
        className={cn("text-gray-800", "border-gray-400", menuLinkCns, className)}
        {...props}
      >
        {children}
      </NavLink>
    </div>
  );
};

interface Props {
  isActive: boolean;
  closeHandler(): void;
}

export const AppMenu: React.FC<Props> = ({ isActive, closeHandler }) => {
  const { t, i18n } = useTranslation();
  const chat = useFreshChat();

  return (
    <Dialog isActive={isActive}>
      {/* make these height - width screen so that safe areas do not show around opaque black background */}
      <div className={cn("w-screen", "h-screen")} style={{ background: "rgba(0, 0, 0, 0.5)" }} onClick={closeHandler}>
        <div
          onClick={(evt) => {
            // Prevent any click events from INSIDE the app menu from propogating to the parent's click away closeHandler.
            evt.stopPropagation();
          }}
          className={cn("absolute", "h-screen", "bg-white", "shadow-xl", "right-0")}
          style={{
            width: "200px",
          }}
        >
          <div className={cn("flex", "flex-col", "h-full", "items-end")}>
            <div className={cn("py-4", "px-3", "w-1/3", "flex", "justify-end", "items-center")}>
              <button onClick={closeHandler}>
                <IconClose width="14" height="12" className={cn("text-blue-600", "fill-current")} />
              </button>
            </div>
            <div className={cn("flex", "flex-col", "flex-grow", "h-full", "w-full", "overflow-auto")}>
              {Config.FRESH_CHAT_TOKEN && (
                <div
                  className={cn("text-white", "bg-blue-600", "flex", menuLinkCns)}
                  onClick={chat.isPresent ? chat?.hide : chat?.open}
                >
                  {chat.isLoading && <IconLoader className={cn("h-6", "w-6", "mr-3")} />}
                  {!chat.isLoading && <IconChat className={cn("h-6", "w-6", "mr-3")} />}
                  {t((d) => (chat.isPresent ? d.actions.hide_chat : d.actions.start_chat))}
                </div>
              )}
              <MenuLink to={Routes.DAY_ROUTE} onClick={closeHandler}>
                <IconMultipleStop className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.day_route)}
              </MenuLink>
              <MenuLink
                onClick={closeHandler}
                to={Routes.PLANNING_JOB_LIST}
                isActive={(...args) => {
                  // The dashboard tab encompasses THREE root level pathnames.
                  // Therefore, it is an active tab so long as any one of those three are active.
                  const location = args[1];
                  return (
                    location.pathname.includes(Routes.SEARCH_JOB_LIST) ||
                    location.pathname.includes(Routes.PLANNING_JOB_LIST) ||
                    location.pathname.includes(Routes.DELIVERED_JOB_LIST)
                  );
                }}
              >
                <IconAssignment className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.jobs)}
              </MenuLink>
              <MenuLink to={Routes.INBOX_LIST} onClick={closeHandler}>
                <IconMail className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.inbox)}
              </MenuLink>

              <MenuLink to={Routes.DASHBOARD_FOR_ACCOUNT} onClick={closeHandler}>
                <IconDashboard
                  className={cn("mr-3")}
                  style={{ height: "1.3rem", width: "1.3rem", marginLeft: "0.1rem" }}
                />
                {t((d) => d.app.menu.dashboard)}
              </MenuLink>
              <MenuLink to={Routes.USER_DETAILS} onClick={closeHandler}>
                <IconAccount className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.profile)}
              </MenuLink>
              <MenuLink to={Routes.USER_REVIEWS} onClick={closeHandler}>
                <IconVerifiedAlt className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.reviews)}
              </MenuLink>
              <MenuLink to={Routes.USER_ID} onClick={closeHandler}>
                <IconTag className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.user_id)}
              </MenuLink>
              <MenuLink to={Routes.USER_SETTINGS} onClick={closeHandler}>
                <IconSettings className={cn("h-6", "mr-3")} />
                {t((d) => d.app.menu.settings)}
              </MenuLink>
              <ExternalLink url={Config.DRIVER_SUPPORT_URL.replace(LOCALE_TEMPLATE, i18n.locale)}>
                <div className={cn(menuLinkCns)}>
                  <IconInfo className={cn("h-6", "w-6", "mr-3")} />
                  {t((d) => d.app.menu.support)}
                </div>
              </ExternalLink>
              <MenuLink to={Routes.LOGOUT} onClick={closeHandler}>
                <IconSignOut
                  className={cn("mr-3")}
                  style={{ height: "1.3rem", width: "1.3rem", marginLeft: "0.1rem" }}
                />
                {t((d) => d.app.menu.logout)}
              </MenuLink>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
