import { Button, Message, Spacer, IconCheck } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { Modal } from "../../../components";
import { usePersistedState, useTranslation } from "../../../hooks";
import { PersistedStorageKey } from "../../../utils";
interface Props {
  isActive: boolean;
  open(): void;
  close(): void;
}

export const SearchJobListVatNotification: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [hasSeen, setHasSeen] = usePersistedState({
    key: PersistedStorageKey.HAS_SEEN_VAT_NOTIFICATION,
    initialState: true,
    defaultState: false,
    version: 2,
  });

  React.useEffect(() => {
    if (!hasSeen) props.open();
  }, [hasSeen]);
  return (
    <Modal isActive={props.isActive} closeHandler={props.close}>
      <h3 className={cn("normal-case")}>{t((d) => d.vat_excluded_notification_title)}</h3>
      <Spacer h={6} />
      <Message type="info">
        <div
          className={cn("message-link-underline")}
          dangerouslySetInnerHTML={{
            // Ignoring __html naming
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __html: t((d) => d.vat_excluded_notification_desc_2),
          }}
        />
      </Message>

      <Spacer h={6} />
      <Button
        buttonType="secondary"
        icon={<IconCheck />}
        className={cn("w-full")}
        onClick={() => {
          // Only set hasSeen to true when clicking the I CONSENT button.
          // Should not set hasSeen to true when the user clicks away from modal.
          setHasSeen(true);
          props.close();
        }}
      >
        {t((d) => d.day_route.explanation.continue_to_route)}
      </Button>
    </Modal>
  );
};
