import * as React from "react";
import cn from "classnames";

import { IconCancel } from "@brenger/react";
import { Dialog } from "..";

interface Props {
  autoScroll?: boolean;
  isActive: boolean;
  closeByBackground?: boolean;
  canCancel?: boolean;
  closeHandler(): void;
}

export const Modal: React.FC<Props> = ({ closeByBackground = true, canCancel = true, ...props }) => {
  return (
    <Dialog isActive={props.isActive}>
      <div
        className={cn("relative", "w-screen", "h-screen")}
        style={{
          // This rgba value matches that of the PWA modal's own background opacity.
          // This ensures smooth/consistent UX when go between PWA elements and our own modals.
          backgroundColor: "rgba(0,0,0,0.32)",
        }}
      />
      <div
        className={cn("absolute", "inset-0", "overflow-y-auto")}
        onClick={closeByBackground ? props.closeHandler : undefined}
      >
        <div className={cn("relative", "w-full", "h-full")}>
          <div
            /**
             * Stop propagating the click event from inside the modal's content.
             * If we don't do this, then any click inside the modal will also cause it to close.
             * Instead, we listen for a click event on the inset-0 parent (the area outside the modal)
             */
            onClick={(evt) => evt.stopPropagation()}
            style={{ left: "50%", transform: "translateX(-50%)" }}
            className={cn(
              "absolute",
              { "overflow-y-scroll": Boolean(!props.autoScroll) },
              { "overflow-y-auto": Boolean(props.autoScroll) },
              "px-4",
              "pt-6",
              "pb-4",
              "bg-white",
              "shadow-2xl",
              "max-w-2xl",
              "rounded",
              "w-full",
              "top-0",
              "h-full",
              "min-h-screen",
              // These are the responsive views for anything larger than a "sm" viewport.
              "sm:my-12",
              "sm:h-auto",
              "sm:min-h-0",
              "sm:w-10/12"
            )}
          >
            {canCancel && (
              <div className={cn("flex", "absolute", "right-0", "top-0", "z-10")}>
                <button className={cn("mr-3", "mt-2")} onClick={props.closeHandler}>
                  <IconCancel className={cn("w-6", "h-6")} />
                </button>
              </div>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
