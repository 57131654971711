import { DayRouteActivity, DayRouteActivityType } from "@brenger/api-client";
import {
  Button,
  Card,
  IconAdd,
  IconInfoCircle,
  IconLoader,
  IconSearch,
  Message,
  Small,
  useModalState,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import transpose from "flatmap-fns/transpose";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { BreadcrumbNav, DriverSelect, Page, TextPlaceholder } from "../../../components";
import { StaticMapToggle } from "../../../components/data/StaticMapToggle";
import { useAdminDrivers, useAuth, useDayRouteParams, useMapContext, useTranslation } from "../../../hooks";

import { CacheKey, coreClient, Routes } from "../../../utils";
import { addIndex, formatActivities, getTravelMeta, validateActivity } from "../utils";
import { DayRouteDateSelection } from "./DayRouteDateSelection";
import { DayRouteEndLocation } from "./DayRouteEndLocation";
import { DayRouteExplainModal } from "./DayRouteExplainModal";
import { DayRouteFooter } from "./DayRouteFooter";
import { DayRouteStartLocation } from "./DayRouteStartLocation";
import { DayRouteStartTitle } from "./DayRouteStartTitle";
import { DayRouteStop } from "./DayRouteStop";
import { DayRouteStopBreak } from "./DayRouteStopBreak";
import { DayRouteStopTravelTime } from "./DayRouteStopTravelTime";

const DELETABLE_ACTIVITY_TYPES = ["custom_other", "custom_pickup", "custom_delivery"] as DayRouteActivityType[];

export const DayRouteDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useDayRouteParams();
  const history = useHistory();
  const auth = useAuth();
  // const mapModal = useModalState();

  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const dayRouteData = dayRoute.data;
  const dayRouteId = dayRouteData?.["@id"];
  const dayRouteActivities = dayRouteData?.day_route_activities || [];
  const drRouteETA = useQuery(
    [CacheKey.RETRIEVE_DAY_ROUTE_LEGS],
    () => coreClient.dayRoutes.retrieveLegs({ routeId: getIdFromIri(dayRouteId) || "" }),
    {
      enabled: dayRouteActivities.length > 0,
    }
  );

  const startedDriving = dayRouteData?.started_driving_at !== null;
  const mapContext = useMapContext({
    presentation: "planning",
    dayRoute: dayRouteData,
    drRoute: drRouteETA.data?.route,
  });

  const explainerModal = useModalState();
  const [activities, setActivities] = useState<DayRouteActivity[]>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [driverUser, setDriverUser] = useState<string | undefined>();
  const isDriverAdmin = useAdminDrivers({ accountId: getIdFromIri(auth.user?.account) });
  // On user change navigate to dayroute
  useEffect(() => {
    if (driverUser) {
      history.push(`/day-route/${params.date}/${driverUser}`);
    }
  }, [driverUser]);

  // When day route activities are loaded, add them to local state so we can re-order them later if need be.
  useEffect(() => {
    // NOTE: listen to dayRoute.loading in case we refresh the day route and therefore need to reset
    // the day route activities in local state based on the updated day route.
    if (!dayRoute.isLoading && dayRouteActivities) {
      setActivities(dayRouteActivities);
    }
    // Add the dayRouteId as a dependency ref - therefore, when day route changes, we update/set the activites in local state.
  }, [dayRouteId, dayRoute.isLoading]);

  const reorder = (currentIndex: number, targetIndex: number): void => {
    const updatedActivities = activities
      .flatMap(transpose(currentIndex, targetIndex))
      .flatMap(formatActivities())
      .map(addIndex());
    setActivities(updatedActivities as DayRouteActivity[]);
    setHasChanged(true);
  };

  const breadcrumbs = [{ to: "/planning", text: "planning" }, { text: t((d) => d.day_route.details.title) }];

  if (dayRoute.error) {
    return (
      <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}>
        <Message type="error">{(dayRoute.error as Error)?.message}</Message>
      </Page>
    );
  }

  const goToDayRouteAdd = (): void => {
    history.push(`/day-route/${params.date}/${params.user_id}/add`);
  };
  const hasActivities = activities.length > 0;

  const legs = drRouteETA.data?.legs || [];
  const legsLength = legs.length;
  const endLeg = dayRoute.data?.end_address && legs[legsLength - 1];
  const endAddressTravelMeta = getTravelMeta({
    eta: endLeg?.eta || null,
    etd: endLeg?.etd || null,
    driving_duration_seconds: endLeg?.duration || null,
    driving_distance_meters: endLeg?.distance || null,
  });

  return (
    <>
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        stickyFooter={
          <DayRouteFooter
            hasChanged={hasChanged}
            setHasChanged={setHasChanged}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            activities={activities}
            dayRoute={dayRoute}
          />
        }
      >
        <div className={cn("mx-[-20px]", "md:mx-0", "mt-[-1rem]")}>
          <StaticMapToggle {...mapContext} />
        </div>
        {/* Negative margins so the content has a white background covers the the sides */}
        <div className={cn("bg-white", "mx-[-20px]", "z-10", "border-transparent")}>
          <div className={cn("mx-[20px]")}>
            <div
              className={cn(
                "-mx-2",
                "md:mx-0",
                "grid",
                "grid-flow-col-dense",
                !isDriverAdmin ? "grid-cols-auto" : "grid-cols-2",
                "gap-2",
                "h-10",
                // Show selectors a little on top of the map
                "-mt-[1rem]"
              )}
            >
              <div className={cn("col-span-1")}>
                <DayRouteDateSelection showBorder={false} />
              </div>
              <DriverSelect
                value={`/users/${params.user_id}`}
                accountId={getIdFromIri(auth.user?.account)}
                showBorder={false}
                onChange={({ userIRI }) => {
                  setDriverUser(getIdFromIri(userIRI) || "");
                }}
              />
            </div>

            <div className={cn("flex", "items-center", "gap-4")}>
              <div className={cn("grow", "my-6")}>
                {/* NOTE: Do not let start button CTAs linger while switching between day routes */}
                {dayRoute.data ? <DayRouteStartTitle dayRoute={dayRoute} /> : null}
              </div>
              {/* Only show the map icon if there are actual markers to display */}
              <IconInfoCircle
                height={"24px"}
                width={"24px"}
                onClick={explainerModal.open}
                className={cn("cursor-pointer")}
              />
            </div>

            {/* // NOTE: only show loader when there are no activities in local state yet - otherwise, all subsequent refreshes to
            // the already-loaded day route will show loader and led to janky-feeling UX. */}
            {!dayRoute.data && dayRoute.isLoading ? (
              <>
                {/* NOTE: add a faux card with some placeholder text while day routes load for a more graceful UX */}
                <Card type="gray">
                  <div className={cn("flex", "flex-col")}>
                    <TextPlaceholder />
                    <TextPlaceholder />
                  </div>
                </Card>
                <div className={cn("mt-8", "w-full")}>{<IconLoader className={cn("w-6", "h-6", "mx-auto")} />}</div>
              </>
            ) : (
              <>
                <DayRouteStartLocation dayRoute={dayRoute} />
                {hasActivities &&
                  activities.map((activity, index) => {
                    const canDelete = DELETABLE_ACTIVITY_TYPES.includes(activity.type);
                    const moveUp = index === 0 ? undefined : () => reorder(index, index - 1);
                    const moveDown = activities.length - 1 === index ? undefined : () => reorder(index, index + 1);

                    if (activity.type === "break") {
                      return (
                        <DayRouteStopBreak
                          key={index}
                          isEditing={isEditing}
                          moveUp={moveUp}
                          moveDown={moveDown}
                          breakSeconds={activity.service_time_seconds || 0}
                        />
                      );
                    }

                    const meta = legs.find((leg) => leg.index === activity.index);
                    const travelMeta = getTravelMeta({
                      eta: meta?.eta || null,
                      etd: meta?.etd || null,
                      driving_duration_seconds: meta?.duration || null,
                      driving_distance_meters: meta?.distance || null,
                    });
                    const validation = validateActivity({ ...activity, eta: meta?.eta || null }, activities);

                    // NOTE: this component is the same in all cases, so create once and interpolate accordingly.
                    const dayRouteStopTravelTime = (
                      <DayRouteStopTravelTime
                        hours={travelMeta.travelTime?.hours}
                        minutes={travelMeta.travelTime?.minutes}
                        distance={travelMeta.travelDistanceInKm || null}
                        isEditing={isEditing}
                      />
                    );

                    if (canDelete) {
                      return (
                        <div key={index}>
                          {dayRouteStopTravelTime}
                          <DayRouteStop
                            startedDriving={startedDriving}
                            key={index}
                            activity={activity}
                            isEditing={isEditing}
                            moveUp={moveUp}
                            moveDown={moveDown}
                            travelMeta={travelMeta}
                            validationError={validation}
                            customStop={true}
                          />
                        </div>
                      );
                    }

                    return (
                      <div key={index}>
                        {dayRouteStopTravelTime}
                        <DayRouteStop
                          startedDriving={startedDriving}
                          key={index}
                          activity={activity}
                          isEditing={isEditing}
                          moveUp={moveUp}
                          moveDown={moveDown}
                          travelMeta={travelMeta}
                          validationError={validation}
                        />
                      </div>
                    );
                  })}
                <DayRouteStopTravelTime
                  hours={endAddressTravelMeta.travelTime?.hours}
                  minutes={endAddressTravelMeta.travelTime?.minutes}
                  distance={endAddressTravelMeta.travelDistanceInKm}
                  isEditing={isEditing}
                />
                <DayRouteEndLocation dayRoute={dayRoute} />
                {!hasActivities && (
                  // NOTE: show empty state with helpful CTAs when there are no activities yet for the given day route.
                  <Card type="primary" className={cn("mt-4")}>
                    <div>{t((d) => d.day_route.empty_state)}</div>
                    <div className={cn("grid", "grid-cols-2", "gap-2", "mt-3")}>
                      <Button
                        size="sm"
                        buttonType="primary-outline"
                        onClick={goToDayRouteAdd}
                        icon={<IconAdd className={cn("ml-3")} />}
                      >
                        {/* {t((d) => d.day_route.details.own_stop)} */}
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => history.push(Routes.SEARCH_JOB_LIST)}
                        icon={
                          <Small>
                            <IconSearch className={cn("ml-3")} />
                          </Small>
                        }
                      >
                        {/* @TRANS needed */}
                        Brenger stop
                      </Button>
                    </div>
                  </Card>
                )}
              </>
            )}
          </div>
        </div>
      </Page>
      <DayRouteExplainModal
        isActive={explainerModal.isActive}
        open={explainerModal.open}
        close={explainerModal.close}
      />
    </>
  );
};
