import OneSignalNative, { OneSignalPlugin } from "onesignal-cordova-plugin";
import * as React from "react";
import OneSignalWeb from "react-onesignal";
import { AppMessagesKey } from "../AppMessages/AppMessages";
import { Config } from "../config";
import { PersistedStorageKey } from "../utils";
import { useAuth } from "./useAuth";
import { useDeviceInfo } from "./useDeviceInfo";
import { usePersistedState } from "./usePersistedState";

interface UsePushNotifications {
  /**
   * App messages related to notifications.
   */
  appMessages: Set<AppMessagesKey>;
}

type IOneSignalWeb = typeof OneSignalWeb;

export const usePushNotifications = (): UsePushNotifications => {
  const platform = useDeviceInfo()?.platform;
  const auth = useAuth();
  const [hasSeenPushMessage, setHasSeenPushMessage] = usePersistedState<boolean>({
    initialState: false,
    defaultState: false,
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_WEB_PROMPT_NOTIFICATION,
  });

  const appMessages = new Set<AppMessagesKey>();

  const initialize = async (): Promise<void> => {
    // Bail early when no app id is set
    if (!Config.ONE_SIGNAL_FCM_ID) {
      return;
    }
    /**
     * INIT WEB PUSH
     * NOTE: Safari is not able to handle payloads, so decided not to implement a listner yet, up for discussion!
     */
    if (platform === "web") {
      await OneSignalWeb.init({
        appId: Config.ONE_SIGNAL_FCM_ID,
      });
      if (auth.userId) {
        OneSignalWeb.setExternalUserId(auth.userId);
      }
      const isEnabled = await OneSignalWeb.isPushNotificationsEnabled();
      if (!isEnabled && !hasSeenPushMessage) {
        appMessages.add("web-push-prompt");
        setHasSeenPushMessage(true);
        return;
      }
    }
    /**
     * INIT NATIVE PUSH
     */
    if (platform === "android" || platform === "ios") {
      OneSignalNative.setAppId(Config.ONE_SIGNAL_FCM_ID);
      if (auth.userId) {
        OneSignalNative.setExternalUserId(auth.userId);
      }
    }
  };

  React.useEffect(() => {
    if (!auth.isLoggedIn || !platform) return;
    initialize();
  }, [auth.isLoggedIn, platform]);

  React.useEffect(() => {
    // Setting onesignal to the correct version
    let onesignal: null | IOneSignalWeb | OneSignalPlugin = null;
    if (platform === "android" || platform === "ios") onesignal = OneSignalNative;
    if (platform === "web") onesignal = OneSignalWeb;
    if (!onesignal) {
      return;
    }
    // setting user id and fallback communication details
    if (auth.isLoggedIn && auth.userId) {
      onesignal.setExternalUserId(auth.userId);
      return;
    }
    // removing user id and communication details
    if (!auth.isLoggedIn) {
      onesignal.removeExternalUserId();
    }
  }, [auth, platform]);

  return {
    appMessages,
  };
};
