import { Button, IconLocation } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import locationExample from "../../../assets/images/location_example.png";
import { Modal } from "../../../components";
import { UseGeolocation, useTranslation } from "../../../hooks";

interface Props {
  isActive: boolean;
  closeHandler(): void;
  geolocation: UseGeolocation;
}

export const EnableLocationModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <div className={cn("grid", "gap-4", "text-center")}>
        <IconLocation className={cn("text-blue-600", "w-12", "h-12", "mx-auto")} />
        <h3>{t((d) => d.settings.enable_geolocation)}</h3>
        <div>{t((d) => d.settings.geolocation_desc)}</div>
        <img className={cn("rounded", "mx-auto", "my-4")} style={{ maxWidth: "80%" }} src={locationExample} />
        <Button
          className={cn("w-fill")}
          buttonType="secondary"
          onClick={async () => {
            await props.geolocation.start();
            props.closeHandler();
          }}
        >
          {t((d) => d.actions.enable)}
        </Button>
        <Button className={cn("w-full")} onClick={props.closeHandler} buttonType="primary-outline">
          {t((d) => d.actions.go_back)}
        </Button>
      </div>
    </Modal>
  );
};
