import { CoreClient, parseErrorMessageFromCore, RoutePlannerClient, UserDataClient } from "@brenger/api-client";
import axios from "axios";
import { Config } from "../config";
import { logger } from "./logger";

// Use these cache keys to set network responses in an in-memory cache
// See useQuery for implementation details.
export enum CacheKey {
  LIST_MESSAGES_USER_AND_TJAL = "LIST_MESSAGES_USER_AND_TJAL",
  LIST_TRANSPORT_JOBS = "LIST_TRANSPORT_JOBS",
  LIST_TRANSPORT_JOBS_SCORES = "LIST_TRANSPORT_JOBS_SCORES",
  LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS = "LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS",
  LIST_DELIVERED_TRANSPORT_JOB_ACCOUNT_LINKS = "LIST_DELIVERED_TRANSPORT_JOB_ACCOUNT_LINKS",
  RETRIEVE_TRANSPORT_JOB = "RETRIEVE_TRANSPORT_JOB",
  RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK = "RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK",
  RETRIEVE_LOGGED_IN_STATUS = "RETRIEVE_LOGGED_IN_STATUS",
  RETRIEVE_CURRENT_USER = "RETRIEVE_CURRENT_USER",
  RETRIEVE_USER = "RETRIEVE_USER",
  RETRIEVE_STOP = "RETRIEVE_STOP",
  RETRIEVE_ROUTE = "RETRIEVE_ROUTE",
  RETRIEVE_DAY_ROUTE = "RETRIEVE_DAY_ROUTE",
  RETRIEVE_DAY_ROUTE_LEGS = "RETRIEVE_DAY_ROUTE_LEGS",
  RETRIEVE_PROPOSED_DAY_ROUTE = "RETRIEVE_PROPOSED_DAY_ROUTE",
  RETRIEVE_PREFERRED_ROUTES = "RETRIEVE_PREFERRED_ROUTES",
  RETRIEVE_CONTACT = "RETRIEVE_CONTACT",
  RETRIEVE_PRODUCT_PAYMENT = "RETRIEVE_PRODUCT_PAYMENT",
  TRANSLATIONS = "TRANSLATIONS",
  INBOX_LIST = "INBOX_LIST",
  INBOX_DETAILS = "INBOX_DETAILS",
  ACCOUNT_USERS = "ACCOUNT_USERS",
  COUNTRY_CODE = "COUNTRY_CODE",
  APP_VERSION = "APP_VERSION",
  RETRIEVE_VEHICLE_LIST = "RETRIEVE_VEHICLE_LIST",
  RETRIEVE_SUMMARY = "RETRIEVE_SUMMARY",
  RETRIEVE_ACCOUNT = "RETRIEVE_ACCOUNT",
  RETRIEVE_USERS = "RETRIEVE_USERS",
  RETRIEVE_ADDRESS = "RETRIEVE_ADDRESS",
  RETRIEVE_DASHBOARD = "RETRIEVE_DASHBOARD",
  RETRIEVE_SIGNATURE = "RETRIEVE_SIGNATURE",
  RETRIEVE_PHOTO_PROOF = "RETRIEVE_PHOTO_PROOF",
  RETRIEVE_GEO_AUTOCOMPLETE = "RETRIEVE_GEO_AUTOCOMPLETE",
  RETRIEVE_GEO_LOCATION_DETAILS = "RETRIEVE_GEO_LOCATION_DETAILS",
  RETRIEVE_GEO_STOP_PROXIMITY = "RETRIEVE_GEO_STOP_PROXIMITY",
  RETRIEVE_CONFIRMATION_RULES = "RETRIEVE_CONFIRMATION_RULES",
  RETRIEVE_BANK_ACCOUNT = "RETRIEVE_BANK_ACCOUNT",
  RETRIEVE_REVIEWS = "RETRIEVE_REVIEWS",
}

export enum PersistedStorageKey {
  HAS_SEEN_VAT_NOTIFICATION = "HAS_SEEN_VAT_NOTIFICATION",
  HAS_SEEN_DAY_ROUTE_NOTIFICATION = "HAS_SEEN_DAY_ROUTE_NOTIFICATION",
  HAS_SEEN_WEB_PROMPT_NOTIFICATION = "HAS_SEEN_WEB_PROMPT_NOTIFICATION",
  NEXT_NPS_DATE = "NEXT_NPS_DATE",
  HAS_SEEN_DAY_DRESSCODE = "HAS_SEEN_DAY_DRESSCODE",
  HAS_SEEN_UPDATE_APP_VERSION = "HAS_SEEN_UPDATE_APP_VERSION",
}

// Some standard TTL to use throughout app
export enum CacheTTL {
  /**
   * 1 minute.
   */
  XS = 60,
  /**
   * 2 minutes.
   */
  SM = 120,
  /**
   * 5 minutes.
   */
  MD = 300,
  /**
   * 10 minutes.
   */
  LG = 600,
  /**
   * 60 minutes.
   */
  XL = 3600,
}

interface InRangeParams {
  val?: number;
  min: number;
  max: number;
  ignore?: number[];
}

export const isValueInRange = ({ val, min, max, ignore }: InRangeParams): boolean => {
  return typeof val === "number" && val >= min && val <= max && !ignore?.includes(val);
};

/**
 * NOTE: This is not in React-land, so we cannot utilize context-aware translations easily. Therefore,
 * default to Dutch erro essage. English version below for the future.
 * "It seems this is taking too long to process. That could either be a problem with your connection or our systems. Please try again shortly."
 */
const timeoutErrorMessage =
  "Het lijkt erop dat het te lang duurt om dit te verwerken. Dat kan aan een probleem met je verbinding liggen of onze systemen. Probeer het zo opnieuw.";

const coreAxiosInstance = axios.create({
  baseURL: Config.API_URL,
  withCredentials: true,
  // A 30s global timeout for network requests to core.
  // By default, axios does not have a timeout. This was added to avoid loading photo proofs endlessly.
  timeout: 30000,
  timeoutErrorMessage,
  headers: {
    Accept: "application/ld+json",
    "Content-Type": "application/ld+json",
  },
});

coreAxiosInstance.interceptors.request.use(undefined, (error) => {
  const message = error?.message || "unknown axios request error";
  const details = { ...error.request };
  logger.error(message, details);

  return Promise.reject(error);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRejectedFromCore = (error: any): Promise<Error> => {
  const details = { ...error?.response, ...error?.request };
  const statusCode = error?.response?.status || 0;

  // Parse the error message from core.
  const errorMessage = parseErrorMessageFromCore(error);

  const shouldLogError = isValueInRange({ val: statusCode, min: 400, max: 499, ignore: [403, 404] });

  // Check if we should if ignore the error message
  const isIgnoredMessaged = (message: string): boolean =>
    ["Transport job already has an accepted offer.", "Max number of offers reached."].some((ignore: string) =>
      message.includes(ignore)
    );
  // If this error is associated with a status code we care about and shouldn't be ignored
  // log the message + details to sentry.
  if (shouldLogError && !isIgnoredMessaged(errorMessage)) logger.error(errorMessage, details);
  // Put the parsed error message into a new error object so easily access it downstream on the "message" key.
  const newError = new Error(errorMessage);

  return Promise.reject(newError);
};

coreAxiosInstance.interceptors.response.use(undefined, onRejectedFromCore);

export const coreClient = new CoreClient(coreAxiosInstance);

const routePlannercoreAxiosInstance = axios.create({
  baseURL: Config.ROUTE_PLANNER_URL,
});

export const routePlannerClient = new RoutePlannerClient(routePlannercoreAxiosInstance);

export const userDataClient = new UserDataClient(Config.USER_DATA_URL || "");
