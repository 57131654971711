import { Button, IconInvoice, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";

import {
  BreadcrumbNav,
  BundledJobSummary,
  ExternalLink,
  ImageGallery,
  Page,
  PillTjalState,
  RegularJobSummary,
  StaticMap,
} from "../../../components";
import { useDeliveredParams, useMapContext, useTranslation } from "../../../hooks";
import { CacheKey, Routes, coreClient } from "../../../utils";

export const DeliveredJobDetails: React.FC = () => {
  const { t } = useTranslation();
  // Get the params from the url
  const params = useDeliveredParams();
  const breadcrumbs = [{ text: t((d) => d.app.tabs.delivered), to: Routes.DELIVERED_JOB_LIST }, { text: "--" }];

  const tjal = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, params.tjal_id], () =>
    coreClient.transportJobAccountLinks.retrieve({ id: params.tjal_id })
  );

  const tjId = getIdFromIri(tjal.data?.transport_job) || "";
  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, tjId], () => coreClient.transportJobs.retrieve({ id: tjId }), {
    enabled: !!tjId,
  });

  const invoiceUrl = tjal.data?.payout_invoice_url;

  // Add the TJ short id to the breadcrumbs when it becomes available.
  if (tj.data) breadcrumbs[1].text = `#${tj.data.short_id}`;

  const mapContext = useMapContext({
    presentation: "delivered",
    tj: tj.data,
  });

  // set up image data
  const itemSets = tj.data?.item_sets || [];

  if (!tj.data || tj.error) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tj.isLoading}
        errorText={(tj.error as Error)?.message}
      />
    );
  }

  return (
    <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}>
      <div className={cn("flex", "flex-wrap", "items-center", "justify-between")}>
        <h3 className={cn("mr-2", "capitalize", "truncate")}>{tj.data.title}</h3>
        {tjal.data && <PillTjalState state={tjal.data.state} />}
      </div>
      <Spacer h={3} />
      <StaticMap markers={mapContext.markers} points={mapContext.points} />
      <Spacer h={4} />
      {tj.data.bundled ? (
        <BundledJobSummary tj={tj.data} tjal={tjal.data} presentation="delivered" />
      ) : (
        <RegularJobSummary tj={tj.data} tjal={tjal.data} presentation="delivered" />
      )}
      <Spacer h={3} />
      <ImageGallery itemSets={itemSets} />
      {invoiceUrl && (
        <ExternalLink url={invoiceUrl} className={cn("mt-3")}>
          <Button buttonType="primary-outline" icon={<IconInvoice className={cn("h-4")} />}>
            {t((d) => d.transport_job.actions.download_invoice)}
          </Button>
        </ExternalLink>
      )}
    </Page>
  );
};
