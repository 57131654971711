import {
  Button,
  HorizontalLine,
  IconDelete,
  InputCheckbox,
  Label,
  Message,
  Spacer,
  Textarea,
  useModalState,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import * as React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { useAuth, useForm, useTranslation } from "../../../hooks";
import { IonicRoutes, Routes, coreClient } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";
import { ForgetRequestSuccessModal } from "./ForgetRequestSuccessModal";

export const MoreForgetAccount: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const successModal = useModalState();
  const history = useHistory();

  const form = useForm({
    initialState: { reason: undefined as string | undefined, opt_in: false },
  });

  const forgetAccount = useMutation(coreClient.accounts.forget, {
    onSuccess: () => {
      successModal.open();
    },
  });

  const submit = (): void => {
    forgetAccount.reset();
    forgetAccount.mutate({
      id: getIdFromIri(auth.user?.["@id"]) as string,
      reason: form.data.reason.value,
    });
  };

  return (
    <NewPageWrapper
      title={t((d) => d.settings.headings.forget_account)}
      backUrl={IonicRoutes.more.locationAndLanguage()}
    >
      {t((d) => d.settings.forget_account.explanation)}
      <Spacer h={2} />
      <HorizontalLine type="gray" spaceWidth={4} />
      <b>{t((d) => d.settings.forget_account.account_name)}:</b> {auth.user?.account?.name}
      <Spacer h={2} />
      <div className="max-w-xl">
        <Label text={<b>{t((d) => d.settings.forget_account.reason)}:</b>}>
          <Textarea
            className={"w-full"}
            onChange={(val) => void form.set({ reason: val })}
            value={form.data.reason.value}
          />
        </Label>
      </div>
      <Spacer h={4} />
      <Label text={t((d) => d.settings.forget_account.opt_in)} position="right">
        <InputCheckbox
          checked={form.data.opt_in.value}
          onChange={() => void form.set({ opt_in: !form.data.opt_in.value })}
        />
      </Label>
      <Spacer h={4} />
      <div>
        <Button
          buttonType="warning"
          disabled={!form.data.opt_in.value || forgetAccount.isLoading}
          loading={forgetAccount.isLoading}
          onClick={submit}
          iconPos={"right"}
          icon={<IconDelete />}
        >
          {t((d) => d.actions.submit)}
        </Button>
      </div>
      {forgetAccount.isError && (
        <Message type="error" className={"mt-2"}>
          {(forgetAccount.error as Error).message}
        </Message>
      )}
      <ForgetRequestSuccessModal
        isActive={successModal.isActive}
        closeHandler={() => history.push(Routes.USER_SETTINGS)}
      />
    </NewPageWrapper>
  );
};
