import { Button, H2, IconThumbsDown, Spacer, Strong } from "@brenger/react";
import { formatPrice } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import {
  BreadcrumbNav,
  BundledJobSummary,
  Page,
  RegularJobSummary,
  StaticMap,
  TooltipDressCode,
  ImageGallery,
} from "../../../components";
import { useClaimJobGate, useFeedbackUrl, useMapContext, useSearchParams, useTranslation } from "../../../hooks";
import { CacheKey, Routes, coreClient } from "../../../utils";

interface Props {
  scrollToTopOnMount?: boolean;
  /**
   * There are some cases where we do not want to show the nav (e.g. the search job details PREVIEW page.)
   */
  showNav?: boolean;
}

export const SearchJobDetails: React.FC<Props> = ({ showNav = true, scrollToTopOnMount = true }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useSearchParams();
  const breadcrumbs = [
    { text: t((d) => d.app.tabs.search), to: Routes.SEARCH_JOB_LIST },
    { text: `#${params.tj_id.slice(-6)}` },
  ];

  // Fetch the transport job
  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, params.tj_id], () =>
    coreClient.transportJobs.retrieve({ id: params.tj_id })
  );

  // get map context
  const mapContext = useMapContext({ presentation: "search", tj: tj.data });

  // Set up gate to check whether transport job can be claimed
  const ClaimJobGatedUI = useClaimJobGate(tj.data);

  const tjData = tj.data;
  const feedbackUrl = useFeedbackUrl({ jobId: tjData?.short_id });

  const itemSets = tjData?.item_sets || [];

  if (!tjData || tj.isError) {
    return (
      <Page
        scrollToTopOnMount={scrollToTopOnMount}
        nav={showNav ? <BreadcrumbNav breadcrumbs={breadcrumbs} /> : null}
        loading={tj.isLoading}
        errorText={(tj.error as Error)?.message}
      />
    );
  }
  // Do not show the claim job CTA if the useClaimJobGate returns something.
  const stickyFooter = !ClaimJobGatedUI && (
    <div className={cn("flex", "items-center")}>
      {/* CLAIM DIRECTLY */}
      <div className={cn("ml-2", "w-full")}>
        <Button
          size="lg"
          className={cn("w-full", "flex", "flex-wrap")}
          buttonType="secondary"
          onClick={() => history.push(`/search/${params.tj_id}/claim`)}
        >
          {/* The text in preview is much longer then claim, so for consistency in button size we use inline-grid */}
          <span>
            <Strong className={cn("mr-1")}>{t((d) => d.transport_job.actions.claim_job_non_guaranteed)}</Strong>
            <Strong>
              {formatPrice({
                amount: tjData.total_payout.excl_vat.amount,
                currency: tjData.total_payout.excl_vat.currency,
                locale: i18n.locale,
              })}
            </Strong>
          </span>
        </Button>
      </div>
    </div>
  );

  if (tjData.state === "unbundled") {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        errorText={t((d) => d.app.errors.general)}
        cta={<Button className={cn("w-full")}>{t((d) => d.actions.go_back)}</Button>}
      />
    );
  }

  return (
    <Page
      loading={tj.isLoading}
      nav={showNav ? <BreadcrumbNav breadcrumbs={breadcrumbs} /> : null}
      stickyFooter={stickyFooter}
      scrollToTopOnMount={scrollToTopOnMount}
    >
      <div className={cn({ "mb-3": Boolean(ClaimJobGatedUI) })}>{ClaimJobGatedUI}</div>
      <div className={cn("flex", "justify-between", "items-start")}>
        <H2 className={cn("capitalize", "truncate")}>{tjData.title}</H2>
        <button className={cn("p-2", "ml-4", "border", "rounded-full", "border-gray-800")} onClick={feedbackUrl.open}>
          <IconThumbsDown className={cn("text-gray-800", "w-5", "h-5")} />
        </button>
      </div>
      <Spacer h={3} />
      <StaticMap markers={mapContext.markers} points={mapContext.points} googleMapsUrl={mapContext.googleMapsUrl} />
      {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
        <>
          <Spacer h={4} />
          <TooltipDressCode />
        </>
      )}
      <Spacer h={2} />
      {tjData.bundled ? (
        <BundledJobSummary tj={tjData} presentation="search" />
      ) : (
        <RegularJobSummary tj={tjData} presentation="search" />
      )}
      <ImageGallery itemSets={itemSets} />
    </Page>
  );
};
