import { User } from "@brenger/api-client";
import { H4, IconHorizontalDots } from "@brenger/react";
import cn from "classnames";
import { cardCns } from ".";
import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { MetaItem, UserStatusPill } from "../../../components";
import { useActionSheet, useAuth, useTranslation, UseURLSearchParam } from "../../../hooks";
import { getFirstAndLastName, Routes } from "../../../utils";

interface Props {
  user: User;
}

export const SubAccountCard: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const showActionSheet = useActionSheet();
  const auth = useAuth();
  const isLoggedInUser = user["@id"] === auth.user?.["@id"];
  return (
    <div className={cardCns}>
      <div className={cn("flex", "items-center")}>
        <div
          style={{ minWidth: "4rem" }}
          className={cn("relative", "overflow-hidden", "rounded-full", "w-16", "h-16", "bg-gray-200")}
        >
          <img className={cn("h-full", "w-full", "object-cover")} src={user?.profile_image || missingImageSrc} />
        </div>
        <div className={cn("flex", "flex-col", "justify-center", "ml-4", "w-full")} style={{ minWidth: 0 }}>
          {user.deletion_token === null && (
            <div className={cn("flex", "justify-between")}>
              <H4>
                {getFirstAndLastName(user)} {isLoggedInUser ? "**" : null}
              </H4>
              <button
                className={cn("px-1")}
                onClick={() => {
                  // Set up the search params for this user
                  const search = new URLSearchParams({
                    user_iri: user["@id"],
                  } as UseURLSearchParam).toString();
                  // TOGGLE OPEN THE ACTION SHEET
                  showActionSheet({
                    title: t((d) => d.actions.more),
                    message: t((d) => d.actions.select_option),
                    options: [
                      {
                        title: t((d) => d.app.tabs.details),
                        onClick: () => window.location.assign(`${Routes.USER_DETAILS}?${search}`),
                      },
                      {
                        title: t((d) => d.app.menu.reviews),
                        onClick: () => window.location.assign(`${Routes.USER_REVIEWS}?${search}`),
                      },
                    ],
                  });
                }}
              >
                <IconHorizontalDots />
              </button>
            </div>
          )}
          {user.deletion_token && <H4>{user.deletion_token}</H4>}
          <div>
            <UserStatusPill user={user} />
          </div>
          {user.total_confirmation_success_rate && (
            <MetaItem
              name={t((d) => d.dashboard.total_confirmation_success_rate)}
              value={`${Math.round(user.total_confirmation_success_rate * 100)}%`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
