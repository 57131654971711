import * as React from "react";

import {
  ActionSheet,
  ActionSheetButtonStyle,
  ShowActionsOptions as IActionSheetOptions,
  ActionSheetButton as IActionSheetOption,
} from "@capacitor/action-sheet";

import { useTranslation } from "./useTranslation";

/**
 * We are extending the ActionSheetOption to include a onClick handler.
 */
export interface ActionSheetOption extends IActionSheetOption {
  onClick(): void;
}

export interface ActionSheetOptions extends IActionSheetOptions {
  options: ActionSheetOption[];
}

export const useActionSheet = (): ((options: ActionSheetOptions) => void) => {
  const { t } = useTranslation();

  const showActions = React.useCallback(
    async (options: ActionSheetOptions) => {
      // Always mix-in a cancel option at the end of each action sheet.
      // The action sheet does not close when user "clicks away", therefore
      // we need to provide a dummy option for this purpose.
      const cancelOption: ActionSheetOption = {
        title: t((d) => d.modal.cancel),
        style: ActionSheetButtonStyle.Cancel,
        onClick: () => null,
      };

      // Mix-in the cancel option.
      const allOptions: ActionSheetOption[] = [...options.options, cancelOption];

      // Remove the click handler from the options, as the `Modals.showActions` method interface
      // does not expect there to be any click handlers. We will utilize the handlers differently.
      const allOptionsSansClickHandlers: IActionSheetOption[] = allOptions.map((option) => {
        const clonedOption = { ...option };
        Reflect.deleteProperty(clonedOption, "onClick");
        return clonedOption;
      });

      // Set the complete list of action sheet options onto the options that will get passed to PWA element.
      const compatOptions = {
        ...options,
        options: allOptionsSansClickHandlers,
      };

      // Grab the index selected by the user.
      const { index } = await ActionSheet.showActions(compatOptions);
      // Use the index to find the clickHandler we need to invoke.
      const { onClick } = allOptions[index];
      // Invoke the onClick inside a timeout so that we wait at least one tick
      // of the JS event loop before invoking the handler. This allows time for
      // the action sheet to "close".
      if (onClick) setTimeout(onClick);
    },
    [t]
  );

  return showActions;
};
