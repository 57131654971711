import { IconCaretRight } from "@brenger/react";
import { IonRouterLink } from "@ionic/react";
import cn from "classnames";

interface Props {
  title?: string;
  links: MoreLinkListItem[];
  split?: boolean;
}

export interface MoreLinkListItem {
  label: string | React.ReactNode;
  link?: string;
  icon: React.ReactNode;
  withCarret?: boolean;
  onClick?(): void;
}

export const MoreLinkList: React.FC<Props> = ({ title, links, split }) => {
  return (
    <div className={cn("my-8", "w-full")}>
      {title && (
        <div className={cn("mb-2")}>
          <b>{title}</b>
        </div>
      )}
      <div className={cn({ "md:grid md:grid-rows-2 md:grid-flow-col md:gap-x-12": split })}>
        {links.map((l, i) => {
          if (l.link) {
            return (
              <IonRouterLink routerLink={l.link} key={i}>
                <LinkContent {...l} />
              </IonRouterLink>
            );
          }
          return (
            <div className={"text-blue-600"} onClick={l.onClick} key={i}>
              <LinkContent {...l} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
const LinkContent: React.FC<MoreLinkListItem> = ({ label, icon, withCarret = true }) => (
  <div style={{ marginTop: "-1px" }} className={cn("flex", "items-center", "gap-4", "border-y", "border-gray-400")}>
    <div className={cn("w-8")}>{icon}</div>
    <div className={cn("br-text-default", "grow", "py-4")}>{label}</div>
    {withCarret && <IconCaretRight className={cn("ml-auto", "mr-2")} />}
  </div>
);
