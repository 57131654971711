import * as React from "react";
import { Keyboard } from "@capacitor/keyboard";

import { useNativePlatform } from ".";

interface Params {
  onWillShow?: () => void;
  onDidShow?: () => void;
  onWillHide?: () => void;
  onDidHide?: () => void;
}

/**
 * For more info on Capacitor Keyboard plugin, see:
 * https://capacitorjs.com/docs/apis/keyboard
 */

export const useNativeKeyboardEvents = (params: Params): void => {
  const isNativePlatform = useNativePlatform();

  React.useEffect(() => {
    // Only works on native platforms
    if (!isNativePlatform) return;

    Keyboard.addListener("keyboardWillShow", () => {
      if (params.onWillShow) params.onWillShow();
    });

    Keyboard.addListener("keyboardDidShow", () => {
      if (params.onDidShow) params.onDidShow();
    });

    Keyboard.addListener("keyboardWillHide", () => {
      if (params.onWillHide) params.onWillHide();
    });

    Keyboard.addListener("keyboardDidHide", () => {
      if (params.onDidHide) params.onDidHide();
    });

    return () => {
      Keyboard.removeAllListeners();
    };
  }, [isNativePlatform, Keyboard, params]);
};
