import { Button, IconCircleClock, IconVanIllustration, Small, Strong, useModalState } from "@brenger/react";
import * as React from "react";
import { Modal } from "../components";

import cn from "classnames";
import { addDays, isAfter } from "date-fns";
import { Config } from "../config";
import { useBrowser, useDeviceInfo, usePersistedState, useTranslation } from "../hooks";
import { PersistedStorageKey } from "../utils";
import { AppPushPrompt } from "./AppPushPrompt";

export type NativeAppMessagesKey = "app-version-unstable" | "app-version-new-update" | "app-push-prompt";

type Props = {
  appMessages: Set<NativeAppMessagesKey>;
};
export const NativeAppMessages: React.FC<Props> = ({ appMessages }) => {
  const { t } = useTranslation();
  const updateModal = useModalState();
  const deviceInfo = useDeviceInfo();
  const browser = useBrowser();
  const now = new Date();
  let appStoreUrl = "";
  if (deviceInfo?.platform === "android") appStoreUrl = Config.GOOGLE_PLAY_STORE_URL;
  if (deviceInfo?.platform === "ios") appStoreUrl = Config.IOS_APP_STORE_URL;

  const [nextShowUpdateModal, setShowUpdateModal] = usePersistedState<string | null>({
    initialState: null, // when localstorage is not retrieved yet
    defaultState: null, // when nothing is set yet
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_UPDATE_APP_VERSION,
  });

  /**
   * --------------------
   * HANDLE DISMISS-ABLE OUT OF DATE APP MODAL
   * --------------------
   */
  React.useEffect(() => {
    if (typeof nextShowUpdateModal === "undefined") {
      return;
    }
    if (nextShowUpdateModal === null || isAfter(now, new Date(nextShowUpdateModal))) {
      updateModal.open();
      return;
    }
    updateModal.close();
  }, [nextShowUpdateModal]);

  const closehandler = (): void => {
    updateModal.close();
    // When the driver decides to ignore this time, ask again after a month
    setShowUpdateModal(addDays(now, 1).toISOString());
  };

  const UnstableAppVersion: React.FC = () => (
    <Modal closeByBackground={false} canCancel={false} isActive={true} closeHandler={updateModal.close}>
      <div className={cn("grid", "gap-4", "text-center", "place-items-center", "text-blue-600")}>
        <IconVanIllustration />
        <h3>{t((d) => d.app.unstable_version.title)}</h3>
        <div>{t((d) => d.app.unstable_version.body)}</div>
        <Button className={cn("w-1/2")} buttonType="secondary" onClick={() => browser.open({ url: appStoreUrl })}>
          {t((d) => d.app.update)}
        </Button>

        <div className={cn("inline-flex", "items-center")}>
          <IconCircleClock className={cn("mr-2")} />
          <Small>{t((d) => d.app.unstable_version.install_duration as string)}</Small>
        </div>
      </div>
    </Modal>
  );

  const AppUpdateAvailable: React.FC = () => (
    <Modal closeByBackground={false} canCancel={false} isActive={updateModal.isActive} closeHandler={closehandler}>
      <div className={cn("grid", "gap-4", "text-center", "place-items-center", "text-blue-600")}>
        <IconVanIllustration />
        <h3>{t((d) => d.app.update_available.title)}</h3>
        <div>{t((d) => d.app.update_available.body)}</div>
        <Button
          className={cn("w-1/2", "py-4")}
          buttonType="secondary"
          onClick={() => browser.open({ url: appStoreUrl })}
        >
          {t((d) => d.app.update)}
        </Button>
        <button className={cn("w-1/2", "text-blue-600")} onClick={closehandler}>
          <Strong>{t((d) => d.app.update_not_now)}</Strong>
        </button>
      </div>
    </Modal>
  );
  return (
    <>
      {appMessages.has("app-version-unstable") && <UnstableAppVersion />}
      {appMessages.has("app-version-new-update") && <AppUpdateAvailable />}
      {appMessages.has("app-push-prompt") && <AppPushPrompt />}
    </>
  );
};
