import { Spacer } from "@brenger/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "../../../components";
import { Routes } from "../../../utils";
import { SearchJobDetails } from "../SearchJobDetails";
import { SearchJobList } from "../SearchJobList";

export const SearchJobDetailsOverlay: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <SearchJobList />
      <Modal isActive={true} closeHandler={() => history.push(Routes.SEARCH_JOB_LIST)}>
        {/*
          Do not allow SearchJobDetails to scroll back to top on mount,
          as this will cause the entire search job list to do so as well.
        */}
        <SearchJobDetails showNav={false} scrollToTopOnMount={false} />
        <Spacer h={2} />
      </Modal>
    </>
  );
};
