import * as React from "react";
import cn from "classnames";
import SignatureCanvas from "react-signature-canvas";
import { Button, Spacer, Label } from "@brenger/react";

import { Modal } from "../../../components";
import { useTranslation } from "../../../hooks";
import { userDataClient } from "../../../utils";

interface Props {
  isActive: boolean;
  closeHandler(): void;
  signatureIri: string;
  resetSignatureIri(): void;
  setSignatureIri(dataUrl: string): void;
}

// Create interface for SignaturePad - project itself does not export this interface for us unfortunately.
interface SignaturePadProps {
  isEmpty(): boolean;
  clear(): void;
  toDataURL(): string;
}

// Reuse this list of classes on both the canvas and signature preview for seamless UX.
const containerClassnames = ["h-56", "border", "border-gray-700", "w-full", "rounded"];

export const ConfirmStopSignatureModal: React.FC<Props> = (props) => {
  const [hasSignature, setHasSignature] = React.useState(false);
  const { t } = useTranslation();
  const signatureRef = React.useRef<HTMLCanvasElement & SignaturePadProps>();

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      {/* Render a preview of the signature if one is in state. */}
      {props.signatureIri ? (
        <Label text={t((d) => d.planning.labels.add_signature)}>
          <img
            src={userDataClient.getBaseForIri(props.signatureIri)}
            className={cn(containerClassnames)}
            // Render the signature pad again the moment the user touches the static preview.
            // This means clearing the previously stored dataURL from state.
            onTouchStart={props.resetSignatureIri}
            onMouseDown={props.resetSignatureIri}
          />
        </Label>
      ) : null}
      {!props.signatureIri ? (
        <Label text={t((d) => d.planning.labels.add_signature)}>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: cn(containerClassnames) }}
            // Target "onBegin" event to register in state if user has created a valid signature.
            onBegin={() => setHasSignature(true)}
            // Disable following line becaues SignatureCanvas is using old ref typings internally.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={signatureRef as any}
          />
        </Label>
      ) : null}
      <Spacer h={4} />
      <Button
        className={cn("w-full")}
        disabled={!hasSignature || !!props.signatureIri}
        onClick={() => {
          // convert the signature to a data url
          const signatureIri = signatureRef.current?.toDataURL() || "";
          // ship the data url to the parent
          props.setSignatureIri(signatureIri);
          // clear the canvas
          signatureRef.current?.clear();
          // close the modal
          props.closeHandler();
        }}
      >
        {t((d) => d.modal.confirm)}
      </Button>
      <Spacer h={1} />
      <Button className={cn("w-full")} buttonType="white" onClick={props.closeHandler}>
        {t((d) => d.modal.cancel)}
      </Button>
    </Modal>
  );
};
