import { CancelTransportJobAccountLinkError } from "@brenger/api-client";
import { Button, Emphasis, IconHorizontalDots, IconMail, Message, Pill, Small, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import {
  BreadcrumbNav,
  BundledJobSummary,
  DriverSelect,
  MetaItem,
  Page,
  PillTjalState,
  RegularJobSummary,
  StaticMap,
  TooltipDressCode,
  ImageGallery,
} from "../../../components";
import {
  ActionSheetOption,
  useActionSheet,
  useAuth,
  useConfirmModal,
  useMapContext,
  usePlanningParams,
  useTjalContext,
  useTranslation,
  useUserGeneratedContent,
} from "../../../hooks";
import { CacheKey, Routes, coreClient } from "../../../utils";

export const PlanningJobDetails: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const params = usePlanningParams();
  const showActionSheet = useActionSheet();
  const [driverUser, setDriverUser] = React.useState("");
  // hook to interact with cache / settings etc
  const queryClient = useQueryClient();

  // Initialize breadcrumbs
  const breadcrumbs = [{ text: t((d) => d.app.tabs.planning), to: Routes.PLANNING_JOB_LIST }, { text: "--" }];

  const { tjal, tj } = useTjalContext(params.tjal_id);

  // Initialize the driverUser with the current one from the TJAL
  React.useEffect(() => {
    // Though a driver_user ought to always be present, it's not guaranteed in practice. Hence, the safety measures.
    const driverUserIRI = tjal.data?.driver_user?.["@id"];
    if (driverUserIRI) setDriverUser(driverUserIRI);
  }, [tjal.data]);

  // Add the TJ short id to the breadcrumbs when it becomes available.
  if (tj.data) breadcrumbs[1].text = `#${tj.data.short_id}`;

  // Fetch conversation in order to print a message count
  const conversation = useQuery(
    [CacheKey.LIST_MESSAGES_USER_AND_TJAL, params.tjal_id, auth.userId],
    () => coreClient.messages.listByUserAndTjal({ user_id: auth.userId as string, tjal_id: params.tjal_id }),
    {
      enabled: !!auth.userId && !tj.data?.bundled,
    }
  );
  const messageCount = (conversation.data?.["hydra:member"] || []).length;

  const replaceTjal = useMutation(coreClient.transportJobAccountLinks.replace, {
    onSuccess: (updatedTjal) => {
      queryClient.clear();
      // If the replace operation is successful, update the selected driver user.
      setDriverUser(updatedTjal.driver_user["@id"]);
    },
  });

  const cancelTjal = useMutation(coreClient.transportJobAccountLinks.cancel, {
    onSuccess: () => {
      // Success callback for cancel TJAL mutation
      // 1. Reset the cache planning, dayroute and route
      queryClient.resetQueries([
        CacheKey.LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS,
        CacheKey.RETRIEVE_DAY_ROUTE,
        CacheKey.RETRIEVE_ROUTE,
        CacheKey.RETRIEVE_DAY_ROUTE_LEGS,
      ]);

      // 2. Move to the planning job list.
      history.push(Routes.PLANNING_JOB_LIST);
    },
  });

  // Cast the core error to the correct type (using type from api-client)
  const cancelTjalError = (cancelTjal.error as CancelTransportJobAccountLinkError)?.response?.data?.[
    "hydra:description"
  ];
  const [ConfirmCancelModal, activateConfirmCancelModal] = useConfirmModal(() => {
    cancelTjal.reset();
    cancelTjal.mutate({ id: params.tjal_id });
  });

  const revokeTjal = useMutation(coreClient.transportJobAccountLinks.revoke, {
    onSuccess: () => {
      // Success callback for revoke TJAL mutation
      // 1. Reset the cache
      queryClient.clear();
      // 2. Move to the planning job list.
      history.push(Routes.PLANNING_JOB_LIST);
    },
  });

  // Cast the core error to the correct type (using type from api-client)
  // @TODO: CREATE A MORE SPECIFIC CANCEL ERROR OBJECT IN API CLIENT
  const revokeTjalError = (revokeTjal.error as CancelTransportJobAccountLinkError)?.response?.data?.[
    "hydra:description"
  ];

  const [ConfirmRevokeModal, activateConfirmRevokeModal] = useConfirmModal(() => {
    revokeTjal.reset();
    revokeTjal.mutate({ id: params.tjal_id });
  });

  const mapContext = useMapContext({ presentation: "planning", tj: tj.data });
  const disputeReason = useUserGeneratedContent(tjal.data?.dispute_reason);

  const itemSets = tj.data?.item_sets || [];

  if (!tj.data || tj.error) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tj.isLoading}
        errorText={(tj.error as Error)?.message}
      />
    );
  }

  // Pluck TJ data we'll be using
  const { state, bundled: isBundled, directly_claimable: isDirectlyClaimable } = tj.data;
  // Check if TJ is in a disputed state
  const isDisputed = state === "disputed";

  // Collect a list of "actions" to display to the user in the "context" menu
  // Additional note: These are only available to admins.
  const actionSheetOptions: ActionSheetOption[] = [];

  if (isBundled) {
    // UPDATE TIME FRAMES
    actionSheetOptions.push({
      title: t((d) => d.actions.update_timeframes),
      onClick: () => history.push(`${Routes.PLANNING_JOB_LIST}/${params.tjal_id}/update`),
    });
    // CANCEL BUNDLED JOB
    actionSheetOptions.push({
      title: t((d) => d.planning.messages.cancel_tjal_bundle_cta),
      onClick: () => {
        activateConfirmCancelModal({
          title: t((d) => d.planning.messages.cancel_tjal_bundle_cta),
          message: t((d) => d.planning.messages.cancel_tjal_bundle),
          okButtonTitle: t((d) => d.actions.accept),
          cancelButtonTitle: t((d) => d.actions.close),
        });
      },
    });
  } else if (isDirectlyClaimable) {
    // UPDATE TIME FRAMES
    actionSheetOptions.push({
      title: t((d) => d.actions.update_timeframes),
      onClick: () => history.push(`${Routes.PLANNING_JOB_LIST}/${params.tjal_id}/update`),
    });
    // CANCEL REGULAR JOB
    actionSheetOptions.push({
      title: t((d) => d.planning.messages.cancel_tjal_cta),
      onClick: () => {
        activateConfirmCancelModal({
          title: t((d) => d.planning.messages.cancel_tjal_cta),
          message: t((d) => d.planning.messages.cancel_tjal),
          okButtonTitle: t((d) => d.actions.accept),
          cancelButtonTitle: t((d) => d.actions.close),
        });
      },
    });
  } else {
    // REVOKE (NOT CANCEL) NON GUARANTEED JOB
    actionSheetOptions.push({
      title: t((d) => d.planning.messages.withdraw_tjal_cta),
      onClick: () => {
        activateConfirmRevokeModal({
          title: t((d) => d.planning.messages.withdraw_tjal_cta),
          message: t((d) => d.planning.messages.withdraw_tjal),
          okButtonTitle: t((d) => d.actions.accept),
          cancelButtonTitle: t((d) => d.actions.close),
        });
      },
    });
  }

  if (tj.data.state === "unbundled") {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        errorText={t((d) => d.app.errors.general)}
        cta={<Button className={cn("w-full")}>{t((d) => d.actions.go_back)}</Button>}
      />
    );
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
      stickyFooter={
        !isBundled && (
          <Button
            className={cn("w-full")}
            disabled={!messageCount}
            onClick={() => history.push(`/inbox/${getIdFromIri(tjal.data)}`)}
            icon={<IconMail className={cn("h-4")} />}
          >
            {t((d) => d.transport_job.actions.view_messages)} {messageCount > 0 && `(${messageCount})`}
          </Button>
        )
      }
    >
      <div>
        {isDisputed && disputeReason.text && (
          <Message type="error" className={cn("mb-2")}>
            <MetaItem
              name={t((d) => d.planning.messages.dispute_reason)}
              value={<Emphasis>{disputeReason.text}</Emphasis>}
            />
          </Message>
        )}
        <div className={cn("flex", "flex-wrap", "items-center", "justify-between")}>
          {/* NOTE: Do not show the TJAL state when the TJ is in dispute. */}
          {tjal.data && !isDisputed && <PillTjalState state={tjal.data.state} />}
          {isDisputed && <Pill type="red">{t((d) => d.transport_job.in_dispute)}</Pill>}
          {/*
           *  ONLY ACCOUNT ADMINS GET ACCESS TO CONTEXT MENU
           *  FURTHER NOTE: WE MAY EVENTUALLY ADD MORE CONTEXT MENU ITEMS THAT DO NOT DEPEND ON
           *  DISPUTED STATUS - BUT FOR NOW THE SOLE ITEM DOES DEPENDS ON IT IS SO SAFE TO DO.
           */}
          {auth.isAccountAdmin && !isDisputed && (
            <button
              className={cn("px-1", "border", "rounded-full", "border-gray-800")}
              onClick={() => {
                // TOGGLE OPEN THE ACTION SHEET
                showActionSheet({
                  title: t((d) => d.actions.more),
                  message: t((d) => d.actions.select_option),
                  options: actionSheetOptions,
                });
              }}
            >
              <IconHorizontalDots />
            </button>
          )}
        </div>
        {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
          <>
            <Spacer h={2} />
            <TooltipDressCode />
          </>
        )}
        <Spacer h={4} />
        <StaticMap markers={mapContext.markers} points={mapContext.points} googleMapsUrl={mapContext.googleMapsUrl} />
        <Spacer h={3} />
        <h3 className={cn("mr-2", "capitalize", "truncate")}>{tj.data.title}</h3>
        <Spacer h={2} />
        {tj.data.bundled ? (
          <BundledJobSummary tj={tj.data} tjal={tjal.data} presentation="planning" />
        ) : (
          <RegularJobSummary tj={tj.data} tjal={tjal.data} presentation="planning" />
        )}
        {/* ALLOW ACCOUNT ADMINS TO RE-ASSIGN JOBS TO SUB ACCOUNTS */}
        <DriverSelect
          disableSuspendedDrivers={true}
          labelText={t((d) => d.planning.labels.change_driver)}
          value={driverUser}
          accountId={getIdFromIri(auth.user?.account)}
          onChange={({ userIRI }) => {
            replaceTjal.reset();
            replaceTjal.mutate({
              id: getIdFromIri(tjal.data) || "",
              driver_user: userIRI,
            });
          }}
        />
        {/* HANDLE ERROR STATE FOR TJAL REPLACE MUTATION DIRECTLY BELOW DRIVER SELECT */}
        {replaceTjal.isError && (
          <Small className={cn("my-2", "text-red-600")}>{(replaceTjal.error as Error)?.message}</Small>
        )}
        <Spacer h={3} />
        <ImageGallery itemSets={itemSets} />
        {/* HANDLE ERROR STATES FOR CANCEL TJAL MUTATION */}
        {cancelTjalError && (
          <Message className={cn("mt-4")} type="error">
            {t((d) => d.planning.errors[cancelTjalError] || cancelTjalError)}
          </Message>
        )}
        {/* HANDLE ERROR STATES FOR REVOKE TJAL MUTATION */}
        {revokeTjalError && (
          <Message className={cn("mt-4")} type="error">
            {t((d) => d.planning.errors[revokeTjalError] || revokeTjalError)}
          </Message>
        )}
        <ConfirmRevokeModal buttonType="warning" />
        <ConfirmCancelModal buttonType="warning" />
      </div>
    </Page>
  );
};
