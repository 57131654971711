import {
  IconChat,
  IconDashboard,
  IconHeart,
  IconID,
  IconLanguage,
  IconLock,
  IconPersonOne,
  IconPersonTwo,
  IconPhone,
  IconSignOut,
  IconStarOutline,
  IconVehicle,
} from "@brenger/react";
import cn from "classnames";
import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { useAppInfo, useAuth, useBrowser, useTranslation } from "../../../hooks";
import { IonicRoutes, Routes, getCustomerAppUrl } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";
import { MoreLinkList, MoreLinkListItem } from "./MoreLinkList";

export const MoreHome: React.FC = () => {
  const auth = useAuth();
  const appInfo = useAppInfo();
  const browser = useBrowser();
  const { t, i18n } = useTranslation();

  const firstList: MoreLinkListItem[] = [
    { link: IonicRoutes.more.inbox(), label: t((d) => d.more.inbox), icon: <IconChat /> },
    {
      link: IonicRoutes.more.dashboard(),
      label: t((d) => d.more.dashboard),
      icon: <IconDashboard style={{ fontSize: 20 }} />,
    },
    { link: IonicRoutes.more.reviews(), label: t((d) => d.more.reviews), icon: <IconStarOutline /> },
    {
      link: IonicRoutes.more.brengerId(),
      label: t((d) => d.more.brenger_id),
      icon: <IconID style={{ fontSize: 21 }} />,
    },
  ];

  const secondList: MoreLinkListItem[] = [
    {
      link: IonicRoutes.more.profile(),
      label: t((d) => d.more.profile),
      icon: <IconPersonOne style={{ fontSize: 26 }} />,
    },
  ];
  if (auth.isAccountAdmin) {
    secondList.push(
      {
        link: IonicRoutes.more.vehicles(),
        label: t((d) => d.more.vehicles),
        icon: <IconVehicle style={{ fontSize: 20 }} />,
      },
      {
        link: IonicRoutes.more.couriers(),
        label: t((d) => d.more.drivers),
        icon: <IconPersonTwo style={{ fontSize: 20 }} />,
      },
      {
        link: IonicRoutes.more.favouriteRoutes(),
        label: t((d) => d.more.favourite_routes),
        icon: <IconHeart style={{ fontSize: 27 }} />,
      }
    );
  }

  const thirdList: MoreLinkListItem[] = [
    {
      label: t((d) => d.more.change_password),
      icon: <IconLock style={{ fontSize: 24 }} />,
      onClick: () => browser.open({ url: `${getCustomerAppUrl(i18n.locale)}/customer/password_reset` }),
    },
    {
      link: IonicRoutes.more.locationAndLanguage(),
      label: t((d) => d.more.location_language),
      icon: <IconLanguage style={{ fontSize: 11 }} />,
    },
    {
      label: <span className="text-blue-600">{t((d) => d.more.log_out)}</span>,
      icon: <IconSignOut style={{ fontSize: 22 }} />,
      withCarret: false,
      link: Routes.LOGOUT,
    },
  ];
  if (appInfo?.build) {
    thirdList.splice(2, 0, {
      label: (
        <div className={cn("flex", "justify-between")}>
          <div>{t((d) => d.more.app_version)}</div>
          <div className={cn("text-gray-500")}>
            {appInfo?.version} #{appInfo?.build}
          </div>
        </div>
      ),
      icon: <IconPhone style={{ fontSize: 30 }} />,
      withCarret: false,
    });
  }
  return (
    <NewPageWrapper>
      <div className={cn("flex", "items-center", "gap-5")}>
        <img
          className={cn("h-[4.5rem]", "w-[4.5rem]", "object-cover", "rounded-full")}
          src={auth.user?.profile_image || missingImageSrc}
        />
        <div>
          <h5>{auth.user?.first_name}</h5>
          <h6 className={cn("br-font", "text-gray-500")}>
            {t((d) => d.more[auth.isAccountAdmin ? "main_account" : "sub_account"])}
          </h6>
        </div>
      </div>
      <MoreLinkList split={true} links={firstList} />
      <div className={cn("md:flex", "md:gap-x-12")}>
        <MoreLinkList title={t((d) => d.more.heading_list_2)} links={secondList} />
        <MoreLinkList
          title={t((d) => d.more[appInfo?.build ? "heading_list_3_app" : "heading_list_3"])}
          links={thirdList}
        />
      </div>
    </NewPageWrapper>
  );
};
