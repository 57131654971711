import React from "react";
import cn from "classnames";
import { Button, H3, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import { useMutation, useQuery } from "react-query";
import { DayRouteActivityForUpdate } from "@brenger/api-client";
import insert from "flatmap-fns/insert";

import { Page, BreadcrumbNav } from "../../../components";
import { useDayRouteParams, useTranslation, useForm } from "../../../hooks";
import { coreClient, CacheKey, Routes } from "../../../utils";

import {
  DayRouteActivityForm,
  DayRouteActivityFormType,
  getDefaultDatetimePeriodForDayRouteActivity,
} from "./DayRouteActivityForm";
import {
  convertStopFormDataIntoDayRouteActivity,
  formatActivities,
  addIndex,
  calculateIndexForActivity,
} from "../utils";

const getInitialState = (date: string): DayRouteActivityFormType => {
  const { start, end } = getDefaultDatetimePeriodForDayRouteActivity(date);

  return {
    type: "custom_delivery",
    search: "",
    place: undefined,
    // 5 minutes in seconds
    service_time_seconds: 60 * 5,
    start_time: start.toISOString(),
    end_time: end.toISOString(),
    index: 0,
    capacity_m3: 5,
  };
};

export const DayRouteAddDelivery: React.FC = () => {
  const { t } = useTranslation();
  const params = useDayRouteParams();
  const dayRouteUrl = `/day-route/${params.date}/${params.user_id}`;

  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const activities = dayRoute.data?.day_route_activities || [];

  const form = useForm({
    initialState: getInitialState(params.date),
    validators: {
      place: (place) => !place,
      start_time: (start_time) => !start_time,
      end_time: (end_time) => !end_time,
      capacity_m3: (capacity_m3) => capacity_m3 === undefined || capacity_m3 <= 0 || capacity_m3 > 10,
    },
  });

  const onSuccess = (): void => {
    window.location.assign(dayRouteUrl);
  };

  const updateDayRoute = useMutation(coreClient.dayRoutes.update, { onSuccess });

  const onSubmitPickup = (): void => {
    const newActivity = convertStopFormDataIntoDayRouteActivity(form);
    const calculatedIndex = calculateIndexForActivity({ newActivity, activities });
    const selectedIndex = form.data.index?.value;
    const index = selectedIndex ?? calculatedIndex;

    const updatedActivities =
      activities.length > 0
        ? activities
            .flatMap(insert<DayRouteActivityForUpdate>(newActivity, index))
            .flatMap(formatActivities())
            .map(addIndex())
        : [newActivity].flatMap(formatActivities()).map(addIndex());

    updateDayRoute.mutate({
      routeId: getIdFromIri(dayRoute.data) || "",
      dayRoute: {
        day_route_activities: updatedActivities,
      },
    });
  };

  return (
    <Page
      stickyFooter={
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          disabled={form.hasErrors}
          onClick={onSubmitPickup}
          loading={updateDayRoute.isLoading}
        >
          {t((d) => d.day_route.create_stop.submit)}
        </Button>
      }
      nav={
        <BreadcrumbNav
          breadcrumbs={[
            { to: Routes.PLANNING_JOB_LIST, text: t((d) => d.app.tabs.planning) },
            { to: dayRouteUrl, text: t((d) => d.day_route.details.title) },
            { to: `${dayRouteUrl}/add`, text: t((d) => d.actions.add) },
            { text: t((d) => d.transport_job.delivery_stop) },
          ]}
        />
      }
    >
      <H3 className={cn("mb-4")}>{t((d) => d.day_route.create_stop.submit)}</H3>
      <DayRouteActivityForm activities={activities} form={form} />
      {updateDayRoute.isError && (
        <Message className={cn("mt-4")} type="error">
          {(updateDayRoute.error as Error)?.message}
        </Message>
      )}
    </Page>
  );
};
