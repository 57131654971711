import { DayRoute } from "@brenger/api-client";
import cn from "classnames";
import isAfter from "date-fns/isAfter";
import React from "react";
import { UseQueryResult } from "react-query";
import { useDayRouteParams, useFormatDate, useTranslation } from "../../../hooks";

interface TitleProps {
  dayRoute: UseQueryResult<DayRoute>;
}

export const UNIX_EPOCH = 0;

export const DayRouteStartTitle: React.FC<TitleProps> = (props) => {
  const { t } = useTranslation();
  const { data: dayRoute } = props.dayRoute;
  const params = useDayRouteParams();
  const formatDateFull = useFormatDate("date-full");
  const formatDateForApi = useFormatDate("api-date");

  // CASE: day route data has not been fetched yet
  if (!dayRoute) return null;

  // CASE: day route is not for the current day OR no activities yet
  const today = new Date().toISOString();
  const dateToday = formatDateForApi(today);
  const isDayRouteForToday = dateToday === params.date;

  if (!isDayRouteForToday || (dayRoute.day_route_activities || []).length === 0) {
    return (
      <h4 className={cn("text-blue-600", "font-bold")}>
        {t((d) => d.day_route.specific_date_day_route)} {formatDateFull(new Date(params.date).toISOString())}
      </h4>
    );
  }
  // CASE: day route has already been started!
  const startedDriving = dayRoute.started_driving_at !== null;
  if (startedDriving) {
    return (
      <h4 className={cn("text-blue-600", "font-bold")}>{t((d) => d.day_route.start_driving.current_day_route)}</h4>
    );
  }

  // CASE: driver has never changed the day route before
  const driverHasNotChangedDayRoute = dayRoute && dayRoute.last_changed_by_driver_at === null;
  if (driverHasNotChangedDayRoute) {
    return (
      <h4 className={cn("text-blue-600", "font-bold")}>{t((d) => d.day_route.start_driving.not_checked_day_route)}</h4>
    );
  }

  // CASE: someone OTHER THAN the driver updated the day route
  const wasDayRouteLastChangedByOthers =
    dayRoute?.last_changed_by_others_at &&
    isAfter(new Date(dayRoute.last_changed_by_others_at), new Date(dayRoute?.last_changed_by_driver_at || UNIX_EPOCH));
  if (wasDayRouteLastChangedByOthers) {
    return (
      <h4 className={cn("text-blue-600", "font-bold")}>{t((d) => d.day_route.start_driving.last_changed_by_others)}</h4>
    );
  }

  // CASE: the driver was the last one to update the day route
  const wasDayRouteLastChangedByDriver =
    dayRoute?.last_changed_by_driver_at &&
    isAfter(new Date(dayRoute.last_changed_by_driver_at), new Date(dayRoute?.last_changed_by_others_at || UNIX_EPOCH));

  if (wasDayRouteLastChangedByDriver) {
    return (
      <h4 className={cn("text-blue-600", "font-bold")}>{t((d) => d.day_route.start_driving.last_changed_by_driver)}</h4>
    );
  }
  return null;
};
