import { Button, Card, IconAdd, Pill, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { Grid } from "../../../components";
import { LicensePlate } from "../../../components/data/LicensePlate";
import { useTranslation } from "../../../hooks";
import { CacheKey, IonicRoutes, coreClient } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";
import { VehicleSummary } from "../vehiclesDetails/VehicleSummary";

export const MoreVehiclesList: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const vehicles = useQuery([CacheKey.RETRIEVE_VEHICLE_LIST], () => coreClient.vehicles.list());

  return (
    <NewPageWrapper title={t((d) => d.more.vehicles)} backUrl={IonicRoutes.more.home()} loading={vehicles.isLoading}>
      <Spacer h={4} />
      <Grid gap={4} cols={{ lg: 2 }}>
        {vehicles.data?.["hydra:member"].map((vehicle) => {
          return (
            <Link
              key={vehicle["@id"]}
              className={cn("block")}
              to={IonicRoutes.more.vehicles(getIdFromIri(vehicle["@id"]))}
            >
              <Card type="gray">
                <h5 className="capitalize">
                  {vehicle.make} {vehicle.model}
                </h5>
                <Spacer h={2} />
                <VehicleSummary vehicle={vehicle} />
                <div className={cn("p-2")}>
                  <LicensePlate vehicle={vehicle} />
                  <div className={cn("flex", "mt-2")}>
                    {vehicle.has_tailgate && (
                      <Pill type="gray-outline" className={cn("mr-1")}>
                        {t((d) => d.vehicles.tailgate)}
                      </Pill>
                    )}
                    {vehicle.has_pallet_jack && <Pill type="gray-outline">{t((d) => d.vehicles.pallet_jack)}</Pill>}
                  </div>
                </div>
              </Card>
            </Link>
          );
        })}
      </Grid>
      <Button
        className={cn("w-full", "mt-4")}
        buttonType="secondary"
        icon={<IconAdd />}
        onClick={() => push(IonicRoutes.more.vehicles("create"))}
      >
        {t((d) => d.profile.add_new_vehicle)}
      </Button>
    </NewPageWrapper>
  );
};
