import { Vehicle } from "@brenger/api-client";
import { Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { Modal } from "../../../components";
import { useTranslation } from "../../../hooks";
import { UserVehicleForm } from "../UserVehicleForm";

interface Props {
  isActive: boolean;
  close(): void;
  vehicle: Vehicle;
  onEditSuccess?: () => void;
}
export const VehicleEditModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal isActive={props.isActive} closeHandler={props.close}>
      <h3 className={cn("normal-case")}>{t((d) => d.actions.update)}</h3>
      <Spacer h={4} />
      <UserVehicleForm vehicle={props.vehicle} onGoBack={props.close} onEditSuccess={props.onEditSuccess} />
    </Modal>
  );
};
