import { Button, HorizontalLine, IconLocation, IconSettings, Label, Spacer, useModalState } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { BreadcrumbNav, LanguageSelect, MetaItem, Page } from "../../components";

import { useAppInfo, useAuth, useDeviceInfo, UseGeolocation, useNativePlatform, useTranslation } from "../../hooks";

import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { useHistory } from "react-router-dom";
import { Routes } from "../../utils";
import { EnableLocationModal } from "./EnableLocationModal";

interface Props {
  geolocation: UseGeolocation;
}

export const UserSettings: React.FC<Props> = ({ geolocation }) => {
  const modal = useModalState();
  const isNativePlatform = useNativePlatform();
  const appInfo = useAppInfo();
  const auth = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const platform = useDeviceInfo()?.platform;

  const openSettings = (): void => {
    if (platform == "android") {
      NativeSettings.openAndroid({ option: AndroidSettings.AppNotification }).finally(close);
    }
    if (platform == "ios") {
      NativeSettings.openIOS({ option: IOSSettings.App }).finally(close);
    }
  };

  return (
    <>
      <Page nav={<BreadcrumbNav breadcrumbs={[{ text: t((d) => d.app.menu.settings) }]} />}>
        <Spacer h={2} />
        <h4>{t((d) => d.settings.headings.main)}</h4>
        <HorizontalLine type={"gray"} spaceWidth={1} />
        <Label text={t((d) => d.settings.select_language)}>
          <LanguageSelect />
        </Label>
        <Spacer h={2} />
        {["android", "ios"].includes(platform || "") && (
          <div>
            <Label text={t((d) => d.settings.notifications)}>
              <Button
                className={cn("w-full")}
                buttonType="primary-outline"
                icon={<IconSettings className={cn("h-4")} />}
                onClick={openSettings}
              >
                {t((d) => d.settings.notifications_enable)}
              </Button>
            </Label>
          </div>
        )}
        {/* NATIVE ONLY - SHOW TURN ON BUTTON WHEN PLUGIN IS NOT YET ENABLED */}
        {isNativePlatform && !geolocation.isPluginEnabled && (
          <Label text={t((d) => d.settings.geo_location)}>
            <Button
              className={cn("w-full")}
              // Do not allow user to enable geolocation when the geolocation plugin is not yet ready for it.
              disabled={!geolocation.isPluginReady}
              buttonType="secondary"
              icon={<IconLocation className={cn("h-4")} />}
              onClick={modal.open}
            >
              {t((d) => d.settings.enable_geolocation)}
            </Button>
          </Label>
        )}
        {/* NATIVE ONLY - SHOW TURN OFF BUTTON WHEN PLUGIN IS ENABLED */}
        {isNativePlatform && geolocation.isPluginEnabled && (
          <Label text={t((d) => d.settings.geo_location)}>
            <Button
              className={cn("w-full")}
              buttonType="warning"
              disabled={!geolocation.isPluginReady}
              icon={<IconLocation className={cn("h-4")} />}
              onClick={async () => {
                await geolocation.stop();
              }}
            >
              {t((d) => d.settings.disable_geolocation)}
            </Button>
          </Label>
        )}
        {auth.isAccountAdmin && (
          <div>
            <Spacer h={4} />
            <h4>{t((d) => d.settings.headings.forget_account)}</h4>
            <HorizontalLine type={"gray"} spaceWidth={1} />
            {t((d) => d.settings.forget_account_desc)}
            <Spacer h={2} />
            <Button type={"button"} buttonType={"warning-outline"} onClick={() => history.push(Routes.FORGET_ACCOUNT)}>
              {t((d) => d.settings.forget_account_button)}
            </Button>
          </div>
        )}
        {/* NATIVE ONLY - DISPLAY LOCATION APP VERSION AND BUILD ON NATIVE DEVICES */}
        {isNativePlatform && appInfo !== undefined && (
          <>
            <Spacer h={4} />
            <h4>{t((d) => d.settings.headings.tech_details)}</h4>
            <HorizontalLine type={"gray"} spaceWidth={1} />
            <MetaItem name="App Version" value={appInfo.version} />
            <MetaItem name="Build #" value={appInfo.build} />
          </>
        )}
      </Page>
      <EnableLocationModal isActive={modal.isActive} closeHandler={modal.close} geolocation={geolocation} />
    </>
  );
};
