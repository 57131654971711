import React from "react";
import cn from "classnames";
import { Select } from "@brenger/react";
import { Locale } from "@brenger/api-client";

import { SUPPORTED_LOCALE_MAP, localeNormalizer } from "../../utils";
import { useTranslation } from "../../hooks";

export const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <Select
      className={cn("w-full")}
      // NOTE: use `normalizeLocale` to deal with edge cases where locales with underscores instead of hyphens
      // enter and get persisted in the system. Ensures that we can still control the language select even with
      // wrongly formatted locale.
      value={localeNormalizer.parseLocale(i18n.locale)}
      onChange={(nextLocale) => {
        // Totally safe to type cast, as these new values are coming from the typed map.
        i18n.changeLocale(nextLocale as Locale);
      }}
    >
      {[...SUPPORTED_LOCALE_MAP.entries()].map(([locale, localePretty]) => {
        return (
          <option key={locale} value={locale}>
            {localePretty}
          </option>
        );
      })}
    </Select>
  );
};
