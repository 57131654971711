import * as React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Strong, IconSmile } from "@brenger/react";

import { Routes } from "../../utils";

interface Props {
  value: React.ReactText;
  label: string;
  to: Routes;
}

export const DashboardCard: React.FC<Props> = (props) => {
  return (
    <Link to={props.to}>
      <div className={cn("p-4", "rounded-md", "flex", "flex-col", "justify-between", "bg-white", "h-full")}>
        <div>{props.label}</div>
        {/* set min width to prevent any number from 0 - 1000 from touching label */}
        <div className={cn("flex", "justify-between", "items-center", "pt-2")}>
          <Strong>{props.value}</Strong>
          <IconSmile className={cn("text-blue-600", "h-4")} />
        </div>
      </div>
    </Link>
  );
};
