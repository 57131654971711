import { Emphasis, Spacer } from "@brenger/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../hooks";
import { UserVehicleForm } from "../../profile/UserVehicleForm";
import { NewPageWrapper } from "../layout/PageWrapper";
import { IonicRoutes } from "../../../utils";

export const MoreVehiclesCreate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <NewPageWrapper title={t((d) => d.more.vehicles)} backUrl={IonicRoutes.more.vehicles()}>
      <div>
        <Emphasis>{t((d) => d.vehicles.sub_title)}</Emphasis>
      </div>
      <Spacer h={4} />
      <UserVehicleForm onGoBack={() => history.push(IonicRoutes.more.vehicles())} />
    </NewPageWrapper>
  );
};
