import { useQuery } from "react-query";
import { getIdFromIri } from "@brenger/utils";

import { coreClient, CacheKey } from "../utils";

/**
 * Motivation: To get the full context for a given TJAL, we often need to make
 * dependent fetches for the underlying TJ + the TJ's route.
 */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const useTjalContext = (tjalId?: string) => {
  /**
   * 1. Fetch the TJAL
   */
  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, tjalId],
    () => coreClient.transportJobAccountLinks.retrieve({ id: tjalId as string }),
    {
      enabled: !!tjalId,
    }
  );

  /**
   * 2. Parse TJ id from the TJAL and make dependent TJ request.
   */
  const tjId = getIdFromIri(tjal.data?.transport_job) || "";
  const tj = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB, tjId],
    () => coreClient.transportJobs.retrieve({ id: tjId as string }),
    {
      enabled: !!tjId,
    }
  );

  /**
   * 3. Parse route id from the TJ and make dependent Route request.
   */
  const routeId = getIdFromIri(tj.data?.transport_route);
  const route = useQuery(
    [CacheKey.RETRIEVE_ROUTE, routeId],
    () => coreClient.routes.retrieve({ id: routeId as string }),
    {
      enabled: !!routeId && !!tj.data?.bundled,
    }
  );

  return { tjal, tj, route };
};
