import { Button, H3, InputCheckbox, Label, Spacer, IconSync } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { BreadcrumbNav, DriverSelect, Page } from "../../../components";
import { useAuth, usePlanningFilter, useSelector, useTranslation } from "../../../hooks";
import { planningFilterActions } from "../../../store/planning/filter";
import { Routes } from "../../../utils";

// Redux store expects "null" when no particular user is selected.
// This is a temp value to satisfy the Select interface. See implementation below.
const ALL_USERS = "--";

export const PlanningJobListFilter: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const breadcrumbs = [{ to: Routes.PLANNING_JOB_LIST, text: t((d) => d.app.tabs.planning) }, { text: "filter" }];
  const history = useHistory();
  const { tjals, filteredTjalsCount } = usePlanningFilter();
  const { tjalStates, user } = useSelector((state) => state.planningFilter);
  const dispatch = useDispatch();

  if (!tjals.data || tjals.error) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tjals.isLoading}
        errorText={(tjals.error as Error)?.message}
      />
    );
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          onClick={() => history.push(Routes.PLANNING_JOB_LIST)}
          icon={<IconSync className={cn("h-4")} />}
        >
          {t((d, withTemplate) => {
            return withTemplate(d.planning.filter.show, {
              number: filteredTjalsCount,
            });
          })}
        </Button>
      }
    >
      <H3>{t((d) => d.planning.filter.status || "n/a")}</H3>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.accepted)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("accepted")}
          onChange={() => dispatch(planningFilterActions.setTjalState("accepted"))}
        />
      </Label>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.pending)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("pending")}
          onChange={() => dispatch(planningFilterActions.setTjalState("pending"))}
        />
      </Label>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.rejected)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("rejected")}
          onChange={() => dispatch(planningFilterActions.setTjalState("rejected"))}
        />
      </Label>
      <DriverSelect
        className={cn("mt-4")}
        disableSuspendedDrivers={true}
        labelText={t((d) => d.profile.select_courier)}
        value={user || ALL_USERS}
        placeholder={{ text: t((d) => d.planning.labels.all_couriers), value: ALL_USERS }}
        accountId={getIdFromIri(auth.user?.account)}
        onChange={({ userIRI }) => {
          // The redux store expects null when TJALs for "all users" are displayed. Therefore,
          // must check if all users string is selected and then coerce accordingly.
          const sanitizedUser = userIRI === ALL_USERS ? null : userIRI;
          dispatch(planningFilterActions.setUser(sanitizedUser));
        }}
      />
      {/* Add spacer to give ample room between last checkbox and sticky footer */}
      <Spacer h={8} />
    </Page>
  );
};
