import * as React from "react";

import { useBrowser, useDeviceInfo, useTranslation } from "../../hooks";

import { Locale } from "@brenger/api-client";
import { Button, IconPhoneClassic, IconWhatsapp, btnCns, btnSizeCns, btnTypeCns } from "@brenger/react";
import { createWhatsAppUrl } from "@brenger/utils";
import cn from "classnames";
import { isAfter, isBefore, set } from "date-fns";
import { NewLayout, NewPageWrapper } from "../";
import { FaqSection } from "./FaqSection";

export const Support: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpenMessageVisible, setIsOpenMessageVisible] = React.useState(false);
  const device = useDeviceInfo();
  const browser = useBrowser();
  const settings = getSupportSettings(i18n.locale);
  const isChatOpen = settings.open.isChatOpen;
  const isPhoneOpen = settings.open.isPhoneOpen;
  const isOpen = isChatOpen || isPhoneOpen;
  const status = `<span class="${isOpen ? "text-green-400" : "text-red-600"}">${t(
    (d) => d.support[isOpen ? "status_open" : "status_closed"]
  )}</span>`;
  return (
    <NewLayout>
      <NewPageWrapper>
        <div
          className={cn("text-blue-600", "-m-4", "px-4", "pt-12", "pb-8", "md:m-0", "md:rounded-2xl", "md:p-8")}
          style={{ backgroundColor: "#ECF5FF" }}
        >
          <h3>{t((d) => d.support.heading)}</h3>
          <div
            className="h4 br-font"
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: `${t((d, withTemplate) => withTemplate(d.support.sub_heading, { status }))}${isOpen ? "*" : ""}`,
            }}
          />
          <div className={cn("pt-4", "flex", "max-w-md", "relative")}>
            {/* Catch clicks on disabled buttons so we can easily show a message to the user */}
            <div
              onClick={() => setIsOpenMessageVisible(true)}
              className={cn("absolute", "inset-0", "z-10", { hidden: isOpen })}
              style={{ left: isOpen && !isPhoneOpen ? "50%" : undefined }}
            />
            {device?.platform === "web" && (
              <>
                <div className="flex lg:hidden w-full">
                  <WhatsappMobileButton disabled={!isChatOpen} whatsapp={settings.whatsapp} />
                  <CallUsMobileButton disabled={!isPhoneOpen} phone={settings.phone} />
                </div>
                <div className={cn("hidden", "lg:flex", "w-full")}>
                  <Button
                    onClick={() => browser.open({ url: `https://web.whatsapp.com/send?phone=${settings.whatsapp}` })}
                    icon={<IconWhatsapp />}
                    className={cn("w-full", "mr-2")}
                    type="button"
                    buttonType={"secondary"}
                    disabled={!isChatOpen}
                  >
                    {t((d) => d.support.start_chat)}
                  </Button>
                  <div className={cn(btnCns, btnSizeCns.md, btnTypeCns.primary, "w-full")}>
                    <small className="br-font-bold flex items-center gap-1 selection:bg-yellow-300">
                      <IconPhoneClassic />
                      {settings.phone}
                    </small>
                  </div>
                </div>
              </>
            )}
            {["ios", "android"].includes(device?.platform || "") && (
              <>
                <WhatsappMobileButton disabled={!isChatOpen} whatsapp={settings.whatsapp} />
                <CallUsMobileButton disabled={!isPhoneOpen} phone={settings.phone} />
              </>
            )}
          </div>
          {isOpenMessageVisible && (
            <div className="mt-4">
              {!isPhoneOpen &&
                isChatOpen &&
                t((d, withTemplate) =>
                  withTemplate(d.support.phone_is_available_at, { time: settings.nextDay.phoneOpen })
                )}
              {!isPhoneOpen &&
                !isChatOpen &&
                t((d, withTemplate) => withTemplate(d.support.is_available_at, { time: settings.nextDay.chatOpen }))}
            </div>
          )}
        </div>
        <div className="mt-12">
          <FaqSection />
        </div>
        {isOpen && (
          <div className="mt-12">
            {"* "}
            {t((d) => d.support.business_hours_disclaimer)}
          </div>
        )}
      </NewPageWrapper>
    </NewLayout>
  );
};

const WhatsappMobileButton: React.FC<{ disabled: boolean; whatsapp: string }> = ({ disabled, whatsapp }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => window.open(createWhatsAppUrl(whatsapp))}
      className={cn("w-full", "mr-2")}
      icon={<IconWhatsapp />}
      type="button"
      buttonType={"secondary"}
      disabled={disabled}
    >
      {t((d) => d.support.start_chat)}
    </Button>
  );
};

const CallUsMobileButton: React.FC<{ disabled: boolean; phone: string }> = ({ disabled, phone }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => window.open(`tel:${phone}`, "_blank")}
      className={cn("w-full", "mr-2")}
      icon={<IconPhoneClassic />}
      type="button"
      buttonType={"primary"}
      disabled={disabled}
    >
      {t((d) => d.support.call_us)}
    </Button>
  );
};

interface Open {
  chatOpen: string;
  chatClosed: string;
  phoneOpen: string;
  phoneClosed: string;
}

interface IsOpen extends Open {
  isChatOpen: boolean;
  isPhoneOpen: boolean;
}

interface SupportSetting {
  whatsapp: string;
  phone: string;
  open: IsOpen;
  nextDay: Open;
}

const getSupportSettings = (locale: Locale): SupportSetting => {
  // Cast to string to hush TS using it as index on a object (without losing locale type as argument)
  // We fall back to dutch settings
  const whatsappNL = "+31633748236";
  const whatsapp =
    {
      "de-DE": "+4928229113057",
    }[locale as "de-DE"] || whatsappNL; // Fallback to NL

  const phone = "+31858881934"; // Not lang specific

  const now = new Date();
  const standard = {
    chatOpen: "08:00",
    chatClosed: "22:00",
    phoneOpen: "08:00",
    phoneClosed: "22:00",
  };
  const weekend = {
    chatOpen: "09:00",
    chatClosed: "21:00",
    phoneOpen: "09:00",
    phoneClosed: "21:00",
  };
  const specialDays = {
    // Saturday
    6: weekend,
    // Sunday
    0: weekend,
  };
  // the good old JS days: 0 - 6
  const dayNumber = now.getDay();
  // Special days are sat and sun, else fallback to weekday
  const supportHoursToday = (specialDays[dayNumber as 0 | 6] || standard) as Open;
  // When saturday (=== 6) then next day is 0, else it is day + 1
  const nextDayNumber = dayNumber === 6 ? 0 : dayNumber + 1;
  // Same trick different day
  const supportHoursNextDay = (specialDays[nextDayNumber as 0 | 6] || standard) as Open;

  const chatOpenTime = supportHoursToday?.chatOpen.split(":").map((time) => Number(time));
  const chatOpen = set(new Date(), {
    hours: chatOpenTime?.[0],
    minutes: chatOpenTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const chatClosedTime = supportHoursToday?.chatClosed.split(":").map((time) => Number(time));
  const chatClosed = set(new Date(), {
    hours: chatClosedTime?.[0],
    minutes: chatClosedTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const phoneOpenTime = supportHoursToday?.phoneOpen.split(":").map((time) => Number(time));
  const phoneOpen = set(new Date(), {
    hours: phoneOpenTime?.[0],
    minutes: phoneOpenTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const phoneClosedTime = supportHoursToday?.phoneClosed.split(":").map((time) => Number(time));
  const phoneClosed = set(new Date(), {
    hours: phoneClosedTime?.[0],
    minutes: phoneClosedTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });

  return {
    phone,
    whatsapp,
    open: {
      ...supportHoursToday,
      isChatOpen: isAfter(now, chatOpen) && isBefore(now, chatClosed),
      isPhoneOpen: isAfter(now, phoneOpen) && isBefore(now, phoneClosed),
    },
    nextDay: supportHoursNextDay,
  };
};
