import { Button, useModalState } from "@brenger/react";
import cn from "classnames";
import { Modal } from "../components";
import { useTranslation } from "../hooks";
import OneSignalNative from "onesignal-cordova-plugin";
import React from "react";

export const AppPushPrompt: React.FC = () => {
  const { t } = useTranslation();
  const appPromptModal = useModalState(true);

  return (
    <Modal key={"AppPrompt"} isActive={appPromptModal.isActive} closeHandler={appPromptModal.close}>
      <h3>{t((d) => d.web_push_modal.title)}</h3>
      <div>{t((d) => d.web_push_modal.body)}</div>
      <div className={cn("flex", "justify-center")}>
        <Button
          className={"mx-4"}
          buttonType="primary"
          onClick={() => {
            appPromptModal.close();
            OneSignalNative.promptForPushNotificationsWithUserResponse();
          }}
        >
          {t((d) => d.web_push_modal.yes)}
        </Button>
      </div>
    </Modal>
  );
};
