/**
 * BIG FAT NOTE: THIS FILE IS COPIED TO DAYROUTE STOP DETAILS
 * - WE HAVEN'T DECIDED YET HOW TO MERGE PLANNING AND DAYROUTES
 * - DAYROUTE COULD REPLACE PLANNING SECTION IN THE FUTURE
 * - DECIDED TO KEEP BOTH UNTANGLED SO THAT THEY COULD EASILY LIVE NEXT TO EACH OTHER UNTIL
 * THERE IS A WINNER OR OTHER STRATEGY WHERE THEY BOTH SERVE A DIFF GOAL
 * - KEEP THEM BOTH IN SYNC, AS IN SAME FUNCTIONALITIES, UNTIL THAT POINT
 */

import { ItemSet } from "@brenger/api-client";
import {
  Button,
  Card,
  H3,
  H4,
  H5,
  HorizontalLine,
  IconArrowLeft,
  IconArrowRight,
  IconEditPencil,
  IconHorizontalDots,
  IconWhatsapp,
  Message,
  Spacer,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  AddressDetails,
  BreadcrumbNav,
  ClientReferences,
  ConfirmationSignatures,
  ContactDetails,
  ExternalInvoices,
  ItemDetails,
  ItemSetAttributes,
  MetaItem,
  Page,
  PhotoProofs,
  StaticMap,
  StopConfirmedAtPill,
  StopDetailPills,
  TooltipDressCode,
  UserGeneratedContent,
  ImageGallery,
} from "../../../components";
import { FormatCommitedStopTime } from "../../../components/data/DayRouteStopTime";
import {
  ActionSheetOption,
  useActionSheet,
  useAuth,
  useCanConfirmStop,
  useMapContext,
  usePlanningParams,
  useStopContext,
  useTranslation,
} from "../../../hooks";
import {
  CacheKey,
  coreClient,
  getStopContactActions,
  getStopOnTimeActions,
  getStopRunningLateActions,
} from "../../../utils";
import { TransportJobEditStop } from "../../editstop/TransportJob";

export const PlanningStopDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const showActionSheet = useActionSheet();
  const params = usePlanningParams();
  const auth = useAuth();

  // Initialize breadcrumbs. Populate with missing data when stopContext has fully resolved further down.
  const breadcrumbs = [
    { to: "/planning", text: "planning" },
    { to: `/planning/${params.tjal_id}`, text: "--" },
    { text: "--" },
  ];

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );

  const itemSets = stop.data?.item_sets as ItemSet[] | undefined;

  const stopContext = useStopContext(stop.data || null);

  const { canConfirmStop, cannotConfirmStopReason, messageType } = useCanConfirmStop({
    iri: stop.data?.["@id"],
    showSuccessMessage: false,
  });

  const mapContext = useMapContext({ presentation: "planning", tj: stopContext.transportJob, stopId: params.stop_id });

  if (!stop.data || stop.isError || !itemSets) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={stop.isLoading}
        errorText={(stop.error as Error)?.message}
      />
    );
  }
  // When we have gathered the stop context and the address, update the breadcrumbs.
  if (typeof stopContext.stopNumber === "number" && stopContext.address && stopContext.transportJob) {
    breadcrumbs[1].text = `#${stopContext.transportJob.short_id}`;
    breadcrumbs[2].text = `${stopContext.stopNumber}: ${stopContext.address?.locality}`;
  }

  const stopType = stop.data?.["@type"];
  const { phone, email } = stopContext.contact || {};

  /**
   * Set up shared action sheet items
   */
  const runningLateActions = getStopRunningLateActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const onTimeActions = getStopOnTimeActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const contactOptions = getStopContactActions({ phone, email, t });
  /**
   * Set up Action Sheet items related to the Address
   */
  const addressActions: ActionSheetOption[] = [
    {
      title: t((d) => d.planning.actions.open_google_maps),
      onClick: () => window.open(mapContext.googleMapsUrl, "_blank"),
    },
    {
      // Related stop link (pick -> delivery, vice versa)
      title: t((d) => d.transport_job.actions[stopType === "Pickup" ? "view_delivery" : "view_pickup"]),
      onClick: () => history.push(`/planning/${params.tjal_id}/stops/${stopContext.relatedStopId}`),
    },
  ];

  /**
   * Determine if stop is confirmed
   */
  let isConfirmed = false;
  if (stopType === "Pickup") {
    isConfirmed = itemSets.some((d) => !!d.driver_pickup_confirmed_at);
  }
  if (stopType === "Delivery") {
    isConfirmed = itemSets.some((d) => !!d.driver_delivery_confirmed_at);
  }

  return (
    <>
      <Page
        loading={stop.isLoading}
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        stickyFooter={
          <div className={cn("flex")}>
            <div>
              <Button
                className={cn("h-full")}
                buttonType="primary-outline"
                onClick={() => history.push(`/planning/${params.tjal_id}/stops/${stopContext.previousStopId}`)}
                disabled={!stopContext.previousStopId}
              >
                <IconArrowLeft />
              </Button>
            </div>
            <div className={cn("mx-2", "w-full")}>
              <Button
                className={cn("w-full")}
                disabled={!canConfirmStop}
                onClick={() => history.push(`/planning/${params.tjal_id}/stops/${params.stop_id}/confirm`)}
              >
                {t(
                  (d) =>
                    d.transport_job.actions[stop.data?.["@type"] === "Pickup" ? "confirm_pickup" : "confirm_delivery"]
                )}
              </Button>
            </div>
            <div>
              <Button
                className={cn("h-full")}
                buttonType="primary-outline"
                onClick={() => history.push(`/planning/${params.tjal_id}/stops/${stopContext.nextStopId}`)}
                disabled={!stopContext.nextStopId}
              >
                <IconArrowRight />
              </Button>
            </div>
          </div>
        }
      >
        {/* PICKUP OR DELIVERY */}
        <div className={cn("flex", "justify-between", "items-center")}>
          <H5 className={cn("uppercase")}>
            {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
          </H5>
          {!isConfirmed && (
            <div
              className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")}
              onClick={() => history.push(`/planning/${params.tjal_id}/stops/${params.stop_id}/edit`)}
            >
              <IconEditPencil className={cn("mr-1")} /> {t((d) => d.actions.edit)}
            </div>
          )}
        </div>
        <Spacer h={3} />
        <HorizontalLine type="gray" />

        {/* SHORT ADDRESS */}
        <Spacer h={3} />
        <H4>
          <AddressDetails address={stopContext.address} presentation="dayroute-details" />
        </H4>
        <Spacer h={3} />
        {/* COMMITED DATETIME*/}
        <div className={cn("flex", "items-center")}>
          {stopContext.committedDtps && stopContext.committedDtps.length !== 0 && (
            <FormatCommitedStopTime
              textClassname={["mr-2"]}
              start={stopContext.committedDtps[0].start}
              end={stopContext.committedDtps[0].end}
            />
          )}
        </div>
        <Spacer h={2} />
        <div>
          <StopConfirmedAtPill stopType={stopType} itemSets={itemSets} committedDtps={stopContext.committedDtps} />
        </div>

        {!canConfirmStop && cannotConfirmStopReason && (
          <Message type={messageType} className={cn("my-4")}>
            {cannotConfirmStopReason}
          </Message>
        )}

        {/* RUNNING LATE CARD*/}
        {phone && canConfirmStop && (
          <Card type="light-blue" className={cn("mt-2", "no-print")}>
            <div>{t((d) => d.planning.running_late.desc)}</div>

            <div className={cn("flex", "mt-3")}>
              <Button
                size="md"
                icon={<IconWhatsapp />}
                className={cn("w-full", "whitespace-nowrap")}
                iconPos="left"
                onClick={() => {
                  showActionSheet({
                    title: t((d) => d.planning.on_time.title),
                    message: t((d) => d.actions.select_option),
                    options: onTimeActions,
                  });
                }}
              >
                {t((d) => d.planning.on_time.cta)}
              </Button>
              <Spacer w={2} />
              <Button
                size="md"
                icon={<IconWhatsapp />}
                className={cn("w-full", "whitespace-nowrap")}
                iconPos="left"
                onClick={() => {
                  showActionSheet({
                    title: t((d) => d.planning.running_late.title),
                    message: t((d) => d.actions.select_option),
                    options: runningLateActions,
                  });
                }}
              >
                {t((d) => d.planning.running_late.cta)}
              </Button>
            </div>
          </Card>
        )}
        {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
          <>
            <Spacer h={2} />
            <TooltipDressCode />
          </>
        )}
        <Spacer h={3} />
        <StaticMap zoom="15" markers={mapContext.markers} googleMapsUrl={mapContext.googleMapsUrl} />
        <Spacer h={8} />

        <H3>
          {t((d) =>
            stopType === "Pickup"
              ? d.transport_job.headings.pickup_information
              : d.transport_job.headings.delivery_information
          )}
        </H3>
        <Spacer h={3} />
        <HorizontalLine type="gray" />
        <Spacer h={3} />
        <StopDetailPills details={stop.data.details} itemSets={itemSets} />
        <Spacer h={3} />
        <H5>{t((d) => d.transport_job.id)}</H5>
        <div>{`#${getIdFromIri(stop.data.transport_job)?.slice(-6)}`}</div>
        <Spacer h={3} />
        <H5>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</H5>

        {/* TITLE & DESCRIPTION OF EACH ITEM SET FOR THIS STOP */}
        {itemSets.map((itemSet, idx) => {
          return (
            <div className={cn("mb-2", "flex", "flex-col")} key={idx}>
              <div>{itemSet.title}</div>
              {itemSet.description && <div> {itemSet.description}</div>}
              {itemSet.assembly_description && (
                <MetaItem
                  name={t((d) => d.transport_job.stop_details.services.assembly)}
                  value={<UserGeneratedContent text={itemSet.assembly_description} />}
                />
              )}
              <ClientReferences itemSets={[itemSet]} />
              <ItemSetAttributes itemSets={[itemSet]} />
              <ItemDetails itemSets={[itemSet]} />
              <ExternalInvoices itemSets={[itemSet]} />
            </div>
          );
        })}

        {/* ADDRESS DETAILS + ASSOCIATED ACTIONS */}
        <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
          <H5>{t((d) => d.transport_job.headings.address)}</H5>
          <button
            className={cn("px-1", "border", "rounded-full", "border-gray-800")}
            onClick={() => {
              showActionSheet({
                title: t((d) => d.actions.more),
                message: t((d) => d.actions.select_option),
                options: addressActions,
              });
            }}
          >
            <IconHorizontalDots />
          </button>
        </div>
        <AddressDetails
          address={stopContext.address}
          instructions={stop.data.details.instructions}
          presentation="planning"
        />
        {/* NOT EVERY STOP HAS A CONTACT */}
        {stopContext.contact && (
          <>
            <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
              <H5>{t((d) => d.transport_job.headings.contact)}</H5>
              <button
                className={cn("px-1", "border", "rounded-full", "border-gray-800")}
                onClick={() => {
                  showActionSheet({
                    title: t((d) => d.actions.more),
                    message: t((d) => d.actions.select_option),
                    options: contactOptions,
                  });
                }}
              >
                <IconHorizontalDots />
              </button>
            </div>
            <ContactDetails contact={stopContext.contact} companyName={stopContext.companyName} />
          </>
        )}
        {/* SIGNATURES */}
        <Spacer h={3} />
        <H5>{t((d) => d.transport_job.headings.signatures)}</H5>
        <ConfirmationSignatures
          itemSets={itemSets}
          stopType={stopType}
          emptyStateText={t((d) => d.app.empty_state.generic)}
        />
        {/* PHOTO PROOFS */}
        <Spacer h={3} />
        <H5>{t((d) => d.transport_job.headings.photo_proofs)}</H5>
        <PhotoProofs itemSets={itemSets} stopType={stopType} emptyStateText={t((d) => d.app.empty_state.generic)} />
        {/* JOB ITEM IMAGES */}
        <Spacer h={3} />
        <ImageGallery itemSets={itemSets} />
        <Spacer h={3} />
      </Page>
      <TransportJobEditStop />
    </>
  );
};
