import { Account, TransportJob, TransportJobAccountLink } from "@brenger/api-client";
import { Button, H2, IconCheck, InputRadio, Label, Message, Select, Spacer, Strong, Textarea } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { startOfToday } from "date-fns";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import * as React from "react";
import { useMutation } from "react-query";
import {
  Breadcrumb,
  BreadcrumbNav,
  ClaimJobAgreement,
  ClaimJobMessagePreview,
  DriverSelect,
  Grid,
  Options,
  Page,
} from "../../../components";
import {
  useAuth,
  useConfirmModal,
  useCreateLinkError,
  useForm,
  useFormatDate,
  useFormatTimeframe,
  useTranslation,
  useUserGeneratedContent,
} from "../../../hooks";
import { coreClient, getDateTimeInUTC, intervalsForDateTimePeriod } from "../../../utils";

interface Props {
  breadcrumbs: Breadcrumb[];
  tj: TransportJob;
  // The claim cta can either state "make an offer" or "claim job" depending on whether it's directly claimable.
  claimCta: string;
  onSuccessFullyClaimed(tjal: TransportJobAccountLink): void;
}

// Each interval is 30 minuntes
const INTERVAL_DURATION = 30;

export const ClaimJobWithAvailableDates: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const formatDateFull = useFormatDate("date-full");
  const formatHourMinute = useFormatDate("hour-minute");
  const formatTimeframe = useFormatTimeframe();

  // Only ONE item_set for these types of jobs, therefore it is safe to pluck out first one.
  const [assemblyDescriptionRaw] = props.tj.item_sets.map((is) => is.assembly_description);
  const assemblyDescription = useUserGeneratedContent(assemblyDescriptionRaw);

  // Set up form to capture user inputs
  const form = useForm({
    initialState: {
      driverUser: "",
      pickupDateTimePeriod: "",
      deliveryDateTimePeriod: "",
      pickupStart: "",
      pickupEnd: "",
      deliveryStart: "",
      deliveryEnd: "",
      driverMessage: "",
    },
    // Add validators for required fields
    validators: {
      driverUser: (val) => !val,
      pickupDateTimePeriod: (val) => !val,
      deliveryDateTimePeriod: (val) => !val,
      pickupStart: (val) => !val,
      pickupEnd: (val) => !val,
      deliveryStart: (val) => !val,
      deliveryEnd: (val) => !val,
    },
  });

  React.useEffect(() => {
    // Pre-select the current user as the assumed driverUser who will be assigned to this job.
    auth.user && form.set({ driverUser: auth.user["@id"] });
  }, [auth.user]);

  const createLink = useMutation(coreClient.transportJobAccountLinks.create, {
    onSuccess: props.onSuccessFullyClaimed,
  });

  const createLinkError = useCreateLinkError(createLink.error as Error);

  const [ConfirmModal, activateConfirmModal] = useConfirmModal(() => {
    // On success callback when user agrees to confirm modal.
    createLink.mutate({
      driver_user: form.data.driverUser.value,
      driver_account: (auth.user?.account as Account | undefined)?.["@id"] || "",
      transport_job: props.tj["@id"],
      driver_message_body: form.data.driverMessage.value,
      pickup_commitments: [
        {
          pickup: pickup["@id"],
          committed_datetime_period: {
            start: getDateTimeInUTC(form.data.pickupStart.value),
            end: getDateTimeInUTC(form.data.pickupEnd.value),
          },
        },
      ],
      delivery_commitments: [
        {
          delivery: delivery["@id"],
          committed_datetime_period: {
            start: getDateTimeInUTC(form.data.deliveryStart.value),
            end: getDateTimeInUTC(form.data.deliveryEnd.value),
          },
        },
      ],
    });
  });

  // Only one pickup in a regualar transport job, so just grab the first one. Nice and easy.
  const [pickup] = props.tj.pickups;
  const pickupAvailableDTPs = pickup.available_datetime_periods.filter(
    (dtp) => !isBefore(parseISO(dtp.start), startOfToday())
  );
  // Use the form data to find the select avaialble datetime period.
  const selectedPickupPeriod = pickup.available_datetime_periods.find(
    ({ start }) => start === form.data.pickupDateTimePeriod.value
  );

  // Only one delivery in a regualar transport job, so just grab the first one. Nice and easy.
  const [delivery] = props.tj.deliveries;
  const deliveryAvailableDTPs = delivery.available_datetime_periods.filter(
    (dtp) => !isBefore(parseISO(dtp.start), startOfToday())
  );
  const selectedDeliveryPeriod = delivery.available_datetime_periods.find(
    ({ start }) => start === form.data.deliveryDateTimePeriod.value
  );

  // For this one, it can never be during the last TWO HOURS of the available date time periods.
  const pickupStartIntervals = intervalsForDateTimePeriod(
    selectedPickupPeriod?.start,
    selectedPickupPeriod?.end,
    INTERVAL_DURATION
  )?.slice(0, -3);
  // For this one, the start time must always be clamped by the pickup start time.
  // In addition, the first TWO hours are sliced off (as that is the minumum timeframe between start + end) and
  // everything after FOUR hours from the start time is also sliced off.
  const pickupEndIntervals = intervalsForDateTimePeriod(
    form.data.pickupStart.value,
    selectedPickupPeriod?.end,
    INTERVAL_DURATION
  )?.slice(3, 8);

  // The delivery start intervals must be clamped by the pickup start.
  // Therefore, if delivery start is earlier, use pickup start. Otherwise, use delivery start.
  const isDeliveryStartBeforePickupStart =
    new Date(selectedDeliveryPeriod?.start || Date.now()).getTime() < new Date(form.data.pickupStart.value).getTime();
  const deliveryPeriodStart = isDeliveryStartBeforePickupStart
    ? form.data.pickupStart.value
    : selectedDeliveryPeriod?.start;
  const deliveryStartIntervals = intervalsForDateTimePeriod(
    deliveryPeriodStart,
    selectedDeliveryPeriod?.end,
    INTERVAL_DURATION
  )?.slice(0, -3);
  const deliveryEndIntervals = intervalsForDateTimePeriod(
    form.data.deliveryStart.value,
    selectedDeliveryPeriod?.end,
    INTERVAL_DURATION
  )?.slice(3, 8);

  return (
    <Page
      infoText={
        assemblyDescription.text &&
        t((d, withTemplate) => {
          return withTemplate(d.transport_job.assembly_message, { assembly_description: assemblyDescription.text });
        })
      }
      nav={<BreadcrumbNav breadcrumbs={props.breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          icon={<IconCheck />}
          disabled={form.hasErrors}
          loading={createLink.isLoading}
          onClick={() => {
            createLink.reset();
            activateConfirmModal({
              title: t((d) => d.search.messages.confirm_claim_modal_title),
              message: t((d) => d.search.messages.confirm_claim_regular_job),
            });
          }}
        >
          {props.claimCta}
        </Button>
      }
    >
      <div>{t((d) => d.search.messages.claim_job_instructions)}</div>
      <Spacer h={4} />
      <H2>{t((d) => d.transport_job.pickup)}</H2>
      <Spacer h={2} />
      {/* Map over all the available datetime periods */}
      <Grid cols={{ lg: 2 }} gap={2}>
        {pickupAvailableDTPs.map((period, idx) => {
          const id = `pickup-${period.start}-${period.end}-${idx}`;
          return (
            <Label
              key={id}
              position="right"
              className={cn("capitalize")}
              text={
                <span>
                  <Strong>{formatDateFull(period.start)}</Strong>
                  <span className={cn("ml-1")}>({formatTimeframe({ start: period.start, end: period.end })})</span>
                </span>
              }
            >
              <InputRadio
                value={period.start}
                checked={form.data.pickupDateTimePeriod.value === period.start}
                onChange={(pickupDateTimePeriod) => form.set({ pickupDateTimePeriod, deliveryDateTimePeriod: "" })}
              />
            </Label>
          );
        })}
      </Grid>
      <Spacer h={2} />
      <div className={cn("grid", "grid-cols-2", "gap-4")}>
        {/* FROM */}
        <Label text={t((d) => d.search.labels.from)}>
          <Select
            className={cn("w-full")}
            disabled={!form.data.pickupDateTimePeriod.value}
            value={form.data.pickupStart.value}
            onChange={(pickupStart) => form.set({ pickupStart, pickupEnd: "", deliveryStart: "", deliveryEnd: "" })}
          >
            <Options placeholderText="--">
              {pickupStartIntervals?.map((dateTimePeriod, idx) => {
                return (
                  <option key={`${dateTimePeriod.start}-${idx}`} value={dateTimePeriod.start}>
                    {formatHourMinute(dateTimePeriod.start)}
                  </option>
                );
              })}
            </Options>
          </Select>
        </Label>
        {/* UNTIL */}
        <Label text={t((d) => d.search.labels.until)}>
          <Select
            className={cn("w-full")}
            disabled={!form.data.pickupDateTimePeriod.value || !form.data.pickupStart.value}
            value={form.data.pickupEnd.value}
            onChange={(pickupEnd) => form.set({ pickupEnd })}
          >
            <Options placeholderText="--">
              {pickupEndIntervals?.map((dateTimePeriod, idx) => {
                return (
                  <option key={`${dateTimePeriod.end}-${idx}`} value={dateTimePeriod.end}>
                    {formatHourMinute(dateTimePeriod.end)}
                  </option>
                );
              })}
            </Options>
          </Select>
        </Label>
      </div>
      <Spacer h={6} />
      <H2>{t((d) => d.transport_job.delivery)}</H2>
      <Spacer h={2} />
      {/* Map over all the available datetime periods */}
      <Grid cols={{ lg: 2 }} gap={2}>
        {deliveryAvailableDTPs.map((period, idx) => {
          const id = `delivery-${period.start}-${period.end}-${idx}`;
          return (
            <Label
              key={id}
              className={cn("capitalize")}
              text={
                <span>
                  <Strong>{formatDateFull(period.start)}</Strong>
                  <span className={cn("ml-1")}>({formatTimeframe({ start: period.start, end: period.end })})</span>
                </span>
              }
              position="right"
            >
              <InputRadio
                value={period.start}
                disabled={
                  !form.data.pickupDateTimePeriod.value || !form.data.pickupStart.value || !form.data.pickupEnd.value
                }
                checked={form.data.deliveryDateTimePeriod.value === period.start}
                onChange={(deliveryDateTimePeriod) => form.set({ deliveryDateTimePeriod })}
              />
            </Label>
          );
        })}
      </Grid>
      <Spacer h={2} />
      <div className={cn("grid", "grid-cols-2", "gap-4")}>
        {/* FROM */}
        <Label text={t((d) => d.search.labels.from)}>
          <Select
            className={cn("w-full")}
            value={form.data.deliveryStart.value}
            disabled={!form.data.pickupDateTimePeriod.value || !form.data.deliveryDateTimePeriod.value}
            onChange={(deliveryStart) => form.set({ deliveryStart, deliveryEnd: "" })}
          >
            <Options placeholderText="--">
              {deliveryStartIntervals?.map((dateTimePeriod, idx) => {
                return (
                  <option key={`${dateTimePeriod.start}-${idx}`} value={dateTimePeriod.start}>
                    {formatHourMinute(dateTimePeriod.start)}
                  </option>
                );
              })}
            </Options>
          </Select>
        </Label>
        {/* UNTIL */}
        <Label text={t((d) => d.search.labels.until)}>
          <Select
            className={cn("w-full")}
            value={form.data.deliveryEnd.value}
            onChange={(deliveryEnd) => form.set({ deliveryEnd })}
            disabled={
              !form.data.pickupDateTimePeriod.value ||
              !form.data.deliveryDateTimePeriod.value ||
              !form.data.deliveryStart.value
            }
          >
            <Options placeholderText="--">
              {deliveryEndIntervals?.map((dateTimePeriod, idx) => {
                return (
                  <option key={`${dateTimePeriod.end}-${idx}`} value={dateTimePeriod.end}>
                    {formatHourMinute(dateTimePeriod.end)}
                  </option>
                );
              })}
            </Options>
          </Select>
        </Label>
      </div>
      <DriverSelect
        disableSuspendedDrivers={true}
        className={cn("mt-6")}
        labelText={t((d) => d.search.labels.select_driver)}
        accountId={getIdFromIri(auth.user?.account)}
        value={form.data.driverUser.value}
        onChange={({ userIRI }) => form.set({ driverUser: userIRI })}
      />
      <Spacer h={6} />
      <Label text={t((d) => d.search.labels.message)}>
        <Textarea
          className={cn("w-full")}
          onChange={(driverMessage) => form.set({ driverMessage })}
          value={form.data.driverMessage.value}
        />
      </Label>
      {/* WHEN FORM IS COMPLETE, SHOW MESSAGE PREVIEW */}
      {!form.hasErrors && (
        <Label text={t((d) => d.search.labels.message_preview)} className={cn("mt-4")}>
          <ClaimJobMessagePreview
            // @TODO replace "klant" - but figure out where to get customer name from?
            customerName="klant"
            pickupDate={form.data.pickupDateTimePeriod.value}
            pickupStart={form.data.pickupStart.value}
            pickupEnd={form.data.pickupEnd.value}
            deliveryDate={form.data.deliveryDateTimePeriod.value}
            deliveryStart={form.data.deliveryStart.value}
            deliveryEnd={form.data.deliveryEnd.value}
            driverMessage={form.data.driverMessage.value}
            driverName={(auth.user?.account as Account)?.name}
          />
        </Label>
      )}
      {createLinkError && (
        <Message className={cn("mt-4")} type="error">
          {createLinkError}
        </Message>
      )}
      <Spacer h={6} />
      <ClaimJobAgreement />
      <ConfirmModal />
    </Page>
  );
};
