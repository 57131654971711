import * as React from "react";
import cn from "classnames";
import { useQuery } from "react-query";
import { H2, H4, Spacer, IconVerified, IconVan } from "@brenger/react";

import { Page, Grid, DashboardCard } from "../../../components";
import { useAuth, useTranslation, useDashboardQuery } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { isBefore, set } from "date-fns";

export const DashboardForUser: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const reviewSummary = useQuery(
    [CacheKey.RETRIEVE_SUMMARY, auth.userId],
    () => coreClient.users.retrieveReviewSummary({ userId: auth.userId as string }),
    {
      enabled: !!auth.userId,
    }
  );

  const allReviewAverage = reviewSummary.data?.all?.average;
  const last25ReviewAverage = reviewSummary.data?.last_25?.average;

  // Call all the jobs data in one batch query
  const jobsData = useDashboardQuery();

  const showOldPunctuality = isBefore(
    new Date(),
    // June 15, 2022 is the deadline, JS month numbers aren't human
    set(new Date(), { year: 2022, month: 5, date: 15, hours: 0, minutes: 0, seconds: 0 })
  );
  return (
    // add parent div to Page component so we can make entire view blue
    <div className={cn("bg-blue-600", "flex-grow", "pb-4")}>
      <Page errorText={(jobsData.error as Error)?.message}>
        <div className={cn("flex", "flex-col", "items-center", "text-white")}>
          <H4>{t((d) => d.dashboard.page_desc)}</H4>
          <H2>Hi {auth.user?.first_name}</H2>
        </div>
        <Spacer h={6} />
        <div className={cn("flex", "justify-center")}>
          <IconVerified className={cn("text-white", "w-12", "h-12")} />
        </div>
        <Spacer h={6} />
        <Grid cols={{ default: 2 }} gap={4}>
          <DashboardCard
            to={Routes.USER_REVIEWS}
            value={allReviewAverage || "--"}
            label={t((d) => d.dashboard.all_time_average_rating)}
          />
          <DashboardCard
            to={Routes.USER_REVIEWS}
            value={last25ReviewAverage || "--"}
            label={t((d) => d.dashboard.last_25_average_rating)}
          />
        </Grid>
        <Spacer h={6} />
        <div className={cn("flex", "justify-center")}>
          <IconVan className={cn("text-white", "w-12", "h-12")} />
        </div>
        <Spacer h={6} />
        <Grid cols={{ default: 2 }} gap={4}>
          <DashboardCard
            to={Routes.PLANNING_JOB_LIST}
            value={jobsData.plannedJobsCount || "--"}
            label={t((d) => d.dashboard.planned)}
          />
          <DashboardCard
            to={Routes.PLANNING_JOB_LIST}
            value={jobsData.pendingJobsCount || "--"}
            label={t((d) => d.dashboard.pending)}
          />
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={jobsData.totalJobsCount || "--"}
            label={t((d) => d.dashboard.transports_total)}
          />
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={jobsData.lastQuarterJobsCount || "--"}
            label={t((d) => d.dashboard.transports_last_90_days)}
          />
          {showOldPunctuality && (
            <DashboardCard
              to={Routes.DELIVERED_JOB_LIST}
              value={
                auth.user?.total_confirmation_success_rate
                  ? `${Math.round(auth.user.total_confirmation_success_rate * 100)}%`
                  : "--"
              }
              label={t((d) => d.dashboard.total_confirmation_success_rate)}
            />
          )}
          <DashboardCard
            to={Routes.DELIVERED_JOB_LIST}
            value={
              auth.user?.total_confirmation_with_proximity_success_rate
                ? `${Math.round(auth.user.total_confirmation_with_proximity_success_rate * 100)}%`
                : "--"
            }
            label={t(
              (d) =>
                d.dashboard[
                  showOldPunctuality
                    ? "total_confirmation_with_proximity_success_rate"
                    : "total_confirmation_with_proximity_success_rate_15june_onward"
                ]
            )}
          />
        </Grid>
      </Page>
    </div>
  );
};
