import { H2, H4, IconBrengerLogo, Spacer, StarRating } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { useAuth, useTranslation } from "../../../hooks";
import { CacheKey, IonicRoutes, coreClient } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";

export const MoreBrengerId: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const reviewSummary = useQuery(
    [CacheKey.RETRIEVE_SUMMARY, auth.userId],
    () => coreClient.users.retrieveReviewSummary({ userId: auth.userId as string }),
    {
      enabled: !!auth.userId,
    }
  );

  const allTimeAverage = reviewSummary.data?.all?.average || 0;
  const allDeliveredJobs = useQuery([CacheKey.LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS, "DELIVERED"], () =>
    coreClient.transportJobAccountLinks.list({
      presentation: "delivered",
      state: ["delivered"],
    })
  );

  return (
    <NewPageWrapper title={t((d) => d.more.brenger_id)} backUrl={IonicRoutes.more.home()}>
      <div className={cn("flex", "items-center", "justify-center", "h-full", "w-full", "rounded-md", "relative")}>
        <div className={cn("flex", "flex-col", "items-center", "w-full")}>
          <IconBrengerLogo className={cn("w-full", "h-12")} />
          <Spacer h={4} />
          <div>
            <div className={cn("relative", "overflow-hidden", "rounded-full", "w-32", "h-32")}>
              <img
                className={cn("h-full", "w-full", "object-cover")}
                src={auth.user?.profile_image || missingImageSrc}
              />
            </div>
          </div>
          <Spacer h={4} />
          <H2>{auth.fullName}</H2>
          <Spacer h={2} />
          <StarRating rating={allTimeAverage} />
          <Spacer h={2} />
          <H4 className={cn("text-white")}>{allTimeAverage}/5</H4>
          <Spacer h={4} />

          <div className={cn("flex", "items-center", "justify-between", "w-full", "max-w-sm")}>
            <H4 className={cn("text-blue-600", "mr-1")}>
              {t((d) => d.dashboard.transports_total)}
              <span>{allDeliveredJobs.data?.["hydra:totalItems"] || "--"}</span>
            </H4>
            <H4 className={cn("text-blue-600", "mr-1")}>
              ID
              <span>{auth.user?.friendly_id}</span>
            </H4>
          </div>
        </div>
      </div>
    </NewPageWrapper>
  );
};
