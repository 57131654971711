import * as Sentry from "@sentry/react";
import { uuid } from "@brenger/utils";

import { Config } from "../config";

class Logger {
  env: string;
  sid: string;

  constructor(env: string | undefined) {
    this.env = env || "development";
    this.sid = uuid();
  }

  public init(): void {
    const productionEnvs = ["production", "android", "ios"];
    // Initialize Sentry logging
    if (productionEnvs.includes(this.env)) {
      Sentry.init({
        environment: Config.NODE_ENV,
        dsn: Config.SENTRY_DSN,
        release: Config.COMMIT_SHA,
        normalizeDepth: 5,
        ignoreErrors: [/SecurityError/i, /NotAllowedError/i, /AbortError/i, /InvalidStateError/i],
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error(message: string, error?: any): string {
    // Print error to dev console
    this.dev("[logger.error]", message);

    const eventId = Sentry.captureMessage(message, { extra: { error } });
    return eventId;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dev(...args: any): void {
    const whitelist = ["development"];

    if (whitelist.includes(this.env)) {
      // eslint-disable-next-line no-console
      console.log("[DEV LOGGER]", ...args);
    }
  }
}

export const logger = new Logger(Config.NODE_ENV);
