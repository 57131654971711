import * as React from "react";
import groupBy from "lodash/groupBy";
import { useMutation } from "react-query";
import { TransportJobAccountLink, PickupCommitment } from "@brenger/api-client";
import { useSelector } from ".";
import { coreClient, CacheKey } from "../utils";
import { useFormatDate } from "./useFormatDate";

/**
 * Filtered TJALS are grouped by date.
 */
interface FilteredTjals {
  [date: string]: TransportJobAccountLink[];
}

interface Params {
  page?: number;
  tj_id?: string;
}

/**
 * This hook takes care of making the request for delivered TJALs as well as
 * filtering the resulting tjals. This hook can be used on the delivered job list page
 * as well as the delivered job list filter page.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDeliveredFilter = (params?: Params) => {
  const deliveredFilter = useSelector((state) => state.deliveredFilter);
  const formatDateForApi = useFormatDate("api-date");
  const page = params?.page || 1;
  const [searchFilter, setSearchFilter] = React.useState<string>("");

  const query = useMutation(
    [CacheKey.LIST_DELIVERED_TRANSPORT_JOB_ACCOUNT_LINKS, page],
    coreClient.transportJobAccountLinks.list
  );
  React.useEffect(() => {
    query.mutate({
      page,
      presentation: "delivered",
      date_from: deliveredFilter.dateFrom,
      date_to: deliveredFilter.dateTo,
      tj_short_id: !searchFilter ? undefined : searchFilter.toLowerCase(),
      order_pickup_date: "desc",
    });
  }, [searchFilter, page]);

  const tjals = query.data?.["hydra:member"];

  /**
   * Filtering all this data is an expensive operation. Therefore, only re-calculate...
   * ...when the dependency list changes.
   */
  const filteredTjals: FilteredTjals = React.useMemo(() => {
    const filterResult = tjals || [];

    /**
     * Group list by dates.
     */
    return groupBy(filterResult, (tjal: TransportJobAccountLink) => {
      const firstPickupCommitment = (tjal.pickup_commitments[0] as PickupCommitment | undefined)
        ?.committed_datetime_period.start;
      return firstPickupCommitment ? formatDateForApi(firstPickupCommitment) : null;
    });
  }, [tjals]);

  return {
    setSearchFilter,
    tjals: query,
    filteredTjals,
    filteredTjalsCount: String(filteredTjals.length) || "0",
  };
};
