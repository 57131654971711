import React from "react";

export const NewLayout: React.FC = ({ children }) => (
  <div>
    {children}
    <div className="lg:absolute lg:inset-0 lg:right-auto lg:w-52 bg-gradient-to-b from-[#0073FA] to-[#005FCE] ">
      Menu
    </div>
  </div>
);
