import { VehicleCreateArgs } from "@brenger/api-client";
import { Emphasis, Spacer } from "@brenger/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Page, TabNav } from "../../../components";
import { useTabNavigation, useTranslation } from "../../../hooks";
import { Routes } from "../../../utils";
import { UserVehicleForm } from "../UserVehicleForm";

export type UserVehiclesCreateForm = VehicleCreateArgs & { image: string };
export const UserVehiclesCreate: React.FC = () => {
  const { t } = useTranslation();
  const tabs = useTabNavigation("profile");
  const history = useHistory();

  return (
    <Page nav={<TabNav tabs={tabs} />}>
      <div>
        <Emphasis>{t((d) => d.vehicles.sub_title)}</Emphasis>
      </div>
      <Spacer h={4} />
      <UserVehicleForm onGoBack={() => history.push(Routes.USER_VEHICLES)} />
    </Page>
  );
};
