import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";

import { useInboxParams, useTranslation } from "../../../hooks";
import { CacheKey, CacheTTL, IonicRoutes, coreClient } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";
import { Conversation } from "./Conversation";
import { ConversationParty } from "./ConversationParty";

export const MoreInboxDetails: React.FC = () => {
  const params = useInboxParams();
  const { t } = useTranslation();
  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, params.tjal_id],
    () => coreClient.transportJobAccountLinks.retrieve({ id: params.tjal_id }),
    {
      cacheTime: CacheTTL.XS * 1000,
    }
  );

  // Handle loading and errors here.
  // This is also a chance to handle incorrect TJAL ID from the URL param before
  // mounting any subsequent components that also rely on the TJAL ID.
  if (!tjal.data || tjal.error) {
    return (
      <NewPageWrapper title={t((d) => d.more.inbox)} backUrl={IonicRoutes.more.inbox()}>
        {(tjal.error as Error)?.message}
      </NewPageWrapper>
    );
  }

  return (
    <NewPageWrapper title={t((d) => d.more.inbox)} backUrl={IonicRoutes.more.inbox()}>
      <div className={cn("mb-4", "sticky", "top-0-with-safe-area-inset", "px-2", "bg-white")}>
        <ConversationParty tjalId={params.tjal_id} />
      </div>
      <div className={cn("px-1")}>
        <Conversation tjalId={params.tjal_id} />
      </div>
    </NewPageWrapper>
  );
};
