import { createWhatsAppUrl } from "@brenger/utils";

import { ActionSheetOption, UseTranslation } from "../hooks";

type GetActions = Pick<UseTranslation, "t">;

interface GetStopTimeMessageActions extends GetActions {
  phone?: string | null;
  driverName?: string | null;
}

export const getStopOnTimeActions = ({ phone, driverName, t }: GetStopTimeMessageActions): ActionSheetOption[] => {
  if (!phone) {
    return [];
  }
  return (["15", "30", "45", "60"] as const).map((option) => {
    const title = t((d) => d.planning.on_time.options[option]);
    return {
      title,
      onClick: () => {
        const text = t((d, withTemplate) => {
          return withTemplate(d.planning.on_time.message, { driver_name: driverName });
        });
        const url = createWhatsAppUrl(phone, `${text}\n${title}`);
        window.open(url, "_blank");
      },
    };
  });
};

export const getStopRunningLateActions = ({ phone, driverName, t }: GetStopTimeMessageActions): ActionSheetOption[] => {
  if (!phone) {
    return [];
  }
  return (["15", "30", "45", "60", "very"] as const).map((option) => {
    const title = t((d) => d.planning.running_late.options[option] || "n/a");
    return {
      title,
      onClick: () => {
        const text = t((d, withTemplate) => {
          return withTemplate(d.planning.on_time.message, { driver_name: driverName });
        });
        const url = createWhatsAppUrl(phone, `${text}\n${title}`);
        window.open(url, "_blank");
      },
    };
  });
};

interface GetStopContactActions extends GetActions {
  phone?: string | null;
  email?: string | null;
}

export const getStopContactActions = ({ phone, email, t }: GetStopContactActions): ActionSheetOption[] => {
  const actions: ActionSheetOption[] = [];
  if (phone) {
    actions.push({
      title: t((d) => d.planning.actions.send_customer_whatsapp),
      onClick: () => window.open(createWhatsAppUrl(phone), "_blank"),
    });
    actions.push({
      title: t((d) => d.planning.actions.call_customer),
      onClick: () => window.open(`tel:${phone}`, "_blank"),
    });
  }

  if (email) {
    actions.push({
      title: t((d) => d.planning.actions.send_customer_email),
      onClick: () => window.open(`mailto:${email}`, "_blank"),
    });
  }
  return actions;
};
