import { Button, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { Modal } from "../../../components";
import { usePersistedState, useTranslation } from "../../../hooks";
import { PersistedStorageKey } from "../../../utils";

interface Props {
  isActive: boolean;
  open(): void;
  close(): void;
}

export const DayRouteExplainModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [hasSeenModal, setHasSeenModal] = usePersistedState<boolean>({
    initialState: true,
    defaultState: false,
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_DAY_ROUTE_NOTIFICATION,
  });

  React.useEffect(() => {
    if (!hasSeenModal) props.open();
  }, [hasSeenModal]);

  const closeModal = (): void => {
    setHasSeenModal(true);
    props.close();
  };

  return (
    <Modal isActive={props.isActive} closeHandler={closeModal}>
      <h3 className={cn("normal-case")}>{t((d) => d.day_route.explanation.title)}</h3>
      <Spacer h={4} />
      <div
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: t((d) => d.day_route.explanation.body),
        }}
      />
      <Spacer h={4} />
      <Button className={cn("w-full")} buttonType="secondary" onClick={closeModal}>
        {t((d) => d.day_route.explanation.continue_to_route)}
      </Button>
    </Modal>
  );
};
