import { Button, H4, InputDate, Label, Spacer, IconSync } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { BreadcrumbNav, Grid, Page } from "../../../components";
import { useDeliveredFilter, useFormatDate, useSelector, useTranslation } from "../../../hooks";
import { deliveredFilterActions } from "../../../store/delivered/filter";
import { INPUT_DATE_MAX, INPUT_DATE_MIN, Routes } from "../../../utils";

export const DeliveredJobListFilter: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ to: Routes.DELIVERED_JOB_LIST, text: t((d) => d.app.tabs.delivered) }, { text: "filter" }];
  const deliveredFilter = useSelector((state) => state.deliveredFilter);
  const dispatch = useDispatch();
  const history = useHistory();
  const { tjals, filteredTjalsCount } = useDeliveredFilter();
  const formatDateForApi = useFormatDate("api-date");

  if (!tjals.data || tjals.isError) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tjals.isLoading}
        errorText={(tjals.error as Error)?.message}
      />
    );
  }

  return (
    <Page
      nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
      stickyFooter={
        <Button
          className={cn("w-full")}
          onClick={() => history.push(Routes.DELIVERED_JOB_LIST)}
          icon={<IconSync className={cn("h-4")} />}
        >
          {t((d, withTemplate) => {
            return withTemplate(d.delivered.filter.show, {
              number: filteredTjalsCount,
            });
          })}
        </Button>
      }
    >
      <H4>{t((d) => d.delivered.filter.date || "n/a")}</H4>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={4}>
        <Label text={t((d) => d.search.labels.from)}>
          <InputDate
            min={formatDateForApi(INPUT_DATE_MIN)}
            max={deliveredFilter.dateTo}
            value={deliveredFilter.dateFrom}
            onChange={(date) => {
              dispatch(deliveredFilterActions.setDateFrom(date));
            }}
          />
        </Label>
        <Label text={t((d) => d.search.labels.until)}>
          <InputDate
            min={deliveredFilter.dateFrom}
            max={formatDateForApi(INPUT_DATE_MAX)}
            value={deliveredFilter.dateTo}
            onChange={(date) => {
              dispatch(deliveredFilterActions.setDateTo(date));
            }}
          />
        </Label>
      </Grid>
    </Page>
  );
};
