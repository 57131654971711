import { DayRouteActivity } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import * as React from "react";
import { useQuery } from "react-query";
import { BreadcrumbNav, Page } from "../../../components";
import { useDayRouteStopParams, UseGeolocation, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { DayRouteConfirmCustomStop } from "./DayRouteConfirmCustomStop";
import { DayRouteConfirmNormalStop } from "./DayRouteConfirmNormalStop";

interface Props {
  geolocation: UseGeolocation;
}

const getDayRouteStopIndex = (currentId: string, activities: DayRouteActivity[] = []): number => {
  const activitiesWithhoutBreak = activities.filter((activity) => activity.type !== "break") || [];
  return activitiesWithhoutBreak.findIndex((activity) => getIdFromIri(activity) === currentId);
};

export const DayRouteConfirmStop: React.FC<Props> = ({ geolocation }) => {
  const { t } = useTranslation();
  const params = useDayRouteStopParams();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );
  const stopIndex = getDayRouteStopIndex(params.stop_id, dayRoute.data?.day_route_activities);
  const activity = dayRoute.data?.day_route_activities?.find((act) => getIdFromIri(act) === params.stop_id);

  const breadcrumbs = [
    { to: Routes.PLANNING_JOB_LIST, text: t((d) => d.app.tabs.planning) },
    { to: `/day-route/${params.date}/${params.user_id}`, text: t((d) => d.day_route.details.title) },
    { to: `/day-route/${params.date}/${params.user_id}/activity/${params.stop_id}`, text: "--" },
    { text: t((d) => d.planning.labels.confirm_stop) },
  ];

  if (activity) {
    breadcrumbs[2].text = `${stopIndex + 1}: ${activity.address?.locality}`;
  }

  if (!activity) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        errorText={(dayRoute.error as Error)?.message || t((d) => d.day_route.activity_not_found)}
        loading={dayRoute.isLoading}
      />
    );
  }
  if (["pickup", "delivery"].includes(activity.type)) {
    return <DayRouteConfirmNormalStop activity={activity} breadcrumbs={breadcrumbs} geolocation={geolocation} />;
  }
  return <DayRouteConfirmCustomStop activity={activity} breadcrumbs={breadcrumbs} />;
};
