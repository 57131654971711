import {
  CarryingHelp,
  Item,
  ItemSet,
  ItemSetServices,
  LargestItem,
  Payout,
  PriceWithVatDetails,
  StopSituation,
} from "@brenger/api-client";
import { IconCheckCircle, IconMultipleStop, IconAdd, Pill, Small, Strong, Timeline } from "@brenger/react";
import { formatKilometers, formatMeters, formatPrice } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { ItemDetails, MetaItem, PillPayoutState } from "..";
import { useTranslation } from "../../hooks";
import { JobPresentation } from "../../utils";
import { StopDetailIcons } from "./StopDetailIcons";

interface Props {
  /**
   * The from locality for the job. In a bundled context, this is usually the first stop.
   */
  from?: string;
  /**
   * The to locality for the job. In a bundled context, this is usually the last stop.
   */
  to?: string;
  /**
   * The total earnings for the driver for completing this job.
   */
  price?: PriceWithVatDetails;
  /**
   * The day on which the transport will occur.
   */
  day?: string | null;
  /**
   * The datetime periods that will appear below the first pickup location.
   * Can be any nullish value, as we do a generic truthy check before rendering.
   * Will not be rendered for bundled jobs.
   */
  pickupPeriod?: string | null;
  /**
   * Renders a bit of UI to indicate multiple pickup periods.
   */
  hasMultiplePickupPeriods?: boolean;
  /**
   * The datetime periods that will appear below the first delivery location.
   * Can be any nullish value, as we do a generic truthy check before rendering.
   * Will not be rendered for bundled jobs.
   */
  deliveryPeriod?: string | null;
  /**
   * Renders a bit of UI to indicate multiple delivery periods.
   */
  hasMultipleDeliveryPeriods?: boolean;
  /**
   * The datetime periods that will appear below the last delivery location.
   */
  string?: string;
  /**
   * Has the job been prepaid. Render a green circle checkmark.
   */
  isPrepaid?: boolean;
  /**
   * Is the job bundled. Render a pill.
   */
  isBundled?: boolean;
  /**
   * Is the job a "combo" job (<= 5 stops && <= 3 hours). Render a pill.
   */
  isComboJob?: boolean;
  /**
   * Render a business pill.
   */
  isBusiness?: boolean;
  /**
   * If there are more than two stops and the job is bundled, render a "tussenstops" section between TO and FROM.
   */
  stopCount?: number;
  /**
   * The total expected time range for the job.
   */
  rewardTime?: { hours: number; minutes: number } | null;
  /**
   * Max loading volume
   */
  maxVolume?: number | null;
  /**
   * Accepts a list of items and renders said list.
   */
  items?: Item[] | LargestItem[];
  /*
   * Accepts a number = distance in KM
   */
  distance?: number;
  /**
   * Accepts a list of item sets.
   */
  itemSets?: ItemSet[];
  /*
   * Presentation has influence on which details should be shown, if not set valuable info will be skipped
   */
  presentation?: JobPresentation;
  /**
   * All stop details
   */
  all_stop_details?: {
    situation: StopSituation[];
    carrying_help: CarryingHelp[];
  };
  /**
   * All stop services for open TJ
   */
  services?: ItemSetServices[];

  /**
   * If the TJ is in dispute
   */
  isDisputed?: boolean;
  /**
   * Driver payouts (aka, type individual not product_payment)
   */
  driverPayouts?: Payout[];
  /**
   * Has multiple delivery days
   */
  hasMultipleAvailableDays?: boolean;
}

/**
 * This component is intended to be a dumb, all-encompassing template for TJ-related details.
 * Can be used in cards or on job details pages. All props are optional for full flexibility.
 * Main concern is layout. Not intended to execute business logic or anything ":"
 */
export const TransportJobDetails: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const hasPayouts = props.driverPayouts && props.driverPayouts.length > 0;

  return (
    <div className={cn("flex", "flex-col", "flex-grow")}>
      <div className={cn("flex", "flex-wrap", "w-full", "items-start", "justify-between")}>
        {(props.to || props.from) && (
          <div className={cn("flex", "flex-col")}>
            {/* FROM LOCALITY */}
            <Timeline isFirst={true} isLast={false}>
              <div>
                <Strong>{props.from}</Strong>
              </div>
              {/* PICKUP PERIOD */}
              {props.pickupPeriod && !props.isBundled && (
                <div className={cn("capitalize")}>{props.pickupPeriod}</div>
              )}{" "}
              {props.hasMultiplePickupPeriods && "+"}
            </Timeline>
            {/* IN BETWEEN STOPS (IF ANY) */}
            {props.isBundled && props.stopCount && props.stopCount > 2 && (
              <Timeline isFirst={false} isLast={false}>
                <div className={cn("flex", "items-center")}>
                  <IconMultipleStop className={cn("h-6", "mr-1")} />
                  <Small>
                    {/* subtract 2 from stop count in order to account for first and last stop */}
                    {props.stopCount - 2} {t((d) => d.transport_job.stopovers)}
                  </Small>
                </div>
              </Timeline>
            )}
            <Timeline isFirst={false} isLast={true}>
              {/* TO LOCALITY */}
              <div>
                <Strong>{props.to}</Strong>
              </div>
              {/* DELIVERY PERIOD */}
              {props.deliveryPeriod && !props.isBundled && (
                <div className={cn("capitalize")}>{props.deliveryPeriod}</div>
              )}
              {props.hasMultipleDeliveryPeriods ? "+" : null}
            </Timeline>
          </div>
        )}
        <div className={cn("text-right")} style={{ letterSpacing: "0.01rem" }}>
          {props.day && (
            <span className={cn("flex", "justify-end", "items-center")}>
              <div className={cn("capitalize")}>{props.day}</div>
              {props.hasMultipleAvailableDays ? (
                <div>
                  <IconAdd className={cn("text-green-400")} />
                </div>
              ) : null}
            </span>
          )}
          {/*
            NOTE: when payouts is available to display, show it to drivers instead of the total payout amount (see below).
            We use the payouts instead because they have statuses that we can show alongside the amount.
          */}
          {hasPayouts &&
            props.driverPayouts?.map((p) => {
              return (
                <div key={p["@id"]} className={cn("flex", "items-center", "justify-end", "mb-1")}>
                  <div>
                    {/* @TODO add currency when it becomes available on payouts */}
                    {formatPrice({ amount: p.amount_excl_vat, locale: i18n.locale })} {t((d) => d.vat.excluded)}
                  </div>
                  <PillPayoutState type={p.type} className={cn("ml-2", "mt-2")} state={p.state} />
                </div>
              );
            })}
          {/*
            This is the total payout amount on the TJAL before a payout has been started. We show this amount
            before any payout is pending or completed yet.
            There is no auth check on this price because the entire list view is hidden from
            non-account-admins. But we still want to show price on the preview list.
          */}
          {!hasPayouts && props.price && (
            <div className={cn("flex", "items-center", "justify-end")}>
              {props.isPrepaid && <IconCheckCircle className={cn("mr-1", "h-4", "w-4", "text-green-400")} />}
              <div>
                {formatPrice({
                  amount: props.price.excl_vat.amount,
                  currency: props.price.excl_vat.currency,
                  locale: i18n.locale,
                })}{" "}
                {t((d) => d.vat.excluded)}
              </div>
            </div>
          )}
        </div>
      </div>
      {props.distance && (
        <MetaItem
          name={t((d) => d.transport_job.total_distance)}
          value={formatKilometers({
            kilometers: props.distance,
            locale: i18n.locale,
          })}
        />
      )}
      {props.rewardTime && (
        <MetaItem
          name={t((d) => d.transport_job.reward_time)}
          value={
            <>
              ± {props.rewardTime.hours} {t((d) => d.calendar.hour)} {props.rewardTime.minutes}{" "}
              {t((d) => d.calendar.minute)}
            </>
          }
        />
      )}
      {/* Many of these are coming back as 0.003 and such - hide if so. */}
      {props.maxVolume && props.maxVolume > 0.009 ? (
        <MetaItem
          name={t((d) => d.transport_job.min_load_space)}
          value={
            <>
              {formatMeters({ meters: props.maxVolume, locale: i18n.locale })}
              <sup>3</sup>
            </>
          }
        />
      ) : null}
      {/* The item set details are shown on the stop cards when it's a bundled job so no need to display agian here */}
      {!props.isBundled && props.itemSets && <ItemDetails itemSets={props.itemSets} />}
      <div className={cn("flex", "items-center", "flex-wrap", "mt-1")}>
        {props.isBundled && <Pill type="blue">{t((d) => d.transport_job.bundled)}</Pill>}
        {props.isComboJob && <Pill type="blue">{t((d) => d.transport_job.combo_job)}</Pill>}
        {props.isBusiness && <Pill type="black">{t((d) => d.transport_job.business)}</Pill>}
        {props.isDisputed && <Pill type="red">{t((d) => d.transport_job.in_dispute)}</Pill>}
        <StopDetailIcons itemSets={props.itemSets} details={props.all_stop_details} />
      </div>
    </div>
  );
};
