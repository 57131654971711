import * as React from "react";
import { useQuery, useQueryClient } from "react-query";

import { BreadcrumbNav, Page } from "../../../components";
import {
  getGroupByDateKey,
  useAuth,
  useClaimJobGate,
  useSearchFilter,
  useSearchParams,
  useTracking,
  useTranslation,
} from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";
import { ClaimBundledJob } from "./ClaimBundledJob";
import { ClaimJobWithAvailableDates } from "./ClaimJobWithAvailableDates";
import { ClaimJobWithoutAvailableDates } from "./ClaimJobWithoutAvailableDates";
import { useHistory } from "react-router-dom";
import { getIdFromIri } from "@brenger/utils";
import { PickupCommitment, TransportJobAccountLink } from "@brenger/api-client";

export const SearchClaimJob: React.FC = () => {
  const { t } = useTranslation();
  const params = useSearchParams();
  // Set up breadcrumbs for each Page instance below
  const breadcrumbs = [
    { text: t((d) => d.app.tabs.search), to: Routes.SEARCH_JOB_LIST },
    {
      text: `#${params.tj_id.slice(-6)}`,
      to: `/search/${params.tj_id}`,
    },
    // @TODO trans missing
    { text: "Claim" },
  ];
  // Fetch the transport job
  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, params.tj_id], () =>
    coreClient.transportJobs.retrieve({ id: params.tj_id })
  );
  //
  const history = useHistory();
  const queryClient = useQueryClient();
  const tracking = useTracking();
  const { filteredOpenTransportJobs, openTransportJobs, activeFilter } = useSearchFilter();
  const auth = useAuth();
  /**
   * Success action - to be executed after a job has been claimed, no matter what job type
   */
  const onSuccessFullyClaimed = (tjal: TransportJobAccountLink): void => {
    // 1. Reset the cache
    queryClient.clear();
    // 2. Take care of tracking
    // Find the date key, and then find the day position
    const dateKey = getGroupByDateKey(
      (tjal.pickup_commitments[0] as PickupCommitment).committed_datetime_period.start,
      !!tj.data?.pickups?.[0]?.available_datetime_periods?.length
    );
    const sortByField = activeFilter.sortBy.field;
    const jobs =
      sortByField === "personalized" ? filteredOpenTransportJobs.grouped[dateKey] : filteredOpenTransportJobs.ungrouped;
    const tjIndex = jobs?.findIndex((openTj) => getIdFromIri(openTj["@id"]) === params.tj_id) || -1;
    const openTj = tjIndex > -1 ? jobs[tjIndex] : null;
    // Send tracking event
    if (openTj) {
      tracking.sendEvent({
        event: "Job funnel",
        // In UTC
        timeStamp: new Date(new Date().toUTCString()).toISOString(),
        action: "claim",
        id: params.tj_id,
        listPosition: tjIndex + 1,
        listLength: jobs.length,
        score: openTj.score ? openTj.score?.value : null,
        isDefaultScore: openTj?.score ? openTj.score.isDefaultScore : null,
        totalJobsCount: openTransportJobs.data ? openTransportJobs.data.length : null,
        activeFilter: JSON.stringify(activeFilter),
        sortByField,
        courierId: auth.userId || null,
      });
    }
    // 3. send them of to planning
    history.push(`${Routes.PLANNING_JOB_LIST}/${getIdFromIri(tjal)}`);
  };

  // Use this gate to determine whether user can the job.
  const GatedUI = useClaimJobGate(tj.data);

  if (GatedUI) {
    return <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}>{GatedUI}</Page>;
  }

  // Handle all loading and error states
  if (!tj.data || tj.isError) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tj.isLoading}
        errorText={(tj.error as Error)?.message}
      />
    );
  }

  if (tj.data.bundled) {
    return <ClaimBundledJob breadcrumbs={breadcrumbs} tj={tj.data} onSuccessFullyClaimed={onSuccessFullyClaimed} />;
  }

  // If the job is both DIRECTLY_CLAIMABLE then the driver can directly claim it by
  // selecting from pre-ordained DTPs in the claim form.
  if (tj.data.directly_claimable) {
    return (
      <ClaimJobWithAvailableDates
        breadcrumbs={breadcrumbs}
        tj={tj.data}
        claimCta={t((d) => d.transport_job.actions.claim_job)}
        onSuccessFullyClaimed={onSuccessFullyClaimed}
      />
    );
  }

  // Check if both pickup and delivery has available DTPs.
  const hasAvailableDtps =
    tj.data.pickups.some((s) => s.available_datetime_periods.length > 0) &&
    tj.data.deliveries.some((s) => s.available_datetime_periods.length > 0);

  // Non-guaranteed jobs can also have DTPs. The only meaningful difference between this claim form and
  // that of a directly claimable job is the CTA copy ("claim" vs "make an offer").
  if (hasAvailableDtps) {
    return (
      <ClaimJobWithAvailableDates
        breadcrumbs={breadcrumbs}
        tj={tj.data}
        claimCta={t((d) => d.transport_job.actions.make_offer)}
        onSuccessFullyClaimed={onSuccessFullyClaimed}
      />
    );
  }

  // In all other cases, show a form that allows a driver to freely select datetime periods.
  return (
    <ClaimJobWithoutAvailableDates
      breadcrumbs={breadcrumbs}
      tj={tj.data}
      onSuccessFullyClaimed={onSuccessFullyClaimed}
    />
  );
};
