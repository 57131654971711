import * as React from "react";
import { useQuery, useQueryClient } from "react-query";
import cn from "classnames";
import { IconLoader } from "@brenger/react";

import { coreClient } from "../../../utils";
import { UseGeolocation } from "../../../hooks";

interface Props {
  geolocation: UseGeolocation;
}

export const Logout: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  // This query triggers the logout as soon as the component mounts.
  useQuery(["LOGOUT"], () => coreClient.users.logout());

  React.useEffect(() => {
    // Stop geolocation tracking when user logs out. We do this for two reasons:
    // 1. It's a nice expectation for drivers
    // 2. We no longer have a valid userId to include
    props.geolocation.stop();

    // Rather than handle redirect (does not work with axios)...
    // ...simply reload the page and let routing / auth take control.
    setTimeout(() => {
      queryClient.clear();
      window.location.reload();
    }, 350);
  }, []);

  return (
    <div className={cn("flex", "justify-center", "items-center", "flex-grow")}>
      <IconLoader className={cn("w-6", "h-6")} />
    </div>
  );
};
