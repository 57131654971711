import { IconCancel, IconLoader } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import ReactDOM from "react-dom";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import "../../assets/styles/overlay.css";

interface Props {
  title: string;
  loading?: boolean;
  activePath: string;
  exitPath: string;
}

export const Overlay: React.FC<Props> = ({ title, loading, activePath, exitPath, children }) => {
  const [active, setActive] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const overlay = document.querySelector("#overlay-root") as HTMLDivElement;

  React.useEffect(() => {
    const hasMatch = matchPath(location.pathname, {
      path: activePath,
      exact: true,
    });
    setActive(Boolean(hasMatch));
  }, [location.pathname]);

  React.useEffect(() => {
    if (active) {
      document.body.classList.add("overlay-active");
    } else {
      document.body.classList.remove("overlay-active");
    }
    return (): void => {
      document.body.classList.remove("overlay-active");
    };
  }, [active]);

  const handleExit = (): void => {
    /**
     * When the user landed on the edit URL directly:
     * We need to replace the URL to prevent opening the edit component again when pressing "back" after closing
     */
    if (history.action === "POP") {
      history.replace(exitPath);
      return;
    }
    // In other cases it is save and wanted behaviour to use the back functionality
    history.goBack();
  };

  return ReactDOM.createPortal(
    <div className={cn("overlay", { "overlay-active": active })}>
      <div
        className={cn(
          "overlay-container",
          "overflow-auto",
          "z-30",
          "bg-white",
          "w-full",
          "h-full",
          "overscroll-contain",
          "px-3",
          "pb-3"
        )}
      >
        <h4
          className={cn(
            "flex",
            "justify-between",
            "items-center",
            "sticky",
            "top-0",
            "bg-white",
            "py-3",
            "z-10",
            "text-blue-600"
          )}
        >
          {title} <IconCancel onClick={handleExit} />
        </h4>
        {!loading ? children : <IconLoader />}
      </div>
      <div className={cn("overlay-backdrop")} onClick={handleExit} />
    </div>,
    overlay
  );
};
