import * as React from "react";
import * as semver from "semver";
import { QueryObserverResult, useQuery } from "react-query";
import axios from "axios";

import { Config } from "../config";
import { CacheKey, CacheTTL } from "../utils";
// Import this file so we can get types for it (not so we can get the actual values)!
import versions from "../../versions.json";
import { DevicePlatform } from "@brenger/api-client";

const VERSION_FILE = "versions.json";

// Set up a small client object so we can utilize it with the useQuery hook.
const appVersionClient = {
  async checkAppVersion() {
    const res = await axios.get(`${Config.URL}/${VERSION_FILE}`);
    return res.data as typeof versions;
  },
};

/**
 * The main idea here is to check the current app version against the following:
 * 1) the currently supported app version
 * 2) a list of unstable versions
 */
export const useNativeAppVersion = (args: {
  platform?: DevicePlatform;
  version?: string;
}): {
  isSupported: boolean;
  isUpdateAvailable: boolean;
  checkVersion(): Promise<QueryObserverResult>;
} => {
  const [isUpdateAvailable, setIsUpdateAvailable] = React.useState(false);
  const [isSupported, setIsSupported] = React.useState(true);

  const versionData = useQuery([CacheKey.APP_VERSION], appVersionClient.checkAppVersion, {
    enabled: !!args.platform && !!args.version,
    cacheTime: CacheTTL.XL * 1000,
  });

  React.useEffect(() => {
    // Ensure we have all required args and values before executing hook body.
    if (versionData.data && args.version && (args.platform === "android" || args.platform === "ios")) {
      const currentAppVersion = semver.coerce(args.version);

      const latestAppVersion = semver.coerce(versionData.data[args.platform]?.supported?.latest);
      const minSupportedVersion = semver.coerce(versionData.data[args.platform]?.supported?.minimum);
      const unstableVersions =
        versionData.data[args.platform]?.supported?.unstable.map((v: string) => semver.coerce(v)).filter(Boolean) || [];

      // First, check if this app's version is lower than the latest app version.
      if (currentAppVersion && latestAppVersion) {
        if (semver.lt(currentAppVersion, latestAppVersion)) {
          setIsUpdateAvailable(true);
        }
      }

      // Second, check if this app's version is lower than the minimum supported version.
      if (currentAppVersion && minSupportedVersion) {
        if (semver.lt(currentAppVersion, minSupportedVersion)) {
          setIsSupported(false);
        }
      }

      // Third, check if the app's version is included in the list of unstable versions.
      if (currentAppVersion && unstableVersions.length > 0) {
        if (unstableVersions.some((unstable) => unstable?.version === currentAppVersion.version)) {
          setIsSupported(false);
        }
      }
    }
  }, [args.version, args.platform, versionData.isLoading, versionData.data]);

  return { checkVersion: versionData.refetch, isUpdateAvailable, isSupported };
};
