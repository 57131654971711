import { Card, Small, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { Grid, PillTjalState } from "../../../components";
import { useFormatDate, useInboxFilter, useTranslation } from "../../../hooks";
import { CacheKey, IonicRoutes, coreClient } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";

export const MoreInboxList: React.FC = () => {
  const { t } = useTranslation();
  const formatDateFull = useFormatDate("date-full");

  // Fetch conversations
  const conversations = useQuery([CacheKey.INBOX_LIST], coreClient.conversations.listForDriver);

  const filteredConversations = useInboxFilter(conversations.data?.["hydra:member"]);

  if (!conversations.data || conversations.error) {
    return (
      <NewPageWrapper title={t((d) => d.more.inbox)} backUrl={IonicRoutes.more.home()}>
        {(conversations.error as Error)?.message}
      </NewPageWrapper>
    );
  }

  /**
   * Handle empty list state.
   */
  if (filteredConversations.length === 0) {
    return (
      <NewPageWrapper title={t((d) => d.more.inbox)} backUrl={IonicRoutes.more.home()}>
        {" "}
        {t((d) => d.app.empty_state.generic)}
      </NewPageWrapper>
    );
  }

  return (
    <NewPageWrapper title={t((d) => d.more.inbox)} backUrl={IonicRoutes.more.home()}>
      <Grid cols={{ lg: 2 }} gap={4}>
        {filteredConversations.map((conversation) => {
          return (
            <Link
              key={conversation["@id"]}
              className={cn("block")}
              to={IonicRoutes.more.inbox(getIdFromIri(conversation.transport_job_account_link))}
            >
              <Card type="gray">
                <div className={cn("flex", "justify-between")}>
                  <div style={{ height: "50px", width: "50px" }} className={cn("overflow-hidden", "rounded-full")}>
                    <img
                      className={cn("h-full", "w-full", "object-cover")}
                      src={conversation.conversation_party.profile_image || missingImageSrc}
                    />
                  </div>
                  <div style={{ width: "calc(100% - 65px)" }}>
                    <div className={cn("truncate", "h-6")}>
                      <div>
                        <Strong>
                          {conversation.pickup_locality} — {conversation.delivery_locality}
                        </Strong>
                      </div>
                    </div>
                    {/* eslint-disable-next-line */}
                    <div className={cn("truncate", "h-6")} dangerouslySetInnerHTML={{ __html: conversation.body }} />
                  </div>
                </div>
                <div className={cn("flex", "flex-wrap", "justify-between", "mt-2")}>
                  <Small className={cn("text-gray-500", "capitalize")}>
                    {formatDateFull(conversation.updated_at as string)}
                  </Small>
                  <PillTjalState state={conversation.transport_job_account_link.state} />
                </div>
              </Card>
            </Link>
          );
        })}
      </Grid>
    </NewPageWrapper>
  );
};
