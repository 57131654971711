import { DayRouteActivity } from "@brenger/api-client";
import React from "react";
import cn from "classnames";
import { useDayRouteStopParams, useTranslation } from "../../../hooks";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { CacheKey, coreClient } from "../../../utils";
import { getIdFromIri } from "@brenger/utils";
import { Button, IconArrowLeft, IconArrowRight } from "@brenger/react";

interface Props {
  activity: DayRouteActivity;
  canConfirm: boolean;
}

export const DayRouteStopStickyFooter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useDayRouteStopParams();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const activitiesWithhoutBreak =
    dayRoute.data?.day_route_activities?.filter((activity) => activity.type !== "break") || [];
  const currentStopIndex = activitiesWithhoutBreak.findIndex((activity) => activity["@id"] === props.activity["@id"]);
  const prevStopId = getIdFromIri(activitiesWithhoutBreak[currentStopIndex - 1]);
  const nextStopId = getIdFromIri(activitiesWithhoutBreak[currentStopIndex + 1]);

  const confirmTrans =
    {
      pickup: t((d) => d.day_route.details.actions.confirm_pickup),
      delivery: t((d) => d.day_route.details.actions.confirm_delivery),
    }[props.activity.type as "pickup" | "delivery"] ?? t((d) => d.day_route.details.actions.confirm_stop);

  return (
    <div className={cn("flex", "whitespace-nowrap")}>
      <div>
        <Button
          className={cn("h-full")}
          buttonType="primary"
          onClick={() => history.push(`/day-route/${params.date}/${params.user_id}/activity/${prevStopId}`)}
          disabled={!prevStopId}
        >
          <IconArrowLeft />
        </Button>
      </div>
      <div className={cn("mx-2", "w-full")}>
        <Button
          buttonType="secondary"
          disabled={!props.canConfirm}
          className={cn("w-full")}
          onClick={() => history.push(`/day-route/${params.date}/${params.user_id}/activity/${params.stop_id}/confirm`)}
        >
          {confirmTrans}
        </Button>
      </div>
      <div>
        <Button
          className={cn("h-full")}
          buttonType="primary"
          onClick={() => history.push(`/day-route/${params.date}/${params.user_id}/activity/${nextStopId}`)}
          disabled={!nextStopId}
        >
          <IconArrowRight />
        </Button>
      </div>
    </div>
  );
};
