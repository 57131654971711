import { DayRouteActivity } from "@brenger/api-client";
import {
  Card,
  CardType,
  ExtraSmall,
  FadeOutText,
  HorizontalLine,
  IconAuction,
  IconFragile,
  IconHeavy,
  IconPersonAdd,
  IconShop,
  IconTailgate,
  IconVan,
  SeperateLine,
  Spacer,
  Strong,
  Timeline,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { DayRouteStopTime } from "../../../components/data/DayRouteStopTime";
import { useDayRouteParams, useFormatDate, useTranslation } from "../../../hooks";
import { ErrorLevel } from "../../../types";
import { serializeAddress } from "../../../utils";
import { ActivityValidationError, TravelMeta, activityValidationErrorLevelMap } from "../utils";
import { DayRouteStopEditCarets } from "./DayRouteStopEditCarrets";
import { DayRouteValidation } from "./DayRouteValidationParagraph";

interface Props {
  activity: DayRouteActivity;
  isEditing: boolean;
  moveUp?(): void;
  moveDown?(): void;
  travelMeta: TravelMeta;
  validationError: ActivityValidationError | null;
  startedDriving: boolean;
  customStop?: boolean;
}

export const DayRouteStop: React.FC<Props> = (props) => {
  const formatHourMinute = useFormatDate("hour-minute");
  const { arrivalTime, departureTime } = props.travelMeta;

  return (
    <Timeline
      isFirst={false}
      isLast={false}
      showPoint={props.isEditing || (!arrivalTime && !departureTime)}
      labelTop={
        !props.isEditing && arrivalTime ? (
          <ExtraSmall className={cn("bg-white")}>{formatHourMinute(arrivalTime)}</ExtraSmall>
        ) : undefined
      }
      labelBottom={
        !props.isEditing && departureTime ? (
          <ExtraSmall className={cn("bg-white")}>{formatHourMinute(departureTime)}</ExtraSmall>
        ) : undefined
      }
    >
      <DayRouteStopCard {...props} />
    </Timeline>
  );
};

export const errorCardTypeMap: Record<ErrorLevel, CardType> = {
  error: "red-bg-gray",
  warning: "orange-bg-gray",
};

export const stopCardType = (validationError: ActivityValidationError | null): CardType | undefined => {
  if (!validationError) {
    return undefined;
  }
  return validationError in activityValidationErrorLevelMap
    ? errorCardTypeMap[activityValidationErrorLevelMap[validationError]]
    : "stale";
};
type StopCard = {
  activity: DayRouteActivity;
  isEditing: boolean;
  validationError: ActivityValidationError | null;
  customStop?: boolean;
  moveUp?(): void;
  moveDown?(): void;
  startedDriving: boolean;
};

export const DayRouteStopCard: React.FC<StopCard> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useDayRouteParams();
  const isPickup = props.activity.type.includes("pickup");
  const cardType = stopCardType(props.validationError);
  const iconProps = { className: cn("m-1", "w-4", "h-4") };
  const stopDetails = props.activity.additional_stop_details;
  const hasStopIcons =
    stopDetails &&
    (stopDetails.auction ||
      stopDetails.store ||
      stopDetails.second_driver ||
      stopDetails.tailgate ||
      stopDetails.van ||
      stopDetails.fragile ||
      stopDetails.heavy);

  return (
    <Card
      type={cardType}
      className={cn({ "cursor-pointer": !props.isEditing }, "!mb-0")}
      onClick={() => {
        if (!props.isEditing) {
          history.push(`/day-route/${params.date}/${params.user_id}/activity/${getIdFromIri(props.activity)}`);
        }
      }}
    >
      <div className={cn("relative", "pr-2")}>
        <div className={cn({ "pr-10": props.isEditing })}>
          <Strong className={cn("text-blue-600")}>{serializeAddress(props.activity.address)}</Strong>
        </div>
        <Spacer w={4} />
        {props.customStop ? (
          <div className={cn("br-text-md")}>{t((d) => d.day_route.details.own_stop)}</div>
        ) : (
          <div className={cn("br-text-md")}>
            <FadeOutText lineclamp={1}>{stopDetails?.title}</FadeOutText>
          </div>
        )}
        {props.validationError && <DayRouteValidation error={props.validationError} />}
        <Spacer h={1} />
        <HorizontalLine type="gray" />
        <small className={cn("inline-flex", "items-center", "flex-wrap")}>
          <div className={cn("inline-flex", "items-center")}>
            <DayRouteStopTime startedDriving={props.startedDriving} activity={props.activity} />
          </div>
          <div className={cn(cn("inline-flex", "items-center"))}>
            <SeperateLine spaceWidth={2} type="gray" />
            <b className={cn("uppercase")}>
              {t((d) => (isPickup ? d.transport_job.pickup : d.transport_job.delivery))}
            </b>

            {hasStopIcons && <SeperateLine spaceWidth={2} type="gray" />}
          </div>
          <div className={cn("inline-flex", "flex-wrap", "items-center")}>
            {stopDetails && (
              <>
                {stopDetails.auction && <IconAuction {...iconProps} />}
                {stopDetails.store && <IconShop {...iconProps} />}
                {stopDetails.second_driver && <IconPersonAdd {...iconProps} />}
                {stopDetails.tailgate && <IconTailgate {...iconProps} />}
                {stopDetails.van && <IconVan {...iconProps} />}
                {stopDetails.fragile && <IconFragile {...iconProps} />}
                {stopDetails.heavy && <IconHeavy {...iconProps} />}
              </>
            )}
          </div>
        </small>
        {props.isEditing && <DayRouteStopEditCarets moveUp={props.moveUp} moveDown={props.moveDown} />}
      </div>
    </Card>
  );
};
