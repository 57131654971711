import { GeoLocationDetails } from "@brenger/api-client";
import { Button, IconEditPencil, Message, IconCheck } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { InputTextWithGeoAutocomplete } from "../../../components";
import { useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";

interface Props {
  canEdit: boolean;
  addressId?: string;
}

export const UserAddressForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = React.useState(false);

  const form = useForm({
    initialState: {
      search: "",
      place: undefined as GeoLocationDetails | undefined,
    },
    validators: {
      search: (val) => !val,
    },
  });

  const address = useQuery(
    [CacheKey.RETRIEVE_ADDRESS, props.addressId],
    () => coreClient.addresses.retrieve({ id: props.addressId as string }),
    {
      enabled: !!props.addressId,
    }
  );

  const updateAddress = useMutation(coreClient.addresses.update, {
    onSuccess: () => {
      setInEditMode(false);
      address.refetch();
    },
  });

  React.useEffect(() => {
    if (address.data) {
      form.set({
        search: address.data ? [address.data.line1, address.data.locality].filter(Boolean).join(", ") : "",
      });
    }
    // Run the effect when the user is present so we can initialize the form.
  }, [Boolean(address.data)]);

  const onSubmit = (): void => {
    if (!props.addressId) return;
    const place = form.data.place.value;

    if (!place) return;

    updateAddress.mutate({
      id: props.addressId,
      line1: place.address.line1 || "",
      postal_code: place.address.postal_code || "",
      locality: place.address.locality,
      municipality: place.address.secondary_subdivision,
      country_code: place.address.country_code,
      lat: place.address.latitude,
      lng: place.address.longitude,
    });
  };

  return (
    <div>
      <InputTextWithGeoAutocomplete
        disabled={!inEditMode}
        value={form.data.search.value}
        onChange={(search) => form.set({ search })}
        onSelect={(place) => form.set({ place })}
        includeHouseNumber={true}
      />

      {props.canEdit && !inEditMode && (
        <Button className={cn("mt-6", "w-full")} onClick={() => setInEditMode(true)} icon={<IconEditPencil />}>
          {t((d) => d.profile.edit_address_details)}
        </Button>
      )}
      {props.canEdit && inEditMode && (
        <Button
          buttonType="secondary"
          className={cn("mt-6", "w-full")}
          loading={updateAddress.isLoading}
          icon={<IconCheck />}
          onClick={onSubmit}
        >
          {t((d) => d.profile.save_personal_details)}
        </Button>
      )}
      {updateAddress.error && (
        <Message className={cn("mt-4")} type="error">
          {(updateAddress.error as Error).message}
        </Message>
      )}
    </div>
  );
};
