import * as React from "react";
import cn from "classnames";
import { useQuery } from "react-query";
import { PhotoProofType, ItemSet } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Emphasis } from "@brenger/react";

import { CacheKey, coreClient } from "../../utils";
import { ExternalLink } from "../../components";
import { StopType } from "../../types";

interface Props {
  stopType: StopType;
  itemSets: ItemSet[];
  emptyStateText: string;
}

export const PhotoProofs: React.FC<Props> = ({ stopType, itemSets, emptyStateText }) => {
  const photoProofIds = itemSets
    .flatMap((itemSet) => itemSet.photo_proofs)
    .filter((photoProof) => photoProof["@type"].startsWith(stopType || String(undefined)))
    .map((photoProof) => getIdFromIri(photoProof) || "")
    // Remove any empty strings from above step
    .filter(Boolean);

  if (!photoProofIds.length) {
    return (
      <div>
        <Emphasis>{emptyStateText}</Emphasis>
      </div>
    );
  }

  return (
    <div>
      {photoProofIds.map((id) => {
        return <PhotoProof key={id} photoProofId={id} stopType={stopType} />;
      })}
    </div>
  );
};

interface SignatureProps {
  photoProofId: string;
  stopType: StopType;
}

const PhotoProof: React.FC<SignatureProps> = ({ photoProofId, stopType }) => {
  const photoProofType: PhotoProofType = stopType === "Pickup" ? "PickupPhotoProof" : "DeliveryPhotoProof";

  const pickupPhotoProof = useQuery(
    [CacheKey.RETRIEVE_PHOTO_PROOF, "PICKUP", photoProofId],
    () => coreClient.photoProofs.retrievePickupResource({ id: photoProofId }),
    {
      enabled: photoProofType === "PickupPhotoProof",
    }
  );

  const deliveryPhotoProof = useQuery(
    [CacheKey.RETRIEVE_PHOTO_PROOF, "DELIVERY", photoProofId],
    () => coreClient.photoProofs.retrieveDeliveryResource({ id: photoProofId }),
    {
      enabled: photoProofType === "DeliveryPhotoProof",
    }
  );

  const photoProofs = [
    ...(pickupPhotoProof.data?.photo_proof_images || []),
    ...(deliveryPhotoProof.data?.photo_proof_images || []),
  ];

  if (!photoProofs.length) return null;

  return (
    <div className={cn("-mx-2", "flex", "flex-wrap")}>
      {photoProofs.map((image) => {
        return (
          <ExternalLink key={image["@id"]} className={cn("p-2")} url={image.download_link}>
            <img className={cn("rounded")} style={{ maxWidth: 150 }} src={`${image.download_link}?w=150`} />
          </ExternalLink>
        );
      })}
    </div>
  );
};
