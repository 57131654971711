import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, RouteComponentProps, useLocation } from "react-router-dom";
import {
  MoreBrengerId,
  MoreCouriers,
  MoreDashboard,
  MoreFavouriteRoutes,
  MoreForgetAccount,
  MoreHome,
  MoreInboxDetails,
  MoreInboxList,
  MoreLocationLanguage,
  MoreProfile,
  MoreReviews,
  MoreVehiclesCreate,
  MoreVehiclesDetails,
  MoreVehiclesList,
  NewLayout,
  Support,
} from "../";
import { IonicRoutes, slideInNavAnimation } from "../../utils";

export const MoreSection: React.FC = ({ children }) => {
  const location = useLocation();
  if (!location.pathname.includes("/more") && location.pathname.indexOf("/support") === -1) {
    return <>{children}</>;
  }
  return (
    <IonApp>
      <NewLayout>
        <IonReactRouter>
          <IonRouterOutlet animation={slideInNavAnimation}>
            <Route exact={true} path={IonicRoutes.more.home()} component={MoreHome} />
            <Route exact={true} path={IonicRoutes.more.inbox()} component={MoreInboxList} />
            <Route exact={true} path={IonicRoutes.more.inbox(":tjal_id")} component={MoreInboxDetails} />
            <Route exact={true} path={IonicRoutes.more.dashboard()} component={MoreDashboard} />
            <Route exact={true} path={IonicRoutes.more.reviews()} component={MoreReviews} />
            <Route exact={true} path={IonicRoutes.more.brengerId()} component={MoreBrengerId} />
            <Route exact={true} path={IonicRoutes.more.profile()} component={MoreProfile} />
            <Route exact={true} path={IonicRoutes.more.vehicles()} component={MoreVehiclesList} />
            <Route exact={true} path={IonicRoutes.more.vehicles("create")} component={MoreVehiclesCreate} />
            <Route exact={true} path={IonicRoutes.more.vehicles(":id")} component={MoreVehiclesDetails} />
            <Route exact={true} path={IonicRoutes.more.couriers()} component={MoreCouriers} />
            <Route exact={true} path={IonicRoutes.more.favouriteRoutes()} component={MoreFavouriteRoutes} />
            <Route exact={true} path={IonicRoutes.more.locationAndLanguage()} component={MoreLocationLanguage} />
            <Route exact={true} path={IonicRoutes.more.forget()} component={MoreForgetAccount} />
            <Route exact={true} path={IonicRoutes.support.home()} component={Support} />
          </IonRouterOutlet>
        </IonReactRouter>
      </NewLayout>
    </IonApp>
  );
};

export interface MoreParams {
  inboxDetails: RouteComponentProps<{
    tjal_id: string;
  }>;
}
