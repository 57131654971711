import * as React from "react";
import cn from "classnames";
import { StaticGoogleMap, Marker, Path } from "react-static-google-map";

import { Config } from "../../config";
import { Marker as MarkerType } from "../../hooks";
import { ExternalLink } from "../../components";

export const styleParts = [
  "feature:administrative",
  "element:labels.text.fill",
  "color:0x444444&style=feature:landscape",
  "color:0xf2f2f2&style=feature:poi",
  "visibility:off&style=feature:road",
  "saturation:-100",
  "lightness:45&style=feature:road.arterial",
  "element:labels.icon",
  "visibility:off&style=feature:road.highway",
  "visibility:simplified&style=feature:transit",
  "visibility:off&style=feature:water",
  "color:0xaad2e3",
  "visibility:on",
];

const containerBaseClassnames = ["relative", "z-0", "bg-gray-200", "rounded-md", "shadow"];
const subContainerBaseClassnames = ["absolute", "inset-0"];

export enum MapDims {
  /**
   * Use this one for small viewports.
   */
  SM = "640x300",
  /**
   * Use this one for large viewports.
   */
  LG = "1280x200",
}

export interface Location {
  lat: number;
  lng: number;
}

export interface StopMapProps {
  markers: MarkerType[];
  points?: Location[];
  googleMapsUrl?: string;
  markerType?: "circle" | "default";
  markerColor?: "brengerBlue" | "green";

  /**
   * Only required when there is a single marker - otherwise Google handles it when there are many.
   * Use "12" for less accurate (eg, SEARCH STOP DETAILS).
   * Use "15" for more accurate (eg, PLANNING STOP DETAILS)
   */
  zoom?: "12" | "15";
}

export const StaticMap: React.FC<StopMapProps> = (props) => {
  const style = encodeURI(styleParts.join("|"));

  const points = props.points ? (
    <Path
      // Default is 5
      weight={4}
      // Brenger blue
      color="0x0073FA"
      points={props.points}
    />
  ) : null;

  const markers = props.markers.map(({ location, stopNumber }, idx) => {
    return (
      <Marker
        key={idx}
        location={location}
        color="blue"
        label={stopNumber}
        anchor="center"
        iconURL={props.markerType === "circle" ? `${Config.URL}/circle-icon.png` : undefined}
      />
    );
  });

  return (
    <ExternalLink
      url={props.googleMapsUrl || ""}
      className={cn("no-print", "cursor-pointer", {
        // NOTE: this CSS effectively disables the external link in cases where an empty string is provided for the google maps url.
        "pointer-events-none": !props.googleMapsUrl,
      })}
    >
      {/*
        We add a container with padding that matches the default ratio of the size map it contains.
        This is used in order to prevent jarring layout changes as map image loads in.
        We basically create a placeholder this way.
      */}
      <div className={cn(containerBaseClassnames, "block", "md:hidden")} style={{ paddingBottom: "46.875%" }}>
        <div className={cn(subContainerBaseClassnames)}>
          {markers.length > 0 && (
            <StaticGoogleMap
              // @TODO remove ts-expect-error annotation below when underlying package has added correct types.
              // Follow this pull request: https://github.com/bondz/react-static-google-map/pull/8
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              rootURL={Config.STATIC_MAPS_ROOT_URL}
              className={cn("rounded-md")}
              size={MapDims.SM}
              scale="2"
              zoom={props.zoom}
              apiKey={Config.STATIC_MAPS_KEY}
              style={style}
            >
              {markers}
              {points}
            </StaticGoogleMap>
          )}
        </div>
      </div>
      <div className={cn(containerBaseClassnames, "hidden", "md:block")} style={{ paddingBottom: "31.25%" }}>
        <div className={cn(subContainerBaseClassnames)}>
          {markers.length > 0 && (
            <StaticGoogleMap
              // @TODO remove ts-expect-error annotation below when underlying package has added correct types.
              // Follow this pull request: https://github.com/bondz/react-static-google-map/pull/87
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              rootURL={Config.STATIC_MAPS_ROOT_URL}
              className={cn("rounded-md", "w-full")}
              size={MapDims.LG}
              scale="2"
              zoom={props.zoom}
              apiKey={Config.STATIC_MAPS_KEY}
              style={style}
            >
              {markers}
              {points}
            </StaticGoogleMap>
          )}
        </div>
      </div>
    </ExternalLink>
  );
};
