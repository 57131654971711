import { Button, ButtonType, Spacer, useModalState } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { Grid, Modal } from "../components";
import { useTranslation } from "./useTranslation";

interface Props {
  /**
   * In some cases, you may want to render a primary CTA that is something other than default color.
   */
  buttonType?: ButtonType;
}

interface ConfirmOptions {
  title: string;
  message: string;
  okButtonTitle?: string;
  cancelButtonTitle?: string;
}

type UseConfirmModalResponse = [(props: Props) => React.ReactElement | null, (confirmOptions: ConfirmOptions) => void];

export const useConfirmModal = (onChange: () => void): UseConfirmModalResponse => {
  const { t } = useTranslation();
  const modalState = useModalState();
  const [options, setOptions] = React.useState<ConfirmOptions>();

  return [
    (props: Props): React.ReactElement | null => {
      return (
        <Modal isActive={modalState.isActive} closeHandler={modalState.close}>
          <h3>{options?.title}</h3>
          <Spacer h={3} />
          <div>{options?.message}</div>
          <Spacer h={4} />
          <Grid cols={{ lg: 2 }} gap={4}>
            <Button
              className={cn("w-full")}
              buttonType={props.buttonType}
              onClick={() => {
                onChange();
                modalState.close();
              }}
            >
              {options?.okButtonTitle || t((d) => d.modal.confirm)}
            </Button>
            <Button className={cn("w-full")} buttonType="primary-outline" onClick={modalState.close}>
              {options?.cancelButtonTitle || t((d) => d.modal.cancel)}
            </Button>
          </Grid>
        </Modal>
      );
    },
    (confirmOptions: ConfirmOptions) => {
      setOptions(confirmOptions);
      modalState.open();
    },
  ];
};
