import { ItemSet } from "@brenger/api-client";
import { Emphasis, H3, H4, H5, HorizontalLine, Spacer, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import {
  AddressDetails,
  BreadcrumbNav,
  ItemDetails,
  MetaItem,
  Page,
  StaticMap,
  StopDetailPills,
  TooltipDressCode,
  UserGeneratedContent,
  ImageGallery,
} from "../../../components";
import { FormatCommitedStopTime } from "../../../components/data/DayRouteStopTime";
import { useMapContext, useSearchParams, useStopContext, useTranslation } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";

export const SearchStopDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useSearchParams();

  let breadcrumbs = [
    { to: "/search", text: t((d) => d.app.tabs.search) },
    { text: `#${params.tj_id.slice(-6)}`, to: `/search/${params.tj_id}` },
    { text: "--" },
  ];

  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );

  const stopContext = useStopContext(stop.data || null);

  const mapContext = useMapContext({ presentation: "search", tj: stopContext.transportJob, stopId: params.stop_id });
  const stopType = stop.data?.["@type"];

  // The item set for this particular stop.
  const itemSets = (stop.data?.item_sets as ItemSet[] | undefined) || [];

  if (!stop.data) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={stop.isLoading}
        errorText={(stop.error as Error)?.message}
      />
    );
  }

  // When we have gathered the stop context and the address, update the third breadcrumb.
  if (stopContext.stopNumber && stopContext.address) {
    breadcrumbs = breadcrumbs.map((breadcrumb, idx) => {
      return idx === 2 ? { text: `${stopContext.stopNumber}: ${stopContext.address?.locality}` } : breadcrumb;
    });
  }
  return (
    <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}>
      {/* PICKUP OR DELIVERY */}
      <H5>
        <Strong className={cn("uppercase")}>
          {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
        </Strong>
      </H5>
      <Spacer h={3} />
      <HorizontalLine type="gray" />
      {/* SHORT ADDRESS */}
      <H4 className={cn("text-blue-500")}>
        <AddressDetails
          address={stopContext.address}
          instructions={stop.data.details.instructions}
          presentation="search"
        />
      </H4>
      <Spacer h={3} />
      {/* AVAILABLE DATETIME*/}
      {stop.data.available_datetime_periods?.length > 0 && (
        <div className={cn("flex", "items-center")}>
          <FormatCommitedStopTime
            textClassname={["mr-2"]}
            start={stop.data.available_datetime_periods[0].start}
            end={stop.data.available_datetime_periods[0].end}
          />
        </div>
      )}
      {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
        <>
          <Spacer h={2} />
          <TooltipDressCode />
        </>
      )}
      <Spacer h={3} />
      {/* MAP */}
      <StaticMap zoom="12" markerType="circle" markers={mapContext.markers} googleMapsUrl={mapContext.googleMapsUrl} />
      <Spacer h={8} />
      {/* PICKUP/DELIVERY INFORMATION */}
      <H3>
        {t((d) =>
          stopType === "Pickup"
            ? d.transport_job.headings.pickup_information
            : d.transport_job.headings.delivery_information
        )}
      </H3>
      <Spacer h={3} />
      <HorizontalLine type="gray" />
      <Spacer h={3} />
      {/* DETAILS PILLS */}
      <StopDetailPills details={stop.data.details} itemSets={itemSets} />
      <Spacer h={3} />
      {/* REFERENCE NUMBER */}
      <H5>{t((d) => d.transport_job.id)}</H5>
      <div>{`#${getIdFromIri(stop.data.transport_job)?.slice(-6)}`}</div>
      <Spacer h={3} />
      {/* PICKUP/DELIVERY  */}

      <H5>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</H5>
      {/* Render title and description of all item sets for this stop. */}
      {itemSets.map((itemSet, idx) => {
        return (
          <div key={idx} className={cn("mb-2", "flex", "flex-col")}>
            <div>{itemSet.title}</div>
            {itemSet.description && <div> {itemSet.description}</div>}
            <ItemDetails itemSets={[itemSet]} truncate={false} />
            {itemSet.assembly_description && (
              <MetaItem
                name={t((d) => d.transport_job.stop_details.services.assembly)}
                value={<UserGeneratedContent text={itemSet.assembly_description} />}
              />
            )}
          </div>
        );
      })}
      {/* ADDRESS DETAILS */}
      <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
        <H5>{t((d) => d.transport_job.headings.address)}</H5>
      </div>
      <AddressDetails
        address={stopContext.address}
        instructions={stop.data.details.instructions}
        presentation="search"
      />
      <Spacer h={3} />
      {/* STOP CONTACT - DO NOT SURFACE AT THIS STAGE */}
      <H5>{t((d) => d.transport_job.headings.address)}</H5>
      <div>
        <Emphasis>{t((d) => d.search.messages.contact_details)}</Emphasis>
      </div>

      {/* JOB ITEM IMAGES */}
      <Spacer h={3} />
      <ImageGallery itemSets={itemSets} />
    </Page>
  );
};
