import { Button, IconSmile, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { useBrowser, useTranslation } from "../../hooks";

export const FaqSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const browser = useBrowser();
  const faqs = [
    {
      question: t((d) => d.support.faq.question_1),
      answer: t((d) => d.support.faq.answer_1),
    },
    {
      question: t((d) => d.support.faq.question_2),
      answer: t((d) => d.support.faq.answer_2),
    },
    {
      question: t((d) => d.support.faq.question_3),
      answer: t((d) => d.support.faq.answer_3),
    },
  ];
  return (
    <div>
      <h4>{t((d, withTemplate) => withTemplate(d.support.faq_heading, { count: 3 }))}</h4>
      {faqs.map((faq, i) => (
        <FaqDisplay key={i} {...faq} />
      ))}
      <Spacer h={4} />
      <Button
        onClick={() => browser.open({ url: Config.DRIVER_SUPPORT_URL.replace(LOCALE_TEMPLATE, i18n.locale) })}
        type={"button"}
        buttonType="primary-outline"
      >
        {t((d) => d.support.faq_show_all)}
      </Button>
    </div>
  );
};

const FaqDisplay: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [hidden, setHidden] = React.useState(true);
  return (
    <>
      <div className="flex flex-wrap my-4" onClick={() => setHidden(!hidden)}>
        <div className="w-6 text-blue-600" style={{ paddingTop: ".25em" }}>
          <IconSmile
            className={cn("rotate-0", "ease-in", "duration-150", { "rotate-90": !hidden })}
            style={{ fontSize: ".9em" }}
          />
        </div>
        <h6 style={{ width: "calc(100% - 1.5rem)" }}>{question}</h6>
      </div>
      <div
        className={cn({ hidden, "pb-4": !hidden }, "ml-6", "br-text-sm")}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </>
  );
};
