import { Vehicle } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { useTranslation } from "../../../hooks";

interface Props {
  vehicle: Vehicle;
}

export const VehicleSummary: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const imageResource = props.vehicle.images[0];
  return (
    <>
      <div className={cn("flex", "items-center")}>
        <div
          style={{
            // Utilize minWidth to prevent image dim distoration on small viewports.
            minWidth: "6rem",
          }}
          className={cn("relative", "overflow-hidden", "rounded-full", "w-24", "h-24", "bg-gray-200")}
        >
          <img
            className={cn("h-full", "w-full", "object-cover")}
            src={
              imageResource ? `${imageResource.download_link}?v=${getIdFromIri(imageResource["@id"])}` : missingImageSrc
            }
          />
        </div>
        <div className={cn("ml-2")}>
          <div>
            <b>{t((d) => d.vehicles.max_capacity_kg)}</b>
            <div className="inline-block ml-1">{props.vehicle.loading_weight_limit_kg}</div>
          </div>
          <div className={cn("mt-1")}>
            <b>{t((d) => d.vehicles.max_volume_cm)}</b>
            <div className="inline-block ml-1">
              {props.vehicle.loading_dimensions_length_cm} x {props.vehicle.loading_dimensions_width_cm} x{" "}
              {props.vehicle.loading_dimensions_height_cm}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
