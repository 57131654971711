import { Button, IconLoader, IconSend, Message, Small, Textarea } from "@brenger/react";
import cn from "classnames";
import isSameDay from "date-fns/isSameDay";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {} from "../../../components";
import { useAuth, useForm, useFormatDate, useTranslation } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";

interface Props {
  tjalId: string;
}

export const Conversation: React.FC<Props> = (props) => {
  const auth = useAuth();
  const formatHourMinute = useFormatDate("hour-minute");
  const { t } = useTranslation();
  // hook to interact with cache / settings etc
  const queryClient = useQueryClient();
  const conversation = useQuery(
    [CacheKey.LIST_MESSAGES_USER_AND_TJAL, auth.userId, props.tjalId],
    () => coreClient.messages.listByUserAndTjal({ user_id: auth.userId || "", tjal_id: props.tjalId || "" }),
    {
      enabled: !!auth.userId,
    }
  );

  const totalItems = conversation.data?.["hydra:totalItems"] || 0;

  /**
   * Scroll to bottom, every time the messages are updated
   */
  React.useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }, [totalItems]);

  const onSuccess = (): void => {
    /**
     * Clear the inbox list cache. If don't do this, then the latest message preview + update time stamp will
     * not appear correctly in the message list.
     * Other option would be to clear ENTIRE query cache, but may be a bit heavy-handed in this instance.
     */
    queryClient.invalidateQueries(CacheKey.INBOX_LIST);
    /**
     * Refresh the converstion so that the latest message appears in the UI after submitting.
     */
    conversation.refetch();
  };

  const createMessage = useMutation(coreClient.messages.createDriverMessage, { onSuccess });

  const form = useForm({
    initialState: { message: "" },
    // Use .trim() to check in case user just hits enter a bunch of times
    validators: { message: (val) => !val?.trim() },
  });

  const messages = conversation.data?.["hydra:member"];

  const submitForm = (): void => {
    createMessage.reset();
    createMessage.mutate({
      author: (auth.user?.["@id"] as string | undefined) || "",
      body: form.data.message.value,
      item_set: (messages?.[0]?.item_set as string | undefined) || "",
      transport_job_account_link: messages?.[0]?.transport_job_account_link["@id"],
    });
    form.reset();
  };

  // Provide an error message to users in case fetching conversation fails.
  if (conversation.error) {
    return <Message type="error">{(conversation.error as Error)?.message}</Message>;
  }

  // Add a centered-loader to prevent UI from jumping around as new items appear.
  if (!conversation.data) {
    return (
      <div className={cn("flex", "justify-center", "items-center", "flex-grow")}>
        <IconLoader className={cn("w-6", "h-6")} />
      </div>
    );
  }

  return (
    <div>
      {/* Messages comes in sorted on latest first, so inverse the view with flex-box, reversing the array lead to some incosistencies / performance issues */}
      <div className={cn("flex", "flex-col-reverse")}>
        {(messages || []).map((message, idx) => {
          const isMine = message.author?.["@id"] === auth.user?.["@id"];
          /* Messages comes in sorted on latest first, so check against next message, not the previous one */
          const nextMessageDate = messages?.[idx + 1]?.created_at;
          const printDate =
            !nextMessageDate || !isSameDay(new Date(message.created_at as string), new Date(nextMessageDate));
          return (
            <React.Fragment key={message["@id"]}>
              <Small className={cn({ "text-right": isMine }, "text-gray-400", "my-1")}>
                {formatHourMinute(message.created_at as string)}
              </Small>
              <div
                className={cn("p-4", "rounded", "shadow", {
                  "bg-blue-100": isMine,
                  "ml-8": isMine,
                  "bg-blue-200": !isMine,
                  "mr-8": !isMine,
                })}
              >
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: message.body }} />
              </div>
              {printDate && (
                <div className={cn("flex", "items-center", "mb-4")}>
                  <div className={cn("border-t", "border-gray-400", "flex-grow")} />
                  <Small className={cn("text-gray-400", "mx-2")}>
                    {isSameDay(new Date(), new Date(message.created_at as string))
                      ? t((d) => d.calendar.today)
                      : formatHourMinute(message.created_at as string)}
                  </Small>
                  <div className={cn("border-t", "border-gray-400", "flex-grow")} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className={cn("mt-4")}>
        <Textarea
          className={cn("w-full")}
          onChange={(val) => void form.set({ message: val })}
          value={form.data.message.value}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              submitForm();
            }
          }}
        />
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          disabled={form.hasErrors}
          loading={createMessage.isLoading}
          onClick={submitForm}
          icon={<IconSend />}
        >
          {t((d) => d.actions.submit)}
        </Button>
        {createMessage.isError && (
          <Message type="error" className={cn("mt-2")}>
            {(createMessage.error as Error).message}
          </Message>
        )}
      </div>
    </div>
  );
};
