import * as React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import {} from "@brenger/react";

import { Routes } from "../../utils";

export interface Tab {
  text: string;
  to: string | Routes;
}

interface Props {
  isActive?: boolean;
  tabs: Tab[] | null;
}

export const TabNav: React.FC<Props> = ({ isActive = true, tabs }) => {
  if (!isActive) {
    return null;
  }

  return (
    <div
      className={cn("w-full", "bg-gray-200", "flex", "items-end", "br-text-sm", "col-gap-2")}
      style={{ height: "43px" }}
    >
      <div className={cn("container", "mx-auto", "px-4", "uppercase", "flex", "justify-between")}>
        {tabs?.map((tab, idx) => {
          return (
            <NavLink
              key={idx}
              to={tab.to}
              className={cn("w-full", "text-center", "pb-1", "pt-2", "border-blue-600")}
              activeClassName={cn("border-b-4")}
            >
              <div>{tab.text}</div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
