import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { onlineManager, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { useAuth, useDeviceInfo, useTranslation } from ".";
import { AppMessagesKey } from "../AppMessages/AppMessages";
import { coreClient } from "../utils";
import { usePushNotifications } from "./usePushNotifications";
import { useTracking } from "./useTracking";

interface UseAppEffects {
  appMessages: Set<AppMessagesKey>;
}

/**
 * This hook is a collection of effects that should run whenever the app mounts (regardless of the underlying platform).
 */
export const useAppEffects = (): UseAppEffects => {
  const { i18n } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const deviceInfo = useDeviceInfo();
  const tracking = useTracking();
  const pushNotifications = usePushNotifications();

  /**
   * ----------
   * Preferred locale
   * ----------
   * NOTE: When user has been loaded AND that user has a preferred locale, ensure app language is set accordingly.
   */
  const hasUser = Boolean(auth.user);
  useEffect(() => {
    const userPreferredLocale = auth.user?.preferred_locale;
    if (hasUser && userPreferredLocale) {
      i18n.changeLocale(userPreferredLocale);
    }
  }, [hasUser]);

  /**
   * ----------
   * Health checks
   * ----------
   */
  const { data: isAlive } = useQuery(["BRAIN"], coreClient.users.isBrainAlive, {
    enabled: onlineManager.isOnline(),
    refetchInterval: 15000,
  });

  if (isAlive && !onlineManager.isOnline()) {
    onlineManager.setOnline(true);
  }
  /**
   * SET DEVICE AS ATTRIBUTE ON BODY
   * Reason to implement was to target platform specific css.
   */

  useEffect(() => {
    if (deviceInfo?.platform) {
      document.body.setAttribute("data-platform", deviceInfo.platform);
    }
  }, [deviceInfo]);

  /**
   * ----------
   * DEEP LINKING
   * ----------
   */
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: URLOpenListenerEvent) => {
      /**
       * Normalize URL by replacing old parts and making everything match new pathname pattern.
       * /nl/driver/search -> /search
       * /search -> /search
       */
      const normalizedUrl = data.url.replace("/nl/driver", "");
      const { pathname } = new URL(normalizedUrl);
      if (pathname) history.push(pathname);
    });

    return () => {
      CapApp.removeAllListeners();
    };
  }, [CapApp]);

  /**
   * ----------
   * SENTRY SCOPE CONFIG
   * ----------
   */
  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (auth.user) {
        scope.setUser({ email: auth.user.email });
      }

      if (deviceInfo) {
        const { platform } = deviceInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scope.setContext("device_info", deviceInfo as any);
        scope.setTag("platform", platform);
      }
    });
  }, [auth.user, deviceInfo]);

  /**
   * ----------
   * TRACKING CHECK PERMISSION
   * ----------
   */
  useEffect(() => {
    // Wait till logged in, so we ask permission (ios) after the login screen
    if (auth.isLoggedIn && deviceInfo?.platform) {
      tracking.checkTrackingPermission();
    }
  }, [auth.isLoggedIn, deviceInfo?.platform]);

  /**
   * --------------------
   * MESSAGES TO DISPLAY ACROSS THE TOP OF THE APP (BANNER STYLE)
   * --------------------
   */
  const appMessages = pushNotifications.appMessages;

  /**
   * Switch to OFFLINE mode when the network status indicates there is no connection.
   * This will in turn trigger the health check above, which, when successful again, will
   * flip back to ONLINE mode again.
   */
  // I THINK REACT_QUERY CAN HANDLE THIS
  // if (networkStatus && networkStatus.connected === false && onlineManager.isOnline()) {
  //   onlineManager.setOnline(false);
  // }

  /**
   * When offline mode, present message.
   */
  if (!onlineManager.isOnline()) {
    appMessages.add("offline-mode");
  }

  /**
   * If the user entity does not contain a profile image url,
   * show an app message with a link to the profile section.
   */
  if (auth.user && !auth.user.profile_image) {
    appMessages.add("missing-profile-image");
  }

  return { appMessages };
};
