import { useAuth } from ".";

import { Routes } from "../utils";
import { Tab } from "../components";
import { useTranslation } from ".";

type AppSection = "dashboard" | "profile";

export const useTabNavigation = (appSection: AppSection): Tab[] | null => {
  const { t } = useTranslation();
  const auth = useAuth();

  const adminTabMap: { [key in AppSection]: Tab[] } = {
    dashboard: [
      { text: t((d) => d.app.tabs.search), to: Routes.SEARCH_JOB_LIST },
      { text: t((d) => d.app.tabs.planning), to: Routes.PLANNING_JOB_LIST },
      { text: t((d) => d.app.tabs.delivered), to: Routes.DELIVERED_JOB_LIST },
    ],
    profile: [
      { text: t((d) => d.app.tabs.details), to: Routes.USER_DETAILS },
      { text: t((d) => d.app.tabs.vehicles), to: Routes.USER_VEHICLES },
      { text: t((d) => d.app.tabs.routes), to: Routes.USER_ROUTES },
    ],
  };

  const driverTabMap: { [key in AppSection]: Tab[] } = {
    dashboard: [
      { text: t((d) => d.app.tabs.planning), to: Routes.PLANNING_JOB_LIST },
      { text: t((d) => d.app.tabs.delivered), to: Routes.DELIVERED_JOB_LIST },
    ],
    profile: [{ text: t((d) => d.app.tabs.details), to: Routes.USER_DETAILS }],
  };

  if (auth.isAccountAdmin) return adminTabMap[appSection];

  if (auth.isDriver) return driverTabMap[appSection];

  // Do not return tabs while user is loading.
  // This prevents tab options from flashing incorrect options.
  return null;
};
