import { DayRouteActivity } from "@brenger/api-client";
import {
  HorizontalLine,
  IconCheck,
  IconEditPencil,
  IconHorizontalDots,
  Pill,
  SeperateLine,
  Spacer,
  Strong,
} from "@brenger/react";
import cn from "classnames";
import { isAfter } from "date-fns";
import { useHistory } from "react-router-dom";

import { AddressDetails, Breadcrumb, BreadcrumbNav, Page, StaticMap } from "../../../components";
import { FormatCommitedStopTime } from "../../../components/data/DayRouteStopTime";
import {
  ActionSheetOption,
  createGoogleMapsLinkFromSingleAddress,
  useActionSheet,
  useDayRouteStopParams,
  useFormatDate,
  useTranslation,
} from "../../../hooks";
import { DayRouteEditCustomStop } from "../../editstop/DayRoute/DayRouteEditCustomStop";
import { DayRouteStopStickyFooter } from "./DayRouteStopStickyFooter";

interface Props {
  breadcrumbs: Breadcrumb[];
  activity: DayRouteActivity;
}

export const DayRouteCustomStopDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formatHourMinute = useFormatDate("hour-minute");
  const showActionSheet = useActionSheet();
  const params = useDayRouteStopParams();
  const history = useHistory();

  // Zero-based inde, so make human friendly.
  const stopIndex = props.activity.index + 1;

  const googleMapsUrl = createGoogleMapsLinkFromSingleAddress(props.activity.address);
  const addressActions: ActionSheetOption[] = [
    {
      title: t((d) => d.planning.actions.open_google_maps),
      onClick: () => window.open(googleMapsUrl, "_blank"),
    },
  ];

  const stopType = props.activity.type;

  const canConfirm = !props.activity.confirmed_by_driver_at && isAfter(new Date(), new Date(params.date));

  return (
    <>
      <Page
        nav={<BreadcrumbNav breadcrumbs={props.breadcrumbs} />}
        stickyFooter={<DayRouteStopStickyFooter activity={props.activity} canConfirm={canConfirm} />}
      >
        {/* PICKUP OR DELIVERY */}
        <div className={cn("flex", "items-center", "justify-between")}>
          <div className={cn("inline-flex", "items-center")}>
            <h6 className="uppercase">
              {t((d) => d.transport_job.headings[stopType === "custom_pickup" ? "pickup" : "delivery"])}
            </h6>
            <SeperateLine spaceWidth={2} type="blueGray" />

            <h6 className={cn("text-gray-500", "uppercase")}>
              {/* OWN STOP */}
              {t((d) => d.day_route.details.own_stop)}
            </h6>
          </div>

          {/* NOTE: when stop is confirmed show the PILL - otherwise show edit stop button! */}
          {props.activity.confirmed_by_driver_at ? (
            <Pill type="green-outline" className={cn("mb-2")}>
              <span className={cn("flex", "items-center")}>
                <IconCheck className={cn("mr-1")} />
                {formatHourMinute(props.activity.confirmed_by_driver_at)}
              </span>
            </Pill>
          ) : (
            <div
              className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")}
              onClick={() =>
                history.push(`/day-route/${params.date}/${params.user_id}/activity/${params.stop_id}/edit`)
              }
            >
              <IconEditPencil className={cn("mr-1")} /> {t((d) => d.actions.edit)}
            </div>
          )}
        </div>
        <Spacer h={3} />
        <HorizontalLine type="gray" />
        <Spacer h={3} />
        {/* SHORT ADDRESS */}
        <h6>
          <AddressDetails address={props.activity.address} presentation="dayroute-details" />
        </h6>
        {/* COMMITED DATETIME & SERVICE TIME */}
        <div className={cn("flex", "items-center")}>
          <FormatCommitedStopTime
            textClassname={["mr-2"]}
            start={props.activity.start_time}
            end={props.activity.end_time}
          />
          {props.activity?.service_time_seconds && (
            <>
              <div>
                {t((d) => d.transport_job.service_time)}: {props.activity?.service_time_seconds / 60}
              </div>
              <div className={cn("m-1")}> {t((d) => d.calendar.minute_full)}</div>
            </>
          )}
        </div>
        {props.activity.confirmed_by_driver_at && (
          <div>
            <Pill type="green" className={cn("mb-2")}>
              <span className={cn("flex", "items-center")}>
                <IconCheck className={cn("mr-1")} />
                {formatHourMinute(props.activity.confirmed_by_driver_at)}
              </span>
            </Pill>
          </div>
        )}
        <Spacer h={8} />
        <StaticMap
          zoom="15"
          markers={[
            {
              location: {
                lat: props.activity.address?.lat || 0,
                lng: props.activity.address?.lng || 0,
              },
              stopNumber: String(stopIndex),
            },
          ]}
          googleMapsUrl={googleMapsUrl}
        />
        <Spacer h={8} />
        {/* PICKUP OR DELIVERY INFORMATION*/}
        <h6>
          {stopType !== "custom_other" &&
            t((d) =>
              stopType === "custom_pickup"
                ? d.transport_job.headings.pickup_information
                : d.transport_job.headings.delivery_information
            )}
        </h6>
        <Spacer h={3} />
        <HorizontalLine type="gray" />
        <Spacer h={3} />
        {/* ADDRESS DETAILS + ASSOCIATED ACTIONS */}
        <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
          <Strong>
            <h6>{t((d) => d.transport_job.headings.address)}</h6>{" "}
          </Strong>
          <button
            className={cn("px-1", "border", "rounded-full", "border-gray-800")}
            onClick={() => {
              showActionSheet({
                title: t((d) => d.actions.more),
                message: t((d) => d.actions.select_option),
                options: addressActions,
              });
            }}
          >
            <IconHorizontalDots />
          </button>
        </div>
        <AddressDetails
          address={props.activity.address}
          presentation="planning"
          instructions={props.activity.description || ""}
        />
      </Page>
      <DayRouteEditCustomStop activity={props.activity} />
    </>
  );
};
