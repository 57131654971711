import * as React from "react";

// Hook to check if user scrolled passed a certain border in pixels, defaults to 0
export const useScrollOffset = (offset = 0): boolean => {
  // Use state for saving if the user has reached the offset
  const [hasReachedOffset, setHasReachedOffset] = React.useState(false);

  const scrollListener = React.useCallback((): void => {
    if (window.scrollY <= offset) {
      if (hasReachedOffset) {
        setHasReachedOffset(false);
      }
    }

    if (window.scrollY > offset) {
      if (!hasReachedOffset) {
        setHasReachedOffset(true);
      }
    }
  }, [hasReachedOffset, offset]);

  // on mount add listener, on unmount cleanup
  React.useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  // return if user scrolled passed border
  return hasReachedOffset;
};
