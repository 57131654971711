/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { List } from "@brenger/api-client";

/**
 * This hook simply handle an incoming list and handles pagination logic.
 * Therefore, what the List contains is irrelevant. Hence the rare use of "any".
 */

/**
 * Accepts a pathname with a query param and extracts the page number from it.
 * EG: "/users?page=1422"
 */
const getPageParam = (pathname?: string): number | null => {
  const qs = pathname?.split("?")?.[1];
  const formattedQs = new URLSearchParams(qs);
  return parseInt(formattedQs.get("page") || "", 10) || null;
};

/**
 * Accepts a pathname with a query param and checks whether the page param exists.
 * EG: "/users?page=1422"
 */
const hasPageParam = (pathname?: string): boolean => {
  const qs = pathname?.split("?")?.[1];
  if (!qs) return false;
  return new URLSearchParams(qs).has("page");
};

interface UsePaginationParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list?: List<any>;
  // The current page number.
  page: number;
  // A setter for incrementing or decrementing the current page number.
  setPage: (pageNumber: number) => void;
}

export const usePagination = (params: UsePaginationParams) => {
  const totalItems = params.list?.["hydra:totalItems"] || 0;
  const itemsPerPage = params.list?.["hydra:member"]?.length || 0;
  const hasNextPage = hasPageParam(params.list?.["hydra:view"]?.["hydra:next"]);
  const hasPreviousPage = params.page > 1;
  const nextPageNumber = getPageParam(params.list?.["hydra:view"]?.["hydra:next"]);

  return {
    hasNextPage,
    goToNextPage: () => {
      if (nextPageNumber) {
        params.setPage(nextPageNumber);
      }
    },
    hasPreviousPage,
    goToPreviousPage: () => {
      if (hasPreviousPage) {
        params.setPage(params.page - 1);
      }
    },
    totalItems,
    currentRangeStart: hasNextPage ? params.page * itemsPerPage - (itemsPerPage - 1) : totalItems - (itemsPerPage - 1),
    currentRangeEnd: hasNextPage ? params.page * itemsPerPage : totalItems,
  };
};
