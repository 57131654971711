import React from "react";
import debounce from "lodash/debounce";

//Using Tailwind's default breakpoints so we have interoperability with our CSS framework
// See v1.tailwindcss.com/docs/responsive-design

type ViewportSize = "sm" | "md" | "lg" | "xl";

type ViewportOrientation = "portrait" | "landscape";

interface Context {
  width: number | undefined;
  height: number | undefined;
  size: ViewportSize | undefined;
  isDesktop: boolean | undefined;
  orientation: ViewportOrientation | undefined;
}

const ViewportContext = React.createContext<Context>({
  width: undefined,
  height: undefined,
  size: undefined,
  isDesktop: undefined,
  orientation: undefined,
});

// NOTE: using a viewport provider ensures that we only add one resize listener to the DOM.
export const ViewportProvider: React.FC = ({ children }) => {
  let size: ViewportSize | undefined = undefined;
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = React.useCallback(
    debounce((): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 250),
    []
  );

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (isBetween(width, 0, 640)) size = "sm";
  if (isBetween(width, 641, 768)) size = "md";
  if (isBetween(width, 769, 1024)) size = "lg";
  if (isBetween(width, 1025, Infinity)) size = "xl";

  const isDesktop = size === "lg" || size === "xl";

  const orientation: ViewportOrientation = width > height ? "landscape" : "portrait";

  return (
    <ViewportContext.Provider value={{ width, height, size, isDesktop, orientation }}>
      {children}
    </ViewportContext.Provider>
  );
};

const isBetween = (val: number, start: number, stop: number): boolean => {
  return val !== undefined && val >= start && val <= stop;
};

export const useViewport = (): Context => {
  return React.useContext(ViewportContext);
};
