import { Button, IconSmile, useModalState } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "react-query";
import { ExternalLink, Grid } from "../../../components";
import { useAuth, useTranslation } from "../../../hooks";
import { CacheKey, IonicRoutes, coreClient, getDeletedUserList, getSanitizedUserList } from "../../../utils";
import { NewPageWrapper } from "../layout/PageWrapper";
import { SubAccountCard } from "./SubAccountCard";
import { UserAddModal } from "./UserAddModal";

export const MoreCouriers: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userAddModal = useModalState();

  const accountUsers = useQuery(
    [CacheKey.RETRIEVE_USERS, auth.user?.account?.["@id"]],
    () => coreClient.accounts.listUsers({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const userList = accountUsers.data?.["hydra:member"];
  const sanitizedUserList = userList ? getSanitizedUserList(userList) : [];
  const deletedUserList = userList ? getDeletedUserList(userList) : [];
  return (
    <NewPageWrapper title={t((d) => d.more.drivers)} backUrl={IonicRoutes.more.home()} loading={accountUsers.isLoading}>
      <Grid cols={{ lg: 2 }} gap={4}>
        {sanitizedUserList.map((user) => (
          <SubAccountCard key={user["@id"]} user={user} />
        ))}
      </Grid>
      {deletedUserList.length !== 0 && (
        <>
          <div className={cn("flex", "items-center", "pt-4")}>
            <hr className={cn("grow")} />
            <div className={cn("text-white", "px-4")}>
              <div>{t((d) => d.overview_couriers_deleted)}</div>
            </div>
            <hr className={cn("grow")} />
          </div>
          <div className={cn("text-white", "text-center", "pb-4")}>
            <div>{t((d) => d.overview_couriers_deleted_explain)}</div>
          </div>
          <Grid cols={{ lg: 2 }} gap={4}>
            {deletedUserList.map((user) => (
              <SubAccountCard key={user["@id"]} user={user} />
            ))}
          </Grid>
        </>
      )}
      <Grid cols={{ default: 2 }} gap={4} className="mt-8">
        <ExternalLink url={t((d) => d.dashboard.performance_tips_url)}>
          <Button
            type="button"
            buttonType="secondary"
            iconPos={"right"}
            className="w-full"
            icon={<IconSmile className={cn("text-white", "h-4", "w-4")} />}
          >
            <div className={cn("inline-block")}>{t((d) => d.dashboard.performance_tips)}</div>
          </Button>
        </ExternalLink>
        <Button
          type="button"
          iconPos={"right"}
          onClick={userAddModal.open}
          icon={<IconSmile className={cn("text-white", "h-4", "w-4")} />}
        >
          <div className={cn("inline-block")}>{t((d) => d.profile.add_user)}</div>
        </Button>
      </Grid>
      <UserAddModal isActive={userAddModal.isActive} closeHandler={userAddModal.close} />
    </NewPageWrapper>
  );
};
