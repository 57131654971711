import * as React from "react";
import cn from "classnames";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { StarRating, Spacer, H2, H4, IconBrengerLogo, IconCancel } from "@brenger/react";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { Dialog } from "../../../components";
import { CacheKey, coreClient } from "../../../utils";
import { useAuth, useTranslation } from "../../../hooks";

export const UserId: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();

  const reviewSummary = useQuery(
    [CacheKey.RETRIEVE_SUMMARY, auth.userId],
    () => coreClient.users.retrieveReviewSummary({ userId: auth.userId as string }),
    {
      enabled: !!auth.userId,
    }
  );

  const allTimeAverage = reviewSummary.data?.all?.average || 0;
  const allDeliveredJobs = useQuery([CacheKey.LIST_PLANNING_TRANSPORT_JOB_ACCOUNT_LINKS, "DELIVERED"], () =>
    coreClient.transportJobAccountLinks.list({
      presentation: "delivered",
      state: ["delivered"],
    })
  );

  return (
    <Dialog isActive={true}>
      <section className={cn("h-full", "bg-white", "p-3")}>
        <div
          className={cn(
            "flex",
            "items-center",
            "justify-center",
            "h-full",
            "w-full",
            "bg-blue-600",
            "rounded-md",
            "relative"
          )}
        >
          <button className={cn("absolute", "top-0", "right-0", "p-2")} onClick={history.goBack}>
            <IconCancel className={cn("text-white", "w-8", "h-8")} />
          </button>
          <div className={cn("flex", "flex-col", "items-center", "w-full")} style={{ maxWidth: "15rem" }}>
            <IconBrengerLogo className={cn("w-full", "h-24", "text-white")} />
            <Spacer h={4} />
            <div>
              <div className={cn("relative", "overflow-hidden", "rounded-full", "w-32", "h-32")}>
                <img
                  className={cn("h-full", "w-full", "object-cover")}
                  src={auth.user?.profile_image || missingImageSrc}
                />
              </div>
            </div>
            <Spacer h={4} />
            <H2 className={cn("text-white")}>{auth.fullName}</H2>
            <Spacer h={2} />
            <StarRating rating={allTimeAverage} />
            <Spacer h={2} />
            <H4 className={cn("text-white")}>{allTimeAverage}/5</H4>
            <Spacer h={4} />
            <div className={cn("rounded-md", "bg-white", "px-4", "py-3", "w-full")}>
              <H4 className={cn("text-blue-600", "mr-1")}>
                {t((d) => d.dashboard.transports_total)}
                <span>{allDeliveredJobs.data?.["hydra:totalItems"] || "--"}</span>
              </H4>
            </div>
            <Spacer h={2} />
            <div className={cn("rounded-md", "bg-white", "px-4", "py-3", "w-full")}>
              <H4 className={cn("text-blue-600", "mr-1")}>
                ID
                <span>{auth.user?.friendly_id}</span>
              </H4>
            </div>
          </div>
        </div>
      </section>
    </Dialog>
  );
};
