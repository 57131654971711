import * as React from "react";
import cn from "classnames";
import { useQuery } from "react-query";
import { getIdFromIri } from "@brenger/utils";

import { Routes, coreClient, CacheKey, CacheTTL } from "../../../utils";
import { Page, BreadcrumbNav } from "../../../components";
import { useInboxParams, useScrollOffset, useTranslation } from "../../../hooks";
import { ConversationParty } from "./ConversationParty";
import { Conversation } from "./Conversation";

export const InboxDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useInboxParams();
  const scrolledPassed = useScrollOffset(100);
  let breadcrumbs = [{ to: Routes.INBOX_LIST, text: t((d) => d.app.menu.inbox) }, { text: `-` }];

  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, params.tjal_id],
    () => coreClient.transportJobAccountLinks.retrieve({ id: params.tjal_id }),
    {
      cacheTime: CacheTTL.XS * 1000,
    }
  );

  // Handle loading and errors here.
  // This is also a chance to handle incorrect TJAL ID from the URL param before
  // mounting any subsequent components that also rely on the TJAL ID.
  if (!tjal.data || tjal.error) {
    return (
      <Page
        nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />}
        loading={tjal.isLoading}
        errorText={(tjal.error as Error)?.message}
      />
    );
  }

  breadcrumbs = [
    { to: Routes.INBOX_LIST, text: t((d) => d.app.menu.inbox) },
    { text: `#${getIdFromIri(tjal.data.transport_job)?.slice(-6)}` },
  ];

  return (
    <Page nav={<BreadcrumbNav breadcrumbs={breadcrumbs} />} scrollToTopOnMount={false}>
      <div
        className={cn("mb-4", "sticky", "top-0-with-safe-area-inset", "px-2", "bg-white", {
          "shadow-md": scrolledPassed,
        })}
      >
        <ConversationParty tjalId={params.tjal_id} />
      </div>
      <div className={cn("px-1")}>
        <Conversation tjalId={params.tjal_id} />
      </div>
    </Page>
  );
};
