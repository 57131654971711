import { CountryCode, EuroNorm, Vehicle, VehicleCreateArgs } from "@brenger/api-client";
import {
  Button,
  Card,
  Emphasis,
  IconArrowLeft,
  IconCheck,
  InputCheckbox,
  InputNumber,
  InputText,
  Label,
  Message,
  Select,
  Spacer,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Grid, Options } from "../../../components";
import { useAuth, useCamera, useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, dataURItoBlob, Routes } from "../../../utils";
import { getFormChangedValues, getFormValues, isRequired } from "../../../utils/form";
import { euroNorms, MakeModel, makeModels } from "../UserVehiclesCreate/options";

export type UserVehiclesCreateForm = VehicleCreateArgs;
interface Props {
  vehicle?: Vehicle;
  onGoBack: () => void;
  onEditSuccess?: () => void;
}

const getInitState = (vehicle?: Vehicle): UserVehiclesCreateForm => {
  return {
    make: vehicle?.make || "",
    model: vehicle?.model || "",
    license_plate: vehicle?.license_plate || "",
    license_plate_country_code: (vehicle?.license_plate_country_code as CountryCode) || "NL",
    euro_norm: vehicle?.euro_norm || "euro1",
    loading_dimensions_width_cm: vehicle?.loading_dimensions_width_cm || 0,
    loading_dimensions_height_cm: vehicle?.loading_dimensions_height_cm || 0,
    loading_dimensions_length_cm: vehicle?.loading_dimensions_length_cm || 0,
    loading_weight_limit_kg: vehicle?.loading_weight_limit_kg || 0,
    has_tailgate: vehicle?.has_tailgate || false,
    has_pallet_jack: vehicle?.has_pallet_jack || false,
    images: vehicle ? vehicle.images.map((e) => e.download_link) : [],
    color: vehicle?.color || "null",
    data_verified: vehicle?.data_verified || true,
    account: vehicle?.account || "",
  };
};

export const UserVehicleForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const queryClient = useQueryClient();
  const onSuccessUpdate = (): void => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
      queryClient.resetQueries([CacheKey.RETRIEVE_VEHICLE_LIST]);
      return;
    }
    window.location.reload();
  };
  const onSuccesCreate = (): void => history.push(Routes.USER_VEHICLES);
  const createVehicle = useMutation(coreClient.vehicles.create, { onSuccess: onSuccesCreate });
  const updateVehicle = useMutation(coreClient.vehicles.update, { onSuccess: onSuccessUpdate });

  const form = useForm<UserVehiclesCreateForm>({
    initialState: { ...getInitState(props.vehicle), account: auth.user?.account?.["@id"] || "" },
    validators: {
      images: (images) => images.length == 0,
      make: isRequired,
      model: isRequired,
      license_plate: isRequired,
      license_plate_country_code: isRequired,
      loading_dimensions_width_cm: isRequired,
      loading_dimensions_height_cm: isRequired,
      loading_dimensions_length_cm: isRequired,
      loading_weight_limit_kg: isRequired,
    },
  });
  const [imgSrc, setImgSrc] = React.useState(form.data.images.value[0]);
  const createVehicleImage = useMutation(coreClient.vehicles.createImage, {
    onSuccess: (vehicleImg) => {
      // On success callback for vehicle image creation - add IRI to form state so we can
      // submit along with all the rest of the vehicles details.
      form.set({ images: [vehicleImg["@id"]] });
    },
  });
  const { takePicture } = useCamera((dataUrl) => {
    setImgSrc(dataUrl);
    // Success callback for takePicture
    const blob = dataURItoBlob(dataUrl);
    const imageData = new FormData();
    imageData.append("file", blob);
    createVehicleImage.mutate({ imageData });
  });
  const onSubmit = (): void => {
    if (props.vehicle) {
      const updateParams = getFormChangedValues(form);
      updateVehicle.mutate({ id: getIdFromIri(props.vehicle) || "", ...updateParams });
    } else {
      createVehicle.mutate(getFormValues(form.data));
    }
  };

  const hasError = (field: keyof VehicleCreateArgs): boolean | string =>
    form.isFieldDirty(field) && form.getError(field);

  const InputNumberField = (input: { field: keyof VehicleCreateArgs; label: string }): JSX.Element => (
    <Label text={input.label} isRequired={true} error={hasError(input.field)}>
      <InputNumber
        min={0}
        className={cn("w-full")}
        value={form.data[input.field].value as number}
        onChange={(val) => form.set({ [input.field]: parseInt(val) })}
      />
    </Label>
  );

  return (
    <div>
      <Label
        text={`${t((d) => d.vehicles.make)} + ${t((d) => d.vehicles.model)}`}
        isRequired={true}
        error={hasError("make")}
      >
        <Select
          className={cn("capitalize", "w-full")}
          value={JSON.stringify({ make: form.getValue("make"), model: form.getValue("model") })}
          onChange={(makeModel) => {
            const { make, model } = JSON.parse(makeModel) as MakeModel;
            form.set({ make, model });
          }}
        >
          <Options placeholderText="--">
            {makeModels
              .map(({ make, model }, idx) => (
                <option value={JSON.stringify({ make, model })} key={idx}>
                  {make} {model}
                </option>
              ))
              /* Note: mix in "other" for the rare case where our list does not contain the vehicle */
              .concat(
                <option value={JSON.stringify({ make: "other", model: "other" })} key="other">
                  {t((d) => d.search.filter.other)}
                </option>
              )}
          </Options>
        </Select>
      </Label>
      <Spacer h={4} />
      <div>{t((d) => d.vehicles.max_volume_cm)}</div>
      <Spacer h={2} />
      <Grid gap={4} cols={{ default: 3 }}>
        {InputNumberField({ field: "loading_dimensions_length_cm", label: t((d) => d.dims.length) })}
        {InputNumberField({ field: "loading_dimensions_width_cm", label: t((d) => d.dims.width) })}
        {InputNumberField({ field: "loading_dimensions_height_cm", label: t((d) => d.dims.height) })}
      </Grid>
      <Spacer h={4} />
      {InputNumberField({ field: "loading_weight_limit_kg", label: t((d) => d.vehicles.max_capacity_kg) })}
      <Spacer h={4} />
      <Label text={t((d) => d.vehicles.license_plate)} isRequired={true} error={hasError("license_plate")}>
        <div className={cn("flex")}>
          <div className={cn("w-24", "mr-4")}>
            <Select
              className={cn("w-full")}
              value={form.data.license_plate_country_code.value}
              onChange={(value) => form.set({ license_plate_country_code: value as CountryCode })}
            >
              {(["NL", "BE", "DE", "ES", "FR"] as CountryCode[]).map((countryCode) => (
                <option key={countryCode} value={countryCode}>
                  {countryCode}
                </option>
              ))}
            </Select>
          </div>
          <InputText
            className={cn("w-full")}
            value={form.data.license_plate.value}
            onChange={(license_plate) => form.set({ license_plate })}
          />
        </div>
      </Label>
      <Spacer h={4} />
      <Label text={t((d) => d.vehicles.euronorm)} isRequired={true} error={hasError("euro_norm")}>
        <Select
          className={cn("w-full")}
          onChange={(euroNorm) => form.set({ euro_norm: euroNorm as EuroNorm })}
          defaultValue={form.data.euro_norm.value as EuroNorm}
        >
          {euroNorms.map(({ val, text }) => {
            return (
              <option key={val} value={val}>
                {text}
              </option>
            );
          })}
        </Select>
      </Label>
      <Spacer h={6} />
      <Grid gap={4} cols={{ lg: 2 }}>
        <div>
          <Label text={`${t((d) => d.vehicles.tailgate)}?`} position="right">
            <InputCheckbox
              onChange={() => form.set({ has_tailgate: !form.data.has_tailgate.value })}
              defaultChecked={form.data.has_tailgate.value}
            />
          </Label>
          <div className={cn("ml-6")}>
            <Emphasis>{t((d) => d.vehicles.tailgate_desc)}</Emphasis>
          </div>
        </div>
        <div>
          <Label text={`${t((d) => d.vehicles.pallet_jack)}?`} position="right">
            <InputCheckbox
              onChange={() => form.set({ has_pallet_jack: !form.data.has_pallet_jack.value })}
              defaultChecked={form.data.has_pallet_jack.value}
            />
          </Label>
          <div className={cn("ml-6")}>
            <Emphasis>{t((d) => d.vehicles.pallet_jack_desc)}</Emphasis>
          </div>
        </div>
      </Grid>
      <Spacer h={6} />
      <Card type="primary" className={cn("flex", "flex-col")}>
        <Button onClick={() => takePicture()}>{t((d) => d.vehicles.add_picture)}</Button>
        <div className={cn("flex", "mt-4", "justify-around", "items-center")}>
          <div>{t((d) => d.vehicles.add_picture_desc)}</div>
          {imgSrc && <img src={imgSrc} className={cn("py-1")} style={{ maxHeight: "5rem" }} />}
        </div>
      </Card>
      <Spacer h={8} />
      {props.vehicle && form.data.loading_weight_limit_kg.value < props.vehicle.loading_weight_limit_kg && (
        <Message className={cn("mt-4")} type="error">
          {t((d) => d.vehicles.max_capacity.warning)}
        </Message>
      )}
      {updateVehicle.error && (
        <Message className={cn("mt-4")} type="error">
          {(updateVehicle.error as Error)?.message || t((d) => d.form.errors.handle_error)}
        </Message>
      )}
      <Spacer h={8} />
      <Grid cols={{ lg: 2 }} gap={4}>
        <Button icon={<IconArrowLeft />} className={cn("w-full")} buttonType="primary-outline" onClick={props.onGoBack}>
          {props.vehicle ? t((d) => d.actions.go_back) : t((d) => d.vehicles.go_back)}
        </Button>
        <Button
          disabled={form.hasErrors}
          icon={<IconCheck />}
          loading={props.vehicle ? updateVehicle.isLoading : createVehicle.isLoading}
          buttonType="secondary"
          onClick={onSubmit}
        >
          {t((d) => d.actions.submit)}
        </Button>
      </Grid>
    </div>
  );
};
