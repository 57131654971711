import { Button, Card, IconArrowRight } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { TransportJobDetails } from "../../../components";
import {
  OpenTjExtended,
  useAuth,
  useFormatDate,
  useFormatTimeframe,
  useIntersection,
  useTracking,
  useTranslation,
  useViewport,
} from "../../../hooks";
import { formatLocality, formatSecondsToHoursMinutes, isComboJob } from "../../../utils";
import { dtpsHaveDifferentDays } from "../../../utils/dtpsHaveDifferentDays";
import { SortByField } from "../../../store/search/types";

/**
 * In some cases, such as the Preview page, we do not show the nav.
 */
interface Props {
  openTJ: OpenTjExtended;
  index: number;
  totalJobsCount: number;
  filteredJobsCount: number;
  // It is only a string because we need it here for the analytics event
  activeFilter: string;
  sortByField: SortByField;
}

export const SearchJobListItem: React.FC<Props> = ({
  openTJ,
  index,
  totalJobsCount,
  filteredJobsCount,
  activeFilter,
  sortByField,
}) => {
  const { t } = useTranslation();
  const viewport = useViewport();
  const formatDateFull = useFormatDate("date-full");
  const formatTimeframe = useFormatTimeframe();
  const intersectionRef = React.useRef(null);
  const isIntersecting = useIntersection(intersectionRef);
  const tracking = useTracking();
  const auth = useAuth();

  const [isItemShown, setIsItemShown] = React.useState(false);

  React.useEffect(() => {
    if (isItemShown || !isIntersecting) {
      // Bail early if already shown
      return;
    }
    tracking.sendEvent({
      event: "Job funnel",
      // In UTC
      timeStamp: new Date(new Date().toUTCString()).toISOString(),
      action: "impression",
      id: getIdFromIri(openTJ["@id"]) || "",
      listPosition: index + 1,
      listLength: filteredJobsCount,
      score: openTJ.score ? openTJ.score?.value : null,
      isDefaultScore: openTJ?.score ? openTJ.score.isDefaultScore : null,
      totalJobsCount,
      activeFilter,
      sortByField,
      courierId: auth.userId || null,
    });
    setIsItemShown(true);
  }, [isIntersecting, isItemShown]);

  const pickupDtps = openTJ.pickup_available_datetime_periods;
  /**
   * Current DTP gets added to an OpenTJ when the view is grouped by day
   * This is needed in case of multiple DTPs:
   * - The day on the card is the same as the group day where it is listed
   * - We ensure that the time is shown belongs to the DTP of the group day (that can be different as well)
   */
  const pickupDtp = openTJ.currentDtp || pickupDtps[0];
  const deliveryDtps = openTJ.pickup_available_datetime_periods;
  const deliveryDtp = deliveryDtps[0];
  return (
    <Link
      /* On large viewports we show a card that links to the search job preview details page. */
      to={(location) => `${location.pathname}/${getIdFromIri(openTJ)}${viewport.isDesktop ? "/preview" : ""}`}
      className={cn("w-full")}
      ref={intersectionRef}
    >
      <Card
        // NOTE: h-full ensures that the card is the same height as its sibling on the same row in the grid.
        className={cn({ "h-full": viewport.isDesktop })}
      >
        {/* {openTJ["@id"].includes("35f35d8c-771e-4b50-a345-21fde98eb21e") && console.log(openTJ)} */}
        <TransportJobDetails
          from={formatLocality(openTJ.first_stop_address.locality, openTJ.first_stop_address.administrative_area)}
          // In the unlikely case of undefined, null is returned
          pickupPeriod={formatTimeframe({ start: pickupDtp?.period_start || "", end: pickupDtp?.period_end || "" })}
          to={formatLocality(openTJ.last_stop_address.locality, openTJ.last_stop_address.administrative_area)}
          // In the unlikely case of undefined, null is returned
          deliveryPeriod={formatTimeframe({
            start: deliveryDtp?.period_start || "",
            end: deliveryDtp?.period_end || "",
          })}
          distance={openTJ.total_distance_km}
          price={openTJ.total_payout}
          isPrepaid={openTJ.directly_claimable}
          isBundled={openTJ.bundled}
          isComboJob={isComboJob(openTJ)}
          isBusiness={openTJ.business}
          day={pickupDtp && formatDateFull(pickupDtp.period_start)}
          stopCount={openTJ.stop_count}
          rewardTime={formatSecondsToHoursMinutes(openTJ.rewarded_working_time)}
          // Open TJ is only working with inflated values
          maxVolume={openTJ.max_volume_m3}
          // Show volume details rather than every single item when dealing with a bundle in this context.
          items={openTJ.bundled ? [] : [openTJ.largest_item]}
          services={openTJ.all_services}
          hasMultipleAvailableDays={dtpsHaveDifferentDays([
            // We merge both pickup and delivery so we also mark TJs with different pickup and delivery dates as multiple
            ...openTJ.pickup_available_datetime_periods,
            ...openTJ.delivery_available_datetime_periods,
          ])}
          all_stop_details={openTJ.all_stop_details}
        />
        {viewport.isDesktop && (
          <div className={cn("w-full", "flex", "justify-end")}>
            <Button buttonType="secondary" size="sm">
              <span className={cn("inline-flex", "items-center")}>
                <span className={cn("mr-1")}>{t((d) => d.actions.quick_look)}</span>
                <IconArrowRight />
              </span>
            </Button>
          </div>
        )}
      </Card>
    </Link>
  );
};
