import { IconCancel, IconSearch, useKeyPress } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useTranslation } from "../../hooks";

interface Props {
  setSearchFilter(query: string): void;
}

export const SearchJobID: React.FC<Props> = (props) => {
  const enter = useKeyPress("Enter");
  const { t } = useTranslation();
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    if (enter) {
      onSubmit();
    }
  }, [enter]);

  const onSubmit = (): void => {
    props.setSearchFilter(search);
  };
  const clearFilter = (): void => {
    /**
     * Both the states set to empty state because we want to clear the search bar
     * and the search filter when the user closes the search bar
     */
    setSearch("");
    props.setSearchFilter("");
  };

  return (
    <div
      className={cn(
        "flex",
        "items-center",
        "mx-[-20px]",
        "mt-[-1rem]",
        "md:mx-0",
        "my-4",
        "bg-gray-100",
        "transition-all",
        "duration-300",
        "ease-in-out",
        "overflow-hidden",
        "h-[70px]"
      )}
    >
      <IconSearch className={cn("ml-4", "mr-4", "w-[30px]", "h-[30px]", "md:w-6", "md:h-6")} onClick={onSubmit} />
      <input
        className={cn("w-full", "bg-gray-100", "outline-none")}
        placeholder={t((d) => d.search.filter.short_id.placeholder)}
        value={search}
        onChange={(value) => setSearch(value.target.value)}
        onSubmit={onSubmit}
      />
      <IconCancel
        className={cn("mx-6", "w-[30px]", "h-[30px]", "md:w-6", "md:h-6", "text-blue-600", "fill-current")}
        onClick={clearFilter}
      />
    </div>
  );
};
