import { useState, useEffect } from "react";
import { Device, DeviceInfo } from "@capacitor/device";
import { useMutation } from "react-query";

export const useDeviceInfo = (): DeviceInfo | undefined => {
  const [info, setInfo] = useState<DeviceInfo>();

  const deviceInfo = useMutation(Device.getInfo, {
    onSuccess: (data): void => {
      setInfo(data);
    },
  });

  useEffect(() => {
    deviceInfo.mutate();
  }, [Device]);

  return info;
};
